import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type GetNumberOfUnreadAnnouncementsVariables = Types.Exact<{ [key: string]: never; }>;


export type GetNumberOfUnreadAnnouncements = { __typename?: 'Query', getNumberOfUnreadAnnouncements: number };


export const GetNumberOfUnreadAnnouncementsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetNumberOfUnreadAnnouncements"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getNumberOfUnreadAnnouncements"}}]}}]} as unknown as DocumentNode;

/**
 * __useGetNumberOfUnreadAnnouncements__
 *
 * To run a query within a React component, call `useGetNumberOfUnreadAnnouncements` and pass it any options that fit your needs.
 * When your component renders, `useGetNumberOfUnreadAnnouncements` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNumberOfUnreadAnnouncements({
 *   variables: {
 *   },
 * });
 */
export function useGetNumberOfUnreadAnnouncements(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNumberOfUnreadAnnouncements, GetNumberOfUnreadAnnouncementsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNumberOfUnreadAnnouncements, GetNumberOfUnreadAnnouncementsVariables>(GetNumberOfUnreadAnnouncementsDocument, options);
      }
export function useGetNumberOfUnreadAnnouncementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNumberOfUnreadAnnouncements, GetNumberOfUnreadAnnouncementsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNumberOfUnreadAnnouncements, GetNumberOfUnreadAnnouncementsVariables>(GetNumberOfUnreadAnnouncementsDocument, options);
        }
export type GetNumberOfUnreadAnnouncementsHookResult = ReturnType<typeof useGetNumberOfUnreadAnnouncements>;
export type GetNumberOfUnreadAnnouncementsLazyQueryHookResult = ReturnType<typeof useGetNumberOfUnreadAnnouncementsLazyQuery>;
export type GetNumberOfUnreadAnnouncementsQueryResult = Apollo.QueryResult<GetNumberOfUnreadAnnouncements, GetNumberOfUnreadAnnouncementsVariables>;