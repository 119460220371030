import { PersistedDataKeys, useGetDefaultHeaders } from '@lib/core';
import { Loader } from '@lib/react-components';
import { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { authModel } from 'features/Auth';
import { usePortal } from 'features/Portal';
import { useRegistrationModelStore } from 'features/Registration/model';
import { useRootModelPath } from 'features/Root/model';
import { persistedData } from 'lib/helpers/persistedData';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';
import { DebugPaths } from 'pages/WrongPath/DebugPaths';
import { DEFAULT_UNAUTHORIZED_ROUTE } from 'routes/constants';
import { IRoute, RouteGuardReturnType } from 'routes/types';

type UnauthorizedRouteProps = RouteProps & IRoute;

export const RootUnauthorizedRoute = (routeProps: UnauthorizedRouteProps): JSX.Element => {
  const { getPath } = useRootModelPath();
  const { portal } = usePortal();
  const isMobileAppWebView = useIsMobileAppWebView();
  const headers = useGetDefaultHeaders();

  const { registrationStatus } = useRegistrationModelStore();
  const [isSignOutProcessing, setIsSignOutProcessing] = useState(true);
  const accessToken = persistedData.get<string>(PersistedDataKeys.AccessToken);
  const signOut = authModel.useSignOut();

  useEffect(() => {
    if (!registrationStatus && routeProps?.autoSignOut && accessToken) {
      signOut().then(() => setIsSignOutProcessing(false));
    } else {
      setIsSignOutProcessing(false);
    }
  }, []);

  if (
    routeProps?.portalSettingsGuards?.some(
      portalSettingsGuard => portalSettingsGuard(portal) === RouteGuardReturnType.Denied
    )
  ) {
    console.error(
      `Redirecting to Dashboard because of RouteGuardReturnType.Denied. Headers: ${JSON.stringify(headers)}`
    );

    return (
      <Redirect
        to={getPath({
          path: isMobileAppWebView ? DebugPaths.routeGuardDeniedUnauthorized : DEFAULT_UNAUTHORIZED_ROUTE,
        })}
      />
    );
  }

  return (
    <Loader loading={isSignOutProcessing} fullScreen>
      <Route {...routeProps} />
    </Loader>
  );
};
