import { gql } from '@apollo/client';

import { FILE_MODEL_FRAGMENT } from '../file';

export const INFO_THREAD_MESSAGE_FRAGMENT = gql`
  fragment InfoThreadMessageFragment on InfoMessage {
    id
    text
    flags {
      id
      isRead
    }
    attachments {
      id
      file {
        ...FileModelFragment
      }
    }
    createdAt
    sender {
      id
      profileType
      user {
        lastName
        firstName
      }
    }
    sentAfterClose
  }
  ${FILE_MODEL_FRAGMENT}
`;

const INFO_THREAD_FRAGMENT = gql`
  fragment InfoThreadFragment on InfoThread {
    id
    subject
    status
    updatedAt
    createdAt
    isPending
    recipients {
      id
      institutionUser {
        id
        profileType
        nonDoctor {
          id
        }
        institution {
          title
        }
        doctor {
          id
          specializations {
            type
          }
          slug
          sphereOfInterest
          professionalData {
            cvFile {
              id
            }
          }
        }
        user {
          id
          firstName
          lastName
        }
      }
    }
    patientInstitutionUser {
      id
      profileType
      # @deprecated(reason: "Patient instance is cross-portal & shouldn't have portalUser at all")
      portalUser {
        id
      }
      patient {
        id
        phoneNumber
        user {
          id
          gender
          firstName
          lastName
          birthDate
          fiscalCode
          email
          birthAddress {
            fullAddress
          }
          currentResidenceAddress {
            fullAddress
            city
          }
        }
        privacyAcceptance {
          ehrConsensus
        }
      }
    }
  }
`;

export const INFO_THREAD_WITH_MESSAGES_FRAGMENT = gql`
  fragment InfoThreadWithMessagesFragment on InfoThread {
    ...InfoThreadFragment
    messages {
      ...InfoThreadMessageFragment
    }
  }
  ${INFO_THREAD_MESSAGE_FRAGMENT}
  ${INFO_THREAD_FRAGMENT}
`;
