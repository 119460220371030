import { NotificationType, useNotifications } from '@lib/core';
import { FormikProps, useFormik } from 'formik';

import { useSupportRequestApiAddSupportRequest, SupportRequestApiAddSupportRequestVariables } from '../api';

import { useSupportRequestModelModalValidationSchema } from './useSupportRequestModelModalValidationSchema';

import { i18n } from 'i18n';

interface IAddSupportRequestAddSupportRequestModalProps {
  profileId?: string;
  onClose?: VoidFunction;
}

type UseSupportRequestAddSupportRequestModalControlsValues = SupportRequestApiAddSupportRequestVariables & {
  isAuthorizedUser: boolean;
};
interface IUseSupportRequestAddSupportRequestModalControlsReturn
  extends FormikProps<UseSupportRequestAddSupportRequestModalControlsValues> {
  isSupportRequestSent: boolean;
}

type UseSupportRequestAddSupportRequestModalControls = (
  params: IAddSupportRequestAddSupportRequestModalProps
) => IUseSupportRequestAddSupportRequestModalControlsReturn;

export const useSupportRequestAddSupportRequestModalControls: UseSupportRequestAddSupportRequestModalControls = ({
  profileId,
  onClose,
}) => {
  const { showNotification } = useNotifications();
  const supportRequestValidationSchema = useSupportRequestModelModalValidationSchema();
  const [addSupportRequestMutation, { loading: addSupportRequestSubmitting }] = useSupportRequestApiAddSupportRequest();

  const initialValues: UseSupportRequestAddSupportRequestModalControlsValues = {
    isAuthorizedUser: !!profileId,
    message: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  };

  const formikProps = useFormik<UseSupportRequestAddSupportRequestModalControlsValues>({
    initialValues,
    validationSchema: supportRequestValidationSchema,

    onSubmit: async ({ message, firstName, lastName, email, phoneNumber }, { resetForm }): Promise<void> => {
      const variables: SupportRequestApiAddSupportRequestVariables = profileId
        ? {
            message,
            profileId,
          }
        : {
            message,
            firstName,
            lastName,
            email,
            phoneNumber,
          };

      try {
        const { data } = await addSupportRequestMutation({ variables });

        if (data) {
          showNotification(i18n.t('notifications.support.success'), NotificationType.SUCCESS);
        }
      } catch (error) {
        showNotification(i18n.t('notifications.support.error'), NotificationType.FAIL);
      } finally {
        onClose?.();

        resetForm();
      }
    },
  });

  return {
    ...formikProps,
    isSupportRequestSent: addSupportRequestSubmitting,
  };
};
