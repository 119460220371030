import { useDoctorPortalUserToDoCounters, usePatientToDoCounters } from '@lib/features-bll';

import { useUserModelStore } from 'features/Users/model';
import { SidebarItems } from 'lib/helpers/sidebarConfig';

type UseGetCounterForSidebarItemReturn = Readonly<{
  getCounterForSidebarItem: (sidebarItem: SidebarItems) => number | undefined;
}>;

export const useGetCounterForSidebarItem = (): UseGetCounterForSidebarItemReturn => {
  const { isPatient } = useUserModelStore();
  const patientCounters = usePatientToDoCounters({
    skip: !isPatient,
  });
  const doctorCounters = useDoctorPortalUserToDoCounters({
    skip: isPatient,
  });

  const getPatientCounterForSidebarItem = (sidebarItem: SidebarItems): number | undefined => {
    if (patientCounters === undefined || !isPatient) {
      return 0;
    }

    switch (sidebarItem) {
      case SidebarItems.Info:
        return patientCounters.infos;
      case SidebarItems.Surveys:
        return patientCounters.surveys;
      case SidebarItems.Reminders:
        return patientCounters.reminders;
      case SidebarItems.Consultations:
        return patientCounters.totalChats;
      case SidebarItems.OneToOneChats:
        return patientCounters.oneToOneChats;
      case SidebarItems.ClinicChats:
        return patientCounters.clinicChats;

      default:
        return undefined;
    }
  };

  const getDoctorCounterForSidebarItem = (sidebarItem: SidebarItems): number | undefined => {
    if (doctorCounters === undefined || isPatient) {
      return 0;
    }

    switch (sidebarItem) {
      case SidebarItems.Info:
        return doctorCounters.infos;
      case SidebarItems.Surveys:
        return doctorCounters.surveys;
      case SidebarItems.Reminders:
        return doctorCounters.reminders;
      case SidebarItems.Consultations:
        return doctorCounters.totalChats;
      case SidebarItems.OneToOneChats:
        return doctorCounters.oneToOneChats;
      case SidebarItems.ClinicChats:
        return doctorCounters.clinicChats;
      case SidebarItems.Intramed:
        return doctorCounters.intrameds;

      default:
        return undefined;
    }
  };

  return {
    getCounterForSidebarItem: isPatient ? getPatientCounterForSidebarItem : getDoctorCounterForSidebarItem,
  };
};
