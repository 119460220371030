import {
  GlobalSearchDetailsGenericClinicsType,
  globalSearchDetailsModel,
  IUseGlobalSearchDetailsGenericReturn,
  searchApi,
} from 'features/GlobalSearchDetails';

export const useGlobalSearchDetailsGenericClinics = (
  skip?: boolean
): IUseGlobalSearchDetailsGenericReturn<GlobalSearchDetailsGenericClinicsType['globalSearchDetails']> => {
  const { globalSearchVariables, fetchMoreWithPagination } =
    globalSearchDetailsModel.useGlobalSearchDetailsQueryConfiguration<GlobalSearchDetailsGenericClinicsType>();
  const {
    data,
    fetchMore: fetchMoreAffiliations,
    loading,
    previousData,
  } = searchApi.useGlobalSearchDetailsGenericClinics({
    variables: globalSearchVariables,
    fetchPolicy: 'cache-first',
    skip,
  });

  return {
    loading,
    globalSearchDetails: data?.globalSearchDetails || previousData?.globalSearchDetails,
    fetchMore: () =>
      fetchMoreWithPagination(
        pagination =>
          fetchMoreAffiliations({
            variables: { pagination },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => ({
              ...fetchMoreResult,
              globalSearchDetails: {
                genericClinics: {
                  items:
                    previousQueryResult?.globalSearchDetails?.genericClinics?.items?.concat(
                      fetchMoreResult?.globalSearchDetails?.genericClinics?.items ?? []
                    ) ?? [],
                },
              },
            }),
          }),
        data?.globalSearchDetails?.genericClinics?.items?.length
      ),
  };
};
