import { makeVar, useReactiveVar } from '@apollo/client';

export type GlobalSearch = { search: string; source: 'app' | 'web' };
type GlobalSearchStore = {
  globalSearch: GlobalSearch;
};
type GlobalSearchStoreActions = {
  setGlobalSearch: (globalSearch: GlobalSearchStore['globalSearch']) => void;
};
const globalSearchVar = makeVar<GlobalSearchStore['globalSearch']>({ search: '', source: 'web' });

export const setGlobalSearch: GlobalSearchStoreActions['setGlobalSearch'] = globalSearchVar;

export type UseGlobalSearchModelStore = () => GlobalSearchStore & GlobalSearchStoreActions;

export const useGlobalSearchModelStore: UseGlobalSearchModelStore = () => {
  const globalSearch = useReactiveVar(globalSearchVar);

  return { globalSearch, setGlobalSearch };
};
