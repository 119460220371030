import { PersistedDataKeys } from '@lib/core';
import { Loader } from '@lib/react-components';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useLoginModelAuthenticate } from 'features/Login/model';
import { useRootModelPath } from 'features/Root/model';
import { persistedData } from 'lib/helpers/persistedData';
import { DEFAULT_UNAUTHORIZED_ROUTE } from 'routes/constants';
import { ALLOWED_LOGIN_OBJECTS } from 'routes/constants/allowedLoginObjects';
import { getIsLoginAllowed } from 'routes/helpers';

interface IAuthorizationProviderProps {
  children: ReactNode;
}

export const RootAuthorizationProvider = ({ children }: IAuthorizationProviderProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const { search, pathname } = useLocation();
  const { authenticate } = useLoginModelAuthenticate();
  const { goToPath } = useRootModelPath();

  const loadUserInfoIfLoggedIn = async (): Promise<void> => {
    const accessToken = persistedData.get<string>(PersistedDataKeys.AccessToken);
    const isLoginAllowed = getIsLoginAllowed(pathname, ALLOWED_LOGIN_OBJECTS);

    if (accessToken && isLoginAllowed) {
      await authenticate({
        withRedirect: false,
        onSuccess: () => setIsLoading(false),
        onError: () => {
          goToPath({ path: `${DEFAULT_UNAUTHORIZED_ROUTE}${search}` });
          setIsLoading(false);
        },
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadUserInfoIfLoggedIn();
  }, []);

  return (
    <Loader loading={isLoading} fullScreen>
      {children}
    </Loader>
  );
};
