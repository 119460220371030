import { Portals } from '@lib/features-bll';
import qs from 'qs';
import { generatePath, useHistory } from 'react-router-dom';

import { usePortalSlug } from 'features/Portal';

type XORProperties<T, U> = (T & { [K in keyof U]?: never }) | (U & { [K in keyof T]?: never });

type PathParams<Path extends string> = Parameters<typeof generatePath<Path>>['1'];

type SearchObj = Parameters<typeof qs.stringify>['0'];

export type SearchParams = Partial<
  XORProperties<
    {
      search: string;
      addQueryPrefix?: boolean;
    },
    {
      searchObj: SearchObj;
      skipNulls?: boolean;
    }
  >
>;

type UsePathParams<Path extends string> = {
  path: Path;
  pathParams?: PathParams<Path>;
} & SearchParams;

interface IUsePathReturn {
  getPath: <Path extends string>(params: UsePathParams<Path>) => string;
  goToPath: <Path extends string>(params: UsePathParams<Path>) => void;
  makeGoToPath: <Path extends string>(params: UsePathParams<Path>) => VoidFunction;
}

const formatSearch = <Path extends string>(params: Omit<UsePathParams<Path>, 'path'>): string => {
  if ('searchObj' in params)
    return qs.stringify(params.searchObj, { addQueryPrefix: true, skipNulls: !!params.skipNulls });

  if ('search' in params) return (params.addQueryPrefix ? '?' : '') + params.search;

  return '';
};

export const useRootModelPath = (customPortalSlug?: Portals | null): IUsePathReturn => {
  const history = useHistory();
  const portalSlug = usePortalSlug();

  const getPath: IUsePathReturn['getPath'] = params => {
    const slugPart = customPortalSlug === null ? '' : `/${customPortalSlug || portalSlug}`;

    return `${slugPart}${generatePath(params.path, params.pathParams)}${formatSearch(params)}`;
  };

  const goToPath: IUsePathReturn['goToPath'] = params => {
    const destination = getPath(params);

    history.push(destination);
  };

  const makeGoToPath: IUsePathReturn['makeGoToPath'] = params => () => goToPath(params);

  return {
    getPath,
    goToPath,
    makeGoToPath,
  };
};
