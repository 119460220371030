import { GlobalSearchDetailsTotalCountsType, searchApi } from '../api';

import { useGlobalSearchDetailsQueryConfiguration } from './useGlobalSearchDetailsQueryConfiguration';

interface IUseGlobalSearchDetailsTotalCountsReturn {
  globalSearchDetails?: GlobalSearchDetailsTotalCountsType['globalSearchDetails'];
  loading: boolean;
  called: boolean;
}

export const useGlobalSearchDetailsTotalCounts = (): IUseGlobalSearchDetailsTotalCountsReturn => {
  const { globalSearchVariables } = useGlobalSearchDetailsQueryConfiguration();
  const { data, previousData, loading, called } = searchApi.useGlobalSearchDetailsTotalCounts({
    variables: globalSearchVariables,
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    called,
    globalSearchDetails: data?.globalSearchDetails || previousData?.globalSearchDetails,
  };
};
