import { FlexBox } from '@lib/react-components';
import { Typography } from '@mui/material';

import { globalSearchDetailsConfig, GlobalSearchDetailsTabType } from '../config';

import {
  GlobalSearchDetails,
  globalSearchDetailsModel,
  useGlobalSearchDetailsTabsConfiguration,
} from 'features/GlobalSearchDetails';

interface IGlobalSearchDetailsTabLabelProps {
  tabType: GlobalSearchDetailsTabType;
}

export const GlobalSearchDetailsTabLabel = ({ tabType }: IGlobalSearchDetailsTabLabelProps): JSX.Element => {
  const { activeTab } = useGlobalSearchDetailsTabsConfiguration();
  const { getSearchListTabIconFill } = globalSearchDetailsModel.useGetSearchListTabIconFill();

  const fill = getSearchListTabIconFill(activeTab === tabType);

  return (
    <FlexBox gap="12px">
      <GlobalSearchDetails.TabIcon tabType={tabType} fill={fill} />
      <Typography variant="h8">{globalSearchDetailsConfig.GLOBAL_SEARCH_DETAILS_TAB_LABEL[tabType]}</Typography>
    </FlexBox>
  );
};
