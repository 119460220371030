import { ModalSize, Modal } from '@lib/react-components';
import { Stack } from '@mui/material';

import { AudioInputList, AudioOutputList } from './DeviceSelector';

interface ICallOptionsProps {
  onCancel: VoidFunction;
}

export const CallOptions = ({ onCancel }: ICallOptionsProps): JSX.Element => (
  <Modal size={ModalSize.SM} onCancel={onCancel}>
    <Stack gap="16px">
      <AudioInputList />
      <AudioOutputList />
    </Stack>
  </Modal>
);
