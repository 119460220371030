import { GlobalSearchDetailsClinicItems, IGlobalSearchDetailsClinicTabCard } from './types';

import { globalSearchDetailsModel } from 'features/GlobalSearchDetails';
import { canPatientCreateRequestForService } from 'features/Service/config/filterServices';

export const getClinicTabData = (
  items?: GlobalSearchDetailsClinicItems
): IGlobalSearchDetailsClinicTabCard[] | undefined =>
  items?.map(({ members, extendedServices, ...clinicData }) => ({
    ...clinicData,
    members: members.map(({ id, user }) => ({
      portalUserId: id,
      user,
    })),
    allowsOnDemandSchedule: extendedServices.some(({ allowsOnDemandSchedule }) => allowsOnDemandSchedule),
    earliestAvailabilityDate: globalSearchDetailsModel.getEarliestAvailabilityDate(extendedServices) || null,
    services: extendedServices.filter(service => canPatientCreateRequestForService(service)),
  }));
