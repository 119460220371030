import { FlexBox, PreviewValue } from '@lib/react-components';
import { Button } from '@mui/material';

import { i18n } from 'i18n';
import { getFormatDate } from 'lib/helpers/date';

interface MainDataSectionProps {
  startDate: string;
  endDate: string;
  onAcceptClick: () => void;
  onRefuseClick: () => void;
}

export const MainDataSection = ({
  startDate,
  endDate,
  onAcceptClick,
  onRefuseClick,
}: MainDataSectionProps): JSX.Element => (
  <FlexBox sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', mobile: 'row' } }}>
    <FlexBox sx={{ gap: { xs: '100px', mobile: '24px' }, padding: { xs: '0 0 20px 0', mobile: '0 0 0 24px' } }}>
      <PreviewValue
        label={i18n.t('features.affiliations.activationStepper.affiliationsStep.startDate')}
        value={getFormatDate(startDate)}
      />
      <PreviewValue
        label={i18n.t('features.affiliations.activationStepper.affiliationsStep.endDate')}
        value={getFormatDate(endDate)}
      />
    </FlexBox>
    <FlexBox sx={{ flexDirection: { xs: 'column', mobile: 'row' }, gap: { xs: '12px', mobile: '24px' } }}>
      <Button
        onClick={onAcceptClick}
        size="small"
        sx={{
          minWidth: 130,
          minHeight: 36,
        }}
      >
        {i18n.t('features.affiliations.activationStepper.affiliationsStep.accept')}
      </Button>
      <Button
        sx={{
          minWidth: 130,
          minHeight: 36,
        }}
        size="small"
        variant="secondary"
        onClick={onRefuseClick}
      >
        {i18n.t('features.affiliations.activationStepper.affiliationsStep.refuse')}
      </Button>
    </FlexBox>
  </FlexBox>
);
