import { globalSearchDetailsModel, useGlobalSearchDetailsTabsConfiguration } from 'features/GlobalSearchDetails';

export const useGlobalSearchDetailsListLoadings = (): boolean => {
  const { isPrivateDoctorActiveTab, isClinicActiveTab, isAffiliatedDoctorActiveTab, isFreeClinicActiveTab } =
    useGlobalSearchDetailsTabsConfiguration();

  const { loading: doctorsLoading } = globalSearchDetailsModel.useGlobalSearchDetailsDoctors(!isPrivateDoctorActiveTab);
  const { loading: affiliationsLoading } = globalSearchDetailsModel.useGlobalSearchDetailsAffiliations(
    !isAffiliatedDoctorActiveTab
  );
  const { loading: genericClinicsLoading } = globalSearchDetailsModel.useGlobalSearchDetailsGenericClinics(
    !isClinicActiveTab
  );
  const { loading: freeClinicsLoading } = globalSearchDetailsModel.useGlobalSearchDetailsFreeClinics(
    !isFreeClinicActiveTab
  );

  return doctorsLoading || affiliationsLoading || genericClinicsLoading || freeClinicsLoading;
};
