import {
  BREAK_POINTS,
  defaultTransition,
  fontWeight,
  leftNavigationCollapsedWidth,
  leftNavigationWidth,
} from '@lib/react-components';
import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

import { LEFT_NAVIGATION_MOBILE_WIDTH } from 'lib/constants/scroll';

export const useStyles = makeStyles()(theme => ({
  drawer: {
    width: leftNavigationWidth,
    flexShrink: 0,
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      width: LEFT_NAVIGATION_MOBILE_WIDTH,
    },
    '& .MuiListItemButton-root': {
      color: theme.palette.generic.dark,
      transition: defaultTransition,
      '& svg': {
        fontSize: 26,
        fill: theme.palette.generic.dark,
        stroke: 'none',
      },
      '&:hover': {
        color: theme.palette.black.main,
        '& svg': {
          fill: theme.palette.black.main,
        },
        '& .MuiTypography-root': {
          '&.MuiListItemText-primary': {
            fontWeight: fontWeight.regular,
          },
        },
      },
      '&.Mui-selected': {
        color: theme.palette.black.main,
        backgroundColor: theme.palette.background.default,
        '& svg': {
          fill: theme.palette.black.main,
        },
        '&:hover .MuiTypography-root.MuiListItemText-primary': {
          fontWeight: fontWeight.regular,
        },
      },
    },
  },

  drawerCollapsed: {
    '&.MuiDrawer-paper': {
      [theme.breakpoints.up('sm')]: {
        width: leftNavigationCollapsedWidth,
      },
    },
  },

  toolbar: theme.mixins.toolbar as CSSObject,

  drawerPaper: {
    width: leftNavigationWidth,
    boxShadow: '0 5px 20px rgba(109, 104, 139, .2)',
    justifyContent: 'space-between',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.generic.dark,
    },
    transition: defaultTransition,
    overflowY: 'initial',
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      width: LEFT_NAVIGATION_MOBILE_WIDTH,
      display: 'flex',
    },
  },

  fullScreen: {
    marginLeft: -200,
  },
}));
