import { BREAK_POINTS, defaultBorderRadius, useBreakpoints } from '@lib/react-components';
import { Box, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';

import { GlobalSearchDetails } from 'features/GlobalSearchDetails';

interface IGlobalSearchDetailsCardWrapperProps {
  objectContent: ReactNode;
  servicesContent: ReactNode;
  onCardClickHandler: VoidFunction;
}

export const GlobalSearchDetailsCardWrapper = ({
  objectContent,
  servicesContent,
  onCardClickHandler,
}: IGlobalSearchDetailsCardWrapperProps): JSX.Element => {
  const { palette } = useTheme();
  const { isDesktopView } = useBreakpoints();
  const isDesktopLgUp = useMediaQuery<Theme>(theme => theme.breakpoints.up(BREAK_POINTS.LARGE));

  const handleCardClick: MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();

    onCardClickHandler();
  };

  return (
    <Box
      sx={{
        border: `1px solid ${palette.lines.main}`,
        borderRadius: `${defaultBorderRadius}px`,
      }}
      onClick={handleCardClick}
    >
      <Stack>
        <Box
          sx={{
            ...(isDesktopView && {
              width: '100%',
              display: 'grid',
              columnGap: '40px',
              gridTemplateColumns: 'repeat(auto-fit, minmax(30%, 210px) minmax(calc(70% - 250px), calc(100% - 250px)))',
            }),
            padding: isDesktopView ? '16px 24px' : '16px 16px 0',
          }}
        >
          <Box sx={{ ...(!isDesktopView && { textAlign: 'center' }) }}>{objectContent}</Box>
          <Box
            sx={{
              ...(servicesContent &&
                !isDesktopView && {
                  marginTop: '24px',
                }),
              display: 'grid',
              rowGap: `${isDesktopView ? 6 : 4}px`,
              gridAutoRows: 'max-content',
              ...(isDesktopLgUp && {
                gridTemplateColumns: 'repeat(auto-fit, 50% 50%)',
                columnGap: '6px',
              }),
            }}
          >
            {servicesContent}
          </Box>
        </Box>
        <GlobalSearchDetails.CardProfileLink onProfileLinkClickHandler={onCardClickHandler} noDetailsView />
      </Stack>
    </Box>
  );
};
