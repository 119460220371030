import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type RootApiSubmitVerifyEmailVariables = Types.Exact<{
  verificationCode: Types.Scalars['String']['input'];
}>;


export type RootApiSubmitVerifyEmail = { __typename?: 'Mutation', submitVerifyEmail: boolean };


export const RootApiSubmitVerifyEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RootApiSubmitVerifyEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"verificationCode"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"submitVerifyEmail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"verificationCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"verificationCode"}}}]}]}}]} as unknown as DocumentNode;
export type RootApiSubmitVerifyEmailMutationFn = Apollo.MutationFunction<RootApiSubmitVerifyEmail, RootApiSubmitVerifyEmailVariables>;

/**
 * __useRootApiSubmitVerifyEmail__
 *
 * To run a mutation, you first call `useRootApiSubmitVerifyEmail` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRootApiSubmitVerifyEmail` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rootApiSubmitVerifyEmail, { data, loading, error }] = useRootApiSubmitVerifyEmail({
 *   variables: {
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useRootApiSubmitVerifyEmail(baseOptions?: ApolloReactHooks.MutationHookOptions<RootApiSubmitVerifyEmail, RootApiSubmitVerifyEmailVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RootApiSubmitVerifyEmail, RootApiSubmitVerifyEmailVariables>(RootApiSubmitVerifyEmailDocument, options);
      }
export type RootApiSubmitVerifyEmailHookResult = ReturnType<typeof useRootApiSubmitVerifyEmail>;
export type RootApiSubmitVerifyEmailMutationResult = Apollo.MutationResult<RootApiSubmitVerifyEmail>;
export type RootApiSubmitVerifyEmailMutationOptions = Apollo.BaseMutationOptions<RootApiSubmitVerifyEmail, RootApiSubmitVerifyEmailVariables>;