import { useBreakpoints } from '@lib/react-components';
import { Button } from '@mui/material';
import { MouseEventHandler } from 'react';

interface ILoginOrRegisterBlockingModalButtonPrimaryProps {
  label: string;
  onClick: VoidFunction;
  disabled?: boolean;
}

export const LoginOrRegisterBlockingModalButtonPrimary = ({
  label,
  onClick,
  disabled,
}: ILoginOrRegisterBlockingModalButtonPrimaryProps): JSX.Element => {
  const { isMobileView } = useBreakpoints();

  const onClickHandler: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();

    onClick();
  };

  return (
    <Button
      size="medium"
      onClick={onClickHandler}
      sx={{ margin: isMobileView ? '0 0 12px 0' : '0 12px 0 0' }}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};
