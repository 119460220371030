import { BREAK_POINTS, leftNavigationCollapsedWidth, leftNavigationWidth } from '@lib/react-components';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const PADDING_DESKTOP = 20;
const PADDING_MOBILE = 16;

export const useStyles = makeStyles()((theme: Theme) => ({
  sidebarExpanded: {
    margin: `0 ${PADDING_DESKTOP}px`,
    width: `calc(100vw - ${leftNavigationWidth + PADDING_DESKTOP * 2}px)`,

    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      margin: `0 ${PADDING_MOBILE}px`,
      width: `calc(100vw - ${PADDING_MOBILE * 2}px)`,
    },
  },

  sidebarCollapsed: {
    margin: `0 ${PADDING_DESKTOP}px`,
    width: `calc(100vw - ${leftNavigationCollapsedWidth + PADDING_DESKTOP * 2}px)`,

    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      margin: `0 ${PADDING_MOBILE}px`,
      width: `calc(100vw - ${PADDING_MOBILE * 2}px)`,
    },
  },

  withoutPadding: {
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      '&.MuiBox-root': {
        margin: 0,
        width: '100%',
      },
    },
  },

  wrapper: {
    padding: '30px 35px',
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      '&.MuiBox-root': {
        padding: '20px 16px',
        borderRadius: 0,
      },
    },
  },
}));
