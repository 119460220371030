import { modalWidth } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  controlsWrapper: {
    padding: 8,
    borderRadius: 8,
    maxWidth: modalWidth.extraSmall,
    width: modalWidth.extraSmall,
  },

  draggableBar: {
    height: 12,
  },
}));
