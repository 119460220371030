import { PersistedDataKeys } from '@lib/core';
import { Portals } from '@lib/features-bll';
import { ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import config from '../../../../config.json';
import { usePortalSlug } from '../hooks';

import { persistedData } from 'lib/helpers/persistedData';

interface IPortalValidateProviderProps {
  children: ReactNode;
}

export const PortalValidateProvider = ({ children }: IPortalValidateProviderProps): JSX.Element => {
  const history = useHistory();
  const portalSlug = usePortalSlug();

  useEffect(() => {
    if (
      config.DisabledPortals.split('|').some(path => path === portalSlug) ||
      !Object.values(Portals).includes(portalSlug)
    ) {
      history.push('/');
    } else {
      persistedData.set(PersistedDataKeys.WelmedSlug, portalSlug);
    }
  }, []);

  return <>{children}</>;
};
