import { CheckIcon, MaterialSymbolsIcon } from '@lib/icons';
import { RedBadgeIconWrapper } from '@lib/react-components';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { MobileHeaderContext } from '../../providers';

import { useProfileMenuItems, useStaffMenuItems } from './hooks';
import { IMobileMenuListProps } from './types';

export const MobileMenuList = ({ profileSwitcherRoleItems }: IMobileMenuListProps): JSX.Element => {
  const { palette } = useTheme();
  const history = useHistory();
  const {
    isStaffModalOpened,
    isProfileModalOpened,
    toggleStaffModalHandler,
    toggleProfileModalHandler,
    toggleSupportModalHandler,
  } = useContext(MobileHeaderContext);
  const staffMenuItems = useStaffMenuItems();
  const profileMenuItems = useProfileMenuItems({ toggleSupportModalHandler, profileSwitcherRoleItems });

  const goToLink = useCallback(
    (link?: string) => (): void => {
      if (link) {
        history.push(link);

        if (isStaffModalOpened) {
          toggleStaffModalHandler();
        }

        if (isProfileModalOpened) {
          toggleProfileModalHandler();
        }
      }
    },
    []
  );

  const iconProps = {
    mr: '18px',
    fill: palette.black.main,
    fontSize: 26,
  };

  return (
    <>
      {isStaffModalOpened && (
        <Stack justifyContent="center" mb="10px">
          {staffMenuItems.map(({ title, linkPath, icon: Icon }, index) => (
            <Box
              display="flex"
              sx={{
                alignItems: 'center',
                color: palette.black.main,
                padding: '13px 25px',
                cursor: 'pointer',
              }}
              onClick={goToLink(linkPath)}
              key={`${title}-${index}`}
            >
              {Icon && <MaterialSymbolsIcon icon={<Icon />} {...iconProps} />}
              <Typography variant="body1">{title}</Typography>
            </Box>
          ))}
        </Stack>
      )}
      {isProfileModalOpened && (
        <Stack justifyContent="center" mb="10px">
          {profileMenuItems.map(
            ({ title, linkPath, icon: Icon, selected, isDividerLine, onClickHandler, withAnnouncement }, index) => {
              const showTitleMargin = !selected && !Icon;

              return isDividerLine ? (
                <Divider sx={{ margin: '13px 25px' }} key={`${title}-${index}`} />
              ) : (
                <Box
                  display="flex"
                  sx={{
                    alignItems: 'center',
                    color: palette.black.main,
                    padding: '13px 25px',
                    cursor: 'pointer',
                  }}
                  onClick={onClickHandler || goToLink(linkPath)}
                  key={`${title}-${index}`}
                >
                  {Icon && (
                    <>
                      {withAnnouncement ? (
                        <RedBadgeIconWrapper top={0} right={16} size={8}>
                          <MaterialSymbolsIcon icon={<Icon />} {...iconProps} />
                        </RedBadgeIconWrapper>
                      ) : (
                        <MaterialSymbolsIcon icon={<Icon />} {...iconProps} />
                      )}
                    </>
                  )}
                  {selected && <MaterialSymbolsIcon icon={<CheckIcon fontWeight="300" />} {...iconProps} />}
                  {title && (
                    <Typography variant="body1" sx={{ ...(showTitleMargin && { marginLeft: '43px' }) }}>
                      {title}
                    </Typography>
                  )}
                </Box>
              );
            }
          )}
        </Stack>
      )}
    </>
  );
};
