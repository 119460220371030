import { Dialog } from '@mui/material';

import { useStyles } from './styles';

import { ActivationStepper } from 'features/Affiliations/ActivationStepper';

interface IAffiliationsActivationProps {
  onSubmit: VoidFunction;
}

export const AffiliationsActivation = ({ onSubmit }: IAffiliationsActivationProps): JSX.Element => {
  const { classes: localClasses } = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open
      classes={{ paper: localClasses.root, container: localClasses.container }}
      BackdropProps={{ style: { backgroundColor: 'background.default' } }}
    >
      <ActivationStepper onSubmit={onSubmit} />
    </Dialog>
  );
};
