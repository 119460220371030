import { useCallContext } from '@lib/features-bll';
import { Device as TwilioDevice } from '@twilio/voice-sdk';

import { AudioDeviceList } from '../AudioDeviceList';

import { Maybe } from '__generated__/types';
import { i18n } from 'i18n';

export const AudioInputList = (): Maybe<JSX.Element> => {
  const { device, volumes } = useCallContext();

  const deviceAudio = (device as TwilioDevice | undefined)?.audio;

  if (!deviceAudio) {
    return null;
  }

  const inputDevices = Array.from(deviceAudio.availableInputDevices.values());

  return (
    <AudioDeviceList
      name="input-devices"
      label={i18n.t('features.calls.callOptions.audioInput')}
      defaultLabel={i18n.t('features.calls.callOptions.defaultAudioInput')}
      onChange={async (event): Promise<void> => {
        await deviceAudio.setInputDevice(event.target.value);
      }}
      devices={inputDevices}
      volume={volumes.inputVolume}
      selectedDevice={deviceAudio.inputDevice}
      disabled={inputDevices.length === 2}
    />
  );
};
