import { SERVICE_TYPES_OPTIONS, SPECIALIZATIONS_TRANSLATIONS } from '@lib/features-bll';
import { MobileFiltersModal, Select } from '@lib/react-components';
import { Stack } from '@mui/material';

import { globalSearchDetailsConfig, GlobalSearchDetailsFilterFields } from '../config';
import { globalSearchDetailsModel } from '../model';

import { GlobalSearchDetails } from 'features/GlobalSearchDetails';
import { i18n } from 'i18n';

interface IGlobalSearchDetailsFiltersModalProps {
  closeModal: VoidFunction;
}

export const GlobalSearchDetailsFiltersModal = ({ closeModal }: IGlobalSearchDetailsFiltersModalProps): JSX.Element => {
  const { filters, setFilter, clearFilters } = globalSearchDetailsModel.useGlobalSearchFilters();
  const filterChipItems = globalSearchDetailsModel.useGlobalSearchDetailsFilterChipItems();

  return (
    <MobileFiltersModal
      onClose={closeModal}
      onSubmit={closeModal}
      clearFilters={clearFilters}
      disableClearFilters={!filterChipItems.length}
    >
      <Stack gap="20px">
        <GlobalSearchDetails.FilterFieldMobile fieldName={GlobalSearchDetailsFilterFields.specificSearch}>
          <GlobalSearchDetails.SpecificSearchFilter />
        </GlobalSearchDetails.FilterFieldMobile>
        <GlobalSearchDetails.FilterFieldMobile fieldName={GlobalSearchDetailsFilterFields.specializations}>
          <Select
            name={GlobalSearchDetailsFilterFields.specializations}
            value={filters.specializations}
            items={SPECIALIZATIONS_TRANSLATIONS}
            placeholder={i18n.t('features.search.filters.specializations')}
            onChange={({ target: { name, value } }): void => setFilter(name, value)}
            hideValue
            displayEmpty
            multiple
          />
        </GlobalSearchDetails.FilterFieldMobile>
        <GlobalSearchDetails.FilterFieldMobile fieldName={GlobalSearchDetailsFilterFields.serviceTypes}>
          <Select
            name={GlobalSearchDetailsFilterFields.serviceTypes}
            value={filters.serviceTypes}
            items={SERVICE_TYPES_OPTIONS}
            placeholder={i18n.t('features.search.filters.serviceTypes')}
            onChange={({ target: { name, value } }): void => setFilter(name, value)}
            hideValue
            displayEmpty
            multiple
          />
        </GlobalSearchDetails.FilterFieldMobile>
        <GlobalSearchDetails.FilterFieldMobile fieldName={GlobalSearchDetailsFilterFields.videoVisitAvailability}>
          <Select
            name={GlobalSearchDetailsFilterFields.videoVisitAvailability}
            value={filters.videoVisitAvailability || ''}
            items={globalSearchDetailsConfig.GLOBAL_SEARCH_DETAILS_VIDEO_VISIT_AVAILABILITY_ITEMS}
            placeholder={i18n.t('features.search.filters.videoVisitAvailability')}
            onChange={({ target: { name, value } }): void => setFilter(name, value)}
            hideValue
            displayEmpty
          />
        </GlobalSearchDetails.FilterFieldMobile>
      </Stack>
    </MobileFiltersModal>
  );
};
