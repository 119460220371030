import { useGlobalSearchDetailsTabsConfiguration } from 'features/GlobalSearchDetails/model';

interface IUseGlobalSearchDetailsListConfigurationReturn {
  hasNoListResults: boolean;
  isFetchMoreAvailable: boolean;
}

interface IUseGlobalSearchDetailsListConfigurationProps {
  itemsLength?: number;
  listLoading: boolean;
}

export const useGlobalSearchDetailsListConfiguration = ({
  itemsLength = 0,
  listLoading,
}: IUseGlobalSearchDetailsListConfigurationProps): IUseGlobalSearchDetailsListConfigurationReturn => {
  const { tabsCountsByItems, activeTab } = useGlobalSearchDetailsTabsConfiguration();

  return {
    hasNoListResults: !itemsLength && !listLoading,
    isFetchMoreAvailable:
      !!itemsLength &&
      !listLoading &&
      !!Object.entries(tabsCountsByItems).find(
        ([currentTab, totalCount]) => activeTab === currentTab && itemsLength < totalCount
      ),
  };
};
