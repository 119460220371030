import { AvatarEntity } from '@lib/features-bll';
import { AvatarInfoListOverlapped } from '@lib/features-ui';
import { FlexBox, useBreakpoints } from '@lib/react-components';
import { getUserFullName, getUserInitials } from '@lib/utils';
import { Typography, useTheme } from '@mui/material';

import { UnauthorizedUserChatMessageWriteToModalsSection } from '../../UnauthorizedUserChatMessage/ui';
import { IGlobalSearchDetailsClinicTabCard } from '../model';

import { ChatUiWriteToButton } from 'features/Chat/ui';
import { useClinicModelWriteToClinic, useClinicModelWriteToClinicButtonVisibility } from 'features/Clinics/model';
import { useUserModelStore } from 'features/Users/model';
import { i18n } from 'i18n';

type GlobalSearchDetailsCardClinicAvatarProps = Pick<
  IGlobalSearchDetailsClinicTabCard,
  'name' | 'members' | 'id' | 'defaultPatientWritePolicy' | 'patientWriteButtonVisibilityPolicy'
>;

export const GlobalSearchDetailsCardClinicAvatar = ({
  name,
  members,
  id: clinicId,
  defaultPatientWritePolicy,
  patientWriteButtonVisibilityPolicy,
}: GlobalSearchDetailsCardClinicAvatarProps): JSX.Element => {
  const { typography, palette } = useTheme();
  const { isDesktopView } = useBreakpoints();
  const { activePatient } = useUserModelStore();
  const patientPortalUserId = activePatient?.portalUser.id;

  const {
    handleWriteToClinicButtonClick,
    isWriteToClinicModalOpened,
    isBlockingModalOpened,
    openBlockingModal,
    closeBlockingModal,
    closeWriteToClinicModal,
  } = useClinicModelWriteToClinic({
    clinicId,
    patientPortalUserId,
  });
  const { isWriteToClinicButtonVisible } = useClinicModelWriteToClinicButtonVisibility({
    isListing: true,
    clinicId,
    patientPortalUserId,
    defaultClinicPolicy: defaultPatientWritePolicy,
    patientWriteButtonVisibilityPolicy,
  });

  return (
    <FlexBox
      alignItems="center"
      flexDirection="row"
      gap="12px"
      {...(!isDesktopView && { gap: '8px', mb: '12px', justifyContent: 'center' })}
    >
      <AvatarInfoListOverlapped
        avatars={members.map(({ user, portalUserId }) => ({
          id: portalUserId,
          name: getUserFullName(user),
          initials: getUserInitials(user),
          avatarEntity: AvatarEntity.PORTAL_USER_ID,
        }))}
        maxVisibleBubbles={3}
        sx={{
          '& .MuiAvatar-root': {
            margin: 0,
          },
        }}
      />
      <FlexBox flexDirection="column">
        <Typography variant="bodyLink" color={palette.black.main}>
          {name}
        </Typography>
        {isWriteToClinicButtonVisible && (
          <ChatUiWriteToButton
            label={i18n.t('features.chat.writeToButton.clinic')}
            onClick={handleWriteToClinicButtonClick}
            sx={{
              width: 'fit-content',
              minWidth: 'unset',
              minHeight: 'unset',
              padding: '8px 24px',
              marginTop: '8px',
              '& svg': {
                fontSize: '20px',
              },
              '& .MuiTypography-root': {
                ...typography.button2,
              },
            }}
          />
        )}
      </FlexBox>
      <UnauthorizedUserChatMessageWriteToModalsSection
        isWriteToModalOpened={isWriteToClinicModalOpened}
        closeWriteToModal={closeWriteToClinicModal}
        isBlockingModalOpened={isBlockingModalOpened}
        openBlockingModal={openBlockingModal}
        closeBlockingModal={closeBlockingModal}
        clinicId={clinicId}
      />
    </FlexBox>
  );
};
