import { closestTo } from 'date-fns';

import { ContextAwareServiceModel } from '__generated__/types';

const DATE_NOW = new Date();

type GetEarliestAvailabilityDateProps = Pick<
  ContextAwareServiceModel,
  'id' | 'title' | 'price' | 'baseServiceType' | 'earliestAvailabilityDate' | 'allowsOnDemandSchedule'
>[];

export const getEarliestAvailabilityDate = (extendedServices: GetEarliestAvailabilityDateProps): Date | undefined => {
  const earliestAvailabilityDates = extendedServices.reduce<Date[]>(
    (acc, { earliestAvailabilityDate }) =>
      earliestAvailabilityDate ? [...acc, new Date(earliestAvailabilityDate)] : acc,
    []
  );

  return closestTo(DATE_NOW, earliestAvailabilityDates);
};
