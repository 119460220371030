import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  checkbox: {
    padding: 4,
    marginLeft: -9,
  },

  errorText: {
    color: theme.palette.error.main,
  },
}));
