import { Yup } from '@lib/react-components';

import { i18n } from 'i18n';

export const useSupportRequestModelModalValidationSchema = (): Yup.AnyObjectSchema => {
  const userSupportRequestValidationSchema = Yup.object().shape({
    isAuthorizedUser: Yup.boolean(),
    message: Yup.string().required(),
    firstName: Yup.string().when('isAuthorizedUser', {
      is: false,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    lastName: Yup.string().when('isAuthorizedUser', {
      is: false,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
    email: Yup.string().when('isAuthorizedUser', {
      is: false,
      then: Yup.string().email().required(),
      otherwise: Yup.string().notRequired(),
    }),
    phoneNumber: Yup.string().when('isAuthorizedUser', {
      is: false,
      then: Yup.string().phone(i18n.t('components.forms.validationErrors.fullPhoneNumber')).required(),
      otherwise: Yup.string().notRequired(),
    }),
  });

  return userSupportRequestValidationSchema;
};
