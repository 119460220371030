import { ExpandCircleLeftIcon, MaterialSymbolsIcon } from '@lib/icons';
import { useBreakpoints } from '@lib/react-components';
import { Box, Drawer, Stack, useTheme } from '@mui/material';
import clsx from 'clsx';
import { memo } from 'react';

import { useSideNavigationControls } from './hooks';
import { DrawerContent } from './partials/DrawerContent';
import { useStyles } from './SideNavigationStyles';

import { Maybe } from '__generated__/types';
import { useRootModelUiStore } from 'features/Root/model';

export const RootSideNavigation = memo((): Maybe<JSX.Element> => {
  const theme = useTheme();
  const { classes: localClasses } = useStyles();
  const { isFullScreen } = useRootModelUiStore();

  const sideNavigationControls = useSideNavigationControls();
  const { isMobileView } = useBreakpoints();

  if (!sideNavigationControls) {
    return null;
  }

  const { isMenuOpen, isMenuCollapsed, handleDrawerToggle, portalSidebarItems, handleCollapseToggle } =
    sideNavigationControls;

  return (
    <nav className={localClasses.drawer} aria-label="mailbox folders">
      {isMobileView ? (
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={isMenuOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: localClasses.drawerPaper,
          }}
        >
          <DrawerContent menuListItem={portalSidebarItems} expanded handleDrawerToggle={handleDrawerToggle} />
        </Drawer>
      ) : (
        <Drawer
          classes={{
            paper: clsx(
              localClasses.drawerPaper,
              isMenuCollapsed && localClasses.drawerCollapsed,
              isFullScreen && localClasses.fullScreen
            ),
          }}
          variant="permanent"
          open
        >
          <Stack sx={{ overflowY: 'hidden', height: '100%' }}>
            <DrawerContent menuListItem={portalSidebarItems} expanded={!isMenuCollapsed} />
          </Stack>
          <Box display="flex" justifyContent="flex-end" m={2}>
            <MaterialSymbolsIcon
              icon={<ExpandCircleLeftIcon />}
              fontSize={26}
              cursor="pointer"
              onClick={handleCollapseToggle}
              transform={isMenuCollapsed ? 'rotate(180deg)' : undefined}
            />
          </Box>
        </Drawer>
      )}
    </nav>
  );
});
