import { GLOBAL_SEARCH_DETAILS_TAB_DESCRIPTION } from './globalSearchDetailsTabDescription';
import { GLOBAL_SEARCH_DETAILS_TAB_ORDER_FILTER_ITEMS } from './globalSearchDetailsTabFilter';
import { GLOBAL_SEARCH_DETAILS_TAB_LABEL, GLOBAL_SEARCH_DETAILS_TAB_LABEL_MOBILE } from './globalSearchDetailsTabLabel';
import { GLOBAL_SEARCH_DETAILS_VIDEO_VISIT_AVAILABILITY_ITEMS } from './globalSearchDetailsVideoVisitAvailability';

export { GlobalSearchDetailsTabType } from './types';

export type { IGlobalSearchDetailsUrlParams } from './types';

export { GlobalSearchDetailsFilterFields, GlobalSearchDetailsTabFilterFields } from './types';

export const globalSearchDetailsConfig = {
  GLOBAL_SEARCH_DETAILS_TAB_LABEL,
  GLOBAL_SEARCH_DETAILS_TAB_LABEL_MOBILE,
  GLOBAL_SEARCH_DETAILS_TAB_DESCRIPTION,
  GLOBAL_SEARCH_DETAILS_VIDEO_VISIT_AVAILABILITY_ITEMS,
  GLOBAL_SEARCH_DETAILS_TAB_ORDER_FILTER_ITEMS,
};
