import { makeVar, useReactiveVar } from '@apollo/client';
import { Portals } from '@lib/features-bll';
import { useEffect } from 'react';
import { isAndroid } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import { ProfileType, Maybe } from '__generated__/types';
import iconAppPolimedico from 'assets/images/AppIcon_polimedico.png';
import iconAppPro from 'assets/images/AppIcon_pro.png';
import iconAppWelcomedicine from 'assets/images/AppIcon_welcomedicine.png';
import { usePortalSlug } from 'features/Portal';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';

type InstalledAppConfig = {
  icon: string;
  appName: string;
  url: string;
};
const installedAppConfigVar = makeVar<Maybe<InstalledAppConfig>>(null);

const setInstalledAppConfig = installedAppConfigVar;

export const useRootModelGetInstalledApp = (activeProfileType: Maybe<ProfileType>): Maybe<InstalledAppConfig> => {
  const { location } = useHistory();
  const portalSlug = usePortalSlug();
  const isMobileAppWebView = useIsMobileAppWebView();
  const installedAppConfig = useReactiveVar(installedAppConfigVar);

  useEffect(() => {
    if (activeProfileType && !isMobileAppWebView && isAndroid && 'getInstalledRelatedApps' in navigator) {
      // @ts-expect-error getInstalledRelatedApps is not typed
      navigator.getInstalledRelatedApps().then(relatedApps => {
        relatedApps.forEach((relatedApp: { id: string }) => {
          if (activeProfileType === ProfileType.DOCTOR) {
            setInstalledAppConfig({
              appName: 'WelmedPro',
              icon: iconAppPro,
              url: window.location.href.replace(window.location.protocol, 'welmedpro:'),
            });
          }

          if (activeProfileType === ProfileType.PATIENT) {
            if (portalSlug === Portals.POLIMEDICO && relatedApp.id.includes('polimedico')) {
              setInstalledAppConfig({
                appName: 'Polimedico',
                icon: iconAppPolimedico,
                url: window.location.href.replace(window.location.protocol, 'polimedico:'),
              });
            }

            if (portalSlug === Portals.WELCOMEDICINE && relatedApp.id.includes('welcomedicine')) {
              setInstalledAppConfig({
                appName: 'Welcomedicine',
                icon: iconAppWelcomedicine,
                url: window.location.href.replace(window.location.protocol, 'welcomedicine:'),
              });
            }
          }
        });
      });
    }

    return () => {
      setInstalledAppConfig(null);
    };
  }, [portalSlug, activeProfileType, location]);

  return installedAppConfig;
};
