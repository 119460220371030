import { Yup } from '@lib/react-components';

import { Maybe } from '__generated__/types';

interface IUseUnauthorizedUserChatMessageModelWriteToFormValidationReturn {
  verificationUnauthorizedUserChatMessageModelWriteToFormSchema: Yup.AnyObjectSchema;
}

export const useUnauthorizedUserChatMessageModelWriteToFormValidation =
  (): IUseUnauthorizedUserChatMessageModelWriteToFormValidationReturn => {
    const verificationUnauthorizedUserChatMessageModelWriteToFormSchema = Yup.object().shape(
      {
        email: Yup.string().email().required(),
        text: Yup.string().when('attachments', {
          is: (attachments: Maybe<string[]>) => !attachments || attachments.length === 0,
          then: Yup.string().nullable().required(),
          otherwise: Yup.string().nullable().notRequired(),
        }),
        attachments: Yup.array().when('text', {
          is: (text: Maybe<string>) => !text || text.length === 0,
          then: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string().required(),
                dateOfExecution: Yup.string().required(),
                id: Yup.string().required(),
              })
            )
            .min(1)
            .required(),
          otherwise: Yup.array().nullable().notRequired(),
        }),
      },
      [['text', 'attachments']]
    );

    return {
      verificationUnauthorizedUserChatMessageModelWriteToFormSchema,
    };
  };
