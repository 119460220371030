import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type ChatApiClinicChatVariables = Types.Exact<{
  clinicId: Types.Scalars['String']['input'];
  patientPortalUserId: Types.Scalars['String']['input'];
}>;


export type ChatApiClinicChat = { __typename?: 'Query', clinicChat: { __typename?: 'ClinicChatModel', id: string, featuredRequest?: { __typename?: 'Request', isStatusFinal: boolean } | null } };


export const ChatApiClinicChatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ChatApiClinicChat"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"clinicId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patientPortalUserId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clinicChat"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"clinicId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"clinicId"}}},{"kind":"Argument","name":{"kind":"Name","value":"patientPortalUserId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patientPortalUserId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"featuredRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isStatusFinal"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useChatApiClinicChat__
 *
 * To run a query within a React component, call `useChatApiClinicChat` and pass it any options that fit your needs.
 * When your component renders, `useChatApiClinicChat` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatApiClinicChat({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *      patientPortalUserId: // value for 'patientPortalUserId'
 *   },
 * });
 */
export function useChatApiClinicChat(baseOptions: ApolloReactHooks.QueryHookOptions<ChatApiClinicChat, ChatApiClinicChatVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ChatApiClinicChat, ChatApiClinicChatVariables>(ChatApiClinicChatDocument, options);
      }
export function useChatApiClinicChatLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChatApiClinicChat, ChatApiClinicChatVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ChatApiClinicChat, ChatApiClinicChatVariables>(ChatApiClinicChatDocument, options);
        }
export type ChatApiClinicChatHookResult = ReturnType<typeof useChatApiClinicChat>;
export type ChatApiClinicChatLazyQueryHookResult = ReturnType<typeof useChatApiClinicChatLazyQuery>;
export type ChatApiClinicChatQueryResult = Apollo.QueryResult<ChatApiClinicChat, ChatApiClinicChatVariables>;