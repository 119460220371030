import { createContext } from 'react';

import { IMobileHeaderContext } from './types';

const defaultValue: IMobileHeaderContext = {
  isStaffModalOpened: false,
  isProfileModalOpened: false,
  showSupportModal: false,
  isMobileMenuModalOpened: false,
  toggleStaffModalHandler: (): void => {},
  toggleProfileModalHandler: (): void => {},
  toggleSupportModalHandler: (): void => {},
  onCancel: (): void => {},
};

export const MobileHeaderContext = createContext<IMobileHeaderContext>(defaultValue);
