import { UnauthorizedUserRequestHeader } from './UnauthorizedUserRequestHeader';
import { UnauthorizedUserRequestHeaderActionAvatar } from './UnauthorizedUserRequestHeaderActionAvatar';
import { UnauthorizedUserRequestHeaderAvatar } from './UnauthorizedUserRequestHeaderAvatar';
import { UnauthorizedUserRequestPageWrapper } from './UnauthorizedUserRequestPageWrapper';

export const UnauthorizedUserRequest = {
  PageWrapper: UnauthorizedUserRequestPageWrapper,
  Header: UnauthorizedUserRequestHeader,
  Avatar: UnauthorizedUserRequestHeaderAvatar,
  ActionAvatar: UnauthorizedUserRequestHeaderActionAvatar,
};
