import { useFaqUrl } from '@lib/features-bll';
import { Modal, ModalSize, StatusCard } from '@lib/react-components';
import { Box, Button, Grid, Link as MaterialLink, TextField } from '@mui/material';
import { Trans } from 'react-i18next';

import { useStyles } from './style';

import { Maybe } from '__generated__/types';
import Input from 'components/Input/Input';
import { useSupportRequestAddSupportRequestModalControls } from 'features/SupportRequest/model';
import { useUserModelStore } from 'features/Users/model';
import { i18n } from 'i18n';
import { getFormErrors } from 'lib/hooks/formErrorsHook';

interface ISupportRequestUiAddSupportRequestModalProps {
  profileId?: string;
  onClose?: VoidFunction;
}

export const SupportRequestUiAddSupportRequestModal = ({
  profileId,
  onClose,
}: ISupportRequestUiAddSupportRequestModalProps): Maybe<JSX.Element> => {
  const { classes: localClasses } = useStyles();
  const { activeProfileType } = useUserModelStore();
  const { faqUrl } = useFaqUrl({ activeProfileType, isLoggedUser: true });
  const { values, handleBlur, handleChange, handleSubmit, errors, touched, isValid, isSupportRequestSent } =
    useSupportRequestAddSupportRequestModalControls({ profileId, onClose });
  const getError = getFormErrors(errors, touched);
  const isAuthorized = !!profileId;

  const subtitle = (
    <>
      {i18n.t('components.support.secondTitle')}
      {isAuthorized && !!faqUrl && (
        <>
          <br />
          <Trans
            i18n={i18n}
            i18nKey="components.support.faqText"
            components={{
              a: <MaterialLink href={faqUrl} target="_blank" />,
            }}
          />
        </>
      )}
    </>
  );

  return (
    <Modal
      size={ModalSize.SM}
      title={i18n.t('components.support.title')}
      secondTitle={subtitle}
      onCancel={onClose}
      footer={
        <Button
          disabled={isSupportRequestSent || !isValid}
          onClick={(): void => handleSubmit()}
          className={localClasses.modalSubmit}
        >
          {i18n.t('components.support.button.submit')}
        </Button>
      }
    >
      <Box data-cy="support-modal">
        {!isAuthorized && (
          <>
            <Grid container item xs={12}>
              <Grid className={localClasses.inputWrapper} item xs={12} md={6} justifyContent="flex-start">
                <Input
                  type="text"
                  name="firstName"
                  label={i18n.t('components.support.inputLabels.name')}
                  value={values.firstName}
                  errorText={getError('firstName')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={localClasses.inputFieldWrapper}
                />
              </Grid>
              <Grid item xs={12} md={6} justifyContent="flex-end">
                <Input
                  type="text"
                  name="lastName"
                  label={i18n.t('components.support.inputLabels.surname')}
                  value={values.lastName}
                  errorText={getError('lastName')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={localClasses.inputFieldWrapper}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid className={localClasses.inputWrapper} item xs={12} md={6} justifyContent="flex-start">
                <Input
                  type="text"
                  name="email"
                  label={i18n.t('components.support.inputLabels.email')}
                  value={values.email}
                  errorText={getError('email')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={localClasses.inputFieldWrapper}
                />
              </Grid>
              <Grid item xs={12} md={6} justifyContent="flex-end">
                <Input
                  type="text"
                  name="phoneNumber"
                  label={i18n.t('components.support.inputLabels.phone')}
                  value={values.phoneNumber}
                  errorText={getError('phoneNumber')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={localClasses.inputFieldWrapper}
                />
              </Grid>
            </Grid>
          </>
        )}
        <TextField
          type="text"
          name="message"
          label={i18n.t('components.support.inputLabels.howCanWeHelp')}
          value={values.message}
          helperText={getError('message')}
          error={!!getError('message')}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          multiline
        />
        <StatusCard
          text={
            <>
              {i18n.t('components.support.infoBox.title')}
              <>
                <br />
                <br />
                <Trans
                  i18n={i18n}
                  i18nKey="components.support.infoBox.fullTitle"
                  components={{
                    tel1: (
                      <a href="tel:0282960009" className={localClasses.phoneLink}>
                        0282960009
                      </a>
                    ),
                  }}
                />
              </>
            </>
          }
          classes={{ cardWrapper: localClasses.infoBoxWrapper }}
        />
      </Box>
    </Modal>
  );
};
