import { Portals } from '@lib/features-bll';
import { Redirect, useLocation } from 'react-router-dom';

import { usePortalSlug } from 'features/Portal';

interface IWithoutPortalRouteRedirectProps {
  defaultPortal?: string;
}

/**
 * Component used to handle redirect from legacy public urls and urls without portal
 * /login/portalSlug -> /portalSlug/login
 * Also, if no portal is provided or portal is invalid redirects to welcomedicine routes
 * /login -> /welcomedicine/login
 * /login/nonExistentPortal -> /welcomedicine/login
 */
export const RootWithoutPortalRouteRedirect = ({ defaultPortal }: IWithoutPortalRouteRedirectProps): JSX.Element => {
  const { pathname, search } = useLocation();
  const portalSlug = usePortalSlug();
  const isEligiblePortal = Object.values(Portals).includes(portalSlug);

  const pathnameWithoutSlug = portalSlug ? pathname.substr(0, pathname.indexOf(`/${portalSlug}`)) : pathname;

  return <Redirect to={`/${isEligiblePortal ? portalSlug : defaultPortal}${pathnameWithoutSlug}${search}`} />;
};
