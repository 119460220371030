import { useFilterQueryParams, useSearchQueryParams, UseSearchQueryParamsReturn } from '@lib/react-components';
import { convertStringToBoolean } from '@lib/utils';
import { useMemo } from 'react';

import { GlobalSearchDetailsFilterFields } from '../config';

import { Maybe, ServiceType, Specialization, VideoVisitAvailability } from '__generated__/types';

type GlobalSearchFilters = {
  [GlobalSearchDetailsFilterFields.specificSearch]: string[];
  [GlobalSearchDetailsFilterFields.serviceTypes]: ServiceType[];
  [GlobalSearchDetailsFilterFields.specializations]: Specialization[];
  [GlobalSearchDetailsFilterFields.videoVisitAvailability]: VideoVisitAvailability;
  [GlobalSearchDetailsFilterFields.affiliationIds]: Maybe<string[]>;
  [GlobalSearchDetailsFilterFields.patientCanWriteOneToOneChatMessage]: Maybe<boolean>;
};

interface IUseGlobalSearchFiltersReturn
  extends Pick<ReturnType<typeof useFilterQueryParams>, 'setFilter' | 'clearFilters'>,
    UseSearchQueryParamsReturn {
  filters: GlobalSearchFilters;
}

export const useGlobalSearchFilters = (): IUseGlobalSearchFiltersReturn => {
  const { search, hitType, setSearch } = useSearchQueryParams();
  const {
    filters: {
      specializations,
      serviceTypes,
      specificSearch,
      videoVisitAvailability,
      affiliationIds,
      patientCanWriteOneToOneChatMessage,
    },
    setFilter,
    clearFilters,
  } = useFilterQueryParams<Record<string, Maybe<string>>>({
    [GlobalSearchDetailsFilterFields.specializations]: null,
    [GlobalSearchDetailsFilterFields.serviceTypes]: null,
    [GlobalSearchDetailsFilterFields.specificSearch]: null,
    [GlobalSearchDetailsFilterFields.videoVisitAvailability]: null,
    [GlobalSearchDetailsFilterFields.affiliationIds]: null,
    [GlobalSearchDetailsFilterFields.patientCanWriteOneToOneChatMessage]: null,
  });

  const parsedFilters = useMemo(
    () => ({
      [GlobalSearchDetailsFilterFields.specializations]: (specializations?.split(',') || []) as Specialization[],
      [GlobalSearchDetailsFilterFields.serviceTypes]: (serviceTypes?.split(',') || []) as ServiceType[],
      [GlobalSearchDetailsFilterFields.specificSearch]: (specificSearch?.split(',') || []) as string[],
      [GlobalSearchDetailsFilterFields.videoVisitAvailability]: videoVisitAvailability as VideoVisitAvailability,
      [GlobalSearchDetailsFilterFields.affiliationIds]: (affiliationIds?.split(',') || []) as string[],
      [GlobalSearchDetailsFilterFields.patientCanWriteOneToOneChatMessage]:
        convertStringToBoolean(patientCanWriteOneToOneChatMessage) || null,
    }),
    [
      specializations,
      serviceTypes,
      specificSearch,
      videoVisitAvailability,
      affiliationIds,
      patientCanWriteOneToOneChatMessage,
    ]
  );

  return {
    search,
    hitType,
    setSearch,
    filters: parsedFilters,
    setFilter,
    clearFilters,
  };
};
