import { createPersistedDataHelpers } from '@lib/core';
import { Portals } from '@lib/features-bll';

import { getOptionsFromEnv } from 'lib/helpers/env';

export const persistedData = createPersistedDataHelpers({
  secure: !getOptionsFromEnv().isDevelopMode,
  enablePortalScoped: true,
  portalSlugGetter: () => {
    const splitPathname = window.location.pathname.split('/');
    const firstSegment = splitPathname[1];

    return splitPathname.length > 2 && Object.values(Portals).includes(firstSegment as Portals) ? firstSegment : null;
  },
});
