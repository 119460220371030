import { FlexBox, zIndexValues } from '@lib/react-components';
import { Maybe } from '@lib/utils';
import { Typography, Button, useTheme, Box } from '@mui/material';

import { useRootModelGetInstalledApp } from '../model';

import { ProfileType } from '__generated__/types';
import { i18n } from 'i18n';

export const RootUiOpenNativeAppBar = ({
  activeProfileType,
}: {
  activeProfileType: Maybe<ProfileType>;
}): Maybe<JSX.Element> => {
  const theme = useTheme();

  const appConfig = useRootModelGetInstalledApp(activeProfileType);

  if (!appConfig) {
    return null;
  }

  const { icon, appName, url } = appConfig;

  return (
    <FlexBox
      position="fixed"
      top={0}
      left={0}
      width="100%"
      zIndex={zIndexValues.fullScreen}
      bgcolor={theme.palette.white.main}
      flexDirection="row"
      gap="8px"
      padding="8px"
      boxShadow="none"
    >
      <FlexBox>
        <Box
          sx={{
            objectFit: 'contain',
            width: '40px',
            height: '40px',
          }}
          component="img"
          src={icon}
        />
      </FlexBox>
      <FlexBox flexDirection="column" flexGrow={1}>
        <Typography variant="h8">{appName}</Typography>
        <Typography variant="body3">{i18n.t('general.openInApp', { appName })}</Typography>
      </FlexBox>
      <FlexBox alignItems="center">
        <Button sx={{ minHeight: 'auto', minWidth: 'auto', height: '32px', borderRadius: '16px' }} href={url}>
          <Typography variant="button2">{i18n.t('general.buttons.open')}</Typography>
        </Button>
      </FlexBox>
    </FlexBox>
  );
};
