import { ROUTE_PATHS, useGetDefaultHeaders } from '@lib/core';
import { useModalState, useQueryParams } from '@lib/react-components';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { RootUiOpenNativeAppBar } from './RootUiOpenNativeAppBar';

import { useAssistedSearchModelDirectMessageFromAssistedSearchRedirect } from 'features/AssistedSearch/model';
import { usePortal } from 'features/Portal';
import { useRootModelPath, useRootModelRedirectByState } from 'features/Root/model';
import { useUnauthorizedUserChatMessageModelDirectMessageFromDraftRedirect } from 'features/UnauthorizedUserChatMessage/model';
import { LoginOrRegisterBlockingModalsControl } from 'features/Users/components';
import { useUserModelStore } from 'features/Users/model';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';
import { DebugPaths } from 'pages/WrongPath/DebugPaths';
import { DEFAULT_AUTHORIZED_ROUTE, DEFAULT_UNAUTHORIZED_ROUTE } from 'routes/constants';
import { IRoute, RouteGuardReturnType } from 'routes/types';

type AuthorizedRouteProps = RouteProps & IRoute;

export const RootAuthorizedRoute = (routeProps: AuthorizedRouteProps): JSX.Element => {
  const { getPath } = useRootModelPath();
  const headers = useGetDefaultHeaders();

  const { portal } = usePortal();
  const isMobileAppWebView = useIsMobileAppWebView();

  const { activeProfile } = useUserModelStore();
  const { redirectTo } = useQueryParams<{ redirectTo: string }>();
  const redirectByState = useRootModelRedirectByState();
  const [isBlockingModalOpened, , closeBlockingModal] = useModalState(true);
  const directMessageFromDraftRedirectRoute = useUnauthorizedUserChatMessageModelDirectMessageFromDraftRedirect();
  const directMessageFromAssistedSearchRedirectRoute = useAssistedSearchModelDirectMessageFromAssistedSearchRedirect();

  if (!activeProfile) {
    return (
      <Redirect
        to={getPath({
          path: DEFAULT_UNAUTHORIZED_ROUTE,
          searchObj: { redirectTo: activeProfile === undefined ? undefined : routeProps?.location?.pathname },
          skipNulls: true,
        })}
      />
    );
  }

  if (routeProps?.authorizedRoles) {
    const { authorizedRoles } = routeProps;
    const matchProfileType =
      activeProfile?.profileType && authorizedRoles && authorizedRoles.includes(activeProfile.profileType);

    if (routeProps?.forbiddenAction) {
      const { forbiddenAction } = routeProps;

      if (!matchProfileType && forbiddenAction) {
        return (
          <>
            <Route {...routeProps} />
            {isBlockingModalOpened && (
              <LoginOrRegisterBlockingModalsControl handleCloseModalClick={closeBlockingModal} isForbiddenAction />
            )}
          </>
        );
      }
    }

    if (!matchProfileType) {
      console.error(`Redirecting to Dashboard because of !matchProfileType. Headers: ${JSON.stringify(headers)}`);

      return (
        <Redirect
          to={getPath({ path: isMobileAppWebView ? DebugPaths.notMatchProfileType : DEFAULT_AUTHORIZED_ROUTE })}
        />
      );
    }
  }

  if (activeProfile?.hasPostRegister) {
    return (
      <>
        <Route {...routeProps} />
        <Redirect to={getPath({ path: ROUTE_PATHS.settings, search: routeProps?.location?.search ?? '' })} />
      </>
    );
  }

  if (
    routeProps?.portalSettingsGuards?.some(
      portalSettingsGuard => portalSettingsGuard(portal) === RouteGuardReturnType.Denied
    )
  ) {
    console.error(
      `Redirecting to Dashboard because of RouteGuardReturnType.Denied. Headers: ${JSON.stringify(headers)}`
    );

    return (
      <Redirect to={getPath({ path: isMobileAppWebView ? DebugPaths.routeGuardDenied : DEFAULT_AUTHORIZED_ROUTE })} />
    );
  }

  if (directMessageFromDraftRedirectRoute) {
    return <Redirect to={directMessageFromDraftRedirectRoute} />;
  }

  if (directMessageFromAssistedSearchRedirectRoute) {
    return <Redirect to={directMessageFromAssistedSearchRedirectRoute} />;
  }

  if (redirectTo) {
    return <Redirect to={redirectTo as string} />;
  }

  if (redirectByState) {
    return <Redirect to={redirectByState} />;
  }

  return (
    <>
      <RootUiOpenNativeAppBar activeProfileType={activeProfile.profileType} />
      <Route {...routeProps} />
    </>
  );
};
