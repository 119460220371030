import { ExtraServiceType } from '@lib/react-components';

import { IGlobalSearchDetailsDoctorTabCard } from '../model';

import { GlobalSearchDetails } from 'features/GlobalSearchDetails';
import { i18n } from 'i18n';

interface IGlobalSearchDetailsCardDoctorProps extends IGlobalSearchDetailsDoctorTabCard {
  onDoctorCardClickHandler: (doctorSlug: string) => void;
}

export const GlobalSearchDetailsCardDoctor = ({
  user,
  doctor,
  services,
  portalUserId,
  onDoctorCardClickHandler,
}: IGlobalSearchDetailsCardDoctorProps): JSX.Element => (
  <GlobalSearchDetails.CardWrapper
    objectContent={
      <GlobalSearchDetails.CardObject
        specializations={doctor.specializations}
        allowsOnDemandSchedule={doctor.allowsOnDemandSchedule}
        earliestAvailabilityDate={doctor.earliestAvailabilityDate}
      >
        <GlobalSearchDetails.CardDoctorAvatar
          user={user}
          portalUserId={portalUserId}
          defaultDoctorPolicy={doctor.defaultPatientWritePolicy}
          patientWriteButtonVisibilityPolicy={doctor.patientWriteButtonVisibilityPolicy}
        />
      </GlobalSearchDetails.CardObject>
    }
    servicesContent={
      services.length > 0
        ? services.map(props => <GlobalSearchDetails.CardServiceChip key={props.id} {...props} />)
        : doctor.acceptsInfoRequest && (
            <GlobalSearchDetails.CardServiceChip
              title={i18n.t('general.labels.infoRequest')}
              baseServiceType={ExtraServiceType.INFO}
            />
          )
    }
    onCardClickHandler={(): void => onDoctorCardClickHandler(doctor.slug)}
  />
);
