import { ProfileType } from '__generated__/types';
import { SidebarItems } from 'lib/helpers/sidebarConfig';

export const SIDEBAR_ITEMS_BASE_CONFIGURATION: Record<ProfileType, SidebarItems[][]> = {
  [ProfileType.NON_DOCTOR]: [
    [SidebarItems.Dashboard],
    [SidebarItems.Patients, SidebarItems.Info, SidebarItems.Clinics, SidebarItems.Surveys],
    [SidebarItems.PortalDocuments, SidebarItems.Intramed, SidebarItems.CarTNetwork, SidebarItems.Equipes],
    [SidebarItems.Invites, SidebarItems.Reminders],
  ],

  [ProfileType.DOCTOR]: [
    [SidebarItems.Dashboard],
    [SidebarItems.Patients, SidebarItems.Info, SidebarItems.Consultations, SidebarItems.Clinics, SidebarItems.Surveys],
    [SidebarItems.PortalDocuments, SidebarItems.Intramed, SidebarItems.CarTNetwork, SidebarItems.Equipes],
    [SidebarItems.Invites, SidebarItems.Calendar, SidebarItems.Reminders, SidebarItems.Accounting],
  ],
  [ProfileType.PATIENT]: [
    [SidebarItems.Dashboard],
    [SidebarItems.Info, SidebarItems.Consultations, SidebarItems.Surveys],
    [SidebarItems.HealthRecord, SidebarItems.Care],
    [SidebarItems.Reminders, SidebarItems.Invoices],
  ],
};
