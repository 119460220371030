import { ROUTE_PATHS } from '@lib/core';
import { WebToNativeActions } from '@lib/features-bll';
import { useQueryParams } from '@lib/react-components';
import { useEffect } from 'react';

import { useRootModelPath } from 'features/Root/model';
import { useUnauthorizedUserChatMessageApiCreateDirectMessageFromDraft } from 'features/UnauthorizedUserChatMessage/api';
import { UnauthorizedUserChatMessageUrlKeys } from 'features/UnauthorizedUserChatMessage/config';
import { useUserModelStore } from 'features/Users/model';
import { createNativeAction, platformBasedAction } from 'lib/helpers/platform';

export const useUnauthorizedUserChatMessageModelDirectMessageFromDraftRedirect = (): string | undefined => {
  const { isPatient } = useUserModelStore();
  const { getPath, makeGoToPath } = useRootModelPath();
  const { draftDirectMessageId } = useQueryParams<{
    [UnauthorizedUserChatMessageUrlKeys.draftDirectMessageId]: string;
  }>();

  const [createDirectMessageFromDraft, { data }] = useUnauthorizedUserChatMessageApiCreateDirectMessageFromDraft({
    variables: { draftDirectMessageId },
    onCompleted: ({ createDirectMessageFromDraft: { id } }) =>
      platformBasedAction({
        webAction: makeGoToPath({ path: ROUTE_PATHS.chatOneToOne, pathParams: { chatId: id } }),
        nativeAction: createNativeAction(WebToNativeActions.openOneToOneChat, { id }),
      }),
  });

  useEffect(() => {
    if (isPatient && draftDirectMessageId) createDirectMessageFromDraft();
  }, [isPatient, draftDirectMessageId]);

  return data?.createDirectMessageFromDraft.id
    ? getPath({ path: ROUTE_PATHS.chatOneToOne, pathParams: { chatId: data.createDirectMessageFromDraft.id } })
    : undefined;
};
