import { NotificationType, PersistedDataKeys, useNotifications } from '@lib/core';
import { PROFILE_TYPE_TRANSLATIONS } from '@lib/features-bll';

import { useUserModelRedirectToParam } from './useUserModelRedirectToParam';
import { useUserModelStore } from './useUserModelStore';

import { ProfileType } from '__generated__/types';
import { useRootModelPath } from 'features/Root/model';
import { client } from 'graphql/apollo';
import { i18n } from 'i18n';
import { persistedData } from 'lib/helpers/persistedData';
import { DEFAULT_AUTHORIZED_ROUTE } from 'routes/constants';

interface IUseUserModelSwitchActiveProfileReturnProps {
  profileType: ProfileType;
  keepCurrentPage?: boolean;
  skipHistoryPush?: boolean;
}

type UseUserModelSwitchActiveProfileReturn = (props: IUseUserModelSwitchActiveProfileReturnProps) => void;

export const useUserModelSwitchActiveProfile = (): UseUserModelSwitchActiveProfileReturn => {
  const { goToPath } = useRootModelPath();
  const redirectParam = useUserModelRedirectToParam();
  const { showNotification } = useNotifications();
  const { setActiveProfileType } = useUserModelStore();

  return ({ profileType, keepCurrentPage, skipHistoryPush }) => {
    persistedData.removeMultiple([
      PersistedDataKeys.InfoOrderBy,
      PersistedDataKeys.OneToOneChatOrderBy,
      PersistedDataKeys.RequestChatOrderBy,
      PersistedDataKeys.ClinicChatOrderBy,
      PersistedDataKeys.IntramedOrderBy,
    ]);

    setActiveProfileType(profileType);

    if (!skipHistoryPush) {
      goToPath({ path: keepCurrentPage ? redirectParam : DEFAULT_AUTHORIZED_ROUTE });
    }

    showNotification(
      i18n.t('general.notifications.profileSwitch', { profileType: PROFILE_TYPE_TRANSLATIONS[profileType] }),
      NotificationType.SUCCESS
    );

    setTimeout(client.reFetchObservableQueries, 100);
  };
};
