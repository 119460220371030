import { ServiceCard } from '@lib/features-ui';
import { Stack } from '@mui/material';

import { ServiceModel } from '__generated__/types';
import { mapAffiliationServiceToAffiliationServiceCardProps } from 'features/Affiliations/helpers/services';

interface IServicesSectionProps {
  services: ServiceModel[];
}

export const ServicesSection = ({ services }: IServicesSectionProps): JSX.Element => (
  <Stack
    sx={{
      marginTop: { xs: '32px', mobile: '24px' },
      maxHeight: { xs: 'unset', mobile: '60vh' },
      overflowY: 'auto',
      gap: '12px',
    }}
  >
    {services.map(service => (
      <ServiceCard
        key={`${service.id}`}
        sx={{ padding: { xs: '16px', mobile: '24px' } }}
        {...mapAffiliationServiceToAffiliationServiceCardProps(service)}
      />
    ))}
  </Stack>
);
