import { convertOptionsToObject, createFilterChipItemsFromFilters, IFilterChipItem } from '@lib/react-components';
import { useMemo } from 'react';

import { GlobalSearchDetailsFilterFields } from '../config';

import { globalSearchDetailsModel } from 'features/GlobalSearchDetails';

export const useGlobalSearchDetailsAffiliationIdsChipItems = (): IFilterChipItem[] => {
  const {
    filters: { affiliationIds },
  } = globalSearchDetailsModel.useGlobalSearchFilters();
  const { affiliationIdsFilterItems } = globalSearchDetailsModel.useGlobalSearchDetailsAffiliationIdsFilterData();

  return useMemo(
    () =>
      createFilterChipItemsFromFilters(
        { [GlobalSearchDetailsFilterFields.affiliationIds]: affiliationIds },
        {
          ...convertOptionsToObject(affiliationIdsFilterItems),
        }
      ),
    [affiliationIds]
  );
};
