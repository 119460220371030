import { PageContentWrapper, useBreakpoints } from '@lib/react-components';
import { ReactNode } from 'react';

import { unauthorizedUserRequestConfig } from '../config';
import { unAuthorizedUserRequestModel } from '../model';

import { SSN_LINK_HEIGHT } from 'lib/constants/scroll';

const { UNAUTHORIZED_HEADER_HEIGHT } = unauthorizedUserRequestConfig;

interface IUnauthorizedUserRequestPageWrapperProps {
  children: ReactNode;
  withHeader: boolean;
}

export const UnauthorizedUserRequestPageWrapper = ({
  children,
  withHeader,
}: IUnauthorizedUserRequestPageWrapperProps): JSX.Element => {
  const { isMobileView } = useBreakpoints();
  const { isSsnClinicLinkVisibleOnPageMobile } = unAuthorizedUserRequestModel.useUnauthorizedUserHeaderControls();

  return (
    <PageContentWrapper
      sx={{
        padding: 0,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        margin: `${
          (withHeader ? UNAUTHORIZED_HEADER_HEIGHT : 0) + (isSsnClinicLinkVisibleOnPageMobile ? SSN_LINK_HEIGHT : 0)
        }px ${isMobileView ? '0px 0px' : '20px 20px'}`,
      }}
    >
      {children}
    </PageContentWrapper>
  );
};
