import { OnMessageHandler } from 'broadcast-channel';
import { useEffect } from 'react';

import { broadcastChannel, IBroadcastMessage, MessageCodes } from './broadcastChannel';

import { authModel } from 'features/Auth';

export const useRootModelBroadcastChannelListener = (): void => {
  const signOut = authModel.useSignOut();

  const broadcastChannelMessageHandler: OnMessageHandler<IBroadcastMessage> = ({ code }) => {
    if (code === MessageCodes.LOG_OUT) {
      signOut(false);
    }
  };

  useEffect(() => {
    broadcastChannel.addEventListener('message', broadcastChannelMessageHandler);

    return (): void => broadcastChannel.removeEventListener('message', broadcastChannelMessageHandler);
  }, []);
};
