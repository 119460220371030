import { Tabs } from '@lib/react-components';
import { iif } from '@lib/utils';
import { Box, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { GlobalSearchDetailsTabType } from '../config';

import {
  GlobalSearchDetails,
  useGlobalSearchDetailsTabChangeHandler,
  useGlobalSearchDetailsTabsConfiguration,
} from 'features/GlobalSearchDetails';

interface IGlobalSearchDetailsListTabsProps {
  children: ReactNode;
}

export const GlobalSearchDetailsListTabs = ({ children }: IGlobalSearchDetailsListTabsProps): JSX.Element => {
  const { palette } = useTheme();
  const {
    isDoctorTabEnabled,
    isAffiliatedDoctorTabEnabled,
    isClinicTabEnabled,
    isFreeClinicTabEnabled,
    isActiveTabValid,
    activeTab,
  } = useGlobalSearchDetailsTabsConfiguration();
  const handleSearchListTabChange = useGlobalSearchDetailsTabChangeHandler();

  return (
    <Box>
      {isActiveTabValid && (
        <Tabs
          scrollButtons={false}
          variant="scrollable"
          items={[
            ...iif(isDoctorTabEnabled, {
              value: GlobalSearchDetailsTabType.PRIVATE_DOCTORS,
              label: <GlobalSearchDetails.TabLabel tabType={GlobalSearchDetailsTabType.PRIVATE_DOCTORS} />,
            }),
            ...iif(isAffiliatedDoctorTabEnabled, {
              value: GlobalSearchDetailsTabType.AFFILIATED_DOCTORS,
              label: <GlobalSearchDetails.TabLabel tabType={GlobalSearchDetailsTabType.AFFILIATED_DOCTORS} />,
            }),
            ...iif(isClinicTabEnabled, {
              value: GlobalSearchDetailsTabType.CLINICS,
              label: <GlobalSearchDetails.TabLabel tabType={GlobalSearchDetailsTabType.CLINICS} />,
            }),
            ...iif(isFreeClinicTabEnabled, {
              value: GlobalSearchDetailsTabType.FREE_CLINICS,
              label: <GlobalSearchDetails.TabLabel tabType={GlobalSearchDetailsTabType.FREE_CLINICS} />,
            }),
          ]}
          value={activeTab}
          onChangeCallback={handleSearchListTabChange}
          sx={{
            '& .selected-tab': {
              backgroundColor: palette.surface.light,
              '&::before, &::after': {
                display: 'none',
              },
            },
            '& .not-selected-tab': {
              backgroundColor: palette.white.main,
            },
          }}
        />
      )}
      {children}
    </Box>
  );
};
