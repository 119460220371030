import { ErrorIcon, ExpandMoreIcon, MaterialSymbolsIcon, SuccessIcon } from '@lib/icons';
import { AccordionSummary, Typography } from '@mui/material';

import { AffiliationDoctorJoinStatus } from '__generated__/types';

interface AffiliationNameProps {
  name: string;
  joinStatus: AffiliationDoctorJoinStatus;
}

export const AffiliationName = ({ name, joinStatus }: AffiliationNameProps): JSX.Element => (
  <AccordionSummary
    sx={{ padding: '20px', backgroundColor: 'white.main' }}
    expandIcon={<MaterialSymbolsIcon icon={<ExpandMoreIcon />} />}
  >
    <Typography variant="h8">{name}</Typography>
    {joinStatus === AffiliationDoctorJoinStatus.JOINED && (
      <MaterialSymbolsIcon icon={<SuccessIcon />} sx={{ marginLeft: '10px' }} />
    )}
    {joinStatus === AffiliationDoctorJoinStatus.REJECTED && (
      <MaterialSymbolsIcon icon={<ErrorIcon />} sx={{ marginLeft: '10px' }} />
    )}
  </AccordionSummary>
);
