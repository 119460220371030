import { GlobalSearchDetails, useGlobalSearchDetailsTabsConfiguration } from 'features/GlobalSearchDetails';

export const GlobalSearchDetailsList = (): JSX.Element => {
  const { isPrivateDoctorActiveTab, isAffiliatedDoctorActiveTab, isClinicActiveTab, isFreeClinicActiveTab } =
    useGlobalSearchDetailsTabsConfiguration();

  return (
    <GlobalSearchDetails.ListWrapper>
      {isPrivateDoctorActiveTab && <GlobalSearchDetails.DoctorTab />}
      {isAffiliatedDoctorActiveTab && <GlobalSearchDetails.AffiliatedDoctorTab />}
      {isClinicActiveTab && <GlobalSearchDetails.ClinicsTab />}
      {isFreeClinicActiveTab && <GlobalSearchDetails.FreeClinicsTab />}
    </GlobalSearchDetails.ListWrapper>
  );
};
