import { SelectItem } from '@lib/react-components';

import { sortMedicalRoles } from 'features/Affiliations/helpers';
import { useMedicalRoles } from 'features/Doctor/graphql/__generated__/MedicalRoles.query';
import { ROLES_TRANSLATION_MAP } from 'lib/constants/medicalRoles';

export const useRoleOptions = (): SelectItem[] => {
  const { data } = useMedicalRoles();

  return sortMedicalRoles(data?.medicalRoles || []).map(({ id, code }) => ({
    label: ROLES_TRANSLATION_MAP[code],
    value: id,
  }));
};
