import { useBreakpoints } from '@lib/react-components';
import { ReactNode } from 'react';

import { useRootModelRoot } from '../model';

import { UnauthorizedUserRequest } from 'features/Requests/components';

interface IPublicRoutesWrapperProps {
  children: ReactNode;
}

export const RootPublicRoutesWrapper = ({ children }: IPublicRoutesWrapperProps): JSX.Element => {
  const { isSideBarHiddenOnMobile, isSideBarHidden } = useRootModelRoot();
  const { isMobileView } = useBreakpoints();
  const withHeader = isMobileView ? !isSideBarHiddenOnMobile : !isSideBarHidden;

  return (
    <UnauthorizedUserRequest.PageWrapper withHeader={withHeader}>
      {withHeader && <UnauthorizedUserRequest.Header />}
      {children}
    </UnauthorizedUserRequest.PageWrapper>
  );
};
