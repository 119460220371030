import { gql } from '@apollo/client';

import { INFO_THREAD_WITH_MESSAGES_FRAGMENT } from 'graphql/fragments/info';

const INFO_THREAD_WITH_MESSAGES = gql`
  query ($infoThread: String) {
    infoThreadsWithMessages(infoThread: $infoThread) {
      ...InfoThreadWithMessagesFragment
    }
  }
  ${INFO_THREAD_WITH_MESSAGES_FRAGMENT}
`;

export default {
  INFO_THREAD_WITH_MESSAGES,
};
