import { useModalState } from '@lib/react-components';

import { DoctorAffiliationsToPromptDocument } from 'features/Affiliations/graphql/__generated__/DoctorAffiliationsToPrompt.query';
import { useDoctorAffiliationsToPrompt } from 'features/Affiliations/hooks';
import { client } from 'graphql/apollo';

interface IUseAffiliationsModalStateReturn {
  isAffiliationsModalAllowed: boolean;
  isAffiliationsLoading: boolean;
  isAffiliationsModalOpened: boolean;
  openAffiliationsModal: VoidFunction;
  closeAffiliationsModal: VoidFunction;
}

export const useRootModelAffiliationsModalState = (): IUseAffiliationsModalStateReturn => {
  const [isAffiliationsModalOpened, openAffiliationsModal, closeAffiliationsModal] = useModalState();
  const { data: affiliationsData, loading } = useDoctorAffiliationsToPrompt();
  const isAffiliationsModalAllowed =
    !!affiliationsData?.doctorAffiliationsToPrompt && affiliationsData?.doctorAffiliationsToPrompt.length > 0;

  const closeModal = async (): Promise<void> => {
    await client.refetchQueries({
      include: [DoctorAffiliationsToPromptDocument],
    });
    closeAffiliationsModal();
  };

  return {
    isAffiliationsModalAllowed,
    isAffiliationsModalOpened,
    isAffiliationsLoading: loading,
    openAffiliationsModal,
    closeAffiliationsModal: closeModal,
  };
};
