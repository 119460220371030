import { ArrowSortDownIcon } from '@lib/icons';
import { FlexBox, Select, useBreakpoints } from '@lib/react-components';
import { Typography, useTheme } from '@mui/material';

import { globalSearchDetailsConfig, GlobalSearchDetailsTabFilterFields } from '../config';
import { globalSearchDetailsModel } from '../model';

import { i18n } from 'i18n';

export const GlobalSearchDetailsTabOrderFilter = (): JSX.Element => {
  const { isDesktopView } = useBreakpoints();
  const { typography, palette } = useTheme();
  const { order, setOrder } = globalSearchDetailsModel.useGlobalSearchDetailsOrderFilter();

  return (
    <FlexBox
      justifyContent="center"
      alignItems="center"
      gap="12px"
      sx={{ ...(!isDesktopView && { backgroundColor: palette.surface.generic, padding: '8px' }) }}
    >
      <Typography variant="body2">{i18n.t('features.search.tabFilterSection.order')}</Typography>
      <Select
        sx={{
          '&.MuiInputBase-root': {
            width: 'fit-content',
            ...(!isDesktopView && { backgroundColor: palette.surface.generic }),
            '& .MuiSelect-select.MuiSelect-outlined': {
              padding: '0 36px 0 0',
            },
          },
          '&.MuiInputBase-root .MuiTypography-root': { ...typography.h9, color: palette.generic.dark },
        }}
        iconComponentProps={{
          icon: <ArrowSortDownIcon fontWeight="300" fill={palette.blue.main} />,
          fontSize: 32,
          sx: {
            '&.MuiSelect-icon': {
              right: 0,
              top: 'calc(50% - 17px)',
            },
          },
        }}
        name={GlobalSearchDetailsTabFilterFields.order}
        value={order}
        items={globalSearchDetailsConfig.GLOBAL_SEARCH_DETAILS_TAB_ORDER_FILTER_ITEMS}
        onChange={({ target: { value } }): void => setOrder(value)}
        withoutBorder
        displayEmpty
      />
    </FlexBox>
  );
};
