import { makeVar, useReactiveVar } from '@apollo/client';

import { IServiceAttachmentFile } from 'graphql/types/request';

export interface IAttachmentsStoreActions {
  setAttachmentsLoading: (isAttachmentsLoading: boolean) => void;
  setAttachments: (attachments: IServiceAttachmentFile[]) => void;
  addAttachments: (attachments: IServiceAttachmentFile[]) => void;
  updateAttachment: (attachment: IServiceAttachmentFile, attachmentId: string) => void;
  removeAttachment: (attachmentId: string) => void;
  removeAttachments: VoidFunction;
}

export interface IAttachmentsStore {
  attachmentsLoading: boolean;
  attachments: IServiceAttachmentFile[];
}

const attachmentsLoadingVar = makeVar<IAttachmentsStore['attachmentsLoading']>(false);
const setAttachmentsLoading: IAttachmentsStoreActions['setAttachmentsLoading'] = attachmentsLoadingVar;

export const attachmentsVar = makeVar<IAttachmentsStore['attachments']>([]);

export const setAttachments: IAttachmentsStoreActions['setAttachments'] = attachments => attachmentsVar(attachments);

const updateAttachment: IAttachmentsStoreActions['updateAttachment'] = (attachment, attachmentId) => {
  attachmentsVar(
    attachmentsVar().map(currentAttachment =>
      currentAttachment.id !== attachmentId ? currentAttachment : { ...currentAttachment, ...attachment }
    )
  );
};

export const addAttachments: IAttachmentsStoreActions['addAttachments'] = attachments =>
  attachmentsVar(attachmentsVar().concat(...attachments));

const removeAttachments: IAttachmentsStoreActions['removeAttachments'] = () => attachmentsVar([]);

const removeAttachment: IAttachmentsStoreActions['removeAttachment'] = attachmentId =>
  attachmentsVar(attachmentsVar().filter(({ file: { id } }) => id !== attachmentId));

type UseAttachmentsStore = () => IAttachmentsStore & IAttachmentsStoreActions;

export const useAttachmentsStore: UseAttachmentsStore = () => {
  const attachmentsLoading = useReactiveVar(attachmentsLoadingVar);
  const attachments = useReactiveVar(attachmentsVar);

  return {
    attachmentsLoading,
    attachments,
    setAttachmentsLoading,
    setAttachments,
    updateAttachment,
    addAttachments,
    removeAttachments,
    removeAttachment,
  };
};
