import { useOnDeleteChip } from '@lib/react-components';
import { Stack, Typography } from '@mui/material';
import { useRef } from 'react';

import { globalSearchDetailsModel } from 'features/GlobalSearchDetails';
import { GlobalSearchDetails } from 'features/GlobalSearchDetails/ui';
import { i18n } from 'i18n';

export const GlobalSearchDetailsFiltersSection = (): JSX.Element => {
  const filterChipItems = globalSearchDetailsModel.useGlobalSearchDetailsFilterChipItems();
  const filtersTitleRef = useRef<HTMLSpanElement>(null);
  const { filters, setFilter, clearFilters } = globalSearchDetailsModel.useGlobalSearchFilters();
  const onDeleteChip = useOnDeleteChip(filters, setFilter);

  return (
    <Stack gap="14px">
      <GlobalSearchDetails.Filters>
        <Typography pr="4px" ref={filtersTitleRef} variant="h9">
          {i18n.t('features.search.filterSection.list')}:
        </Typography>
      </GlobalSearchDetails.Filters>
      {!!filterChipItems.length && (
        <GlobalSearchDetails.FilterChips
          clearFilters={clearFilters}
          onDeleteChip={onDeleteChip}
          filterChipItems={filterChipItems}
        >
          <Typography minWidth={filtersTitleRef.current?.clientWidth} variant="body2">
            {i18n.t('features.search.filterSection.chips')}
          </Typography>
        </GlobalSearchDetails.FilterChips>
      )}
    </Stack>
  );
};
