import { MaterialSymbolsIcon, SearchIcon } from '@lib/icons';
import { useBreakpoints } from '@lib/react-components';
import { TextField, useTheme } from '@mui/material';
import { useState } from 'react';

import { GlobalSearchDetailsFilterFields, globalSearchDetailsModel } from 'features/GlobalSearchDetails';
import { i18n } from 'i18n';

export const GlobalSearchDetailsSpecificSearchFilter = (): JSX.Element => {
  const { typography } = useTheme();
  const { filters, setFilter } = globalSearchDetailsModel.useGlobalSearchFilters();
  const [specificSearch, setSpecificSearch] = useState('');
  const { isMobileView } = useBreakpoints();

  const handleSpecificSearch = (): void => {
    const specificSearchTrimmed = specificSearch.trim();

    if (specificSearchTrimmed) {
      setFilter(GlobalSearchDetailsFilterFields.specificSearch, [...filters.specificSearch, specificSearchTrimmed]);
      setSpecificSearch('');
    }
  };

  return (
    <TextField
      sx={{
        minWidth: isMobileView ? undefined : '300px',
        width: 'auto',
        flexGrow: isMobileView ? undefined : 2,
        '& .MuiInputBase-input': {
          paddingLeft: '8px',
          height: isMobileView ? '36px' : undefined,
        },
        '& .MuiInputBase-input::placeholder': {
          ...typography.body2,
        },
      }}
      value={specificSearch}
      placeholder={i18n.t('features.search.filters.specificSearch')}
      onBlur={handleSpecificSearch}
      onKeyPress={({ key }): void => {
        if (key === 'Enter') {
          handleSpecificSearch();
        }
      }}
      onChange={({ target: { value } }): void => setSpecificSearch(value)}
      InputProps={{
        startAdornment: <MaterialSymbolsIcon icon={<SearchIcon fontWeight="300" />} />,
      }}
    />
  );
};
