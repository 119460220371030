import { Call, DeviceInstance, IUsePatientCallProps } from '@lib/features-bll';
import { useHistory } from 'react-router-dom';

import { ensureMediaPermissions } from 'features/Calls/helpers';
import { getTwilioSdk } from 'features/Calls/helpers/getTwilioSdk';
import { useChatModelOneToOneChatRedirect } from 'features/Chat/model';

type UseWebCall = () => Pick<IUsePatientCallProps, 'callSdk' | 'redirectAfterCallAcceptance'>;

export const useWebCall: UseWebCall = () => {
  const history = useHistory();
  const { getRedirectPathByParams } = useChatModelOneToOneChatRedirect();

  return {
    redirectAfterCallAcceptance: async ({ portalUserId }): Promise<void> => {
      const { redirectPath } = await getRedirectPathByParams({ portalUserId });
      if (redirectPath) history.push(redirectPath);
    },
    callSdk: {
      createInstance: async (twilioJwtToken): Promise<DeviceInstance> => {
        const { Device: TwilioDevice, Call: TwilioCall } = await getTwilioSdk();

        const instance = new TwilioDevice(twilioJwtToken, {
          logLevel: 0,
          codecPreferences: [TwilioCall.Codec.Opus, TwilioCall.Codec.PCMU],
        });

        return {
          getOptionsEnabled: () => /* !!instance.audio?.isOutputSelectionSupported */ false,
          handleDeviceError: callback => instance.on('error', callback),
          createCall: async (_jwtToken, options): Promise<Call> => {
            const callInstance = await instance.connect(options);

            return {
              ...callInstance,
              parameters: { ...callInstance.parameters, CallSid: callInstance.parameters.CallSid },
              disconnect: () => callInstance.disconnect(),
              mute: muted => callInstance.mute(muted),
              handleAcceptance: callback => callInstance.on('accept', callback),
              handleDisconnection: callback => callInstance.on('disconnect', callback),
              handleCancellation: callback => callInstance.on('cancel', callback),
              handleMuteChange: callback => callInstance.on('mute', callback),
              handleVolumeChange: callback => callInstance.on('volume', callback),
              handleCallError: callback => callInstance.on('error', callback),
            };
          },
        };
      },
      ensureMediaPermissions,
    },
  };
};
