import { useReactiveVar, makeVar, ReactiveVar } from '@apollo/client';

interface IUseRootModelUiStoreActions {
  setIsFullScreen: ReactiveVar<boolean>;
  setIsMenuOpen: ReactiveVar<boolean>;
  setIsMenuCollapsed: ReactiveVar<boolean>;
}

interface IUseRootModelUiStore {
  isFullScreen: boolean;
  isMenuOpen: boolean;
  isMenuCollapsed: boolean;
}

const isFullScreenVar = makeVar<IUseRootModelUiStore['isFullScreen']>(false);
const isMenuCollapsedVar = makeVar<IUseRootModelUiStore['isMenuCollapsed']>(false);
const isMenuOpenVar = makeVar<IUseRootModelUiStore['isMenuOpen']>(false);

const setIsFullScreen: IUseRootModelUiStoreActions['setIsFullScreen'] = isFullScreenVar;
const setIsMenuOpen: IUseRootModelUiStoreActions['setIsMenuOpen'] = isMenuOpenVar;
const setIsMenuCollapsed: IUseRootModelUiStoreActions['setIsMenuCollapsed'] = isMenuCollapsedVar;

type UseRootModelUiStore = () => IUseRootModelUiStore & IUseRootModelUiStoreActions;

export const useRootModelUiStore: UseRootModelUiStore = () => {
  const isFullScreen = useReactiveVar(isFullScreenVar);
  const isMenuOpen = useReactiveVar(isMenuOpenVar);
  const isMenuCollapsed = useReactiveVar(isMenuCollapsedVar);

  return {
    isFullScreen,
    isMenuOpen,
    isMenuCollapsed,
    setIsFullScreen,
    setIsMenuOpen,
    setIsMenuCollapsed,
  };
};
