import { GlobalSearchDetailsTabType } from './types';

import { i18n } from 'i18n';

export const GLOBAL_SEARCH_DETAILS_TAB_LABEL: Record<GlobalSearchDetailsTabType, string> = {
  [GlobalSearchDetailsTabType.PRIVATE_DOCTORS]: i18n.t('features.search.tabs.label.privateDoctor'),
  [GlobalSearchDetailsTabType.AFFILIATED_DOCTORS]: i18n.t('features.search.tabs.label.affiliatedDoctor'),
  [GlobalSearchDetailsTabType.CLINICS]: i18n.t('features.search.tabs.label.clinic'),
  [GlobalSearchDetailsTabType.FREE_CLINICS]: i18n.t('features.search.tabs.label.freeClinic'),
};

export const GLOBAL_SEARCH_DETAILS_TAB_LABEL_MOBILE: Record<GlobalSearchDetailsTabType, string> = {
  [GlobalSearchDetailsTabType.PRIVATE_DOCTORS]: i18n.t('features.search.tabs.label.mobile.privateDoctor'),
  [GlobalSearchDetailsTabType.AFFILIATED_DOCTORS]: i18n.t('features.search.tabs.label.mobile.affiliatedDoctor'),
  [GlobalSearchDetailsTabType.CLINICS]: i18n.t('features.search.tabs.label.mobile.clinic'),
  [GlobalSearchDetailsTabType.FREE_CLINICS]: i18n.t('features.search.tabs.label.mobile.freeClinic'),
};
