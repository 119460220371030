import { Divider, List } from '@mui/material';
import clsx from 'clsx';
import { Fragment, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FaqListItem } from './components/FaqListItem';
import { MenuListItem } from './components/MenuListItem';
import { SupportListItem } from './components/SupportListItem';
import { useAdditionalSidebarItems } from './hooks/useAdditionalSidebarItems';
import { useGetCounterForSidebarItem } from './hooks/useGetCounterForSidebarItem';
import { useStyles } from './styles';
import { IMenuListProps } from './types';

import { SupportRequestUiAddSupportRequestModal } from 'features/SupportRequest/ui';
import { useUserModelStore } from 'features/Users/model';
import { SidebarItemState } from 'lib/helpers/sidebarConfig';

export const MenuList = ({
  menuListItem,
  isExpanded,
  handleDrawerToggle,
  portalLogo,
  classes,
}: IMenuListProps): JSX.Element => {
  const history = useHistory();

  const { classes: localClasses } = useStyles();
  const [isSupportModalOpened, setSupportModalOpened] = useState<boolean>(false);
  const { activeProfile } = useUserModelStore();
  const { isAdditionalItemVisible, isSupportItemVisible, isFaqItemVisible } = useAdditionalSidebarItems();

  const { getCounterForSidebarItem } = useGetCounterForSidebarItem();

  const goToLink = useCallback(
    (link?: string): VoidFunction =>
      () => {
        if (link) {
          history.push(link);
        }

        handleDrawerToggle?.();
      },
    []
  );

  return (
    <>
      <List className={clsx(classes && classes.list, localClasses.list)} component="div">
        {menuListItem.map((section, sectionIndex) => (
          <Fragment key={`sidebarMenu-item-${sectionIndex}`}>
            {section.length > 0 && sectionIndex > 0 && <Divider className={localClasses.divider} light />}
            {section.map(listItem => (
              <MenuListItem
                {...listItem}
                key={listItem.id}
                goToLink={goToLink}
                isExpanded={isExpanded}
                state={activeProfile?.hasPostRegister ? SidebarItemState.DISABLED : listItem.state}
                getCounterForSidebarItem={getCounterForSidebarItem}
              />
            ))}
          </Fragment>
        ))}
        {isAdditionalItemVisible && (
          <>
            <Divider className={localClasses.divider} light />
            {isSupportItemVisible && <SupportListItem setSupportModalOpened={setSupportModalOpened} />}
            {isFaqItemVisible && <FaqListItem isExpanded={isExpanded} />}
            {portalLogo && portalLogo}
          </>
        )}
      </List>
      {isSupportModalOpened && (
        <SupportRequestUiAddSupportRequestModal
          profileId={activeProfile?.id}
          onClose={(): void => setSupportModalOpened(false)}
        />
      )}
    </>
  );
};
