import { useIdentityVerificationInitialization } from '@lib/features-bll';
import { useBreakpoints } from '@lib/react-components';

import { SetupNativeAppSection, ISetupNativeAppSectionProps } from 'features/Users/components';

interface ISetupNativeAppSectionWithSubscriptionProps extends ISetupNativeAppSectionProps {
  onSuccess: VoidFunction;
}

export const SetupNativeAppSectionWithSubscription = ({
  onSuccess,
  ...props
}: ISetupNativeAppSectionWithSubscriptionProps): JSX.Element => {
  const { isMobileView } = useBreakpoints();
  useIdentityVerificationInitialization({ onSuccess });

  return <SetupNativeAppSection {...props} hideSplash={isMobileView} />;
};
