import { RefObject, useEffect, useRef } from 'react';

export const useGlobalSearchDetailsAccordionScrollEffect = (scrollCondition: boolean): RefObject<HTMLDivElement> => {
  const accordionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (accordionRef.current && scrollCondition) {
        window.scrollTo(0, window.scrollY + accordionRef.current.getBoundingClientRect().top);
      }
      // should be more than 0 to scroll after list render
    }, 1);
  }, [scrollCondition]);

  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'smooth';

    return () => document.documentElement.removeAttribute('style');
  }, []);

  return accordionRef;
};
