import { FlexBox, useBreakpoints } from '@lib/react-components';
import { SxProps, Theme, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { i18n } from 'i18n';

interface IGlobalSearchDetailsTabRefineSearchWrapperProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export const GlobalSearchDetailsTabRefineSearchWrapper = ({
  children,
  sx,
}: IGlobalSearchDetailsTabRefineSearchWrapperProps): JSX.Element => {
  const { isDesktopView } = useBreakpoints();

  return (
    <FlexBox
      flexDirection={isDesktopView ? 'row' : 'column'}
      justifyContent="flex-start"
      alignItems={isDesktopView ? 'center' : 'flex-start'}
      sx={{
        padding: isDesktopView ? 0 : '0 26px',
        ...sx,
      }}
      gap="8px"
    >
      <Typography variant="h9">
        {i18n.t('features.search.tabFilterSection.refineSearch')}
        {isDesktopView && ':'}
      </Typography>
      {children}
    </FlexBox>
  );
};
