import { ThreadMessageType } from '../config';
import {
  IThreadMessage,
  ThreadAssistedDoctorSearchFormMessage,
  ThreadLog,
  ThreadMessageUnion,
  ThreadRequest,
  ThreadRequestProposal,
  ThreadVoipCall,
} from '../types';

import { RequestHistoryEventType } from '__generated__/types';

export const isLog = (data: ThreadMessageUnion): data is ThreadLog => data.type === ThreadMessageType.LOG;

export const isReminderLog = (data: ThreadMessageUnion): data is ThreadLog =>
  data.type === ThreadMessageType.REMINDER_CREATION;

export const isMessage = (data: ThreadMessageUnion): data is IThreadMessage => data.type === ThreadMessageType.MESSAGE;

export const isMailMessage = (data: ThreadMessageUnion): data is IThreadMessage => data.type === ThreadMessageType.MAIL;

export const isRequestProposal = (data: ThreadMessageUnion): data is ThreadRequestProposal =>
  data.type === ThreadMessageType.REQUEST_PROPOSAL;

export const isRequest = (data: ThreadMessageUnion): data is ThreadRequest => data.type === ThreadMessageType.REQUEST;

export const isClosedRequestEvent = (data: ThreadMessageUnion): boolean =>
  isRequest(data) && data.eventType === RequestHistoryEventType.CLOSED;

export const isCancelledRequestEvent = (data: ThreadMessageUnion): boolean =>
  isRequest(data) && data.eventType === RequestHistoryEventType.CANCELED;

export const isProposedOnDemandDatesRequestEvent = (data: ThreadMessageUnion): boolean =>
  isRequest(data) && data.eventType === RequestHistoryEventType.APPOINTMENT_PROPOSED;

export const isConfirmedOnDemandDatesRequestEvent = (data: ThreadMessageUnion): boolean =>
  isRequest(data) && data.eventType === RequestHistoryEventType.APPOINTMENT_ACCEPTED;

export const isVoipCall = (data: ThreadMessageUnion): data is ThreadVoipCall =>
  data.type === ThreadMessageType.VOIP_CALL;

export const isAssistedSearch = (data: ThreadMessageUnion): data is ThreadAssistedDoctorSearchFormMessage =>
  data.type === ThreadMessageType.ASSISTED_DOCTOR_SEARCH_FORM;
