export enum CreateUserClaimErrorCodes {
  EmailNotUnique = 'User with the same email already exists',
  PhoneNumberNotUnique = 'User with the same phone number already exists',
  MissedAttachments = 'User claim cannot be created without any attachments',
  IncorrectFlowUsage = 'Only patient created by doctor without email or phone number should use this flow',
}

export enum RegistrationsSteps {
  PersonalInformation = 'PersonalInformation',
  LoginInformation = 'LoginInformation',
  EnterPassword = 'EnterPassword',
  ProceedVerification = 'VerificationStep',
}
