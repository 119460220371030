import { Chip } from '@lib/react-components';
import { Box, InputBase } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './styles';
import { FieldsOfInterestTextAreaProps } from './types';

export const FieldsOfInterestTextArea = ({
  values,
  inputValue,
  onClick,
  onKeyDown,
  onChange,
  classes,
}: FieldsOfInterestTextAreaProps): JSX.Element => {
  const { classes: localClasses } = useStyles();

  return (
    <Box className={clsx(localClasses.subSectionWrapper, classes?.subSectionWrapper)}>
      {values.map((field, index) => (
        <Chip
          key={`${field}-${index}`}
          chipLabel={field}
          onDeleteChip={(): void => onClick(index)}
          classes={{
            root: clsx(localClasses.root, classes?.root),
            label: clsx(localClasses.label, classes?.label),
            deleteIcon: clsx(localClasses.deleteIcon, classes?.deleteIcon),
          }}
        />
      ))}
      <Box className={clsx(localClasses.fieldInputWrapper, classes?.fieldInputWrapper)} display="inline">
        <InputBase
          multiline
          rows={4}
          className={clsx(localClasses.fieldInput, classes?.fieldInput)}
          onKeyDown={onKeyDown}
          onChange={onChange}
          value={inputValue}
        />
      </Box>
    </Box>
  );
};
