import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  getGlobalSearchDetailsTabsConfiguration,
  IGetGlobalSearchDetailsTabsConfigurationReturn,
} from './getGlobalSearchDetailsTabsConfiguration';

import {
  GlobalSearchDetailsTabType,
  IGlobalSearchDetailsUrlParams,
  useGlobalSearchDetailsTotalCounts,
} from 'features/GlobalSearchDetails';

interface IUseGlobalSearchDetailsTabsConfigurationReturn extends IGetGlobalSearchDetailsTabsConfigurationReturn {
  activeTab: GlobalSearchDetailsTabType;
  isAllTabsEmpty: boolean;
  isPrivateDoctorActiveTab: boolean;
  isAffiliatedDoctorActiveTab: boolean;
  isClinicActiveTab: boolean;
  isFreeClinicActiveTab: boolean;
  isActiveTabValid: boolean;
  loading: boolean;
}

export const useGlobalSearchDetailsTabsConfiguration = (): IUseGlobalSearchDetailsTabsConfigurationReturn => {
  const { activeTab } = useParams<IGlobalSearchDetailsUrlParams>();
  const { globalSearchDetails, called, loading } = useGlobalSearchDetailsTotalCounts();
  const {
    priorityTab,
    tabsCountsByItems,
    isAffiliatedDoctorTabEnabled,
    isClinicTabEnabled,
    isFreeClinicTabEnabled,
    isDoctorTabEnabled,
  } = useMemo(() => getGlobalSearchDetailsTabsConfiguration(globalSearchDetails), [globalSearchDetails]);

  return {
    activeTab,
    isAllTabsEmpty: !priorityTab && !loading,
    tabsCountsByItems,
    priorityTab,
    loading,
    isDoctorTabEnabled,
    isAffiliatedDoctorTabEnabled,
    isClinicTabEnabled,
    isFreeClinicTabEnabled,
    isActiveTabValid: (called || !loading) && activeTab in tabsCountsByItems,
    isPrivateDoctorActiveTab: activeTab === GlobalSearchDetailsTabType.PRIVATE_DOCTORS,
    isAffiliatedDoctorActiveTab: activeTab === GlobalSearchDetailsTabType.AFFILIATED_DOCTORS,
    isClinicActiveTab: activeTab === GlobalSearchDetailsTabType.CLINICS,
    isFreeClinicActiveTab: activeTab === GlobalSearchDetailsTabType.FREE_CLINICS,
  };
};
