import { ROUTE_PATHS } from '@lib/core';
import { useAuthenticatedUserLazyQuery } from '@lib/features-bll';
import { useLocation } from 'react-router-dom';

import { useRootModelPath } from 'features/Root/model';
import { useUserModelActiveProfile, useUserModelStore } from 'features/Users/model';

interface ILoginModelAuthenticateProps {
  withRedirect?: boolean;
  onError?: VoidFunction;
  onSuccess?: VoidFunction;
}

interface IUseLoginModelAuthenticateReturn {
  loading: boolean;
  authenticate: (props?: ILoginModelAuthenticateProps) => Promise<void>;
}

export const useLoginModelAuthenticate = (): IUseLoginModelAuthenticateReturn => {
  const { goToPath } = useRootModelPath();
  const { search } = useLocation();
  const [getAuthenticatedUser, { loading }] = useAuthenticatedUserLazyQuery();
  const getActiveProfileTypeData = useUserModelActiveProfile();
  const { setUserInfo, setActiveProfileType } = useUserModelStore();

  const authenticate: IUseLoginModelAuthenticateReturn['authenticate'] = async ({
    onError,
    onSuccess,
    withRedirect = true,
  } = {}) => {
    const { data: { authenticatedUser } = {} } = await getAuthenticatedUser();

    if (authenticatedUser) {
      setUserInfo(authenticatedUser);
    } else {
      onError?.();
    }

    const { profileType, isBlocked } = await getActiveProfileTypeData();

    if (isBlocked) {
      onError?.();
    }

    if (profileType) {
      setActiveProfileType(profileType);
    } else {
      onError?.();

      return;
    }

    if (withRedirect) {
      goToPath({ path: ROUTE_PATHS.dashboard, search });
    }

    onSuccess?.();
  };

  return { loading, authenticate };
};
