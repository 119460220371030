import { FlexBox, Loader, StatusCard, useBreakpoints } from '@lib/react-components';
import { Accordion, Box, Button, Typography } from '@mui/material';
import { useState } from 'react';

import { AffiliationName } from './components/AffiliationName';
import { MainDataSection } from './components/MainDataSection';
import { ServicesSection } from './components/ServicesSection';
import { ActionType } from './const';
import { useAffiliationsAccordion, useAffiliationsActionHandler, useSortedAffiliations } from './hooks';
import { useStyles } from './styles';

import { AffiliationDoctorJoinStatus } from '__generated__/types';
import { useUserModelStore } from 'features/Users/model';
import { i18n } from 'i18n';
import { ROLES_TRANSLATION_MAP } from 'lib/constants/medicalRoles';

interface IAffiliationsStepProps {
  showRoleInfoBox: boolean;
  goToPreviousStep: () => void;
  goToNextStep: () => void;
}

export const AffiliationsStep = ({
  showRoleInfoBox,
  goToNextStep,
  goToPreviousStep,
}: IAffiliationsStepProps): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { isMobileView } = useBreakpoints();
  const [isCurrentRoleInfoBoxVisible, setIsCurrentRoleInfoBoxVisible] = useState<boolean>(showRoleInfoBox);
  const { activeDoctor } = useUserModelStore();
  const medicalRole = activeDoctor?.doctor.medicalRole;
  const { affiliations, loading } = useSortedAffiliations();
  const { expandedAccordionId, handleAccordionChange, openFirstPendingAffiliation } =
    useAffiliationsAccordion(affiliations);
  const { onActionButtonClick } = useAffiliationsActionHandler({ onActionSuccess: openFirstPendingAffiliation });

  if (!affiliations || loading) {
    return <Loader />;
  }

  const isSingleAffiliation = affiliations.length === 1;
  const isContinueButtonDisabled = affiliations.some(
    affiliation => affiliation.joinStatus === AffiliationDoctorJoinStatus.PENDING
  );

  return (
    <>
      {isCurrentRoleInfoBoxVisible && medicalRole && (
        <Box sx={{ padding: { xs: '0 24px', mobile: 'initial' } }}>
          <StatusCard
            onCloseClick={(): void => setIsCurrentRoleInfoBoxVisible(false)}
            text={i18n.t('features.affiliations.activationStepper.affiliationsStep.roleInfoBoxText', {
              currentRole: ROLES_TRANSLATION_MAP[medicalRole.code],
            })}
          />
        </Box>
      )}
      <Box className={localClasses.greyCard}>
        <Typography sx={{ padding: '16px 24px' }}>
          {`${i18n.t(
            `features.affiliations.activationStepper.affiliationsStep.${
              isSingleAffiliation ? 'singleAffiliationAvailable' : 'multipleAffiliationsAvailable'
            }`,
            { affiliationsCount: affiliations.length }
          )} ${i18n.t('features.affiliations.activationStepper.affiliationsStep.viewTheDetails')}`}
        </Typography>
        {affiliations.map(({ affiliationId, joinStatus, name, startDate, endDate, services }) => (
          <Accordion
            key={affiliationId}
            className={localClasses.accordion}
            expanded={expandedAccordionId === affiliationId}
            onChange={handleAccordionChange(affiliationId)}
          >
            <AffiliationName name={name} joinStatus={joinStatus} />
            <Box sx={{ padding: '24px' }}>
              <MainDataSection
                startDate={startDate}
                endDate={endDate}
                onAcceptClick={(): Promise<void> => onActionButtonClick(affiliationId, ActionType.Accept)}
                onRefuseClick={(): Promise<void> => onActionButtonClick(affiliationId, ActionType.Refuse)}
              />
              <ServicesSection services={services} />
            </Box>
          </Accordion>
        ))}
      </Box>
      {!isSingleAffiliation && (
        <Box sx={{ padding: { xs: '20px 24px 0', mobile: 'initial' } }}>
          <StatusCard
            text={i18n.t('features.affiliations.activationStepper.affiliationsStep.multipleAcceptanceDisclaimer')}
          />
        </Box>
      )}
      <FlexBox
        sx={{
          flexDirection: { xs: 'column', mobile: 'row' },
          margin: { xs: '32px 24px 48px', mobile: '32px 0 0' },
          justifyContent: 'space-between',
        }}
      >
        <Button
          sx={{ margin: { xs: '0 0 16px', mobile: 'initial' } }}
          size={isMobileView ? 'small' : 'medium'}
          variant={isMobileView ? 'secondary' : 'link'}
          onClick={goToPreviousStep}
        >
          {i18n.t('features.affiliations.activationStepper.affiliationsStep.changeRole')}
        </Button>
        <Button size={isMobileView ? 'small' : 'medium'} disabled={isContinueButtonDisabled} onClick={goToNextStep}>
          {i18n.t('features.affiliations.activationStepper.affiliationsStep.continue')}
        </Button>
      </FlexBox>
    </>
  );
};
