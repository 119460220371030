import { gql } from '@apollo/client';

const SIGN_IN = gql`
  mutation SignIn($emailOrPhoneNumber: String!, $password: String!, $institutionId: String!) {
    signIn(emailOrPhoneNumber: $emailOrPhoneNumber, password: $password, institutionId: $institutionId) {
      accessToken
      refreshToken
      expiresIn
    }
  }
`;

const SIGN_IN_FOR_PATIENT_CREATED_BY_DOCTOR = gql`
  mutation SignInForPatientCreatedByDoctor(
    $userId: String!
    $password: String!
    $acceptedPrivacies: [PrivacyAcceptance!]!
    $residenceAddressExternalGooglePlaceId: String!
    $phoneNumber: String = null
  ) {
    signInForPatientCreatedByDoctor(
      userId: $userId
      password: $password
      acceptedPrivacies: $acceptedPrivacies
      residenceAddressExternalGooglePlaceId: $residenceAddressExternalGooglePlaceId
      phoneNumber: $phoneNumber
    ) {
      accessToken
      refreshToken
      expiresIn
    }
  }
`;

const SIGN_IN_VIA_REGISTRATION = gql`
  mutation SignInViaRegistration(
    $emailOrPhoneNumber: String!
    $password: String!
    $institutionId: String!
    $profileType: ProfileType!
    $acceptedPrivacies: [PrivacyAcceptance!]!
    $inviteId: String
  ) {
    signInViaRegistration(
      emailOrPhoneNumber: $emailOrPhoneNumber
      password: $password
      institutionId: $institutionId
      profileType: $profileType
      acceptedPrivacies: $acceptedPrivacies
      inviteId: $inviteId
    ) {
      accessToken
      refreshToken
      expiresIn
    }
  }
`;

const REFRESH_SESSION = gql`
  mutation RefreshSession($refreshToken: String!) {
    refreshSession(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      expiresIn
    }
  }
`;

export default {
  SIGN_IN,
  SIGN_IN_FOR_PATIENT_CREATED_BY_DOCTOR,
  SIGN_IN_VIA_REGISTRATION,
  REFRESH_SESSION,
};
