import { useModalState } from '@lib/react-components';

import { Maybe } from '__generated__/types';
import { useUpdateNoticeModelUnreadList } from 'features/UpdateNotice/model';
import { UpdateNotice } from 'features/UpdateNotice/types';

interface IUseUpdateNoticeModalStateReturn {
  isUpdateNoticeModalOpened: boolean;
  openUpdateNoticeModal: VoidFunction;
  closeUpdateNoticeModal: VoidFunction;
  notice?: Maybe<UpdateNotice>;
  isNoticeLoading: boolean;
}

type UseUpdateNoticeModalState = () => IUseUpdateNoticeModalStateReturn;

export const useRootModelUpdateNoticeModalState: UseUpdateNoticeModalState = () => {
  const { unreadNoticeToShow, loading } = useUpdateNoticeModelUnreadList();
  const [isUpdateNoticeModalOpened, openUpdateNoticeModal, closeUpdateNoticeModal] = useModalState();

  return {
    notice: unreadNoticeToShow,
    isNoticeLoading: loading,
    isUpdateNoticeModalOpened,
    openUpdateNoticeModal,
    closeUpdateNoticeModal,
  };
};
