import { i18n } from 'i18n';

interface IUseDashboardWelcomeTitleProps {
  fullName: string;
  gender: string;
  greetingTitle?: string;
  portal?: string;
  isPatient?: boolean;
}

interface IUseDashboardWelcomeTitleReturn {
  welcomeBackTitle: string;
}

export const useDashboardWelcomeTitle = ({
  fullName,
  greetingTitle = '',
  portal = '',
  gender,
  isPatient,
}: IUseDashboardWelcomeTitleProps): IUseDashboardWelcomeTitleReturn => ({
  welcomeBackTitle: i18n.t(isPatient ? 'pages.dashboard.welcomeBack_patient' : 'pages.dashboard.welcomeBack', {
    context: gender.toLocaleLowerCase(),
    greetingTitle,
    name: fullName,
    portal,
  }),
});
