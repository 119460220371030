import { PersistedDataKeys } from '@lib/core';

import { Maybe } from '__generated__/types';
import { LocalStoragePlatformKeys } from 'lib/constants/localStorage';
import { persistedData } from 'lib/helpers/persistedData';

// ts-unused-exports:disable-next-line
export const getPlatform = (): Maybe<LocalStoragePlatformKeys> =>
  (persistedData.get(PersistedDataKeys.Platform) as LocalStoragePlatformKeys) ?? null;

// ts-unused-exports:disable-next-line
export const isPlatformIos = (): boolean => getPlatform() === LocalStoragePlatformKeys.Ios;

// ts-unused-exports:disable-next-line
export const isPlatformAndroid = (): boolean => getPlatform() === LocalStoragePlatformKeys.Android;

// ts-unused-exports:disable-next-line
export const isPlatformNative = (): boolean => isPlatformIos() || isPlatformAndroid();

// ts-unused-exports:disable-next-line
export const isPlatformValid = (platform: LocalStoragePlatformKeys): boolean =>
  [LocalStoragePlatformKeys.Ios, LocalStoragePlatformKeys.Android].includes(platform);

// ts-unused-exports:disable-next-line
export const setPlatform = (platform: Maybe<LocalStoragePlatformKeys>): void => {
  if (platform && isPlatformValid(platform)) {
    persistedData.set(PersistedDataKeys.Platform, platform);
  }
};
