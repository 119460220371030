import { useFaqUrl } from '@lib/features-bll';
import { useBreakpoints } from '@lib/react-components';

import { useUserModelStore } from '../../../../../../Users/model';

interface IUseAdditionalSidebarItemsReturn {
  isAdditionalItemVisible: boolean;
  isSupportItemVisible: boolean;
  isFaqItemVisible: boolean;
}

export const useAdditionalSidebarItems = (): IUseAdditionalSidebarItemsReturn => {
  const { isMobileView } = useBreakpoints();
  const { activeProfileType } = useUserModelStore();
  const { faqUrl } = useFaqUrl({ activeProfileType, isLoggedUser: true });

  const isSupportItemVisible = isMobileView;
  const isFaqItemVisible = faqUrl !== null;

  return {
    isSupportItemVisible,
    isFaqItemVisible,
    isAdditionalItemVisible: isFaqItemVisible || isSupportItemVisible,
  };
};
