import { CustomerServiceIcon, MaterialSymbolsIcon } from '@lib/icons';
import { useBreakpoints, useModalState } from '@lib/react-components';
import { Tooltip, useTheme } from '@mui/material';

import { SupportRequestUiAddSupportRequestModal } from '../SupportRequestUiAddSupportRequestModal';

import { i18n } from 'i18n';

const ICON_SIZE = 36;
const ICON_POSITION = 45;

interface ISupportRequestUiCanWeHelpClasses {
  modalToggle?: string;
}

interface ISupportRequestUiCanWeHelpProps {
  profileId?: string;
  classes?: ISupportRequestUiCanWeHelpClasses;
  isStaticPosition?: boolean;
}

export const SupportRequestUiCanWeHelp = ({
  profileId,
  classes,
  isStaticPosition,
}: ISupportRequestUiCanWeHelpProps): JSX.Element => {
  const { palette } = useTheme();
  const { isMobileView } = useBreakpoints();
  const [isModalOpened, openModal, closeModal] = useModalState();

  return (
    <>
      <Tooltip title={i18n.t('components.support.tooltipText') || ''} placement="top">
        <MaterialSymbolsIcon
          fontSize={ICON_SIZE}
          icon={<CustomerServiceIcon fontWeight="300" />}
          fill={isModalOpened ? palette.black.main : palette.generic.dark}
          className={classes?.modalToggle}
          sx={
            !profileId && !isStaticPosition
              ? {
                  position: isMobileView ? 'absolute' : 'fixed',
                  top: isMobileView ? `calc(${ICON_POSITION}px - ${ICON_SIZE / 2}px)` : ICON_POSITION,
                  right: isMobileView ? 24 : ICON_POSITION,
                  ...(!isMobileView && { margin: 0 }),
                }
              : undefined
          }
          onClick={openModal}
          cursor="pointer"
          data-cy="open-support-modal"
        />
      </Tooltip>
      {isModalOpened && <SupportRequestUiAddSupportRequestModal profileId={profileId} onClose={closeModal} />}
    </>
  );
};
