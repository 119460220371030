import { SERVICE_TYPES_OPTIONS, SPECIALIZATIONS_TRANSLATIONS } from '@lib/features-bll';
import { FlexBox, Select } from '@lib/react-components';
import { ReactNode } from 'react';

import { globalSearchDetailsConfig, GlobalSearchDetailsFilterFields } from '../config';

import { globalSearchDetailsModel } from 'features/GlobalSearchDetails';
import { GlobalSearchDetails } from 'features/GlobalSearchDetails/ui';
import { i18n } from 'i18n';

interface IGlobalSearchDetailsFiltersProps {
  children: ReactNode;
}

export const GlobalSearchDetailsFilters = ({ children }: IGlobalSearchDetailsFiltersProps): JSX.Element => {
  const { filters, setFilter } = globalSearchDetailsModel.useGlobalSearchFilters();

  return (
    <FlexBox gap="8px" flexWrap="wrap" alignItems="center">
      {children}
      <GlobalSearchDetails.SpecificSearchFilter />
      <Select
        sx={{ '&.MuiInputBase-root': { width: '170px' } }}
        name={GlobalSearchDetailsFilterFields.specializations}
        value={filters.specializations}
        items={SPECIALIZATIONS_TRANSLATIONS}
        placeholder={i18n.t('features.search.filters.specializations')}
        onChange={({ target: { name, value } }): void => setFilter(name, value)}
        hideValue
        displayEmpty
        multiple
      />
      <Select
        sx={{ '&.MuiInputBase-root': { width: '190px' } }}
        name={GlobalSearchDetailsFilterFields.serviceTypes}
        value={filters.serviceTypes}
        items={SERVICE_TYPES_OPTIONS}
        placeholder={i18n.t('features.search.filters.serviceTypes')}
        onChange={({ target: { name, value } }): void => setFilter(name, value)}
        hideValue
        displayEmpty
        multiple
      />
      <Select
        sx={{ '&.MuiInputBase-root': { width: '240px' } }}
        name={GlobalSearchDetailsFilterFields.videoVisitAvailability}
        value={filters.videoVisitAvailability || ''}
        items={globalSearchDetailsConfig.GLOBAL_SEARCH_DETAILS_VIDEO_VISIT_AVAILABILITY_ITEMS}
        placeholder={i18n.t('features.search.filters.videoVisitAvailability')}
        onChange={({ target: { name, value } }): void => setFilter(name, value)}
        hideValue
        displayEmpty
      />
    </FlexBox>
  );
};
