import { ROUTE_PATHS } from '@lib/core';
import {
  CalendarIcon,
  CareTeamIcon,
  ClinicsIcon,
  DocumentsIcon,
  EquipeIcon,
  EuroIcon,
  HealthRecordIcon,
  HomeIcon,
  InfoRequestIcon,
  IntramedIcon,
  InvitesIcon,
  NetworkIcon,
  PatientIcon,
  ReminderIcon,
  RequestsIcon,
  SurveysIcon,
} from '@lib/icons';
import { generatePath } from 'react-router-dom';

import { SidebarItems } from './sidebarItems.enum';
import { GetSidebarItemConfig, SidebarConfig } from './types';

const SIDEBAR_CONFIG: SidebarConfig = {
  [SidebarItems.Dashboard]: {
    icon: HomeIcon,
    link: ROUTE_PATHS.dashboard,
  },
  [SidebarItems.Patients]: {
    icon: PatientIcon,
    link: ROUTE_PATHS.patientsList,
  },
  [SidebarItems.Consultations]: {
    icon: RequestsIcon,
    link: '',
  },
  [SidebarItems.OneToOneChats]: {
    icon: undefined,
    link: ROUTE_PATHS.chatsOneToOneList,
  },
  [SidebarItems.ClinicChats]: {
    icon: undefined,
    link: ROUTE_PATHS.chatsClinicList,
  },
  [SidebarItems.Intramed]: {
    icon: IntramedIcon,
    link: ROUTE_PATHS.intramedList,
  },
  [SidebarItems.Equipes]: {
    icon: EquipeIcon,
    link: ROUTE_PATHS.equipesList,
  },
  [SidebarItems.Invites]: {
    icon: InvitesIcon,
    link: ROUTE_PATHS.invites,
  },
  [SidebarItems.Calendar]: {
    icon: CalendarIcon,
    link: ROUTE_PATHS.calendar,
  },
  [SidebarItems.Reminders]: {
    icon: ReminderIcon,
    link: ROUTE_PATHS.reminders,
  },
  [SidebarItems.Info]: {
    icon: InfoRequestIcon,
    link: ROUTE_PATHS.infoList,
  },
  [SidebarItems.Invoices]: {
    icon: EuroIcon,
    link: ROUTE_PATHS.patientInvoices,
  },
  [SidebarItems.Care]: {
    icon: CareTeamIcon,
    link: ROUTE_PATHS.patientCareTeam,
  },
  [SidebarItems.HealthRecord]: {
    icon: HealthRecordIcon,
    // TODO: Understand why importing PATIENT_CARD_TABS.MEDICAL makes test suites collapse, hardcoded for the timebeing.
    link: generatePath(ROUTE_PATHS.patientsSelf, { tabType: 'medical' }),
  },
  [SidebarItems.CarTNetwork]: {
    icon: NetworkIcon,
    link: ROUTE_PATHS.carTNetworkList,
  },
  [SidebarItems.Surveys]: {
    icon: SurveysIcon,
    link: ROUTE_PATHS.surveyList,
  },
  [SidebarItems.Accounting]: {
    icon: EuroIcon,
    link: ROUTE_PATHS.accounting,
  },
  [SidebarItems.Clinics]: {
    icon: ClinicsIcon,
    link: ROUTE_PATHS.clinics,
  },
  [SidebarItems.PortalDocuments]: {
    icon: DocumentsIcon,
    link: ROUTE_PATHS.portalDocuments,
  },
};

export const getSidebarItemConfig: GetSidebarItemConfig = sidebarItem => {
  if (!sidebarItem) return null;

  return SIDEBAR_CONFIG[sidebarItem as SidebarItems];
};
