import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import { DoctorsSecretaryAssociation } from './DoctorsSecretaryAssociation.fragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type DoctorsSecretaryAssociationsVariables = Types.Exact<{ [key: string]: never; }>;


export type DoctorsSecretaryAssociations = { __typename?: 'Query', doctorsSecretaryAssociations: Array<{ __typename?: 'DoctorsSecretaryAssociation', id: string, createdAt: string, isManagingDoctorsInfoRequests: boolean, isManagingPatientsMedicalData: boolean, associatedInstitutionUser: { __typename?: 'InstitutionUser', id: string, profileType: Types.ProfileType, user: { __typename?: 'User', id: string, firstName: string, lastName: string, email?: string | null }, doctor?: { __typename?: 'Doctor', id: string } | null, nonDoctor?: { __typename?: 'NonDoctor', id: string } | null }, onBehalfOfInstitutionUser: { __typename?: 'InstitutionUser', id: string, profileType: Types.ProfileType, user: { __typename?: 'User', id: string, firstName: string, lastName: string, email?: string | null }, doctor?: { __typename?: 'Doctor', id: string } | null, nonDoctor?: { __typename?: 'NonDoctor', id: string } | null } }> };


export const DoctorsSecretaryAssociationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DoctorsSecretaryAssociations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"doctorsSecretaryAssociations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DoctorsSecretaryAssociation"}}]}}]}},...DoctorsSecretaryAssociation.definitions]} as unknown as DocumentNode;

/**
 * __useDoctorsSecretaryAssociations__
 *
 * To run a query within a React component, call `useDoctorsSecretaryAssociations` and pass it any options that fit your needs.
 * When your component renders, `useDoctorsSecretaryAssociations` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorsSecretaryAssociations({
 *   variables: {
 *   },
 * });
 */
export function useDoctorsSecretaryAssociations(baseOptions?: ApolloReactHooks.QueryHookOptions<DoctorsSecretaryAssociations, DoctorsSecretaryAssociationsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DoctorsSecretaryAssociations, DoctorsSecretaryAssociationsVariables>(DoctorsSecretaryAssociationsDocument, options);
      }
export function useDoctorsSecretaryAssociationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DoctorsSecretaryAssociations, DoctorsSecretaryAssociationsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DoctorsSecretaryAssociations, DoctorsSecretaryAssociationsVariables>(DoctorsSecretaryAssociationsDocument, options);
        }
export type DoctorsSecretaryAssociationsHookResult = ReturnType<typeof useDoctorsSecretaryAssociations>;
export type DoctorsSecretaryAssociationsLazyQueryHookResult = ReturnType<typeof useDoctorsSecretaryAssociationsLazyQuery>;
export type DoctorsSecretaryAssociationsQueryResult = Apollo.QueryResult<DoctorsSecretaryAssociations, DoctorsSecretaryAssociationsVariables>;