import { getNonDoctorProfessionalRole } from '@lib/features-bll';
import { getSpecializationLabel } from '@lib/features-ui';
import capitalize from 'lodash-es/capitalize';
import { DependencyList } from 'react';

import { DoctorSpecializationModel, Maybe, OrderByArgs, OrderByKeyword } from '__generated__/types';
import { IError } from 'authorization/types';
import { SortingOrder } from 'graphql/types';
import { INonDoctorProfessionalRole } from 'graphql/types/doctor';
import { IRequestMessageFlag } from 'graphql/types/request';
import { IRowHeaderProps } from 'store/dashboard/types';

const fixSortingOrderToOrderByKeyword = (order?: SortingOrder): OrderByKeyword => order as unknown as OrderByKeyword;

type ErrorCreator = {
  code?: string | undefined;
  message?: string | undefined;
  name?: string | undefined;
};

export const errorCreator = ({ code, name, message }: ErrorCreator): IError => ({
  code: code || '',
  message: message || '',
  name: name || '',
});

export const getDoctorSpecialization = (
  doctorSpecializations: Pick<DoctorSpecializationModel, 'type'>[] = []
): string => {
  const specializations = doctorSpecializations.map(({ type }) => getSpecializationLabel(type));

  return specializations.length > 1 ? specializations.join(', ') : specializations[0] || '';
};

export const getUserSpecialization = (
  doctor?: Maybe<{ specializations: Pick<DoctorSpecializationModel, 'type'>[] }>,
  nonDoctor?: Maybe<{ professionalRole: Pick<INonDoctorProfessionalRole, 'type'>[] }>
): string => {
  if (doctor?.specializations?.[0]) {
    return getDoctorSpecialization(doctor.specializations);
  }

  if (nonDoctor?.professionalRole?.[0]) {
    return getNonDoctorProfessionalRole(nonDoctor.professionalRole);
  }

  return '';
};

export interface ISpecialization {
  id: string;
  type: string;
  title: string;
}

type GeneratedSpecialization = Pick<DoctorSpecializationModel, 'id' | 'type'>;

const generateSpecialization = (specialization: GeneratedSpecialization): ISpecialization => ({
  id: specialization.id,
  type: specialization.type,
  title: getSpecializationLabel(specialization.type),
});

const generateProfessionalRoles = (specialization: INonDoctorProfessionalRole): ISpecialization => ({
  id: specialization.id,
  type: specialization.type,
  title: capitalize(specialization.type.replace(/_/g, ' ')),
});

export const getUserSpecializationArray = (
  doctor: Maybe<{ specializations?: Maybe<GeneratedSpecialization[]> }>,
  nonDoctor: Maybe<{ professionalRole?: Maybe<INonDoctorProfessionalRole[]> }>
): ISpecialization[] => {
  if (doctor && doctor.specializations) {
    return doctor.specializations.map(specialization => generateSpecialization(specialization));
  }

  if (nonDoctor && nonDoctor.professionalRole) {
    return nonDoctor.professionalRole.map(professionalRole => generateProfessionalRoles(professionalRole));
  }

  return [];
};

export const filterValueByNumbersOnly = (value: string): string => value.replace(/[^0-9]/g, '');

export const formatText = (text: string): string => capitalize(text.replace('_', ' '));

export const getOrderByFromHeaders = (tableHeaders: IRowHeaderProps[]): OrderByArgs =>
  tableHeaders
    .filter(({ order }) => order)
    .map(({ filterName, order }) => ({
      field: filterName || '',
      type: fixSortingOrderToOrderByKeyword(order || SortingOrder.DESC),
    }))[0];

export const isMessageRead = (flags?: Maybe<IRequestMessageFlag[]>): boolean =>
  flags && flags[0] ? flags[0].isRead : true;

export const getRandomId = (): string => Math.random().toString(16);

export type MemoisedObject<T, Y extends keyof T> = Pick<T, Y>;
type GetUseMemoArgumentsFromObjectByKeys<T, Y extends keyof T> = [
  () => MemoisedObject<T, Y>,
  DependencyList | undefined,
];

export const getUseMemoArgumentsFromObjectByKeys = <T, Y extends keyof T>(
  object: T,
  keys: Y[]
): GetUseMemoArgumentsFromObjectByKeys<T, Y> => {
  const targetObject = keys.reduce((acc, curr) => ({ ...acc, [curr]: object[curr] }), {} as MemoisedObject<T, Y>);
  const targetDependencies = keys.map(key => object[key]);

  return [() => targetObject, targetDependencies];
};

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  someEnum: T,
  enumValue: string
): keyof T | null {
  const keys = Object.keys(someEnum).filter(key => someEnum[key] === enumValue);

  return keys.length > 0 ? keys[0] : null;
}
