import { PersistedDataKeys } from '@lib/core';
import { Portals } from '@lib/features-bll';
import { useModalState } from '@lib/react-components';

import { usePortalConfiguration, usePortalSlug } from 'features/Portal';
import { useUserModelStore } from 'features/Users/model';
import { persistedData } from 'lib/helpers/persistedData';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';

interface IUseSphereOfInterestModalStateReturn {
  doctorSphereOfInterest: string[];
  openSphereOfInterestModal: VoidFunction;
  closeSphereOfInterestModal: VoidFunction;
  isSphereOfInterestModalOpened: boolean;
  isSphereOfInterestModalAllowed: boolean;
}

export const useRootModelSphereOfInterestModalState = (): IUseSphereOfInterestModalStateReturn => {
  const isMobileAppWebView = useIsMobileAppWebView();
  const { activeDoctor } = useUserModelStore();
  const portalConfiguration = usePortalConfiguration();
  const portalSlug = usePortalSlug();
  const [isSphereOfInterestModalOpened, openSphereOfInterestModal, closeSphereOfInterestModal] = useModalState();

  const doctorSphereOfInterestModalShown = persistedData.get(PersistedDataKeys.DoctorSphereOfInterestModalShown);
  const doctorSphereOfInterest = activeDoctor?.doctor.sphereOfInterest ?? [];
  const isSphereOfInterestModalAllowed =
    portalSlug !== Portals.BRAVOMED &&
    !isMobileAppWebView &&
    !!portalConfiguration?.isPatientSelfRegistrationEnabled &&
    !doctorSphereOfInterestModalShown &&
    !!activeDoctor &&
    (activeDoctor.doctor.sphereOfInterest?.length === 0 || !activeDoctor.doctor.sphereOfInterestUpdatedAt);

  const handleCloseSphereOfInterestModal: VoidFunction = () => {
    persistedData.set(PersistedDataKeys.DoctorSphereOfInterestModalShown, true);
    closeSphereOfInterestModal();
  };

  return {
    doctorSphereOfInterest,
    openSphereOfInterestModal,
    closeSphereOfInterestModal: handleCloseSphereOfInterestModal,
    isSphereOfInterestModalOpened,
    isSphereOfInterestModalAllowed,
  };
};
