import { EMAIL_PARSING_REG_EXP } from '@lib/utils';

import { MINIMUM_PHONE_LENGTH } from 'lib/constants';

export const hideStringFromIndex = (data: string, hideFromIndex: number): string =>
  `${data.substr(0, hideFromIndex)}${data.substr(hideFromIndex).replace(/./g, '*')}`;

export const prepareHiddenPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber.length < MINIMUM_PHONE_LENGTH) {
    return phoneNumber;
  }

  return `${phoneNumber.includes('+') ? '' : '+'}${hideStringFromIndex(phoneNumber, 5)}`;
};

export const prepareHiddenEmail = (email: string): string => {
  if (!EMAIL_PARSING_REG_EXP.test(email)) {
    return email;
  }

  return hideStringFromIndex(email, 2);
};

export const getDataFromJSON = <T>(jsonResponse: string): T | undefined => {
  try {
    return JSON.parse(jsonResponse);
  } catch {
    return undefined;
  }
};
