import { PrivacyUrl } from '@lib/features-bll';
import { Trans } from 'react-i18next';

import { RegistrationUiCheckboxItem } from './RegistrationUiCheckboxItem';
import { useStyles as useCheckboxesAreaStyles } from './styles';

import { PrivacyType } from '__generated__/types';
import { RegistrationCheckboxesAreaFormikProps } from 'features/Registration/types';
import { PrivacyPolicyCheckbox } from 'features/Users/components';
import { i18n } from 'i18n';
import { getFormErrors } from 'lib/hooks/formErrorsHook';

interface IRegistrationUiCheckboxesAreaNonDoctorItems {
  termsOfUse: PrivacyUrl;
  privacyPolicy: PrivacyUrl;
  newsletterConsensus: PrivacyUrl;
}

type RegistrationUiCheckboxesAreaNonDoctorItemsProps = IRegistrationUiCheckboxesAreaNonDoctorItems &
  RegistrationCheckboxesAreaFormikProps;

export const RegistrationUiCheckboxesAreaNonDoctorItems = ({
  values,
  errors,
  touched,
  termsOfUse,
  handleChange,
  privacyPolicy,
  newsletterConsensus,
}: RegistrationUiCheckboxesAreaNonDoctorItemsProps): JSX.Element => {
  const { classes: checkboxesAreaClasses } = useCheckboxesAreaStyles();

  const getError = getFormErrors(errors, touched);

  return (
    <>
      {termsOfUse.enabled && (
        <RegistrationUiCheckboxItem
          label={PrivacyType.TERMS_OF_USE}
          title={i18n.t('components.forms.registrationForm.privacy.rulesOfUse')}
          agreementText={
            <>
              <Trans i18n={i18n} i18nKey="components.forms.registrationForm.privacy.rulesOfUseAgree">
                I declare that I have read and accept the
                <a
                  className={checkboxesAreaClasses.documentLink}
                  href={termsOfUse.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of use
                </a>
              </Trans>
              *
            </>
          }
          values={values}
          handleChange={handleChange}
          errorMessage={
            getError(PrivacyType.TERMS_OF_USE) &&
            i18n.t('components.forms.registrationForm.validationAdvice.rulesOfUse')
          }
        />
      )}
      {privacyPolicy.enabled && (
        <PrivacyPolicyCheckbox
          link={privacyPolicy.link}
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
        />
      )}
      {newsletterConsensus.enabled && (
        <RegistrationUiCheckboxItem
          label={PrivacyType.NEWSLETTER_CONSENSUS}
          title={i18n.t('components.forms.registrationForm.privacy.newsletter')}
          agreementText={i18n.t('components.forms.registrationForm.privacy.newsletterAgree')}
          values={values}
          handleChange={handleChange}
        />
      )}
    </>
  );
};
