import { defaultBorderRadius, getDefaultCardBoxShadow } from '@lib/react-components';
import { Stack, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface IGlobalSearchDetailsResultSectionProps {
  children: ReactNode;
}

export const GlobalSearchDetailsResultSection = ({ children }: IGlobalSearchDetailsResultSectionProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Stack
      sx={{
        borderRadius: `${defaultBorderRadius}px`,
        boxShadow: getDefaultCardBoxShadow(palette.shadow.main),
        padding: '16px 24px',
        backgroundColor: palette.white.main,
        gap: '16px',
      }}
    >
      {children}
    </Stack>
  );
};
