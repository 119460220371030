import { CheckCircleIcon, MaterialSymbolsIcon } from '@lib/icons';
import { Stepper, useBreakpoints } from '@lib/react-components';
import { Box, Typography, useTheme } from '@mui/material';

import { RoleStep } from './components';
import { AffiliationsStep } from './components/AffiliationsStep';
import { SummaryStep } from './components/SummaryStep';
import { AffiliationsActivationSteps } from './enums';
import { useActiveStep } from './hooks/useActiveStep.hook';
import { useStyles } from './styles';

import { i18n } from 'i18n';

interface IActivationStepperProps {
  onSubmit: VoidFunction;
}

export const ActivationStepper = ({ onSubmit }: IActivationStepperProps): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { palette } = useTheme();
  const { isMobileView } = useBreakpoints();

  const { activeStep, setActiveStep, startFromStepTwo } = useActiveStep();

  const steps = [
    i18n.t('features.affiliations.role'),
    i18n.t('features.affiliations.affiliations'),
    i18n.t('features.affiliations.activationStepper.summary'),
  ];

  return (
    <Box>
      <Typography variant={isMobileView ? 'h1' : 'h4'} className={localClasses.title}>
        {i18n.t('features.affiliations.activationStepper.title')}
      </Typography>
      {activeStep !== AffiliationsActivationSteps.Summary || !isMobileView ? (
        <Stepper className={localClasses.stepper} steps={steps} activeStep={activeStep} />
      ) : (
        <MaterialSymbolsIcon
          icon={<CheckCircleIcon />}
          fill={palette.green.main}
          fontSize={100}
          sx={{ width: '100%', margin: '28px 0' }}
        />
      )}
      <Box className={localClasses.contentWrapper}>
        {activeStep === AffiliationsActivationSteps.Role && (
          <RoleStep goToNextStep={(): void => setActiveStep(AffiliationsActivationSteps.Affiliations)} />
        )}
        {activeStep === AffiliationsActivationSteps.Affiliations && (
          <AffiliationsStep
            showRoleInfoBox={startFromStepTwo}
            goToPreviousStep={(): void => setActiveStep(AffiliationsActivationSteps.Role)}
            goToNextStep={(): void => setActiveStep(AffiliationsActivationSteps.Summary)}
          />
        )}
        {activeStep === AffiliationsActivationSteps.Summary && <SummaryStep onSubmit={onSubmit} />}
      </Box>
    </Box>
  );
};
