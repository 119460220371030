import authorization from './authorization/requests';
import dicom from './dicom/requests';
import info from './info/requests';
import patient from './patient/requests';

export default {
  patient,
  dicom,
  info,
  authorization,
};
