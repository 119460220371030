import { makeStyles } from 'tss-react/mui';

const WELMED_PRO_ICON_SIZE = 94;

export const useStyles = makeStyles()(() => ({
  welmedProIcon: {
    width: WELMED_PRO_ICON_SIZE,
    height: WELMED_PRO_ICON_SIZE,
    fill: 'transparent',
  },
}));
