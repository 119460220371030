import { CheckboxWithLabel, useBreakpoints } from '@lib/react-components';
import { Typography } from '@mui/material';

import { GlobalSearchDetailsFilterFields } from '../config';
import { globalSearchDetailsModel } from '../model';

import { GlobalSearchDetails } from 'features/GlobalSearchDetails/ui';
import { i18n } from 'i18n';

export const GlobalSearchDetailsTabDoctorWriteMeFilter = (): JSX.Element => {
  const { isDesktopView } = useBreakpoints();
  const { filters, setFilter } = globalSearchDetailsModel.useGlobalSearchFilters();
  const { writeMeFilterAvailability } =
    globalSearchDetailsModel.useGlobalSearchDetailsDoctorWriteMeFilterAvailability();

  return (
    <GlobalSearchDetails.TabRefineSearchWrapper
      sx={{ '& .MuiFormControlLabel-root': { padding: isDesktopView ? '0 0 0 24px' : '0 0 0 12px' } }}
    >
      <CheckboxWithLabel
        sx={{
          ...(!isDesktopView && {
            '& .MuiSvgIcon-root': {
              width: '20px',
              height: '20px',
            },
          }),
        }}
        disabled={!writeMeFilterAvailability}
        name={GlobalSearchDetailsFilterFields.patientCanWriteOneToOneChatMessage}
        checked={!!filters.patientCanWriteOneToOneChatMessage}
        onChange={({ target: { name } }, checked): void => setFilter(name, checked ? checked.toString() : null)}
        label={
          <Typography sx={{ marginLeft: '10px' }} variant="h9">
            {i18n.t('features.search.tabFilterSection.writeMeFilter')}
          </Typography>
        }
      />
    </GlobalSearchDetails.TabRefineSearchWrapper>
  );
};
