import { FlexBox, useBreakpoints, useOnDeleteChip } from '@lib/react-components';
import { Stack } from '@mui/material';

import { GlobalSearchDetailsFilterFields } from '../config';
import { globalSearchDetailsModel } from '../model';

import { GlobalSearchDetails } from 'features/GlobalSearchDetails/ui';

export const GlobalSearchDetailsTabFiltersSection = (): JSX.Element => {
  const { isDesktopView } = useBreakpoints();
  const { withAffiliationIdsFilter } = globalSearchDetailsModel.useGlobalSearchDetailsAffiliationIdsFilterData();
  const { writeMeFilterAvailability } =
    globalSearchDetailsModel.useGlobalSearchDetailsDoctorWriteMeFilterAvailability();
  const filterChipItems = globalSearchDetailsModel.useGlobalSearchDetailsAffiliationIdsChipItems();
  const {
    filters: { affiliationIds },
    setFilter,
  } = globalSearchDetailsModel.useGlobalSearchFilters();
  const onDeleteChip = useOnDeleteChip({ [GlobalSearchDetailsFilterFields.affiliationIds]: affiliationIds }, setFilter);
  const isOnlyOrderFilterAvailable = !withAffiliationIdsFilter && !writeMeFilterAvailability;

  return (
    <Stack gap={isDesktopView ? '12px' : '20px'} sx={{ padding: isDesktopView ? '24px 24px 12px 24px' : '0 0 20px 0' }}>
      <FlexBox
        width={1}
        flexDirection={isDesktopView ? 'row' : 'column-reverse'}
        justifyContent={isOnlyOrderFilterAvailable ? 'flex-end' : 'space-between'}
        gap="24px"
      >
        {writeMeFilterAvailability && <GlobalSearchDetails.TabDoctorWriteMeFilter />}
        {withAffiliationIdsFilter && <GlobalSearchDetails.TabAffiliationFilter />}
        <GlobalSearchDetails.TabOrderFilter />
      </FlexBox>
      {withAffiliationIdsFilter && !!filterChipItems.length && (
        <GlobalSearchDetails.FilterChips
          sx={{ padding: isDesktopView ? 0 : '0 26px' }}
          filterChipItems={filterChipItems}
          onDeleteChip={onDeleteChip}
          clearFilters={
            isDesktopView ? (): void => setFilter(GlobalSearchDetailsFilterFields.affiliationIds, null) : undefined
          }
        />
      )}
    </Stack>
  );
};
