import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  validationFailed: {
    '& .MuiInput-underline:after': {
      borderBottom: `2px solid ${theme.palette.error.main}`,
      transform: 'scale(1)',
    },
    '& .MuiFormHelperText-root': {
      whiteSpace: 'break-spaces',
    },
  },

  numberInput: {
    '& input': {
      '&[type=number]': {
        MozAppearance: 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
    },
  },
}));
