import { Reducer } from 'redux';

import { Action, initialState, IState } from './types';

export const userReducer: Reducer<IState, Action> = (state: IState = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
