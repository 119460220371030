import { MaterialSymbolsIcon, SuccessIcon } from '@lib/icons';
import { WelmedPro } from '@lib/react-components';
import { useExecuteOnTimeout } from '@lib/utils';
import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { Trans } from 'react-i18next';

import { useStyles } from './styles';

import { i18n } from 'i18n';

const AUTO_CONFIRM_DELAY = 5000;

interface ISetupNativeAppSuccessSectionProps {
  onIdentityVerificationInitialized: VoidFunction;
}

export const SetupNativeAppSuccessSection = ({
  onIdentityVerificationInitialized,
}: ISetupNativeAppSuccessSectionProps): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { palette } = useTheme();

  // Auto confirm proceed to authorize after 5 sec
  useExecuteOnTimeout(onIdentityVerificationInitialized, AUTO_CONFIRM_DELAY);

  return (
    <Stack alignItems="center" justifyContent="center" textAlign="center" mt={3.5}>
      <Typography typography="h6">{i18n.t('features.setupNativeApp.successSection.title')}</Typography>
      <Box position="relative" my={4.25}>
        <MaterialSymbolsIcon
          icon={<SuccessIcon fill={palette.green.main} />}
          fontSize={25}
          sx={{ position: 'absolute', top: 0, right: 0 }}
        />
        <SvgIcon className={localClasses.welmedProIcon} viewBox={WelmedPro.viewBox}>
          {WelmedPro.icon}
        </SvgIcon>
      </Box>
      <Typography typography="body1" marginBottom={6}>
        <Trans
          i18n={i18n}
          i18nKey="features.setupNativeApp.successSection.label"
          components={{
            anchor: <Typography variant="bodyLink2" onClick={onIdentityVerificationInitialized} />,
          }}
        />
      </Typography>
    </Stack>
  );
};
