import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type ChatApiOneToOneChatVariables = Types.Exact<{
  portalUserId: Types.Scalars['String']['input'];
}>;


export type ChatApiOneToOneChat = { __typename?: 'Query', oneToOneChat: { __typename?: 'OneToOneChatModel', id: string } };


export const ChatApiOneToOneChatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ChatApiOneToOneChat"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"portalUserId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"oneToOneChat"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"portalUserId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"portalUserId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useChatApiOneToOneChat__
 *
 * To run a query within a React component, call `useChatApiOneToOneChat` and pass it any options that fit your needs.
 * When your component renders, `useChatApiOneToOneChat` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatApiOneToOneChat({
 *   variables: {
 *      portalUserId: // value for 'portalUserId'
 *   },
 * });
 */
export function useChatApiOneToOneChat(baseOptions: ApolloReactHooks.QueryHookOptions<ChatApiOneToOneChat, ChatApiOneToOneChatVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ChatApiOneToOneChat, ChatApiOneToOneChatVariables>(ChatApiOneToOneChatDocument, options);
      }
export function useChatApiOneToOneChatLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChatApiOneToOneChat, ChatApiOneToOneChatVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ChatApiOneToOneChat, ChatApiOneToOneChatVariables>(ChatApiOneToOneChatDocument, options);
        }
export type ChatApiOneToOneChatHookResult = ReturnType<typeof useChatApiOneToOneChat>;
export type ChatApiOneToOneChatLazyQueryHookResult = ReturnType<typeof useChatApiOneToOneChatLazyQuery>;
export type ChatApiOneToOneChatQueryResult = Apollo.QueryResult<ChatApiOneToOneChat, ChatApiOneToOneChatVariables>;