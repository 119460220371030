import { ExpandLessIcon, MaterialSymbolsIcon } from '@lib/icons';
import {
  defaultBorderRadius,
  defaultTransition,
  FlexBox,
  getDefaultCardBoxShadow,
  Loader,
  zIndexValues,
} from '@lib/react-components';
import { Accordion, AccordionSummary, Stack, Typography, useTheme } from '@mui/material';
import { JSXElementConstructor, useEffect } from 'react';

import { globalSearchDetailsConfig, GlobalSearchDetailsTabType } from '../config';
import { globalSearchDetailsModel, useGlobalSearchDetailsTabChangeHandler } from '../model';

import { GlobalSearchDetails, useGlobalSearchDetailsTabsConfiguration } from 'features/GlobalSearchDetails';

const EXPANDED_Z_INDEX = zIndexValues.draggableModal - zIndexValues.defaultValue * 2;

interface IGlobalSearchDetailsAccordionProps {
  tabType: GlobalSearchDetailsTabType;
  TabComponent: JSXElementConstructor<unknown>;
}

export const GlobalSearchDetailsAccordion = ({
  tabType,
  TabComponent,
}: IGlobalSearchDetailsAccordionProps): JSX.Element => {
  const { palette } = useTheme();
  const handleSearchListTabChange = useGlobalSearchDetailsTabChangeHandler();
  const { tabsCountsByItems, activeTab } = useGlobalSearchDetailsTabsConfiguration();
  const isListLoading = globalSearchDetailsModel.useGlobalSearchDetailsListLoadings();

  const hasOnlyOneTab = Object.keys(tabsCountsByItems).length === 1;
  const hasNoResults = !tabsCountsByItems[tabType];
  const isTabActive = activeTab === tabType;
  const isStickyView = isTabActive && !hasNoResults && !hasOnlyOneTab;

  const accordionRef = globalSearchDetailsModel.useGlobalSearchDetailsAccordionScrollEffect(
    isStickyView && !hasOnlyOneTab && !isListLoading
  );

  useEffect(() => {
    if (hasOnlyOneTab) {
      handleSearchListTabChange(tabType);
    }
  }, [hasOnlyOneTab, tabType, activeTab]);

  return (
    <Accordion
      ref={accordionRef}
      expanded={isTabActive}
      defaultExpanded={hasOnlyOneTab}
      onChange={
        hasOnlyOneTab ? undefined : (_, expanded): void => handleSearchListTabChange(expanded ? tabType : undefined)
      }
      sx={{
        ...(isStickyView && {
          position: 'relative',
          zIndex: EXPANDED_Z_INDEX,
        }),
        transition: defaultTransition,
        '&.MuiPaper-root.Mui-expanded': {
          margin: 0,
        },
      }}
      TransitionProps={{ timeout: 0 }}
    >
      <AccordionSummary
        sx={{
          ...(isStickyView && {
            position: 'sticky',
            top: 0,
            zIndex: EXPANDED_Z_INDEX,
          }),
          transition: defaultTransition,
          padding: isStickyView || hasOnlyOneTab ? '8px 8px 12px' : '2px 8px',
        }}
      >
        <Stack
          sx={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: palette.surface.light,
            padding: '16px',
            borderRadius: `${defaultBorderRadius}px`,
            ...(isStickyView && { boxShadow: getDefaultCardBoxShadow(palette.shadow.main) }),
          }}
        >
          <FlexBox gap="20px" alignItems="center">
            <GlobalSearchDetails.TabIcon tabType={tabType} fill={palette.black.main} />
            <Typography variant="h3" sx={{ width: '50px', textAlign: 'center' }}>
              {tabsCountsByItems[tabType]}
            </Typography>
            <Typography variant="h8">
              {globalSearchDetailsConfig.GLOBAL_SEARCH_DETAILS_TAB_LABEL_MOBILE[tabType]}
            </Typography>
          </FlexBox>
          <MaterialSymbolsIcon
            fill={hasOnlyOneTab ? 'transparent' : palette.black.main}
            icon={<ExpandLessIcon fontWeight="300" />}
            transform={isTabActive ? undefined : 'rotate(180deg)'}
          />
        </Stack>
      </AccordionSummary>
      <Stack
        sx={{
          ...(hasNoResults && {
            backgroundColor: palette.surface.generic,
            borderRadius: '6px',
            margin: '2px 8px',
          }),
        }}
      >
        <Loader loading={isListLoading} withShadow>
          {!hasNoResults && (
            <>
              <GlobalSearchDetails.TabDescription />
              <GlobalSearchDetails.TabFiltersSection />
            </>
          )}
          {isTabActive && <TabComponent />}
        </Loader>
      </Stack>
    </Accordion>
  );
};
