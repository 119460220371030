import { FlexBox, OverflownText, useBreakpoints } from '@lib/react-components';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';

import { useStyles } from './styles';
import { VerifyUserModal } from './VerifyUserModal';
import { IVerifyUserModal } from './VerifyUserModal/types';

import { useRootModelUiStore } from 'features/Root/model';

interface IVerifyUserProps {
  notificationText: string;
  modalProps: Omit<IVerifyUserModal, 'isModalOpened' | 'setModalOpened'>;
}

export const VerifyUser = ({ modalProps, notificationText }: IVerifyUserProps): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { isMobileView } = useBreakpoints();
  const { isFullScreen, isMenuCollapsed } = useRootModelUiStore();
  const [isModalOpened, setModalOpened] = useState<boolean>(false);

  return (
    <>
      <FlexBox
        justifyContent="center"
        alignItems="center"
        className={clsx(
          localClasses.verifyUserNotificationWrapper,
          !isFullScreen && (isMenuCollapsed ? localClasses.sidebarCollapsed : localClasses.sidebarExpanded),
          isFullScreen && localClasses.fullScreen
        )}
        onClick={(): void => setModalOpened(true)}
      >
        {isMobileView ? (
          <Typography textAlign="left" display="block" variant="body3Medium">
            {notificationText}
          </Typography>
        ) : (
          <OverflownText maxWidth="100%">{notificationText}</OverflownText>
        )}
      </FlexBox>
      {isModalOpened && (
        <VerifyUserModal {...modalProps} isModalOpened={isModalOpened} setModalOpened={setModalOpened} />
      )}
    </>
  );
};
