import { ROUTE_PATHS } from '@lib/core';
import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import { useRootModelPath } from 'features/Root/model';
import { i18n } from 'i18n';

interface IGlobalSearchDetailsSsnClinicLinkProps {
  sx?: SxProps<Theme>;
}

export const GlobalSearchDetailsSsnClinicLink = ({ sx }: IGlobalSearchDetailsSsnClinicLinkProps): JSX.Element => {
  const { typography } = useTheme();
  const { getPath } = useRootModelPath();

  return (
    <Box sx={sx} alignSelf="center" ml="auto" mr="26px">
      <Link style={{ ...typography.bodyLink }} to={getPath({ path: ROUTE_PATHS.requestSsnClinics })}>
        {i18n.t('features.search.tabs.label.ssnClinic')}
      </Link>
    </Box>
  );
};
