import { hasErrorByErrorCode, useNotifications } from '@lib/core';
import { NotificationType } from '@lib/react-components';

import { ErrorCodes } from '__generated__/types';
import {
  UnauthorizedUserChatMessageApiSendDraftDirectMessage,
  useUnauthorizedUserChatMessageApiSendDraftDirectMessage,
} from 'features/UnauthorizedUserChatMessage/api';
import { IUnauthorizedUserChatMessageWriteToFormValues } from 'features/UnauthorizedUserChatMessage/types';
import { i18n } from 'i18n';

interface IUseUnauthorizedUserChatMessageModelSendDraftDirectMessageProps {
  onError: VoidFunction;
  onCompleted: (
    sendDraftDirectMessage: UnauthorizedUserChatMessageApiSendDraftDirectMessage['sendDraftDirectMessage']
  ) => void;
  doctorPortalUserId?: string;
  clinicId?: string;
}

export interface IUseUnauthorizedUserChatMessageModelSendDraftDirectMessageReturn {
  handleSendDraftDirectMessage: (formValue: IUnauthorizedUserChatMessageWriteToFormValues) => void;
  isDraftDirectMessageSending: boolean;
}

export const useUnauthorizedUserChatMessageModelSendDraftDirectMessage = ({
  onError,
  onCompleted,
  doctorPortalUserId,
  clinicId,
}: IUseUnauthorizedUserChatMessageModelSendDraftDirectMessageProps): IUseUnauthorizedUserChatMessageModelSendDraftDirectMessageReturn => {
  const { showNotification } = useNotifications();

  const [sendDraftDirectMessage, { loading: isDraftDirectMessageSending }] =
    useUnauthorizedUserChatMessageApiSendDraftDirectMessage({
      notifications: {
        onError: false,
      },
      onError: (errors): void => {
        if (hasErrorByErrorCode(errors.graphQLErrors, ErrorCodes.CHAT_WRITE_POLICY_ACCESS_DENIED)) {
          onError();
        } else {
          showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
        }
      },
      onCompleted: data => onCompleted(data.sendDraftDirectMessage),
    });

  const handleSendDraftDirectMessage: IUseUnauthorizedUserChatMessageModelSendDraftDirectMessageReturn['handleSendDraftDirectMessage'] =
    ({ email, text, attachments }) => {
      sendDraftDirectMessage({
        variables: {
          email,
          text: text || null,
          attachmentFileIds: attachments?.map(({ id }) => id),
          doctorPortalUserId: doctorPortalUserId || null,
          clinicId: clinicId || null,
        },
      });
    };

  return {
    handleSendDraftDirectMessage,
    isDraftDirectMessageSending,
  };
};
