import { ROUTE_PATHS } from '@lib/core';

import { useRootModelInfoRedirectByState, useRootModelPath } from 'features/Root/model';

export const useRootModelRedirectByState = (): string | undefined => {
  const { getPath } = useRootModelPath();
  const infoRedirect = useRootModelInfoRedirectByState();

  if (infoRedirect) {
    return getPath({ path: ROUTE_PATHS.dashboard });
  }

  return undefined;
};
