import { PersistedDataKeys } from '@lib/core';
import { useEffect } from 'react';

import { useUserModelStore } from 'features/Users/model';
import { persistedData } from 'lib/helpers/persistedData';

export const useRootModelOnProfileChange: VoidFunction = () => {
  const { activeProfile } = useUserModelStore();

  useEffect(() => {
    if (activeProfile) {
      if (!activeProfile.hasPostRegister) {
        persistedData.set(PersistedDataKeys.ActiveProfileId, activeProfile.id);
      }
    }
  }, [activeProfile?.id, activeProfile?.hasPostRegister]);
};
