import { loginOrRegisterBlockingModalModel } from 'features/Users/components';
import { useUserModelStore } from 'features/Users/model';

interface IUseLoginOrRegisterBlockingModalActiveProfile {
  isUnauthorizedUser: boolean;
  isUserWithoutPatientProfile: boolean;
  isAuthorizedUserWithPatientProfile: boolean;
}

export const useLoginOrRegisterBlockingModalActiveProfile = (
  isUnauthorizedUserWithoutPatientProfile: boolean
): IUseLoginOrRegisterBlockingModalActiveProfile => {
  const { activeProfile } = useUserModelStore();
  const { patientProfile } = loginOrRegisterBlockingModalModel.useLoginOrRegisterBlockingModalPatientProfile();

  return {
    isUnauthorizedUser: !activeProfile && !isUnauthorizedUserWithoutPatientProfile,
    isAuthorizedUserWithPatientProfile: !!activeProfile && !!patientProfile,
    isUserWithoutPatientProfile: (!!activeProfile && !patientProfile) || isUnauthorizedUserWithoutPatientProfile,
  };
};
