import { ExpandLessIcon, MaterialSymbolsIcon } from '@lib/icons';
import { FlexBox, zIndexValues } from '@lib/react-components';
import { useTheme } from '@mui/material';

const BUTTON_SIZE = '44px';

interface IGlobalSearchDetailsScrollTopBottomButtonProps {
  onClick: VoidFunction;
}

export const GlobalSearchDetailsScrollTopBottomButton = ({
  onClick,
}: IGlobalSearchDetailsScrollTopBottomButtonProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <FlexBox
      onClick={onClick}
      sx={{
        width: BUTTON_SIZE,
        height: BUTTON_SIZE,
        position: 'fixed',
        right: '15px',
        bottom: '48px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: palette.primary.main,
        borderRadius: '50%',
        zIndex: zIndexValues.draggableModal - zIndexValues.defaultValue,
      }}
    >
      <MaterialSymbolsIcon fill={palette.white.main} icon={<ExpandLessIcon fontWeight="300" />} />
    </FlexBox>
  );
};
