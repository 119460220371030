import { Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

import { globalSearchDetailsModel, GlobalSearchDetails } from 'features/GlobalSearchDetails';
import { i18n } from 'i18n';

export const GlobalSearchDetailsResultTitle = (): JSX.Element => {
  const { search } = globalSearchDetailsModel.useGlobalSearchFilters();
  const resultTitle = globalSearchDetailsModel.useResultSearchDetailsTitle();

  return (
    <Stack textAlign="center" gap="2px">
      {search && (
        <Typography>
          {i18n.t('features.search.filterSection.search')} <Typography variant="h8">{search}</Typography>
        </Typography>
      )}
      <Typography>
        <Trans
          i18n={i18n}
          i18nKey="features.search.filterSection.searchResult"
          components={{ title: <Typography variant="h8" /> }}
          values={{ resultTitle }}
        />
      </Typography>
      <GlobalSearchDetails.ContactsLabel sx={{ marginTop: '8px' }} />
    </Stack>
  );
};
