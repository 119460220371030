import { makeVar, useReactiveVar } from '@apollo/client';
import { DeepPartial, mergeDeep } from '@apollo/client/utilities';
import { AuthenticatedUser, ProfileListInstitutionUser, UsersApiGenericUser } from '@lib/features-bll';
import { isNil, keyBy } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useUserModelSetPriorProfileType } from './useUserModelSetPriorProfileType';

import { Maybe, ProfileType } from '__generated__/types';
import { IError } from 'authorization/types';
import { useAttachmentsStore } from 'features/Attachments/model/useAttachmentsStore';
import { signOut } from 'store/user/actionCreators';

type WithOptionalUser = { user?: UsersApiGenericUser };
type WithPostRegister = { hasPostRegister?: boolean };

export type ProfileListItem = ProfileListInstitutionUser & WithOptionalUser & WithPostRegister;

type UserModelStoreBase = {
  userError: Maybe<IError>;
  isUserEmailVerified: boolean;
  isUserTelephoneNumberVerified: boolean;
  profileList: ProfileListItem[];
  userInfo: Maybe<AuthenticatedUser['authenticatedUser']>;
  canSwitchToPatient: boolean;
};

type UserModelStoreAnonymous = {
  activeProfileType: null;
  activeProfile: null;
  activePatient: null;
  activeDoctor: null;
  activeNonDoctor: null;
  isPatient: false;
  isDoctor: false;
  isNonDoctor: false;
  roles: {
    [ProfileType.PATIENT]: null;
    [ProfileType.DOCTOR]: null;
    [ProfileType.NON_DOCTOR]: null;
  };
} & UserModelStoreBase;

export type PatientProfileListItem = Omit<ProfileListItem, 'doctor' | 'nonDoctor'> & {
  patient: NonNullable<ProfileListItem['patient']>;
};
type UserModelStorePatient = {
  activeProfileType: ProfileType.PATIENT;
  activeProfile: PatientProfileListItem;
  activePatient: PatientProfileListItem;
  activeDoctor: null;
  activeNonDoctor: null;
  isPatient: true;
  isDoctor: false;
  isNonDoctor: false;
  roles: {
    [ProfileType.PATIENT]: PatientProfileListItem;
    [ProfileType.DOCTOR]: Maybe<DoctorProfileListItem>;
    [ProfileType.NON_DOCTOR]: Maybe<NonDoctorProfileListItem>;
  };
} & UserModelStoreBase;

export type DoctorProfileListItem = Omit<ProfileListItem, 'patient' | 'nonDoctor'> & {
  doctor: NonNullable<ProfileListItem['doctor']>;
};
type UserModelStoreDoctor = {
  activeProfileType: ProfileType.DOCTOR;
  activeProfile: DoctorProfileListItem;
  activePatient: null;
  activeDoctor: DoctorProfileListItem;
  activeNonDoctor: null;
  isPatient: false;
  isDoctor: true;
  isNonDoctor: false;
  roles: {
    [ProfileType.PATIENT]: Maybe<PatientProfileListItem>;
    [ProfileType.DOCTOR]: DoctorProfileListItem;
    [ProfileType.NON_DOCTOR]: Maybe<NonDoctorProfileListItem>;
  };
} & UserModelStoreBase;

type NonDoctorProfileListItem = Omit<ProfileListItem, 'patient' | 'doctor'> & {
  nonDoctor: NonNullable<ProfileListItem['nonDoctor']>;
};
type UserModelStoreNonDoctor = {
  activeProfileType: ProfileType.NON_DOCTOR;
  activeProfile: NonDoctorProfileListItem;
  activePatient: null;
  activeDoctor: null;
  activeNonDoctor: NonDoctorProfileListItem;
  isPatient: false;
  isDoctor: false;
  isNonDoctor: true;
  roles: {
    [ProfileType.PATIENT]: Maybe<PatientProfileListItem>;
    [ProfileType.DOCTOR]: Maybe<DoctorProfileListItem>;
    [ProfileType.NON_DOCTOR]: NonDoctorProfileListItem;
  };
} & UserModelStoreBase;

type UserModelStore = UserModelStoreAnonymous | UserModelStorePatient | UserModelStoreDoctor | UserModelStoreNonDoctor;

type UserModelActions = {
  setUserError: (userError: UserModelStore['userError']) => void;
  setIsUserEmailVerified: (isUserEmailVerified: UserModelStore['isUserEmailVerified']) => void;
  setIsUserTelephoneNumberVerified: (
    isUserTelephoneNumberVerified: UserModelStore['isUserTelephoneNumberVerified']
  ) => void;
  setProfileList: (profileList: UserModelStore['profileList']) => void;
  setActiveProfileType: (activeProfileType: UserModelStore['activeProfileType']) => void;
  setUserInfo: (userInfo: UserModelStore['userInfo']) => void;
  updateUserInfo: (updatedUserInfo: DeepPartial<UserModelStore['userInfo']>) => void;
  updatePatientRole: (updatedPatientRole: DeepPartial<ProfileListItem['patient']>) => void;
  updateDoctorRole: (updatedDoctorRole: DeepPartial<ProfileListItem['doctor']>) => void;
  updateNonDoctorRole: (updatedNonDoctorRole: DeepPartial<ProfileListItem['nonDoctor']>) => void;
};

type UseUserModelStore = () => UserModelStore & UserModelActions;

const userErrorVar = makeVar<UserModelStore['userError']>(null);
const isUserEmailVerifiedVar = makeVar<UserModelStore['isUserEmailVerified']>(false);
const isUserTelephoneNumberVerifiedVar = makeVar<UserModelStore['isUserTelephoneNumberVerified']>(false);

const profileListVar = makeVar<UserModelStore['profileList']>([]);
const activeProfileTypeVar = makeVar<UserModelStore['activeProfileType']>(null);
const userInfoVar = makeVar<UserModelStore['userInfo']>(null);

const setUserError: UserModelActions['setUserError'] = userErrorVar;
const setIsUserEmailVerified: UserModelActions['setIsUserEmailVerified'] = isUserEmailVerifiedVar;
const setIsUserTelephoneNumberVerified: UserModelActions['setIsUserTelephoneNumberVerified'] =
  isUserTelephoneNumberVerifiedVar;
const setProfileList: UserModelActions['setProfileList'] = profileListVar;
const setActiveProfileType: UserModelActions['setActiveProfileType'] = activeProfileTypeVar;
const setUserInfo: UserModelActions['setUserInfo'] = userInfoVar;

export const useUserModelStore: UseUserModelStore = () => {
  const dispatch = useDispatch();
  const userError = useReactiveVar(userErrorVar);
  const isUserEmailVerified = useReactiveVar(isUserEmailVerifiedVar);
  const isUserTelephoneNumberVerified = useReactiveVar(isUserTelephoneNumberVerifiedVar);
  const profileList = useReactiveVar(profileListVar);
  const activeProfileType = useReactiveVar(activeProfileTypeVar);
  const userInfo = useReactiveVar(userInfoVar);
  const { removeAttachments } = useAttachmentsStore();
  const setPriorProfileType = useUserModelSetPriorProfileType();

  useEffect(() => {
    if (isNil(userInfo)) {
      removeAttachments();
      dispatch(signOut);
    }
  }, [userInfo]);

  useEffect(() => {
    if (activeProfileType) {
      setPriorProfileType(activeProfileType);
    }
  }, [activeProfileType]);

  const updateUserInfo: UserModelActions['updateUserInfo'] = useCallback(
    updatedUserInfo => {
      mergeDeep(userInfo, updatedUserInfo);
    },
    [userInfo]
  );

  const updateRole = useCallback(
    ({
      updatedRole,
      profileType,
    }:
      | {
          updatedRole: DeepPartial<ProfileListItem['patient']>;
          profileType: ProfileType.PATIENT;
        }
      | {
          updatedRole: DeepPartial<ProfileListItem['doctor']>;
          profileType: ProfileType.DOCTOR;
        }
      | {
          updatedRole: DeepPartial<ProfileListItem['nonDoctor']>;
          profileType: ProfileType.NON_DOCTOR;
        }) => {
      const newProfileList = profileList.map(profile => {
        if (profile.profileType === profileType) {
          switch (profileType) {
            case ProfileType.PATIENT:
              return { ...profile, patient: { ...profile.patient, ...updatedRole } } as ProfileListItem;
            case ProfileType.DOCTOR:
              return { ...profile, doctor: { ...profile.doctor, ...updatedRole } } as ProfileListItem;
            case ProfileType.NON_DOCTOR:
              return { ...profile, nonDoctor: { ...profile.nonDoctor, ...updatedRole } } as ProfileListItem;

            default:
              return profile;
          }
        }

        return profile;
      });
      setProfileList(newProfileList);
    },
    [profileList]
  );
  const updatePatientRole: UserModelActions['updatePatientRole'] = useCallback(
    updatedPatientRole => {
      updateRole({ updatedRole: updatedPatientRole, profileType: ProfileType.PATIENT });
    },
    [updateRole]
  );
  const updateDoctorRole: UserModelActions['updateDoctorRole'] = useCallback(
    updatedDoctorRole => {
      updateRole({ updatedRole: updatedDoctorRole, profileType: ProfileType.DOCTOR });
    },
    [updateRole]
  );
  const updateNonDoctorRole: UserModelActions['updateNonDoctorRole'] = useCallback(
    updatedNonDoctorRole => {
      updateRole({ updatedRole: updatedNonDoctorRole, profileType: ProfileType.NON_DOCTOR });
    },
    [updateRole]
  );

  const baseStore = {
    userError,
    setUserError,
    isUserEmailVerified,
    setIsUserEmailVerified,
    isUserTelephoneNumberVerified,
    setIsUserTelephoneNumberVerified,
    setProfileList,
    setActiveProfileType,
    userInfo,
    setUserInfo,
    updateUserInfo,
    updatePatientRole,
    updateDoctorRole,
    updateNonDoctorRole,
    profileList,
  };

  const roles = useMemo(
    () =>
      keyBy(profileList, 'profileType') as {
        [ProfileType.PATIENT]: Maybe<PatientProfileListItem>;
        [ProfileType.DOCTOR]: Maybe<DoctorProfileListItem>;
        [ProfileType.NON_DOCTOR]: Maybe<NonDoctorProfileListItem>;
      },
    [profileList]
  );

  if (activeProfileType === ProfileType.PATIENT) {
    return {
      ...baseStore,
      activeProfileType,
      activeProfile: roles[activeProfileType]!,
      activePatient: roles[activeProfileType]!,
      activeDoctor: null,
      activeNonDoctor: null,
      isPatient: true,
      isDoctor: false,
      isNonDoctor: false,
      canSwitchToPatient: false,
      roles: {
        [ProfileType.PATIENT]: roles[activeProfileType]!,
        [ProfileType.DOCTOR]: roles[ProfileType.DOCTOR],
        [ProfileType.NON_DOCTOR]: roles[ProfileType.NON_DOCTOR],
      },
    };
  }

  if (activeProfileType === ProfileType.DOCTOR) {
    return {
      ...baseStore,
      activeProfileType,
      activeProfile: roles[activeProfileType]!,
      activePatient: null,
      activeDoctor: roles[activeProfileType]!,
      activeNonDoctor: null,
      isPatient: false,
      isDoctor: true,
      isNonDoctor: false,
      canSwitchToPatient: !!roles[ProfileType.PATIENT],
      roles: {
        [ProfileType.PATIENT]: roles[ProfileType.PATIENT],
        [ProfileType.DOCTOR]: roles[activeProfileType]!,
        [ProfileType.NON_DOCTOR]: roles[ProfileType.NON_DOCTOR],
      },
    };
  }

  if (activeProfileType === ProfileType.NON_DOCTOR) {
    return {
      ...baseStore,
      activeProfileType,
      activeProfile: roles[activeProfileType]!,
      activePatient: null,
      activeDoctor: null,
      activeNonDoctor: roles[activeProfileType]!,
      isPatient: false,
      isDoctor: false,
      isNonDoctor: true,
      canSwitchToPatient: !!roles[ProfileType.PATIENT],
      roles: {
        [ProfileType.PATIENT]: roles[ProfileType.PATIENT],
        [ProfileType.DOCTOR]: roles[ProfileType.DOCTOR],
        [ProfileType.NON_DOCTOR]: roles[activeProfileType]!,
      },
    };
  }

  return {
    ...baseStore,
    activeProfileType,
    activeProfile: null,
    activePatient: null,
    activeDoctor: null,
    activeNonDoctor: null,
    isPatient: false,
    isDoctor: false,
    isNonDoctor: false,
    canSwitchToPatient: false,
    roles: {
      [ProfileType.PATIENT]: null,
      [ProfileType.DOCTOR]: null,
      [ProfileType.NON_DOCTOR]: null,
    },
  };
};
