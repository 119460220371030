export const APP_WRAPPER_ID = 'app';

export const HEADER_ID = 'page-header';

export const MINIMUM_PHONE_LENGTH = 5;

export const getHeaderHeight = (): number => {
  const header = document.getElementById(HEADER_ID);

  return (header && header.clientHeight) || 0;
};

export const COMPONENT_FADE_TIMEOUT = 500;

export const DATE_VALID_LENGTH_REG_EXP = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;
