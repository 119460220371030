import { NotificationType, useNotifications } from '@lib/core';
import { useQueryParams } from '@lib/react-components';
import { useEffect } from 'react';

import { usePatientJoinAffiliation } from '../graphql/__generated__/PatientJoinAffiliation.mutation';

import { i18n } from 'i18n';

export const useJoinAffiliationForPatientEffect = (): void => {
  const { showNotification } = useNotifications();
  const { c: affiliationCode } = useQueryParams<{ c: string }>();
  const [patientJoinAffiliation] = usePatientJoinAffiliation({
    onCompleted: () =>
      showNotification(i18n.t('features.affiliations.patientAffiliations.joinSuccess'), NotificationType.SUCCESS),
    onError: () =>
      showNotification(i18n.t('features.affiliations.patientAffiliations.joinFailure'), NotificationType.FAIL),
  });

  useEffect(() => {
    if (affiliationCode) {
      patientJoinAffiliation({
        variables: {
          code: affiliationCode as string,
        },
      });
    }
  }, [affiliationCode]);
};
