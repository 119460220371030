import { gql } from '@apollo/client';

const PDF_DATA_FRAGMENT = gql`
  fragment PdfDataFragment on PDFDataStorage {
    id
    fileType
    data {
      title
      subjects
      multidisciplinaryTeam {
        firstName
        lastName
        gender
        specializations
        titleCode
      }
      additions {
        subjects
        addedAt
        type
      }
    }
    isInvalidated
  }
`;

export const FILE_FRAGMENT = gql`
  fragment FileFragment on File {
    id
    tag
    label
    uploadedAt
    type
    uploaderInstitutionUser {
      id
      profileType
      user {
        firstName
        lastName
      }
    }
    pdfData {
      ...PdfDataFragment
    }
    dateOfExecution
    isCancellable
    isModifiable
    cancelledAt
    hasAnyPdfParametersRevision
    pdfParametersRevision {
      completionPercent
      lastUpdatedAt
    }
  }
  ${PDF_DATA_FRAGMENT}
`;

export const FILE_MODEL_FRAGMENT = gql`
  fragment FileModelFragment on FileModel {
    id
    tag
    label
    uploadedAt
    uploaderInstitutionUser {
      id
      profileType
      user {
        firstName
        lastName
      }
    }
    pdfData {
      ...PdfDataFragment
    }
    dateOfExecution
    isCancellable
    isModifiable
  }
  ${PDF_DATA_FRAGMENT}
`;
