import { useDoctorAffiliationsToPrompt as useDoctorAffiliationsToPromptQuery } from '../graphql/__generated__/DoctorAffiliationsToPrompt.query';

import { useUserModelStore } from 'features/Users/model';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';

export const useDoctorAffiliationsToPrompt = (): ReturnType<typeof useDoctorAffiliationsToPromptQuery> => {
  const isMobileAppWebView = useIsMobileAppWebView();
  const { isDoctor, activeProfile } = useUserModelStore();

  return useDoctorAffiliationsToPromptQuery({
    fetchPolicy: 'cache-first',
    skip: !isDoctor || activeProfile?.hasPostRegister || isMobileAppWebView,
    notifications: {
      // TODO: Hiding error message due to BE showing error right after doctor registration for this query
      // TODO: Check if this is actually working fine right now
      onError: false,
    },
  });
};
