import { RefObject, useEffect, useRef } from 'react';

import { useGlobalSearchDetailsTabsConfiguration } from 'features/GlobalSearchDetails';

interface IUseGlobalSearchDetailsSetAccordionsWrapperMinHeightEffectReturn {
  accordionsWrapper: RefObject<HTMLDivElement>;
}

export const useGlobalSearchDetailsSetAccordionsWrapperMinHeightEffect =
  (): IUseGlobalSearchDetailsSetAccordionsWrapperMinHeightEffectReturn => {
    const accordionsWrapper = useRef<HTMLDivElement>(null);
    const { activeTab } = useGlobalSearchDetailsTabsConfiguration();

    useEffect(() => {
      setTimeout(() => {
        if (accordionsWrapper.current) {
          accordionsWrapper.current.style.minHeight = `calc(100vh - ${
            accordionsWrapper.current.getBoundingClientRect().top
          }px)`;
        }
      }, 0);
    }, [activeTab]);

    return { accordionsWrapper };
  };
