import { ArrowRightIcon, MaterialSymbolsIcon } from '@lib/icons';
import { defaultBorderRadius, FlexBox, useBreakpoints } from '@lib/react-components';
import { Typography, useTheme } from '@mui/material';

import { i18n } from 'i18n';

interface IGlobalSearchDetailsCardProfileLinkProps {
  onProfileLinkClickHandler: VoidFunction;
  noDetailsView?: boolean;
}

export const GlobalSearchDetailsCardProfileLink = ({
  noDetailsView,
  onProfileLinkClickHandler,
}: IGlobalSearchDetailsCardProfileLinkProps): JSX.Element => {
  const { palette } = useTheme();
  const { isDesktopView } = useBreakpoints();

  return (
    <FlexBox
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: isDesktopView ? 'flex-end' : 'center',
        ...(noDetailsView
          ? {
              ...(isDesktopView
                ? { padding: '0 24px 14px' }
                : { backgroundColor: palette.surface.generic, marginTop: '24px' }),
            }
          : {
              backgroundColor: palette.surface.light,
              ...(isDesktopView && { padding: '16px 24px' }),
            }),
        ...(!isDesktopView && {
          padding: '14px',
        }),
        borderRadius: `0 0 ${defaultBorderRadius}px ${defaultBorderRadius}px`,
        cursor: 'pointer',
      }}
      onClick={onProfileLinkClickHandler}
    >
      <Typography variant="button2" display="flex" color={palette.primary.main}>
        {i18n.t('features.search.card.label.seeFullProfile')}
        <MaterialSymbolsIcon
          icon={<ArrowRightIcon fontWeight="300" />}
          fill={palette.primary.main}
          fontSize={20}
          marginLeft="10px"
        />
      </Typography>
    </FlexBox>
  );
};
