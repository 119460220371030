import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type AddPatientProfileVariables = Types.Exact<{ [key: string]: never; }>;


export type AddPatientProfile = { __typename?: 'Mutation', addPatientProfile: { __typename?: 'PatientPortalUserModel', id: string } };


export const AddPatientProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddPatientProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addPatientProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type AddPatientProfileMutationFn = Apollo.MutationFunction<AddPatientProfile, AddPatientProfileVariables>;

/**
 * __useAddPatientProfile__
 *
 * To run a mutation, you first call `useAddPatientProfile` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientProfile` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientProfile, { data, loading, error }] = useAddPatientProfile({
 *   variables: {
 *   },
 * });
 */
export function useAddPatientProfile(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPatientProfile, AddPatientProfileVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddPatientProfile, AddPatientProfileVariables>(AddPatientProfileDocument, options);
      }
export type AddPatientProfileHookResult = ReturnType<typeof useAddPatientProfile>;
export type AddPatientProfileMutationResult = Apollo.MutationResult<AddPatientProfile>;
export type AddPatientProfileMutationOptions = Apollo.BaseMutationOptions<AddPatientProfile, AddPatientProfileVariables>;