import { PlacesAutocomplete, GsdHsrUiResidenceAddressModal } from '@lib/features-ui';

import { SettingsModalWrapper } from 'components/SettingsModalWrapper';
import { useProfileSettingsModelEditResidenceAddress } from 'features/ProfileSettings/model';
import { i18n } from 'i18n';

type ProfileSettingsUiUpdateResidenceAddressDataModalProps = {
  lastValue?: string;
  isNotDraggable?: boolean;
  onCancel: VoidFunction;
  onSuccess: VoidFunction;
  isCancelButtonHidden?: boolean;
};

export const ProfileSettingsUiUpdateResidenceAddressDataModal = ({
  lastValue,
  isNotDraggable,
  onCancel,
  onSuccess,
  isCancelButtonHidden,
}: ProfileSettingsUiUpdateResidenceAddressDataModalProps): JSX.Element => {
  const {
    textValue,
    handleSave,
    addressDetails,
    residenceAddressErrorText,
    handleResidenceAddressChange,
    handleResidenceAddressErrorsBlur,
    handleResidenceAddressDetailsSave,
    closeResidenceAddressDetailsModal,
    isResidenceAddressDetailsModalOpened,
  } = useProfileSettingsModelEditResidenceAddress({ lastValue, onSuccess });

  return (
    <>
      <SettingsModalWrapper
        title={i18n.t(
          isNotDraggable
            ? 'pages.profileSettings.fields.residenceAddressForceUpdate'
            : 'pages.profileSettings.fields.residenceAddress.modalTitle'
        )}
        onCancel={onCancel}
        onSave={handleSave}
        isSaveButtonDisabled={!addressDetails}
        isDraggable={!isNotDraggable}
        isCancelButtonHidden={!!isCancelButtonHidden}
      >
        <PlacesAutocomplete
          onChange={handleResidenceAddressChange}
          onBlur={({ target: { value } }): void => handleResidenceAddressErrorsBlur(value)}
          defaultValue={textValue}
          inputLabel={i18n.t('pages.profileSettings.fields.residenceAddress.label')}
          inputHelperText={i18n.t('components.forms.registrationForm.helperText.residenceAddress')}
          inputErrorText={residenceAddressErrorText}
          disableBrowserAutocomplete
          disableGoogleAutocomplete="update-residence-address-data-modal"
        />
      </SettingsModalWrapper>
      {isResidenceAddressDetailsModalOpened && (
        <GsdHsrUiResidenceAddressModal
          onCancel={closeResidenceAddressDetailsModal}
          onSubmitCallback={handleResidenceAddressDetailsSave}
        />
      )}
    </>
  );
};
