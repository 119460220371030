import { StatusCard } from '@lib/react-components';
import { SxProps, Theme } from '@mui/material';
import { Trans } from 'react-i18next';

import { i18n } from 'i18n';

interface ISphereOfInterestStatusCardProps {
  sx?: SxProps<Theme>;
}

export const SphereOfInterestStatusCard = ({ sx }: ISphereOfInterestStatusCardProps): JSX.Element => (
  <StatusCard
    text={<Trans i18n={i18n} i18nKey="pages.profileSettings.fields.fieldOfInterestInstructions" />}
    classes={{ text: 'fieldOfInterestInstructions' }}
    sx={{
      ...sx,
      '& .fieldOfInterestInstructions': {
        width: '100%',
      },
    }}
    textVariant="info"
  />
);
