import { getFormErrors } from '@lib/react-components';
import { FormikErrors, FormikProps, useFormik } from 'formik';

import { IUseUnauthorizedUserChatMessageModelSendDraftDirectMessageReturn } from './useUnauthorizedUserChatMessageModelSendDraftDirectMessage';
import { useUnauthorizedUserChatMessageModelWriteToFormValidation } from './useUnauthorizedUserChatMessageModelWriteToFormValidation';

import { IUnauthorizedUserChatMessageWriteToFormValues } from 'features/UnauthorizedUserChatMessage/types';

interface IUseUnauthorizedUserChatMessageModelWriteToFormProps {
  onSubmit: IUseUnauthorizedUserChatMessageModelSendDraftDirectMessageReturn['handleSendDraftDirectMessage'];
}

export interface IUseUnauthorizedUserChatMessageModelWriteToFormReturn
  extends Pick<
    FormikProps<IUnauthorizedUserChatMessageWriteToFormValues>,
    'handleBlur' | 'handleChange' | 'handleSubmit' | 'values' | 'setFieldValue'
  > {
  getError: <U extends keyof IUnauthorizedUserChatMessageWriteToFormValues>(
    name: U
  ) => FormikErrors<IUnauthorizedUserChatMessageWriteToFormValues>[U];
  isSubmitDisabled: boolean;
}

export const useUnauthorizedUserChatMessageModelWriteToForm = ({
  onSubmit,
}: IUseUnauthorizedUserChatMessageModelWriteToFormProps): IUseUnauthorizedUserChatMessageModelWriteToFormReturn => {
  const { verificationUnauthorizedUserChatMessageModelWriteToFormSchema } =
    useUnauthorizedUserChatMessageModelWriteToFormValidation();

  const { values, handleSubmit, handleBlur, handleChange, setFieldValue, isValid, isSubmitting, errors, touched } =
    useFormik<IUnauthorizedUserChatMessageWriteToFormValues>({
      validateOnMount: true,
      initialValues: { email: '', text: null, attachments: null },
      validationSchema: verificationUnauthorizedUserChatMessageModelWriteToFormSchema,
      onSubmit: formValues => {
        onSubmit(formValues);
      },
    });

  const getError = getFormErrors(errors, touched);

  return {
    values,
    isSubmitDisabled: !isValid || isSubmitting,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    getError,
  };
};
