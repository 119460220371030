import { ROUTE_PATHS } from '@lib/core';
import { lazy } from 'react';

import { bravomedBlockedPageGuard, patientSelfRegistrationPageGuard } from '../guards';
import { IRoute } from '../types';

import { ProfileType } from '__generated__/types';

const IN_APP_BROWSER_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.inAppBrowser,
    component: lazy(() => import('pages/InAppBrowser')),
    exact: true,
  },
];

const SEARCH_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.searchByTabType,
    component: lazy(() => import('pages/Search')),
    exact: true,
    portalSettingsGuards: [patientSelfRegistrationPageGuard, bravomedBlockedPageGuard],
  },
];

const DOCTOR_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.doctor,
    component: lazy(() => import('pages/Request/Doctor/Private')),
    exact: true,
  },
  {
    path: ROUTE_PATHS.doctorService,
    component: lazy(() => import('pages/Request/Doctor/PrivateService')),
    authorizedRoles: [ProfileType.PATIENT],
    forbiddenAction: true,
    exact: true,
  },
];

const CLINIC_ROUTES: IRoute[] = [
  {
    path: ROUTE_PATHS.clinic,
    component: lazy(() => import('pages/Request/Clinic/View')),
    exact: true,
  },
  {
    path: ROUTE_PATHS.clinicService,
    component: lazy(() => import('pages/Request/Clinic/ClinicService')),
    authorizedRoles: [ProfileType.PATIENT],
    forbiddenAction: true,
    exact: true,
  },
  {
    path: ROUTE_PATHS.freeClinic,
    component: lazy(() => import('pages/Request/FreeClinic/View')),
    exact: true,
  },
  {
    path: ROUTE_PATHS.freeClinicService,
    component: lazy(() => import('pages/Request/FreeClinic/ClinicService')),
    authorizedRoles: [ProfileType.PATIENT],
    forbiddenAction: true,
    exact: true,
  },
];

export const publicRoutes: IRoute[] = [...IN_APP_BROWSER_ROUTES, ...SEARCH_ROUTES, ...DOCTOR_ROUTES, ...CLINIC_ROUTES];
