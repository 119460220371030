import { useBreakpoints } from '@lib/react-components';
import { FullNameFormat, getUserFullName } from '@lib/utils';
import { Typography } from '@mui/material';

import { IGlobalSearchDetailsCardDoctor, IGlobalSearchDetailsDoctorTabCard } from '../model';

import { useFileClickRedirect } from 'features/Files';
import { i18n } from 'i18n';

type GlobalSearchDetailsCardCvProps = Pick<IGlobalSearchDetailsDoctorTabCard, 'user'> &
  Pick<IGlobalSearchDetailsCardDoctor, 'cvFileId'>;

export const GlobalSearchDetailsCardCv = ({ user, cvFileId }: GlobalSearchDetailsCardCvProps): JSX.Element => {
  const { isDesktopView } = useBreakpoints();
  const { onFileClickRedirect } = useFileClickRedirect();

  const handleClick = (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.stopPropagation();

    return onFileClickRedirect(cvFileId);
  };

  return (
    <>
      <Typography
        variant="h8"
        component={isDesktopView ? 'span' : 'p'}
        margin={isDesktopView ? undefined : '24px 0 12px'}
      >
        {i18n.t('general.labels.cv')}
      </Typography>
      <Typography variant="bodyLink" onClick={handleClick}>
        {`CV - ${getUserFullName(user, FullNameFormat.FIRST_NAME_LAST_NAME)}`}
      </Typography>
    </>
  );
};
