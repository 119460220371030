import { Loader, Modal, StatusCard } from '@lib/react-components';
import { Button, Stack } from '@mui/material';

import { useStyles } from './styles';

import { Maybe } from '__generated__/types';
import { usePrivaciesModelUpdatePrivaciesModal } from 'features/Privacies/model';
import { RegistrationUiCheckboxesArea } from 'features/Registration/ui';
import { i18n } from 'i18n';

interface IPrivaciesUiUpdatePrivaciesModalProps {
  onSuccess: VoidFunction;
}

export const PrivaciesUiUpdatePrivaciesModal = ({
  onSuccess,
}: IPrivaciesUiUpdatePrivaciesModalProps): Maybe<JSX.Element> => {
  const { classes: localClasses } = useStyles();
  const { values, errors, touched, handleChange, handleSubmit, privaciesData, isUpdatePrivacyAcceptanceSubmitting } =
    usePrivaciesModelUpdatePrivaciesModal({ onSuccess });

  const dataMandatoryPrivacies = privaciesData?.pendingPrivacies.filter((privacy): boolean => privacy.isMandatory);
  const hasUncheckedMandatoryPrivacies = dataMandatoryPrivacies?.some(
    mandatoryPrivacy => !values[mandatoryPrivacy.type]
  );

  return (
    <Modal
      title={i18n.t('components.forms.updatePrivaciesForm.title')}
      classes={{ title: localClasses.textCenter, footer: localClasses.textCenter }}
      isDraggable={false}
      bluredBackDrop
      footer={
        privaciesData && (
          <Stack mt="20px" direction="row" gap="16px">
            <Button
              onClick={(): void => handleSubmit()}
              disabled={hasUncheckedMandatoryPrivacies || isUpdatePrivacyAcceptanceSubmitting}
              color="primary"
            >
              {isUpdatePrivacyAcceptanceSubmitting ? (
                <Loader />
              ) : (
                i18n.t('components.forms.updatePrivaciesForm.confirm')
              )}
            </Button>
          </Stack>
        )
      }
    >
      <StatusCard
        text={i18n.t('components.forms.updatePrivaciesForm.label')}
        classes={{ cardWrapper: localClasses.infoBlockWrapper, text: localClasses.infoBlockText }}
      />
      <RegistrationUiCheckboxesArea
        values={values}
        errors={errors}
        touched={touched}
        privacies={privaciesData?.pendingPrivacies}
        handleChange={handleChange}
      />
    </Modal>
  );
};
