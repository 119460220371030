import { MedicalRole } from 'features/Affiliations/enums';
import { i18n } from 'i18n';

/**
 * A map, containing the translations for each BE roleCode
 */
export const ROLES_TRANSLATION_MAP: Record<string, string> = {
  [MedicalRole.HeadPhysician]: i18n.t('features.affiliations.roles.headPhysician'),
  [MedicalRole.Physician]: i18n.t('features.affiliations.roles.physician'),
  [MedicalRole.NonPhysician]: i18n.t('features.affiliations.roles.nonPhysician'),
};
