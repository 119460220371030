import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  supportItem: {
    display: 'flex',
    height: 50,
    padding: '9px 10px 9px 20px',
    cursor: 'pointer',
    '& > .MuiSvgIcon-root': {
      fill: 'transparent',
    },
  },
}));
