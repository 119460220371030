import { useFilterQueryParams } from '@lib/react-components';
import { useMemo } from 'react';

import { GlobalSearchDetailsTabFilterFields } from '../config';

import { GlobalSearchDetailsOrder, Maybe } from '__generated__/types';

interface IUseGlobalSearchDetailsOrderFilterReturn {
  order: GlobalSearchDetailsOrder;
  setOrder: (value: string) => void;
}

export const useGlobalSearchDetailsOrderFilter = (): IUseGlobalSearchDetailsOrderFilterReturn => {
  const {
    filters: { order },
    setFilter,
  } = useFilterQueryParams<Record<string, Maybe<string>>>({
    [GlobalSearchDetailsTabFilterFields.order]: GlobalSearchDetailsOrder.MOST_RELEVANT,
  });
  const parsedFilter = useMemo(() => order as GlobalSearchDetailsOrder, [order]);
  const setOrder = (value: string): void => setFilter(GlobalSearchDetailsTabFilterFields.order, value);

  return {
    order: parsedFilter,
    setOrder,
  };
};
