import { Reducer } from 'redux';

import { Action, ActionTypeList, initialState, IState } from './types';

export const medicalParametersReducer: Reducer<IState, Action> = (state: IState = initialState, action) => {
  switch (action.type) {
    case ActionTypeList.SET_LAB_RESULTS_DATA:
      return {
        ...state,
        labResults: action.payload.labResultsData,
      };

    case ActionTypeList.ADD_LAB_RESULTS_DATA:
      return {
        ...state,
        labResults: (state.labResults || []).concat(action.payload.labResultData),
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_DATA:
      return {
        ...state,
        labResults: null,
      };

    case ActionTypeList.SET_ANAMNESIS_DONORS_LAST_VALUE:
      return {
        ...state,
        anamnesisDonorsLastValue: action.payload.anamnesisDonorsLastValue,
      };

    case ActionTypeList.REMOVE_ANAMNESIS_DONORS_LAST_VALUE:
      return {
        ...state,
        anamnesisDonorsLastValue: null,
      };

    case ActionTypeList.SET_ANAMNESIS_DONORS_HISTORY_VALUES:
      return {
        ...state,
        anamnesisDonorsHistoryValues: action.payload.anamnesisDonorsHistoryValues,
      };

    case ActionTypeList.REMOVE_ANAMNESIS_DONORS_HISTORY_VALUES:
      return {
        ...state,
        anamnesisDonorsHistoryValues: null,
      };

    case ActionTypeList.SET_ANAMNESIS_SURGERY_LAST_VALUE:
      return {
        ...state,
        anamnesisSurgeryLastValue: action.payload.anamnesisSurgeryLastValue,
      };

    case ActionTypeList.REMOVE_ANAMNESIS_SURGERY_LAST_VALUE:
      return {
        ...state,
        anamnesisSurgeryLastValue: null,
      };

    case ActionTypeList.SET_ANAMNESIS_SURGERY_HISTORY_VALUES:
      return {
        ...state,
        anamnesisSurgeryHistoryValues: action.payload.anamnesisSurgeryHistoryValues,
      };

    case ActionTypeList.REMOVE_ANAMNESIS_SURGERY_HISTORY_VALUES:
      return {
        ...state,
        anamnesisSurgeryHistoryValues: null,
      };

    case ActionTypeList.SET_RISK_SCORE_NOTES_LAST_VALUE:
      return {
        ...state,
        riskScoreNotesLastValue: action.payload.riskScoreNotesLastValue,
      };

    case ActionTypeList.REMOVE_RISK_SCORE_NOTES_LAST_VALUE:
      return {
        ...state,
        riskScoreNotesLastValue: null,
      };

    case ActionTypeList.SET_RISK_SCORE_NOTES_HISTORY_VALUES:
      return {
        ...state,
        riskScoreNotesHistoryValues: action.payload.riskScoreNotesHistoryValues,
      };

    case ActionTypeList.REMOVE_RISK_SCORE_NOTES_HISTORY_VALUES:
      return {
        ...state,
        riskScoreNotesHistoryValues: null,
      };

    case ActionTypeList.SET_VITALS_PARAMETERS_NOTES_LAST_VALUE:
      return {
        ...state,
        vitalsParametersNotesLastValue: action.payload.vitalsParametersNotesLastValue,
      };

    case ActionTypeList.REMOVE_VITALS_PARAMETERS_NOTES_LAST_VALUE:
      return {
        ...state,
        vitalsParametersNotesLastValue: null,
      };

    case ActionTypeList.SET_VITALS_PARAMETERS_NOTES_HISTORY_VALUES:
      return {
        ...state,
        vitalsParametersNotesHistoryValues: action.payload.vitalsParametersNotesHistoryValues,
      };

    case ActionTypeList.REMOVE_VITALS_PARAMETERS_NOTES_HISTORY_VALUES:
      return {
        ...state,
        vitalsParametersNotesHistoryValues: null,
      };

    case ActionTypeList.SET_SIGNS_AND_SYMPTOMS_NOTES_LAST_VALUE:
      return {
        ...state,
        signsAndSymptomsNotesLastValue: action.payload.signsAndSymptomsNotesLastValue,
      };

    case ActionTypeList.REMOVE_SIGNS_AND_SYMPTOMS_NOTES_LAST_VALUE:
      return {
        ...state,
        signsAndSymptomsNotesLastValue: null,
      };

    case ActionTypeList.SET_SIGNS_AND_SYMPTOMS_NOTES_HISTORY_VALUES:
      return {
        ...state,
        signsAndSymptomsNotesHistoryValues: action.payload.signsAndSymptomsNotesHistoryValues,
      };

    case ActionTypeList.SET_PHYSIOLOGICAL_HISTORY_NOTES_LAST_VALUE:
      return {
        ...state,
        physiologicalHistoryNotesLastValue: action.payload.physiologicalHistoryNotesLastValue,
      };

    case ActionTypeList.SET_PHYSIOLOGICAL_HISTORY_NOTES_HISTORY_VALUES:
      return {
        ...state,
        physiologicalHistoryNotesHistoryValues: action.payload.physiologicalHistoryNotesHistoryValues,
      };

    case ActionTypeList.SET_PHYSIOLOGICAL_HISTORY_SMOKE_LAST_VALUE:
      return {
        ...state,
        physiologicalHistorySmokeLastValue: action.payload.physiologicalHistorySmokeLastValue,
      };

    case ActionTypeList.REMOVE_PHYSIOLOGICAL_HISTORY_SMOKE_LAST_VALUE:
      return {
        ...state,
        physiologicalHistorySmokeLastValue: null,
      };

    case ActionTypeList.SET_PHYSIOLOGICAL_HISTORY_SMOKE_HISTORY_VALUES:
      return {
        ...state,
        physiologicalHistorySmokeHistoryValues: action.payload.physiologicalHistorySmokeHistoryValues,
      };

    case ActionTypeList.REMOVE_PHYSIOLOGICAL_HISTORY_SMOKE_HISTORY_VALUES:
      return {
        ...state,
        physiologicalHistorySmokeHistoryValues: null,
      };

    case ActionTypeList.SET_PHYSIOLOGICAL_HISTORY_MENOPAUSE_LAST_VALUE:
      return {
        ...state,
        physiologicalHistoryMenopauseLastValue: action.payload.physiologicalHistoryMenopauseLastValue,
      };

    case ActionTypeList.REMOVE_PHYSIOLOGICAL_HISTORY_MENOPAUSE_LAST_VALUE:
      return {
        ...state,
        physiologicalHistoryMenopauseLastValue: null,
      };

    case ActionTypeList.SET_PHYSIOLOGICAL_HISTORY_MENOPAUSE_HISTORY_VALUES:
      return {
        ...state,
        physiologicalHistoryMenopauseHistoryValues: action.payload.physiologicalHistoryMenopauseHistoryValues,
      };

    case ActionTypeList.REMOVE_PHYSIOLOGICAL_HISTORY_MENOPAUSE_HISTORY_VALUES:
      return {
        ...state,
        physiologicalHistoryMenopauseHistoryValues: null,
      };

    case ActionTypeList.SET_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_LAST_VALUE:
      return {
        ...state,
        physiologicalHistoryPhototypeLastValue: action.payload.physiologicalHistoryPhototypeLastValue,
      };

    case ActionTypeList.REMOVE_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_LAST_VALUE:
      return {
        ...state,
        physiologicalHistoryPhototypeLastValue: null,
      };

    case ActionTypeList.SET_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_HISTORY_VALUES:
      return {
        ...state,
        physiologicalHistoryPhototypeHistoryValues: action.payload.physiologicalHistoryPhototypeHistoryValues,
      };

    case ActionTypeList.REMOVE_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_HISTORY_VALUES:
      return {
        ...state,
        physiologicalHistoryPhototypeHistoryValues: null,
      };

    case ActionTypeList.SET_PATHOLOGY_RESULTS_BMA_LAST_VALUE:
      return {
        ...state,
        pathologyResultsBmaLastValue: action.payload.pathologyResultsBmaLastValue,
      };

    case ActionTypeList.REMOVE_PATHOLOGY_RESULTS_BMA_LAST_VALUE:
      return {
        ...state,
        pathologyResultsBmaLastValue: null,
      };

    case ActionTypeList.SET_PATHOLOGY_RESULTS_BMA_HISTORY_VALUES:
      return {
        ...state,
        pathologyResultsBmaHistoryValues: action.payload.pathologyResultsBmaHistoryValues,
      };

    case ActionTypeList.REMOVE_PATHOLOGY_RESULTS_BMA_HISTORY_VALUES:
      return {
        ...state,
        pathologyResultsBmaHistoryValues: null,
      };

    case ActionTypeList.SET_PATHOLOGY_RESULTS_BMB_LAST_VALUE:
      return {
        ...state,
        pathologyResultsBmbLastValue: action.payload.pathologyResultsBmbLastValue,
      };

    case ActionTypeList.REMOVE_PATHOLOGY_RESULTS_BMB_LAST_VALUE:
      return {
        ...state,
        pathologyResultsBmbLastValue: null,
      };

    case ActionTypeList.SET_PATHOLOGY_RESULTS_BMB_HISTORY_VALUES:
      return {
        ...state,
        pathologyResultsBmbHistoryValues: action.payload.pathologyResultsBmbHistoryValues,
      };

    case ActionTypeList.REMOVE_PATHOLOGY_RESULTS_BMB_HISTORY_VALUES:
      return {
        ...state,
        pathologyResultsBmbHistoryValues: null,
      };

    case ActionTypeList.SET_PATHOLOGY_RESULTS_ACA_LAST_VALUE:
      return {
        ...state,
        pathologyResultsAcaLastValue: action.payload.pathologyResultsAcaLastValue,
      };

    case ActionTypeList.REMOVE_PATHOLOGY_RESULTS_ACA_LAST_VALUE:
      return {
        ...state,
        pathologyResultsAcaLastValue: null,
      };

    case ActionTypeList.SET_PATHOLOGY_RESULTS_ACA_HISTORY_VALUES:
      return {
        ...state,
        pathologyResultsAcaHistoryValues: action.payload.pathologyResultsAcaHistoryValues,
      };

    case ActionTypeList.REMOVE_PATHOLOGY_RESULTS_ACA_HISTORY_VALUES:
      return {
        ...state,
        pathologyResultsAcaHistoryValues: null,
      };

    case ActionTypeList.SET_PATHOLOGY_RESULTS_HISTOLOGICAL_EXAMINATION_LAST_VALUE:
      return {
        ...state,
        pathologyResultsHistologicalExaminationLastValue:
          action.payload.pathologyResultsHistologicalExaminationLastValue,
      };

    case ActionTypeList.REMOVE_PATHOLOGY_RESULTS_HISTOLOGICAL_EXAMINATION_LAST_VALUE:
      return {
        ...state,
        pathologyResultsHistologicalExaminationLastValue: null,
      };

    case ActionTypeList.SET_PATHOLOGY_RESULTS_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES:
      return {
        ...state,
        pathologyResultsHistologicalExaminationHistoryValues:
          action.payload.pathologyResultsHistologicalExaminationHistoryValues,
      };

    case ActionTypeList.REMOVE_PATHOLOGY_RESULTS_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES:
      return {
        ...state,
        pathologyResultsHistologicalExaminationHistoryValues: null,
      };

    case ActionTypeList.SET_PATHOLOGY_RESULTS_PRIMARY_TUMOR_HISTOLOGICAL_EXAMINATION_LAST_VALUE:
      return {
        ...state,
        pathologyResultsPrimaryTumorHistologicalExaminationLastValue:
          action.payload.pathologyResultsPrimaryTumorHistologicalExaminationLastValue,
      };

    case ActionTypeList.REMOVE_PATHOLOGY_RESULTS_PRIMARY_TUMOR_HISTOLOGICAL_EXAMINATION_LAST_VALUE:
      return {
        ...state,
        pathologyResultsPrimaryTumorHistologicalExaminationLastValue: null,
      };

    case ActionTypeList.SET_PATHOLOGY_RESULTS_PRIMARY_TUMOR_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES:
      return {
        ...state,
        pathologyResultsPrimaryTumorHistologicalExaminationHistoryValues:
          action.payload.pathologyResultsPrimaryTumorHistologicalExaminationHistoryValues,
      };

    case ActionTypeList.REMOVE_PATHOLOGY_RESULTS_PRIMARY_TUMOR_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES:
      return {
        ...state,
        pathologyResultsPrimaryTumorHistologicalExaminationHistoryValues: null,
      };

    case ActionTypeList.SET_PATHOLOGY_RESULTS_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_LAST_VALUE:
      return {
        ...state,
        pathologyResultsLymphNodesHistologicalExaminationLastValue:
          action.payload.pathologyResultsLymphNodesHistologicalExaminationLastValue,
      };

    case ActionTypeList.REMOVE_PATHOLOGY_RESULTS_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_LAST_VALUE:
      return {
        ...state,
        pathologyResultsLymphNodesHistologicalExaminationLastValue: null,
      };

    case ActionTypeList.SET_PATHOLOGY_RESULTS_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES:
      return {
        ...state,
        pathologyResultsLymphNodesHistologicalExaminationHistoryValues:
          action.payload.pathologyResultsLymphNodesHistologicalExaminationHistoryValues,
      };

    case ActionTypeList.REMOVE_PATHOLOGY_RESULTS_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES:
      return {
        ...state,
        pathologyResultsLymphNodesHistologicalExaminationHistoryValues: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_LAST_VALUE:
      return {
        ...state,
        labResultsBcrAbl1TranscriptLastValue: action.payload.labResultsBcrAbl1TranscriptLastValue,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_LAST_VALUE:
      return {
        ...state,
        labResultsBcrAbl1TranscriptLastValue: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_HISTORY_VALUES:
      return {
        ...state,
        labResultsBcrAbl1TranscriptHistoryValues: action.payload.labResultsBcrAbl1TranscriptHistoryValues,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_HISTORY_VALUES:
      return {
        ...state,
        labResultsBcrAbl1TranscriptHistoryValues: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_BCR_ABL1_Q_PCR_LAST_VALUE:
      return {
        ...state,
        labResultsBcrAbl1PcrQLastValue: action.payload.labResultsBcrAbl1PcrQLastValue,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_BCR_ABL1_Q_PCR_LAST_VALUE:
      return {
        ...state,
        labResultsBcrAbl1PcrQLastValue: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_BCR_ABL1_Q_PCR_HISTORY_VALUES:
      return {
        ...state,
        labResultsBcrAbl1PcrQHistoryValues: action.payload.labResultsBcrAbl1PcrQHistoryValues,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_BCR_ABL1_Q_PCR_HISTORY_VALUES:
      return {
        ...state,
        labResultsBcrAbl1PcrQHistoryValues: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_BCR_ABL1_MUTATION_LAST_VALUE:
      return {
        ...state,
        labResultsBcrAbl1MutationLastValue: action.payload.labResultsBcrAbl1MutationLastValue,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_BCR_ABL1_MUTATION_LAST_VALUE:
      return {
        ...state,
        labResultsBcrAbl1MutationLastValue: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_BCR_ABL1_MUTATION_HISTORY_VALUES:
      return {
        ...state,
        labResultsBcrAbl1MutationHistoryValues: action.payload.labResultsBcrAbl1MutationHistoryValues,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_BCR_ABL1_MUTATION_HISTORY_VALUES:
      return {
        ...state,
        labResultsBcrAbl1MutationHistoryValues: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_JAK2_V617F_LAST_VALUE:
      return {
        ...state,
        labResultsJak2V617FLastValue: action.payload.labResultsJak2V617FLastValue,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_JAK2_V617F_LAST_VALUE:
      return {
        ...state,
        labResultsJak2V617FLastValue: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_JAK2_V617F_HISTORY_VALUES:
      return {
        ...state,
        labResultsJak2V617FHistoryValues: action.payload.labResultsJak2V617FHistoryValues,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_JAK2_V617F_HISTORY_VALUES:
      return {
        ...state,
        labResultsJak2V617FHistoryValues: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_JAK2_EXON12_LAST_VALUE:
      return {
        ...state,
        labResultsJak2Exon12LastValue: action.payload.labResultsJak2Exon12LastValue,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_JAK2_EXON12_LAST_VALUE:
      return {
        ...state,
        labResultsJak2Exon12LastValue: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_JAK2_EXON12_HISTORY_VALUES:
      return {
        ...state,
        labResultsJak2Exon12HistoryValues: action.payload.labResultsJak2Exon12HistoryValues,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_JAK2_EXON12_HISTORY_VALUES:
      return {
        ...state,
        labResultsJak2Exon12HistoryValues: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_CALR_LAST_VALUE:
      return {
        ...state,
        labResultsCalrLastValue: action.payload.labResultsCalrLastValue,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_CALR_LAST_VALUE:
      return {
        ...state,
        labResultsCalrLastValue: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_CALR_HISTORY_VALUES:
      return {
        ...state,
        labResultsCalrHistoryValues: action.payload.labResultsCalrHistoryValues,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_CALR_HISTORY_VALUES:
      return {
        ...state,
        labResultsCalrHistoryValues: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_MPL_LAST_VALUE:
      return {
        ...state,
        labResultsMplLastValue: action.payload.labResultsMplLastValue,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_MPL_LAST_VALUE:
      return {
        ...state,
        labResultsMplLastValue: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_MPL_HISTORY_VALUES:
      return {
        ...state,
        labResultsMplHistoryValues: action.payload.labResultsMplHistoryValues,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_MPL_HISTORY_VALUES:
      return {
        ...state,
        labResultsMplHistoryValues: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_SUBCLONAL_LAST_VALUE:
      return {
        ...state,
        labResultsSubclonalLastValue: action.payload.labResultsSubclonalMutationLastValue,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_SUBCLONAL_LAST_VALUE:
      return {
        ...state,
        labResultsSubclonalLastValue: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_SUBCLONAL_HISTORY_VALUES:
      return {
        ...state,
        labResultsSubclonalHistoryValues: action.payload.labResultsSubclonalMutationHistoryValues,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_SUBCLONAL_HISTORY_VALUES:
      return {
        ...state,
        labResultsSubclonalHistoryValues: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_NGS_LAST_VALUE:
      return {
        ...state,
        labResultsNgsLastValue: action.payload.labResultsNgsLastValue,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_NGS_LAST_VALUE:
      return {
        ...state,
        labResultsNgsLastValue: null,
      };

    case ActionTypeList.SET_LAB_RESULTS_NGS_HISTORY_VALUES:
      return {
        ...state,
        labResultsNgsHistoryValues: action.payload.labResultsNgsHistoryValues,
      };

    case ActionTypeList.REMOVE_LAB_RESULTS_NGS_HISTORY_VALUES:
      return {
        ...state,
        labResultsNgsHistoryValues: null,
      };

    case ActionTypeList.SET_HEMATOLOGICAL_THERAPY_ROWS:
      return {
        ...state,
        hematologicalTherapyRows: action.payload.hematologicalTherapyRows.map(hematologicalTherapyRow => ({
          ...hematologicalTherapyRow,
          objectId: hematologicalTherapyRow.objectId,
        })),
      };

    case ActionTypeList.REMOVE_HEMATOLOGICAL_THERAPY_ROWS:
      return {
        ...state,
        hematologicalTherapyRows: null,
      };

    case ActionTypeList.ADD_HEMATOLOGICAL_THERAPY_ROW:
      return {
        ...state,
        hematologicalTherapyRows: (state.hematologicalTherapyRows || []).concat({
          objectId: action.payload.hematologicalTherapyRow.data.objectId,
          history: [action.payload.hematologicalTherapyRow],
        }),
      };

    case ActionTypeList.UPDATE_HEMATOLOGICAL_THERAPY_ROW:
      return {
        ...state,
        hematologicalTherapyRows:
          state.hematologicalTherapyRows &&
          state.hematologicalTherapyRows.map(hematologicalTherapyRow =>
            hematologicalTherapyRow.objectId === action.payload.hematologicalTherapyRowItem.data.objectId
              ? {
                  objectId: hematologicalTherapyRow.objectId,
                  history: [action.payload.hematologicalTherapyRowItem].concat(hematologicalTherapyRow.history),
                }
              : hematologicalTherapyRow
          ),
      };

    case ActionTypeList.SET_BRAF_MUTATION_LAST_VALUE:
      return {
        ...state,
        brafMutationLastValue: action.payload.brafMutationLastValue,
      };

    case ActionTypeList.REMOVE_BRAF_MUTATION_LAST_VALUE:
      return {
        ...state,
        brafMutationLastValue: null,
      };

    case ActionTypeList.SET_BRAF_MUTATION_HISTORY_VALUES:
      return {
        ...state,
        brafMutationHistoryValues: action.payload.brafMutationHistoryValues,
      };

    case ActionTypeList.REMOVE_BRAF_MUTATION_HISTORY_VALUES:
      return {
        ...state,
        brafMutationHistoryValues: null,
      };

    case ActionTypeList.SET_NON_BRAF_MUTATION_LAST_VALUE:
      return {
        ...state,
        nonBrafMutationLastValue: action.payload.nonBrafMutationLastValue,
      };

    case ActionTypeList.REMOVE_NON_BRAF_MUTATION_LAST_VALUE:
      return {
        ...state,
        nonBrafMutationLastValue: null,
      };

    case ActionTypeList.SET_NON_BRAF_MUTATION_HISTORY_VALUES:
      return {
        ...state,
        nonBrafMutationHistoryValues: action.payload.nonBrafMutationHistoryValues,
      };

    case ActionTypeList.REMOVE_NON_BRAF_MUTATION_HISTORY_VALUES:
      return {
        ...state,
        nonBrafMutationHistoryValues: null,
      };

    case ActionTypeList.SET_SENTINEL_LYMPH_LAST_VALUE:
      return {
        ...state,
        sentinelLymphLastValue: action.payload.sentinelLymphLastValue,
      };

    case ActionTypeList.REMOVE_SENTINEL_LYMPH_LAST_VALUE:
      return {
        ...state,
        sentinelLymphLastValue: null,
      };

    case ActionTypeList.SET_SENTINEL_LYMPH_HISTORY_VALUES:
      return {
        ...state,
        sentinelLymphHistoryValues: action.payload.sentinelLymphHistoryValues,
      };

    case ActionTypeList.REMOVE_SENTINEL_LYMPH_HISTORY_VALUES:
      return {
        ...state,
        sentinelLymphHistoryValues: null,
      };

    case ActionTypeList.SET_LYMPH_DISSECTION_LAST_VALUE:
      return {
        ...state,
        lymphDissectionLastValue: action.payload.lymphDissectionLastValue,
      };

    case ActionTypeList.REMOVE_LYMPH_DISSECTION_LAST_VALUE:
      return {
        ...state,
        lymphDissectionLastValue: null,
      };

    case ActionTypeList.SET_LYMPH_DISSECTION_HISTORY_VALUES:
      return {
        ...state,
        lymphDissectionHistoryValues: action.payload.lymphDissectionHistoryValues,
      };

    case ActionTypeList.REMOVE_LYMPH_DISSECTION_HISTORY_VALUES:
      return {
        ...state,
        lymphDissectionHistoryValues: null,
      };

    case ActionTypeList.SET_METASTASIS_LAST_VALUE:
      return {
        ...state,
        metastasisLastValue: action.payload.metastasisLastValue,
      };

    case ActionTypeList.REMOVE_METASTASIS_LAST_VALUE:
      return {
        ...state,
        metastasisLastValue: null,
      };

    case ActionTypeList.SET_METASTASIS_HISTORY_VALUES:
      return {
        ...state,
        metastasisHistoryValues: action.payload.metastasisHistoryValues,
      };

    case ActionTypeList.REMOVE_METASTASIS_HISTORY_VALUES:
      return {
        ...state,
        metastasisHistoryValues: null,
      };

    case ActionTypeList.SET_RESECTION_MARGINS_LAST_VALUE:
      return {
        ...state,
        resectionMarginsLastValue: action.payload.resectionMarginsLastValue,
      };

    case ActionTypeList.REMOVE_RESECTION_MARGINS_LAST_VALUE:
      return {
        ...state,
        resectionMarginsLastValue: null,
      };

    case ActionTypeList.SET_RESECTION_MARGINS_HISTORY_VALUES:
      return {
        ...state,
        resectionMarginsHistoryValues: action.payload.resectionMarginsHistoryValues,
      };

    case ActionTypeList.REMOVE_RESECTION_MARGINS_HISTORY_VALUES:
      return {
        ...state,
        resectionMarginsHistoryValues: null,
      };

    case ActionTypeList.SET_HISTOTYPE_LAST_VALUE:
      return {
        ...state,
        histotypeLastValue: action.payload.histotypeLastValue,
      };

    case ActionTypeList.REMOVE_HISTOTYPE_LAST_VALUE:
      return {
        ...state,
        histotypeLastValue: null,
      };

    case ActionTypeList.SET_HISTOTYPE_HISTORY_VALUES:
      return {
        ...state,
        histotypeHistoryValues: action.payload.histotypeHistoryValues,
      };

    case ActionTypeList.REMOVE_HISTOTYPE_HISTORY_VALUES:
      return {
        ...state,
        histotypeHistoryValues: null,
      };

    case ActionTypeList.SET_MELANOMA_FAMILY_LAST_VALUE:
      return {
        ...state,
        melanomaFamilyLastValue: action.payload.melanomaFamilyLastValue,
      };

    case ActionTypeList.REMOVE_MELANOMA_FAMILY_LAST_VALUE:
      return {
        ...state,
        melanomaFamilyLastValue: null,
      };

    case ActionTypeList.SET_MELANOMA_FAMILY_HISTORY_VALUES:
      return {
        ...state,
        melanomaFamilyHistoryValues: action.payload.melanomaFamilyHistoryValues,
      };

    case ActionTypeList.REMOVE_MELANOMA_FAMILY_HISTORY_VALUES:
      return {
        ...state,
        melanomaFamilyHistoryValues: null,
      };

    case ActionTypeList.SET_BREAST_FAMILY_LAST_VALUE:
      return {
        ...state,
        breastFamilyLastValue: action.payload.breastFamilyLastValue,
      };

    case ActionTypeList.REMOVE_BREAST_FAMILY_LAST_VALUE:
      return {
        ...state,
        breastFamilyLastValue: null,
      };

    case ActionTypeList.SET_BREAST_FAMILY_HISTORY_VALUES:
      return {
        ...state,
        breastFamilyHistoryValues: action.payload.breastFamilyHistoryValues,
      };

    case ActionTypeList.REMOVE_BREAST_FAMILY_HISTORY_VALUES:
      return {
        ...state,
        breastFamilyHistoryValues: null,
      };

    case ActionTypeList.SET_THERAPY_RADIOTHERAPY_LAST_VALUE:
      return {
        ...state,
        therapyRadiotherapyLastValue: action.payload.therapyRadiotherapyLastValue,
      };

    case ActionTypeList.REMOVE_THERAPY_RADIOTHERAPY_LAST_VALUE:
      return {
        ...state,
        therapyRadiotherapyLastValue: null,
      };

    case ActionTypeList.SET_THERAPY_RADIOTHERAPY_HISTORY_VALUES:
      return {
        ...state,
        therapyRadiotherapyHistoryValues: action.payload.therapyRadiotherapyHistoryValues,
      };

    case ActionTypeList.REMOVE_THERAPY_RADIOTHERAPY_HISTORY_VALUES:
      return {
        ...state,
        therapyRadiotherapyHistoryValues: null,
      };

    case ActionTypeList.SET_THERAPY_NEOADJUVANT_LAST_VALUE:
      return {
        ...state,
        therapyNeoadjuvantLastValue: action.payload.therapyNeoadjuvantLastValue,
      };

    case ActionTypeList.REMOVE_THERAPY_NEOADJUVANT_LAST_VALUE:
      return {
        ...state,
        therapyNeoadjuvantLastValue: null,
      };

    case ActionTypeList.SET_THERAPY_NEOADJUVANT_HISTORY_VALUES:
      return {
        ...state,
        therapyNeoadjuvantHistoryValues: action.payload.therapyNeoadjuvantHistoryValues,
      };

    case ActionTypeList.REMOVE_THERAPY_NEOADJUVANT_HISTORY_VALUES:
      return {
        ...state,
        therapyNeoadjuvantHistoryValues: null,
      };

    case ActionTypeList.SET_THERAPY_HORMONE_LAST_VALUE:
      return {
        ...state,
        therapyHormoneLastValue: action.payload.therapyHormoneLastValue,
      };

    case ActionTypeList.REMOVE_THERAPY_HORMONE_LAST_VALUE:
      return {
        ...state,
        therapyHormoneLastValue: null,
      };

    case ActionTypeList.SET_THERAPY_HORMONE_HISTORY_VALUES:
      return {
        ...state,
        therapyHormoneHistoryValues: action.payload.therapyHormoneHistoryValues,
      };

    case ActionTypeList.REMOVE_THERAPY_HORMONE_HISTORY_VALUES:
      return {
        ...state,
        therapyHormoneHistoryValues: null,
      };

    case ActionTypeList.SET_THERAPY_ADJUVANT_LAST_VALUE:
      return {
        ...state,
        therapyAdjuvantLastValue: action.payload.therapyAdjuvantLastValue,
      };

    case ActionTypeList.REMOVE_THERAPY_ADJUVANT_LAST_VALUE:
      return {
        ...state,
        therapyAdjuvantLastValue: null,
      };

    case ActionTypeList.SET_THERAPY_ADJUVANT_HISTORY_VALUES:
      return {
        ...state,
        therapyAdjuvantHistoryValues: action.payload.therapyAdjuvantHistoryValues,
      };

    case ActionTypeList.REMOVE_THERAPY_ADJUVANT_HISTORY_VALUES:
      return {
        ...state,
        therapyAdjuvantHistoryValues: null,
      };

    default:
      return state;
  }
};
