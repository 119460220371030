import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import { MedicalRole } from './MedicalRole.fragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type MedicalRolesVariables = Types.Exact<{ [key: string]: never; }>;


export type MedicalRoles = { __typename?: 'Query', medicalRoles: Array<{ __typename?: 'MedicalRoleModel', id: string, code: string, sortOrder: number }> };


export const MedicalRolesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MedicalRoles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"medicalRoles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MedicalRole"}}]}}]}},...MedicalRole.definitions]} as unknown as DocumentNode;

/**
 * __useMedicalRoles__
 *
 * To run a query within a React component, call `useMedicalRoles` and pass it any options that fit your needs.
 * When your component renders, `useMedicalRoles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalRoles({
 *   variables: {
 *   },
 * });
 */
export function useMedicalRoles(baseOptions?: ApolloReactHooks.QueryHookOptions<MedicalRoles, MedicalRolesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MedicalRoles, MedicalRolesVariables>(MedicalRolesDocument, options);
      }
export function useMedicalRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MedicalRoles, MedicalRolesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MedicalRoles, MedicalRolesVariables>(MedicalRolesDocument, options);
        }
export type MedicalRolesHookResult = ReturnType<typeof useMedicalRoles>;
export type MedicalRolesLazyQueryHookResult = ReturnType<typeof useMedicalRolesLazyQuery>;
export type MedicalRolesQueryResult = Apollo.QueryResult<MedicalRoles, MedicalRolesVariables>;