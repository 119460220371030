import { useModalState } from '@lib/react-components';
import { useEffect, useState } from 'react';

import { UnauthorizedUserChatMessageAccessDeniedErrorModal } from './UnauthorizedUserChatMessageAccessDeniedErrorModal';
import { UnauthorizedUserChatMessageAuthorizeMessageSendingModal } from './UnauthorizedUserChatMessageAuthorizeMessageSendingModal';
import { UnauthorizedUserChatMessageProceedToRegistrationModal } from './UnauthorizedUserChatMessageProceedToRegistrationModal';
import { UnauthorizedUserChatMessageWriteToModal } from './UnauthorizedUserChatMessageWriteToModal';

import { DraftDirectMessageUserStatus } from '__generated__/types';
import { UnauthorizedUserChatMessageApiSendDraftDirectMessage } from 'features/UnauthorizedUserChatMessage/api';
import { LoginOrRegisterBlockingModalsControl } from 'features/Users/components';
import { useUserModelStore } from 'features/Users/model';

interface IUnauthorizedUserChatMessageWriteToModalsSectionProps {
  isWriteToModalOpened: boolean;
  isBlockingModalOpened: boolean;
  openBlockingModal: VoidFunction;
  closeBlockingModal: VoidFunction;
  closeWriteToModal: VoidFunction;
  doctorPortalUserId?: string;
  clinicId?: string;
}

export const UnauthorizedUserChatMessageWriteToModalsSection = ({
  isWriteToModalOpened,
  isBlockingModalOpened,
  openBlockingModal,
  closeBlockingModal,
  closeWriteToModal,
  doctorPortalUserId,
  clinicId,
}: IUnauthorizedUserChatMessageWriteToModalsSectionProps): JSX.Element => {
  const { activeProfile } = useUserModelStore();
  const [sendDraftDirectMessageResult, setSendDraftDirectMessageResult] =
    useState<UnauthorizedUserChatMessageApiSendDraftDirectMessage['sendDraftDirectMessage']>();
  const [isAccessDeniedErrorModalOpened, openAccessDeniedErrorModal, closeAccessDeniedErrorModal] = useModalState();
  const [isProceedToRegistrationModalOpened, openProceedToRegistrationModal, closeProceedToRegistrationModal] =
    useModalState();
  const [isAuthorizeMessageSendingModalOpened, openAuthorizeMessageSendingModal, closeAuthorizeMessageSendingModal] =
    useModalState();

  const draftDirectMessageId = sendDraftDirectMessageResult?.draftDirectMessage?.id;
  const isUnauthorizedUserWithoutPatientProfile =
    sendDraftDirectMessageResult?.draftDirectMessage.userStatus ===
    DraftDirectMessageUserStatus.USER_EXISTS_WITHOUT_PATIENT;

  useEffect(() => {
    switch (sendDraftDirectMessageResult?.draftDirectMessage.userStatus) {
      case DraftDirectMessageUserStatus.UNKNOWN_USER:
      case DraftDirectMessageUserStatus.USER_EXISTS_WITH_INCOMPLETE_PATIENT_REGISTRATION:
        openProceedToRegistrationModal();
        break;
      case DraftDirectMessageUserStatus.USER_EXISTS_WITH_PATIENT:
        openAuthorizeMessageSendingModal();
        break;
      case DraftDirectMessageUserStatus.USER_EXISTS_WITHOUT_PATIENT:
        openBlockingModal();
        break;

      default:
        break;
    }
  }, [sendDraftDirectMessageResult?.draftDirectMessage.userStatus, draftDirectMessageId]);

  return (
    <>
      {isBlockingModalOpened && (
        <LoginOrRegisterBlockingModalsControl
          handleCloseModalClick={closeBlockingModal}
          doctorPortalUserId={activeProfile ? doctorPortalUserId : undefined}
          isUnauthorizedUserWithoutPatientProfile={isUnauthorizedUserWithoutPatientProfile}
          handleUnathorizedUserProceedToPatientRegisterClick={openAuthorizeMessageSendingModal}
        />
      )}
      {isWriteToModalOpened && (
        <UnauthorizedUserChatMessageWriteToModal
          onCloseModalClick={closeWriteToModal}
          onWriteToError={(): void => {
            closeWriteToModal();
            openAccessDeniedErrorModal();
          }}
          onWriteToCompleted={(sendDraftDirectMessage): void => {
            setSendDraftDirectMessageResult(sendDraftDirectMessage);
            closeWriteToModal();
          }}
          doctorPortalUserId={doctorPortalUserId}
          clinicId={clinicId}
        />
      )}
      {isProceedToRegistrationModalOpened && (
        <UnauthorizedUserChatMessageProceedToRegistrationModal
          patientInviteId={sendDraftDirectMessageResult?.patientInviteId}
          draftDirectMessageId={draftDirectMessageId}
          onCancel={closeProceedToRegistrationModal}
          isClinic={!!clinicId}
        />
      )}
      {draftDirectMessageId && isAuthorizeMessageSendingModalOpened && (
        <UnauthorizedUserChatMessageAuthorizeMessageSendingModal
          onCancel={closeAuthorizeMessageSendingModal}
          draftDirectMessageId={draftDirectMessageId}
          isClinic={!!clinicId}
        />
      )}
      {isAccessDeniedErrorModalOpened && (
        <UnauthorizedUserChatMessageAccessDeniedErrorModal
          onCloseModalClick={closeAccessDeniedErrorModal}
          isClinic={!!clinicId}
        />
      )}
    </>
  );
};
