import { getSpecializationLabel, IUserAvatarInfoProps } from '@lib/features-ui';
import { dateFormat } from '@lib/react-components';
import { Chip, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { ReactNode } from 'react';
import { Trans } from 'react-i18next';

import { IGlobalSearchDetailsCardDoctor } from '../model';

import { i18n } from 'i18n';

interface IGlobalSearchDetailsCardObjectProps
  extends Pick<
    IGlobalSearchDetailsCardDoctor,
    'specializations' | 'allowsOnDemandSchedule' | 'earliestAvailabilityDate'
  > {
  doctorAvatarProps?: IUserAvatarInfoProps;
  children: ReactNode;
}

export const GlobalSearchDetailsCardObject = ({
  children,
  specializations,
  allowsOnDemandSchedule,
  earliestAvailabilityDate,
}: IGlobalSearchDetailsCardObjectProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <>
      {children}
      {earliestAvailabilityDate && (
        <Typography variant="body2">
          <Trans
            i18n={i18n}
            i18nKey="features.search.card.label.videoVisitEarliestAvailability"
            components={{ strong: <Typography variant="h9" /> }}
            values={{ earliestAvailabilityDate: format(earliestAvailabilityDate, dateFormat) }}
          />
        </Typography>
      )}
      {allowsOnDemandSchedule && (
        <Typography variant="body2">
          <Trans
            i18n={i18n}
            i18nKey="features.search.card.label.allowsOnDemandSchedule"
            components={{ strong: <Typography variant="h9" /> }}
          />
        </Typography>
      )}
      {specializations?.map(specialization => (
        <Chip
          key={specialization}
          sx={{
            height: 30,
            backgroundColor: palette.surface.default,
            margin: '8px 8px 0 0',
          }}
          label={<Typography variant="info">{getSpecializationLabel(specialization)}</Typography>}
        />
      ))}
    </>
  );
};
