import { Yup } from '@lib/react-components';

import { i18n } from 'i18n';

export interface IVerificationCodeValues {
  verificationCode: string;
}

export const CHECK_VERIFICATION_CODE = /^[0-9]{6}$/;

interface IUseVerificationCodeValidationReturn {
  verificationCodeValidationSchema: Yup.AnyObjectSchema;
}

export const useVerificationCodeValidation = (): IUseVerificationCodeValidationReturn => {
  const verificationCodeValidationSchema = Yup.object().shape({
    verificationCode: Yup.string()
      .required()
      .matches(CHECK_VERIFICATION_CODE, i18n.t('components.forms.registrationForm.errors.validation.verificationCode')),
  });

  return { verificationCodeValidationSchema };
};
