import { Portals } from '@lib/features-bll';

import config from '../../../../config.json';
import { AppUrlConfig } from '../types';

import AppPatientOspedaleSanRaffaeleQrCode from 'assets/images/AppPatientOspedaleSanRaffaeleQrCode.svg';
import AppPatientPolimedicoQrCode from 'assets/images/AppPatientPolimedicoQrCode.svg';
import AppPatientTiberiaHospitalQrCode from 'assets/images/AppPatientTiberiaHospitalQrCode.svg';
import AppPatientWelcomedicineQrCode from 'assets/images/AppPatientWelcomedicineQrCode.svg';
import AppProQrCode from 'assets/images/AppProQrCode.png';
import setupNativeAppOspedaleSanRaffaele from 'assets/images/setupNativeAppOspedaleSanRaffaele.png';
import setupNativeAppPolimedico from 'assets/images/setupNativeAppPolimedico.png';
import setupNativeAppPro from 'assets/images/setupNativeAppPro.png';
import setupNativeAppTiberiaHospital from 'assets/images/setupNativeAppTiberiaHospital.png';
import setupNativeAppWelcomedicine from 'assets/images/setupNativeAppWelcomedicine.png';

export const APP_PRO_URL: AppUrlConfig = {
  googlePlayUrl: `https://play.google.com/store/apps/details?id=${config.AppIdAndroidPro}`,
  appStoreUrl: 'https://apps.apple.com/it/app/welmed-pro/id1635162198',
  qrCode: AppProQrCode,
  img: setupNativeAppPro,
};

type AppPatientUrlMap = Partial<Record<Portals, AppUrlConfig>>;

export const APP_PATIENT_URL: AppPatientUrlMap = {
  [Portals.POLIMEDICO]: {
    googlePlayUrl: `https://play.google.com/store/apps/details?id=${config.AppIdAndroidPolimedico}`,
    appStoreUrl: 'https://apps.apple.com/it/app/polimedico/id1635161984',
    qrCode: AppPatientPolimedicoQrCode,
    img: setupNativeAppPolimedico,
  },
  [Portals.TIBERIAHOSPITAL]: {
    googlePlayUrl: `https://play.google.com/store/apps/details?id=${config.AppIdAndroidTiberiaHospital}`,
    appStoreUrl: 'https://apps.apple.com/it/app/tiberia-hospital-by-welmed/id6480044647',
    qrCode: AppPatientTiberiaHospitalQrCode,
    img: setupNativeAppTiberiaHospital,
  },
  [Portals.OSPEDALESANRAFFAELE]: {
    googlePlayUrl: `https://play.google.com/store/apps/details?id=${config.AppIdAndroidOspedaleSanRaffaele}`,
    appStoreUrl: 'https://apps.apple.com/it/app/ospedale-san-raffaele-bywelmed/id6478528556',
    qrCode: AppPatientOspedaleSanRaffaeleQrCode,
    img: setupNativeAppOspedaleSanRaffaele,
  },
  [Portals.WELCOMEDICINE]: {
    googlePlayUrl: `https://play.google.com/store/apps/details?id=${config.AppIdAndroidWelcomedicine}`,
    appStoreUrl: 'https://apps.apple.com/it/app/welcomedicine-by-welmed/id6478114317',
    qrCode: AppPatientWelcomedicineQrCode,
    img: setupNativeAppWelcomedicine,
  },
};
