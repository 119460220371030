import { fontWeight } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  textCenter: {
    textAlign: 'center',
  },

  closeLabel: {
    display: 'none',
  },

  infoBlockWrapper: {
    marginBottom: 22,
  },

  infoBlockText: {
    '&.MuiTypography-root': {
      // TODO: h body3
      fontSize: 12,
      fontWeight: fontWeight.regular,
      lineHeight: '18px',
    },
  },
}));
