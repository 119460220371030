import { useCallContext } from '@lib/features-bll';
import { Device as TwilioDevice } from '@twilio/voice-sdk';

import { AudioDeviceList } from '../AudioDeviceList';

import { Maybe } from '__generated__/types';
import { i18n } from 'i18n';

export const AudioOutputList = (): Maybe<JSX.Element> => {
  const { device } = useCallContext();

  const deviceAudio = (device as TwilioDevice | undefined)?.audio;

  if (!deviceAudio) {
    return null;
  }

  const outputDevices = Array.from(deviceAudio.availableOutputDevices.values());
  const selectedOutputDevice = Array.from(deviceAudio.speakerDevices.get().values())[0];

  return (
    <AudioDeviceList
      name="output-devices"
      label={i18n.t('features.calls.callOptions.audioOutput')}
      defaultLabel={i18n.t('features.calls.callOptions.defaultAudioOutput')}
      onChange={async (e): Promise<void> => {
        await deviceAudio.speakerDevices.delete(selectedOutputDevice);
        await deviceAudio.speakerDevices.set([e.target.value]);
      }}
      devices={outputDevices}
      selectedDevice={selectedOutputDevice}
    />
  );
};
