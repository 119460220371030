import { changeLibLanguage as changeCoreLanguage } from '@lib/core';
import { changeLibLanguage as changeBllLanguage } from '@lib/features-bll';
import { changeLibLanguage as changeUiLanguage, PortalThemeProvider as PortalThemeProviderUI } from '@lib/features-ui';
import { changeLibLanguage } from '@lib/react-components';
import { ReactNode } from 'react';

import { changeLanguage } from 'i18n';

interface IPortalThemeProviderProps {
  children: ReactNode;
}

export const PortalThemeProvider = ({ children }: IPortalThemeProviderProps): JSX.Element => (
  <PortalThemeProviderUI
    changeLanguage={(language): void => {
      changeLibLanguage(language);
      changeCoreLanguage(language);
      changeLanguage(language);
      changeBllLanguage(language);
      changeUiLanguage(language);
    }}
  >
    {children}
  </PortalThemeProviderUI>
);
