import { BREAK_POINTS } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  stepper: {
    padding: '50px 15px',
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      padding: '24px',
    },
  },

  title: {
    paddingTop: 60,
    textAlign: 'center',
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      padding: '48px 24px 0',
    },
  },

  contentWrapper: {
    padding: '0px 100px 80px',
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      padding: 0,
    },
  },
}));
