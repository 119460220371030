import {
  GlobalSearchDetailsDoctorsType,
  globalSearchDetailsModel,
  IUseGlobalSearchDetailsGenericReturn,
  searchApi,
} from 'features/GlobalSearchDetails';

export const useGlobalSearchDetailsDoctors = (
  skip?: boolean
): IUseGlobalSearchDetailsGenericReturn<GlobalSearchDetailsDoctorsType['globalSearchDetails']> => {
  const { globalSearchVariables, fetchMoreWithPagination } =
    globalSearchDetailsModel.useGlobalSearchDetailsQueryConfiguration<GlobalSearchDetailsDoctorsType>();
  const {
    data,
    fetchMore: fetchMoreDoctors,
    loading,
    previousData,
  } = searchApi.useGlobalSearchDetailsDoctors({
    variables: globalSearchVariables,
    fetchPolicy: 'cache-first',
    skip,
  });

  return {
    loading,
    globalSearchDetails: data?.globalSearchDetails || previousData?.globalSearchDetails,
    fetchMore: () =>
      fetchMoreWithPagination(
        pagination =>
          fetchMoreDoctors({
            variables: { pagination },
            updateQuery: (previousQueryResult, { fetchMoreResult }) => ({
              globalSearchDetails: {
                doctors: {
                  items:
                    previousQueryResult?.globalSearchDetails?.doctors?.items?.concat(
                      fetchMoreResult?.globalSearchDetails?.doctors?.items ?? []
                    ) ?? [],
                },
              },
            }),
          }),
        data?.globalSearchDetails?.doctors?.items?.length
      ),
  };
};
