import { useSetDoctorMedicalRole } from 'features/Doctor/graphql/__generated__/SetDoctorMedicalRole.mutation';
import { useUserModelStore } from 'features/Users/model';

interface IUseDoctorMedicalRoleReturn {
  updateMedicalRole: (newRoleId: string, onSuccess: () => void) => Promise<void>;
}

export const useUpdateDoctorMedicalRole = (): IUseDoctorMedicalRoleReturn => {
  const { updateDoctorRole } = useUserModelStore();
  const [setDoctorMedicalRole] = useSetDoctorMedicalRole();

  const updateMedicalRole = async (newRoleId: string, onSuccess: () => void): Promise<void> => {
    await setDoctorMedicalRole({
      variables: {
        medicalRoleId: newRoleId,
      },
      onCompleted: ({ setDoctorMedicalRole: { medicalRole } }) => {
        if (medicalRole) {
          updateDoctorRole({ medicalRole });
          onSuccess();
        }
      },
    });
  };

  return {
    updateMedicalRole,
  };
};
