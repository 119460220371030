import { Reducer } from 'redux';

import { Action, ActionTypeList, initialState, IState } from './types';

export const patientReducer: Reducer<IState, Action> = (state: IState = initialState, action) => {
  switch (action.type) {
    case ActionTypeList.SET_PATIENT_FILE_ERROR:
      return {
        ...state,
        attachFilesError: action.payload.error,
      };

    case ActionTypeList.SET_PATIENT_FILE_UPLOADING:
      return {
        ...state,
        isPatientFileIsLoading: action.payload.isPatientFileIsLoading,
      };

    case ActionTypeList.SET_PATIENT_EHR_DATA_LOADING:
      return {
        ...state,
        isPatientDataIsLoading: action.payload.isPatientDataIsLoading,
      };

    case ActionTypeList.SET_PATIENT_MEDICAL_DATA:
      return {
        ...state,
        patientMedicalData: {
          ...action.payload.patientMedical,
          patient: {
            ...action.payload.patientMedical.patient,
            fileList: action.payload.patientMedical.patient.fileList.filter(
              ({ file: { cancelledAt } }) => !cancelledAt
            ),
          },
        },
      };

    case ActionTypeList.SET_PATIENT_MEDICAL_DATA_USER:
      return {
        ...state,
        patientMedicalData: state.patientMedicalData && {
          ...state.patientMedicalData,
          patient: {
            ...state.patientMedicalData.patient,
            user: {
              ...state.patientMedicalData.patient.user,
              ...action.payload.user,
            },
          },
        },
      };

    case ActionTypeList.REMOVE_PATIENT_MEDICAL_DATA:
      return {
        ...state,
        patientMedicalData: null,
      };

    case ActionTypeList.UPDATE_PATIENT_FILE_LIST:
      return {
        ...state,
        patientMedicalData: state.patientMedicalData && {
          ...state.patientMedicalData,
          patient: {
            ...state.patientMedicalData.patient,
            fileList: [action.payload.newPatientFile, ...state.patientMedicalData.patient.fileList],
          },
        },
      };

    case ActionTypeList.UPDATE_PATIENT_FILE_IN_LIST:
      return {
        ...state,
        patientMedicalData: state.patientMedicalData && {
          ...state.patientMedicalData,
          patient: {
            ...state.patientMedicalData.patient,
            fileList: (state.patientMedicalData.patient.fileList || []).map(attachment =>
              attachment.id === action.payload.newPatientFile.id
                ? { ...attachment, ...action.payload.newPatientFile }
                : attachment
            ),
          },
        },
      };

    case ActionTypeList.EDIT_PATIENT_FILE_IN_LIST:
      return {
        ...state,
        patientMedicalData: state.patientMedicalData && {
          ...state.patientMedicalData,
          patient: {
            ...state.patientMedicalData.patient,
            fileList: (state.patientMedicalData.patient.fileList || []).map(attachment =>
              attachment.id === action.payload.editedPatientFile.id ? action.payload.editedPatientFile : attachment
            ),
          },
        },
      };

    case ActionTypeList.CANCEL_PATIENT_FILE_IN_LIST:
      return {
        ...state,
        patientMedicalData: state.patientMedicalData && {
          ...state.patientMedicalData,
          patient: {
            ...state.patientMedicalData.patient,
            fileList: (state.patientMedicalData.patient.fileList || []).filter(
              attachment => attachment.id !== action.payload.canceledPatientFile.id
            ),
          },
        },
      };

    case ActionTypeList.SET_CANCELLED_FILES_LIST_PATIENT:
      return {
        ...state,
        cancelledFilesPatient: action.payload.patientUser,
      };

    case ActionTypeList.REMOVE_CANCELLED_FILES_LIST_PATIENT:
      return {
        ...state,
        cancelledFilesPatient: null,
      };

    case ActionTypeList.UPDATE_PATIENT_INFO:
      return {
        ...state,
        patientMedicalData: state.patientMedicalData && {
          ...state.patientMedicalData,
          patient: {
            ...state.patientMedicalData.patient,
            ...action.payload.newPatientFields,
          },
        },
      };

    case ActionTypeList.SET_PATIENT_MASTOCYTOSIS_DATA:
      return {
        ...state,
        patientMastocytosisData: action.payload.patientMastocytosisData,
      };

    case ActionTypeList.SET_PATIENT_MASTOCYTOSIS_HISTORY_DATA:
      return {
        ...state,
        patientMastocytosisHistoryData: action.payload.patientMastocytosisHistoryData,
      };

    case ActionTypeList.REMOVE_PATIENT_MASTOCYTOSIS_HISTORY_DATA:
      return {
        ...state,
        patientMastocytosisHistoryData: null,
      };

    case ActionTypeList.SET_TUMOURS_MEDICAL_DATA_PARAMETER:
      return {
        ...state,
        patientMastocytosisData: state.patientMastocytosisData && {
          ...state.patientMastocytosisData,
          tumours: {
            ...state.patientMastocytosisData.tumours,
            genericInformation: action.payload.tumoursMedicalDataParameter,
          },
        },
      };

    case ActionTypeList.SET_ANAPHYLACTIC_SHOCK_MEDICAL_DATA_PARAMETER:
      return {
        ...state,
        patientMastocytosisData: state.patientMastocytosisData && {
          ...state.patientMastocytosisData,
          allergies: {
            ...state.patientMastocytosisData.allergies,
            anaphylacticShock: action.payload.anaphylacticShockMedicalDataParameter,
          },
        },
      };

    case ActionTypeList.SET_ALLERGY_TO_HYMENOPTERA_MEDICAL_DATA_PARAMETER:
      return {
        ...state,
        patientMastocytosisData: state.patientMastocytosisData && {
          ...state.patientMastocytosisData,
          allergies: {
            ...state.patientMastocytosisData.allergies,
            hymenoptera: action.payload.allergyToHymenopteraMedicalDataParameter,
          },
        },
      };

    case ActionTypeList.SET_OTHER_ALLERGIES_MEDICAL_DATA_PARAMETER:
      return {
        ...state,
        patientMastocytosisData: state.patientMastocytosisData && {
          ...state.patientMastocytosisData,
          allergies: {
            ...state.patientMastocytosisData.allergies,
            other: action.payload.otherAllergiesMedicalDataParameter,
          },
        },
      };

    case ActionTypeList.SET_SYNCOPES_MEDICAL_DATA_PARAMETER:
      return {
        ...state,
        patientMastocytosisData: state.patientMastocytosisData && {
          ...state.patientMastocytosisData,
          signsAndSymptoms: {
            ...state.patientMastocytosisData.signsAndSymptoms,
            syncopes: action.payload.syncopesMedicalDataParameter,
          },
        },
      };

    case ActionTypeList.SET_GASTROINTESTINAL_DISORDERS_MEDICAL_DATA_PARAMETER:
      return {
        ...state,
        patientMastocytosisData: state.patientMastocytosisData && {
          ...state.patientMastocytosisData,
          signsAndSymptoms: {
            ...state.patientMastocytosisData.signsAndSymptoms,
            gastrointestinalDisorders: action.payload.gastrointestinalDisordersMedicalDataParameter,
          },
        },
      };

    case ActionTypeList.SET_SKIN_INVOLVEMENT_MEDICAL_DATA_PARAMETER:
      return {
        ...state,
        patientMastocytosisData: state.patientMastocytosisData && {
          ...state.patientMastocytosisData,
          signsAndSymptoms: {
            ...state.patientMastocytosisData.signsAndSymptoms,
            skinInvolvement: action.payload.skinInvolvementMedicalDataParameter,
          },
        },
      };

    case ActionTypeList.SET_BONE_DENSITOMETRY_MEDICAL_DATA_PARAMETER:
      return {
        ...state,
        patientMastocytosisData: state.patientMastocytosisData && {
          ...state.patientMastocytosisData,
          radiology: {
            ...state.patientMastocytosisData.radiology,
            boneDensitometry: action.payload.boneDensitometryMedicalDataParameter,
          },
        },
      };

    case ActionTypeList.SET_BONE_IMAGING_MEDICAL_DATA_PARAMETER:
      return {
        ...state,
        patientMastocytosisData: state.patientMastocytosisData && {
          ...state.patientMastocytosisData,
          radiology: {
            ...state.patientMastocytosisData.radiology,
            boneImaging: action.payload.boneImagingMedicalDataParameter,
          },
        },
      };

    case ActionTypeList.SET_ABDOMEN_ULTRASOUND_MEDICAL_DATA_PARAMETER:
      return {
        ...state,
        patientMastocytosisData: state.patientMastocytosisData && {
          ...state.patientMastocytosisData,
          radiology: {
            ...state.patientMastocytosisData.radiology,
            abdomenUltrasound: action.payload.abdomenUltrasoundMedicalDataParameter,
          },
        },
      };

    case ActionTypeList.SET_IMMUNOPHENOTYPING_MEDICAL_DATA_PARAMETER:
      return {
        ...state,
        patientMastocytosisData: state.patientMastocytosisData && {
          ...state.patientMastocytosisData,
          pathologyResults: {
            ...state.patientMastocytosisData.pathologyResults,
            immunophenotyping: action.payload.immunophenotypingMedicalDataParameter,
          },
        },
      };

    case ActionTypeList.SET_C_KIT_MUTATION_MEDICAL_DATA_PARAMETER:
      return {
        ...state,
        patientMastocytosisData: state.patientMastocytosisData && {
          ...state.patientMastocytosisData,
          pathologyResults: {
            ...state.patientMastocytosisData.pathologyResults,
            cKitMutation: action.payload.cKitMutationMedicalDataParameter,
          },
        },
      };

    case ActionTypeList.SET_CHANGE_PATIENT_MEDICAL_DATA_RESIDENCE_ADDRESS:
      return {
        ...state,
        patientMedicalData: state.patientMedicalData && {
          ...state.patientMedicalData,
          patient: state.patientMedicalData.patient && {
            ...state.patientMedicalData.patient,
            user: state.patientMedicalData.patient.user && {
              ...state.patientMedicalData.patient.user,
              currentResidenceAddress: action.payload.currentResidenceAddress,
            },
          },
        },
      };

    case ActionTypeList.SET_SOKAL_INDEX_SCORE:
      return {
        ...state,
        patientIndexesData: {
          ...(state.patientIndexesData || {}),
          sokal: action.payload.indexScore,
        },
      };

    case ActionTypeList.SET_ELTS_INDEX_SCORE:
      return {
        ...state,
        patientIndexesData: {
          ...(state.patientIndexesData || {}),
          elts: action.payload.indexScore,
        },
      };

    case ActionTypeList.SET_MPN10_INDEX_SCORE:
      return {
        ...state,
        patientIndexesData: {
          ...(state.patientIndexesData || {}),
          mpn10: action.payload.indexScore,
        },
      };

    case ActionTypeList.SET_CTNM_INDEX_SCORE:
      return {
        ...state,
        patientIndexesData: {
          ...(state.patientIndexesData || {}),
          cTnm: action.payload.indexScore,
        },
      };

    case ActionTypeList.SET_PTNM_INDEX_SCORE:
      return {
        ...state,
        patientIndexesData: {
          ...(state.patientIndexesData || {}),
          pTnm: action.payload.indexScore,
        },
      };

    case ActionTypeList.SET_TNM_INDEX_SCORE:
      return {
        ...state,
        patientIndexesData: {
          ...(state.patientIndexesData || {}),
          tnm: action.payload.indexScore,
        },
      };

    case ActionTypeList.SET_DIPSS_PLUS_INDEX_SCORE:
      return {
        ...state,
        patientIndexesData: {
          ...(state.patientIndexesData || {}),
          dipssPlus: action.payload.indexScore,
        },
      };

    case ActionTypeList.SET_IPSSR_MEDICAL_INDEX_SCORE:
      return {
        ...state,
        patientIndexesData: {
          ...(state.patientIndexesData || {}),
          ipssr: action.payload.indexScore,
        },
      };

    case ActionTypeList.SET_MIPSS70_INDEX_SCORE:
      return {
        ...state,
        patientIndexesData: {
          ...(state.patientIndexesData || {}),
          mipss70: action.payload.indexScore,
        },
      };

    case ActionTypeList.SET_CHARLSON_INDEX_SCORE:
      return {
        ...state,
        patientIndexesData: {
          ...(state.patientIndexesData || {}),
          charlson: action.payload.indexScore,
        },
      };

    case ActionTypeList.SET_MYSEC_PM_INDEX_SCORE:
      return {
        ...state,
        patientIndexesData: {
          ...(state.patientIndexesData || {}),
          mysecPm: action.payload.indexScore,
        },
      };

    case ActionTypeList.SET_DIPSS_INDEX_SCORE:
      return {
        ...state,
        patientIndexesData: {
          ...(state.patientIndexesData || {}),
          dipss: action.payload.indexScore,
        },
      };

    case ActionTypeList.ADD_SOKAL_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          sokal: [...(state.patientIndexesHistoryData.sokal || []), action.payload.indexData],
        },
      };

    case ActionTypeList.ADD_ELTS_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          elts: [...(state.patientIndexesHistoryData.elts || []), action.payload.indexData],
        },
      };

    case ActionTypeList.ADD_MPN10_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          mpn10: [...(state.patientIndexesHistoryData.mpn10 || []), action.payload.indexData],
        },
      };

    case ActionTypeList.ADD_CTNM_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          cTnm: [...(state.patientIndexesHistoryData.cTnm || []), action.payload.indexData],
        },
      };

    case ActionTypeList.ADD_PTNM_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          pTnm: [...(state.patientIndexesHistoryData.pTnm || []), action.payload.indexData],
        },
      };

    case ActionTypeList.ADD_TNM_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          tnm: [...(state.patientIndexesHistoryData.tnm || []), action.payload.indexData],
        },
      };

    case ActionTypeList.ADD_TNM_ASCO_2021_STOMACH_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          tnmAsco2021Stomach: [...(state.patientIndexesHistoryData.tnmAsco2021Stomach || []), action.payload.indexData],
        },
      };

    case ActionTypeList.ADD_TNM_ASCO_2021_PANCREAS_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          tnmAsco2021Pancreas: [
            ...(state.patientIndexesHistoryData.tnmAsco2021Pancreas || []),
            action.payload.indexData,
          ],
        },
      };

    case ActionTypeList.ADD_TNM_ASCO_2021_DUODENUM_AND_VATER_AMPULLA_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          tnmAsco2021DuodenumAndVaterAmpulla: [
            ...(state.patientIndexesHistoryData.tnmAsco2021DuodenumAndVaterAmpulla || []),
            action.payload.indexData,
          ],
        },
      };

    case ActionTypeList.ADD_TNM_ASCO_2021_JEJUNUM_AND_ILEUM_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          tnmAsco2021JejunumAndIleum: [
            ...(state.patientIndexesHistoryData.tnmAsco2021JejunumAndIleum || []),
            action.payload.indexData,
          ],
        },
      };

    case ActionTypeList.ADD_TNM_ASCO_2021_APPENDIX_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          tnmAsco2021Appendix: [
            ...(state.patientIndexesHistoryData.tnmAsco2021Appendix || []),
            action.payload.indexData,
          ],
        },
      };

    case ActionTypeList.ADD_TNM_ASCO_2021_COLON_AND_RECTUM_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          tnmAsco2021ColonAndRectum: [
            ...(state.patientIndexesHistoryData.tnmAsco2021ColonAndRectum || []),
            action.payload.indexData,
          ],
        },
      };

    case ActionTypeList.ADD_DIPSS_PLUS_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          dipssPlus: [...(state.patientIndexesHistoryData.dipssPlus || []), action.payload.indexData],
        },
      };

    case ActionTypeList.ADD_IPSSR_MEDICAL_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          ipssr: [...(state.patientIndexesHistoryData.ipssr || []), action.payload.indexData],
        },
      };

    case ActionTypeList.ADD_MIPSS70_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          mipss70: [...(state.patientIndexesHistoryData.mipss70 || []), action.payload.indexData],
        },
      };

    case ActionTypeList.ADD_CHARLSON_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          charlson: [...(state.patientIndexesHistoryData.charlson || []), action.payload.indexData],
        },
      };

    case ActionTypeList.ADD_MYSEC_PM_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          mysecPm: [...(state.patientIndexesHistoryData.mysecPm || []), action.payload.indexData],
        },
      };

    case ActionTypeList.ADD_DIPSS_INDEX_DATA:
      return {
        ...state,
        patientIndexesHistoryData: state.patientIndexesHistoryData && {
          ...state.patientIndexesHistoryData,
          dipss: [...(state.patientIndexesHistoryData.dipss || []), action.payload.indexData],
        },
      };

    case ActionTypeList.SET_PATIENT_CALCULATIONS:
      return {
        ...state,
        patientCalculations: action.payload.patientCalculations,
      };

    case ActionTypeList.ADD_PATIENT_CALCULATIONS:
      return {
        ...state,
        patientCalculations: [...(state.patientCalculations || []), action.payload.patientCalculations],
      };

    case ActionTypeList.SET_NO_EHR_PRIVACIES:
      return {
        ...state,
        noEhrPrivacies: action.payload.noEhrPrivacies,
      };

    default:
      return state;
  }
};
