import { useBreakpoints } from '@lib/react-components';
import { Box, Button, Typography } from '@mui/material';

import { i18n } from 'i18n';

interface ISummaryStepProps {
  onSubmit: VoidFunction;
}

export const SummaryStep = ({ onSubmit }: ISummaryStepProps): JSX.Element => {
  const { isMobileView } = useBreakpoints();

  return (
    <Box sx={{ padding: { xs: '0 24px', mobile: 'initial' } }}>
      <Typography sx={{ textAlign: 'center' }}>
        {i18n.t('features.affiliations.activationStepper.summaryStep.mainText')}
      </Typography>
      <Box
        sx={{
          textAlign: 'center',
          marginTop: '50px',
        }}
      >
        <Button size={isMobileView ? 'small' : 'medium'} fullWidth={isMobileView} onClick={onSubmit}>
          {i18n.t('features.affiliations.activationStepper.summaryStep.backToDashboard')}
        </Button>
      </Box>
    </Box>
  );
};
