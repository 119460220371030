import { defaultBorderRadius, getDefaultBoxShadow, Loader } from '@lib/react-components';
import { Stack, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import {
  GlobalSearchDetails,
  globalSearchDetailsModel,
  useGlobalSearchDetailsTabsConfiguration,
} from 'features/GlobalSearchDetails';

interface IGlobalSearchDetailsListWrapperProps {
  children: ReactNode;
}

export const GlobalSearchDetailsListWrapper = ({ children }: IGlobalSearchDetailsListWrapperProps): JSX.Element => {
  const { palette } = useTheme();
  const {
    loading: tabLoading,
    isAllTabsEmpty,
    isActiveTabValid,
    activeTab,
  } = useGlobalSearchDetailsTabsConfiguration();
  const listLoading = globalSearchDetailsModel.useGlobalSearchDetailsListLoadings();

  return (
    <Loader loading={tabLoading || listLoading || (!isAllTabsEmpty && !activeTab)} withShadow>
      <Stack
        sx={{
          minHeight: '260px',
          width: '100%',
          backgroundColor: 'white.main',
          borderRadius: `${defaultBorderRadius}px ${defaultBorderRadius}px ${defaultBorderRadius}px ${defaultBorderRadius}px`,
          boxShadow: getDefaultBoxShadow(palette.shadow.main),
        }}
      >
        {isActiveTabValid && (
          <>
            <GlobalSearchDetails.TabDescription />
            <GlobalSearchDetails.TabFiltersSection />
          </>
        )}
        {isAllTabsEmpty ? <GlobalSearchDetails.NoResult /> : children}
      </Stack>
    </Loader>
  );
};
