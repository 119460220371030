import {
  PersonIcon,
  ConventionIcon,
  PrivateClinicIcon,
  FreeClinicIcon,
  MaterialSymbolsIcon,
  RequestConventionTypeIcon,
  RequestFreeClinicTypeIcon,
  RequestPrivateClinicTypeIcon,
  RequestPrivateTypeIcon,
  IMaterialSymbolsIconProps,
} from '@lib/icons';
import { useBreakpoints } from '@lib/react-components';

import { GlobalSearchDetailsTabType } from '../config';

interface IGlobalSearchDetailsTabIconProps extends Omit<IMaterialSymbolsIconProps, 'icon'> {
  tabType: GlobalSearchDetailsTabType;
}

export const GlobalSearchDetailsTabIcon = ({
  tabType,
  fill,
  ...props
}: IGlobalSearchDetailsTabIconProps): JSX.Element => {
  const { isMobileView } = useBreakpoints();

  const globalSearchDetailsTabIcon: Record<GlobalSearchDetailsTabType, JSX.Element> = {
    [GlobalSearchDetailsTabType.PRIVATE_DOCTORS]: isMobileView ? (
      <PersonIcon fill={fill} fontWeight="300" />
    ) : (
      <RequestPrivateTypeIcon fill={fill} />
    ),
    [GlobalSearchDetailsTabType.AFFILIATED_DOCTORS]: isMobileView ? (
      <ConventionIcon fill={fill} fontWeight="300" />
    ) : (
      <RequestConventionTypeIcon fill={fill} />
    ),
    [GlobalSearchDetailsTabType.CLINICS]: isMobileView ? (
      <PrivateClinicIcon fill={fill} fontWeight="300" />
    ) : (
      <RequestPrivateClinicTypeIcon fill={fill} />
    ),
    [GlobalSearchDetailsTabType.FREE_CLINICS]: isMobileView ? (
      <FreeClinicIcon fill={fill} />
    ) : (
      <RequestFreeClinicTypeIcon fill={fill} />
    ),
  };

  return <MaterialSymbolsIcon {...props} icon={globalSearchDetailsTabIcon[tabType]} />;
};
