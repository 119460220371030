import { BREAK_POINTS } from '@lib/react-components';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    borderRadius: '20px',
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      margin: 0,
      width: '100%',
      maxHeight: '100%',
      borderRadius: 0,
    },
  },
  container: {
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      alignItems: 'initial',
    },
  },
}));
