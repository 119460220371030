import { SxProps, Theme, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

import { i18n } from 'i18n';

interface ILoginOrRegisterBlockingModalTitleProps {
  translationPath: string;
  sx?: SxProps<Theme>;
}

export const LoginOrRegisterBlockingModalTitle = ({
  translationPath,
  ...props
}: ILoginOrRegisterBlockingModalTitleProps): JSX.Element => (
  <Typography textAlign="center" {...props} variant="h6">
    <Trans i18n={i18n} i18nKey={translationPath} />
  </Typography>
);
