import { intervalToDuration } from 'date-fns';

import { Maybe } from '__generated__/types';

const zeroPad = (number?: number): string => (number ? String(number).padStart(2, '0') : '00');

export const parseCallDuration = (startDate: Date, endDate?: Maybe<Date>): string => {
  const { hours, minutes, seconds } = intervalToDuration({
    start: startDate.getTime(),
    end: (endDate ? new Date(endDate) : new Date()).getTime(),
  });

  return [hours, minutes, seconds].map<string>(zeroPad).join(':');
};
