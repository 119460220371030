import { useInvitesModelGuestInviteData } from '@lib/features-bll';

import { ProfileType } from '__generated__/types';
import { usePortalSlug } from 'features/Portal';
import { isDoctorRegistration, isNonDoctorRegistration } from 'lib/helpers/registration';

interface IUseRegistrationModelGetProfileTypeWithInviteCheckReturn {
  isDoctor: boolean;
  isNonDoctor: boolean;
}

export const useRegistrationModelGetProfileTypeWithInviteCheck =
  (): IUseRegistrationModelGetProfileTypeWithInviteCheckReturn => {
    const portalSlug = usePortalSlug();
    const { guestInvite } = useInvitesModelGuestInviteData();

    return {
      isDoctor: guestInvite?.profileType === ProfileType.DOCTOR || isDoctorRegistration(portalSlug),
      isNonDoctor: guestInvite?.profileType === ProfileType.NON_DOCTOR || isNonDoctorRegistration(portalSlug),
    };
  };
