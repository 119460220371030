import { WebToNativeActions, useGetFileUrlLazyQuery } from '@lib/features-bll';
import { openLinkInNewTab, openLinkSelfTab } from '@lib/react-components';

import { createNativeAction, usePlatformBasedAction } from 'lib/hooks/usePlatformBasedAction.hook';

type UseFileClickRedirect = () => {
  onFileClickRedirect: (id: string, nativeActionOnMobile?: boolean, openNewTab?: boolean) => Promise<void>;
};

export const useFileClickRedirect: UseFileClickRedirect = () => {
  const platformBasedAction = usePlatformBasedAction();
  const [getFileUrl] = useGetFileUrlLazyQuery();

  const onFileClickRedirect: ReturnType<UseFileClickRedirect>['onFileClickRedirect'] = async (
    id,
    nativeActionOnMobile,
    openNewTab = true
  ) => {
    const { data, error } = await getFileUrl({ variables: { fileId: id }, fetchPolicy: 'no-cache' });

    if (!data || error) {
      return;
    }

    const url = data.getFileUrl;

    const webRedirect = (): void => (openNewTab ? openLinkInNewTab(url) : openLinkSelfTab(url));

    if (nativeActionOnMobile) {
      platformBasedAction({
        webAction: webRedirect,
        nativeAction: createNativeAction(WebToNativeActions.openFile, {
          id,
          url,
        }),
      });
    } else {
      webRedirect();
    }
  };

  return { onFileClickRedirect };
};
