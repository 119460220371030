import { ISetupNativeAppSectionProps } from '../components';
import { APP_PATIENT_URL, APP_PRO_URL } from '../constants';

import { ProfileType, Maybe } from '__generated__/types';
import { usePortalSlug } from 'features/Portal';
import { AppUrlConfig } from 'features/Users/types';
import { i18n } from 'i18n';

type NativeAppConfiguration = AppUrlConfig & {
  title: string;
  sectionLabel: string;
  downloadLabel: string;
};

const getModalAppTitle = ({ isShownOnLogin, profileType }: ISetupNativeAppSectionProps): string => {
  if (profileType === ProfileType.PATIENT) {
    return i18n.t('features.setupNativeApp.patient.title');
  }

  return i18n.t(isShownOnLogin ? 'features.setupNativeApp.pro.title' : 'features.setupNativeApp.pro.titleForSignature');
};

const getModalAppSectionLabel = ({
  isShownOnLogin,
  isUsingLegacyOtp,
  profileType,
}: ISetupNativeAppSectionProps): string =>
  i18n.t(
    profileType === ProfileType.PATIENT ? 'features.setupNativeApp.patient.label' : 'features.setupNativeApp.pro.label',
    { context: (isShownOnLogin && 'login') || (isUsingLegacyOtp && 'novelty') || undefined }
  );

export const useUserModelNativeAppConfiguration = (
  params: ISetupNativeAppSectionProps
): Maybe<NativeAppConfiguration> => {
  const portalSlug = usePortalSlug();

  if (params.profileType === ProfileType.PATIENT) {
    const config = APP_PATIENT_URL[portalSlug];

    if (config) {
      return {
        sectionLabel: getModalAppSectionLabel(params),
        title: getModalAppTitle(params),
        downloadLabel: i18n.t('features.setupNativeApp.patient.downloadLabel'),
        ...APP_PATIENT_URL[portalSlug],
      } as NativeAppConfiguration;
    }

    return null;
  }

  return {
    sectionLabel: getModalAppSectionLabel(params),
    title: getModalAppTitle(params),
    downloadLabel: i18n.t('features.setupNativeApp.pro.downloadLabel'),
    ...APP_PRO_URL,
  } as NativeAppConfiguration;
};
