import { gql } from '@apollo/client';

import { FILE_MODEL_FRAGMENT } from 'graphql/fragments/file';

const COMPLETE_DICOM_DISK_UPLOAD = gql`
  mutation CompleteDicomDiskUpload($dicomDiskId: String!, $dateOfExecution: DateTime!, $label: String!) {
    completeDicomDiskUpload(dicomDiskId: $dicomDiskId, dateOfExecution: $dateOfExecution, label: $label) {
      ...FileModelFragment
    }
  }
  ${FILE_MODEL_FRAGMENT}
`;

export default { COMPLETE_DICOM_DISK_UPLOAD };
