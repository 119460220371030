import { GlobalSearchDetailsOrder } from '__generated__/types';
import { i18n } from 'i18n';

export const GLOBAL_SEARCH_DETAILS_TAB_ORDER_FILTER_ITEMS = [
  {
    label: i18n.t('features.search.tabFilterSection.orderFilter.moreRelevant'),
    value: GlobalSearchDetailsOrder.MOST_RELEVANT,
  },
  {
    label: i18n.t('features.search.tabFilterSection.orderFilter.mostRequested'),
    value: GlobalSearchDetailsOrder.MOST_REQUESTED,
  },
  {
    label: i18n.t('features.search.tabFilterSection.orderFilter.earliestAvailable'),
    value: GlobalSearchDetailsOrder.EARLIEST_AVAILABLE,
  },
];
