import {
  BREAK_POINTS,
  defaultTransition,
  leftNavigationCollapsedWidth,
  leftNavigationWidth,
  TOP_MARGIN,
} from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

import { INeedUserVerification } from 'components/VerifyUser';
import { SSN_LINK_HEIGHT, TOP_MARGIN_MOBILE } from 'lib/constants/scroll';

interface IAuthorizedRoutStyle extends INeedUserVerification {
  withHeader: boolean;
  withSsnClinicLinkVisible: boolean;
}

export const useStyles = makeStyles<IAuthorizedRoutStyle>()(
  (
    theme,
    { needUserVerification, isFullScreen, verifyUserNotificationHeight, withHeader, withSsnClinicLinkVisible }
  ) => ({
    bodyWhiteBackground: {
      backgroundColor: theme.palette.white.main,
    },
    rootWrapper: {
      marginTop: !isFullScreen
        ? `${TOP_MARGIN + (needUserVerification ? verifyUserNotificationHeight : 0)}px`
        : 'initial',
    },
    rootWrapperMobile: {
      [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
        marginTop: `${
          (withHeader ? TOP_MARGIN_MOBILE : 0) +
          (needUserVerification ? verifyUserNotificationHeight : 0) +
          (withSsnClinicLinkVisible ? SSN_LINK_HEIGHT : 0)
        }px`,
      },
    },
    rootWrapperDesktop: {
      '@media print': {
        margin: 0,
      },
      [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
        width: '100%',
      },
    },
    rootWrapperFull: {
      [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
        '&.MuiBox-root': {
          margin: 0,
        },
      },
    },
    sidebarExpanded: {
      marginLeft: leftNavigationWidth,
      [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
        marginLeft: 0,
        paddingBottom: 0,
      },
    },
    fullScreen: {
      margin: 0,
    },
    sideBarTransition: {
      transition: defaultTransition,
    },
    sidebarCollapsed: {
      marginLeft: leftNavigationCollapsedWidth,
      [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
        marginLeft: 0,
        marginBottom: 35,
      },
    },
    disableApp: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: theme.palette.lines.table,
      opacity: 0.6,
      zIndex: 2000,
    },
    loadingWrapper: {
      height: 'calc(100vh - 16px)',
    },
    hiddenOnMobile: {
      [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
        display: 'none',
      },
    },
  })
);
