import { DefaultOptions } from '@apollo/client';
import { createApolloClient, PersistedDataKeys } from '@lib/core';

import config from '../../config.json';

import mutations from './mutations';
import queries from './queries';
import { Mutation, Query } from './types/queries';

import authorization from 'authorization';
import { persistedData } from 'lib/helpers/persistedData';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const client = createApolloClient({
  defaultOptions,
  graphqlEndpoint: config.BaseApiUrl,
  useErrorLink: true,
  wsGraphqlEndpoint: config.BaseWSApiUrl,
  getAccessToken: () => persistedData.get<string>(PersistedDataKeys.AccessToken),
  getTokenExpire: () => persistedData.get<string>(PersistedDataKeys.ExpirationTime),
  getRefreshToken: () => persistedData.get<string>(PersistedDataKeys.RefreshToken),
  getProfileId: () => persistedData.get<string>(PersistedDataKeys.ActiveProfileId),
  getPortalId: () => persistedData.get<string>(PersistedDataKeys.PortalId),
  refreshSessionHandler: refreshToken => authorization.refreshSession(refreshToken),
});

/**
 * @deprecated(reason: "Use graphql-codegen system")
 */
export const graphQLMutate: Mutation = options => client.mutate(options);

/** @deprecated(reason: "Use graphql-codegen system")  */
export const graphQLQuery: Query = options => client.query(options);

client.defaultOptions = defaultOptions;

export default {
  queries,
  mutations,
};
