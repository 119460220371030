import { useLoginOrRegisterBlockingModalActiveProfile } from './useLoginOrRegisterBlockingModalActiveProfile';
import { useLoginOrRegisterBlockingModalControls } from './useLoginOrRegisterBlockingModalControls';
import { useLoginOrRegisterBlockingModalPatientProfile } from './useLoginOrRegisterBlockingModalPatientProfile';
import { useLoginOrRegisterBlockingModalSwitchToPatientProfileEffect } from './useLoginOrRegisterBlockingModalSwitchToPatientProfileEffect';

export const loginOrRegisterBlockingModalModel = {
  useLoginOrRegisterBlockingModalActiveProfile,
  useLoginOrRegisterBlockingModalPatientProfile,
  useLoginOrRegisterBlockingModalControls,
  useLoginOrRegisterBlockingModalSwitchToPatientProfileEffect,
};
