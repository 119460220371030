import { OrderByKeyword } from '__generated__/types';

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNKNOWN = 'UNKNOWN',
}

export enum SortingOrder {
  ASC = OrderByKeyword.ASC,
  DESC = OrderByKeyword.DESC,
  DEFAULT = '',
}

export type PaginatedRequestData<T> = {
  totalCount: number;
  items: T;
};

export type IGraphQLVariables<T> = {
  variables: T;
};
