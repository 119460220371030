import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type ChatApiClinicChatPatientWritePolicyVariables = Types.Exact<{
  clinicId: Types.Scalars['String']['input'];
  patientPortalUserId: Types.Scalars['String']['input'];
}>;


export type ChatApiClinicChatPatientWritePolicy = { __typename?: 'Query', clinicChat: { __typename?: 'ClinicChatModel', patientWritePolicy: Types.AllowDenyPolicyType, numberOfChatItems: number, featuredRequest?: { __typename?: 'Request', isStatusFinal: boolean } | null } };


export const ChatApiClinicChatPatientWritePolicyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ChatApiClinicChatPatientWritePolicy"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"clinicId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patientPortalUserId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clinicChat"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"clinicId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"clinicId"}}},{"kind":"Argument","name":{"kind":"Name","value":"patientPortalUserId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patientPortalUserId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ClinicChatModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"featuredRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isStatusFinal"}}]}},{"kind":"Field","name":{"kind":"Name","value":"patientWritePolicy"}},{"kind":"Field","name":{"kind":"Name","value":"numberOfChatItems"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useChatApiClinicChatPatientWritePolicy__
 *
 * To run a query within a React component, call `useChatApiClinicChatPatientWritePolicy` and pass it any options that fit your needs.
 * When your component renders, `useChatApiClinicChatPatientWritePolicy` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatApiClinicChatPatientWritePolicy({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *      patientPortalUserId: // value for 'patientPortalUserId'
 *   },
 * });
 */
export function useChatApiClinicChatPatientWritePolicy(baseOptions: ApolloReactHooks.QueryHookOptions<ChatApiClinicChatPatientWritePolicy, ChatApiClinicChatPatientWritePolicyVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ChatApiClinicChatPatientWritePolicy, ChatApiClinicChatPatientWritePolicyVariables>(ChatApiClinicChatPatientWritePolicyDocument, options);
      }
export function useChatApiClinicChatPatientWritePolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChatApiClinicChatPatientWritePolicy, ChatApiClinicChatPatientWritePolicyVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ChatApiClinicChatPatientWritePolicy, ChatApiClinicChatPatientWritePolicyVariables>(ChatApiClinicChatPatientWritePolicyDocument, options);
        }
export type ChatApiClinicChatPatientWritePolicyHookResult = ReturnType<typeof useChatApiClinicChatPatientWritePolicy>;
export type ChatApiClinicChatPatientWritePolicyLazyQueryHookResult = ReturnType<typeof useChatApiClinicChatPatientWritePolicyLazyQuery>;
export type ChatApiClinicChatPatientWritePolicyQueryResult = Apollo.QueryResult<ChatApiClinicChatPatientWritePolicy, ChatApiClinicChatPatientWritePolicyVariables>;