import {
  defaultBorderRadius,
  FlexBox,
  OverflownText,
  ServiceCardType,
  ServiceTypeIcon,
  useBreakpoints,
} from '@lib/react-components';
import { Chip, Typography } from '@mui/material';

import { IGlobalSearchDetailsCardService } from '../model';

import { i18n } from 'i18n';

interface IGlobalSearchDetailsCardServiceChipProps
  extends Pick<IGlobalSearchDetailsCardService, 'title' | 'price' | 'affiliationName'> {
  baseServiceType: ServiceCardType;
}

export const GlobalSearchDetailsCardServiceChip = ({
  title,
  price,
  baseServiceType,
  affiliationName,
}: IGlobalSearchDetailsCardServiceChipProps): JSX.Element => {
  const { isDesktopView } = useBreakpoints();

  const affiliationTitle = affiliationName ? `${i18n.t('general.affiliation')} ${affiliationName}` : affiliationName;

  return (
    <Chip
      sx={{
        minWidth: 0,
        ...(!isDesktopView && { height: 'unset' }),
        backgroundColor: 'background.default',
        borderRadius: `${defaultBorderRadius}px`,
        '& .MuiChip-label': {
          width: '100%',
          padding: isDesktopView ? '6px 16px' : '4px 12px',
        },
      }}
      label={
        <FlexBox
          alignItems={!isDesktopView && affiliationTitle ? 'flex-start' : 'center'}
          justifyContent="space-between"
        >
          <FlexBox minWidth={0} flexDirection={!isDesktopView && affiliationTitle ? 'column' : 'row'}>
            {isDesktopView && <ServiceTypeIcon fontSize={20} serviceIconType={baseServiceType} marginRight="8px" />}
            <OverflownText
              variant="h9"
              color="black.main"
              sx={{
                ...(!isDesktopView &&
                  affiliationTitle && {
                    display: 'flex',
                    flexDirection: 'column',
                  }),
              }}
            >
              {title}
              {affiliationTitle && (
                <Typography
                  variant="h9"
                  sx={{
                    ...(!isDesktopView && {
                      color: 'generic.dark',
                      whiteSpace: 'initial',
                    }),
                  }}
                >
                  {isDesktopView ? `  | ${affiliationTitle}` : affiliationTitle}
                </Typography>
              )}
            </OverflownText>
          </FlexBox>
          <Typography variant="h9" color="generic.dark" ml={`${isDesktopView ? 16 : 8}px`}>
            {price ? `${price}€` : i18n.t('general.free')}
          </Typography>
        </FlexBox>
      }
    />
  );
};
