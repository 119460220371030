import { PrivaciesApiPendingPrivaciesDocument, usePrivaciesApiPendingPrivacies } from '@lib/features-bll';
import { useModalState } from '@lib/react-components';

import { useUserModelStore } from 'features/Users/model';
import { client } from 'graphql/apollo';

interface IUsePrivaciesModalStateReturn {
  isPrivaciesModalAllowed: boolean;
  isPrivaciesLoading: boolean;
  isPrivaciesModalOpened: boolean;
  openPrivaciesModal: VoidFunction;
  closePrivaciesModal: VoidFunction;
}

export const useRootModelPrivaciesModalState = (): IUsePrivaciesModalStateReturn => {
  const { activeProfile } = useUserModelStore();

  const [isPrivaciesModalOpened, openPrivaciesModal, closePrivaciesModal] = useModalState();
  const { data: privaciesData, loading } = usePrivaciesApiPendingPrivacies({
    fetchPolicy: 'cache-first',
    skip: activeProfile?.hasPostRegister,
  });
  const isPrivaciesModalAllowed = !!privaciesData?.pendingPrivacies.length;

  const closeModal = async (): Promise<void> => {
    await client.refetchQueries({
      include: [PrivaciesApiPendingPrivaciesDocument],
    });
    closePrivaciesModal();
  };

  return {
    isPrivaciesModalAllowed,
    isPrivaciesModalOpened,
    isPrivaciesLoading: loading,
    openPrivaciesModal,
    closePrivaciesModal: closeModal,
  };
};
