import { matchPath, useLocation } from 'react-router-dom';

import { usePortalSlug } from 'features/Portal';

interface IUseCheckModalConditionsReturn {
  matchRoutes: (routes: string[]) => boolean;
}

type UseCheckModalConditions = () => IUseCheckModalConditionsReturn;

export const useMatchRoutes: UseCheckModalConditions = () => {
  const { pathname } = useLocation();
  const portalSlug = usePortalSlug();

  const mapRoutes = (routes?: string[]): string[] => (routes ?? []).map(route => `/${portalSlug}${route}`);

  const matchRoutes: IUseCheckModalConditionsReturn['matchRoutes'] = routes => !!matchPath(pathname, mapRoutes(routes));

  return { matchRoutes };
};
