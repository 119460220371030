import { ActionType } from 'typesafe-actions';

import * as actions from './actionCreators';

export interface IState {}

export enum ActionTypeList {
  // KEPT TO RESET ROOT
  SIGN_OUT = '@@user/SIGN_OUT',
}

export type Action = ActionType<typeof actions>;

export const initialState: IState = {};
