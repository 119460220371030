import { Portals } from '@lib/features-bll';
import { useInitYup } from '@lib/react-components';
import { Attributes, Suspense } from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';
import { GlobalStyles } from 'tss-react';

import { useStyles } from './styles';

import BravomedPattern from 'assets/images/BravomedPattern.png';
import { usePortalSlug } from 'features/Portal';
import {
  useIubendaScript,
  useRootModelBodyClassNameEffect,
  useRootModelBroadcastChannelListener,
  useRootModelOnProfileChange,
} from 'features/Root/model';
import {
  RootAuthorizedRoute,
  RootAuthorizedRoutesWrapper,
  RootPublicRoute,
  RootPublicRoutesWrapper,
  RootUnauthorizedRoute,
} from 'features/Root/ui';
import { useUserModelStore } from 'features/Users/model';
import { useGTM } from 'lib/hooks/useGTM';
import { authorizedRoutes, publicRoutes, unauthorizedRoutes } from 'routes/config';
import { PORTAL_SLUG_ROUTE } from 'routes/constants';
import { IRoute } from 'routes/types';

const prepareRouteProps = ({ path, ...route }: IRoute): RouteProps & Attributes => ({
  ...route,
  path: `${PORTAL_SLUG_ROUTE}${path}`,
  key: path?.toString(),
});

export const RootMain = (): JSX.Element => {
  const { classes: localClasses } = useStyles();
  useRootModelBodyClassNameEffect(localClasses.bodyWhiteBackground);
  const portalSlug = usePortalSlug();
  useGTM();
  useInitYup();
  useIubendaScript();
  useRootModelOnProfileChange();
  useRootModelBroadcastChannelListener();
  const { activeProfileType } = useUserModelStore();

  const isBravomed = portalSlug === Portals.BRAVOMED;

  return (
    <>
      <GlobalStyles
        styles={{
          ...(isBravomed && {
            body: {
              backgroundImage: `url(${BravomedPattern})`,
              backgroundRepeat: 'repeat',
            },
          }),
        }}
      />
      <Suspense fallback={null}>
        <Switch>
          {unauthorizedRoutes.map(route => (
            <RootUnauthorizedRoute {...prepareRouteProps(route)} />
          ))}
          {!activeProfileType && (
            <Route path={publicRoutes.map(route => `${PORTAL_SLUG_ROUTE}${route.path}`)}>
              <RootPublicRoutesWrapper>
                <Switch>
                  {publicRoutes.map(route => (
                    <RootPublicRoute {...prepareRouteProps(route)} />
                  ))}
                </Switch>
              </RootPublicRoutesWrapper>
            </Route>
          )}
          <Route path={authorizedRoutes.map(route => `${PORTAL_SLUG_ROUTE}${route.path}`)}>
            <RootAuthorizedRoutesWrapper>
              <Switch>
                {authorizedRoutes.map(route => (
                  <RootAuthorizedRoute {...prepareRouteProps(route)} />
                ))}
              </Switch>
            </RootAuthorizedRoutesWrapper>
          </Route>
        </Switch>
      </Suspense>
    </>
  );
};
