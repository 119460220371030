import { useBreakpoints } from '@lib/react-components';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { ReactNode, Suspense } from 'react';

import { useStyles } from './styles';

import { globalSearchDetailsModel } from 'features/GlobalSearchDetails';
import { useRootModelRoot } from 'features/Root/model';
import {
  RootHeader,
  RootMobileHeaderProvider,
  RootOnboardingModals,
  RootSideNavigation,
  RootVerifyUserEmail,
} from 'features/Root/ui';
import { useUserModelStore } from 'features/Users/model';
import { useGetVerifyUserNotificationHeight } from 'lib/hooks/useGetVerifyUserNotificationHeight';

interface IAuthorizedRoutesWrapperProps {
  children: ReactNode;
}

export const RootAuthorizedRoutesWrapper = ({ children }: IAuthorizedRoutesWrapperProps): JSX.Element => {
  const { userInfo } = useUserModelStore();
  const { isMobileView } = useBreakpoints();
  const { isFullScreen, isMenuCollapsed, needUserVerification, isSideBarHiddenOnMobile, isSideBarHidden } =
    useRootModelRoot();
  const verifyUserNotificationHeight = useGetVerifyUserNotificationHeight();
  const isSsnClinicAvailable = globalSearchDetailsModel.useSsnClinicAvailability();

  const withHeader = isMobileView ? !isSideBarHiddenOnMobile : !isSideBarHidden;
  const { classes: localClasses } = useStyles({
    isFullScreen,
    needUserVerification,
    verifyUserNotificationHeight,
    withHeader,
    withSsnClinicLinkVisible: isSsnClinicAvailable,
  });

  return (
    <>
      <RootOnboardingModals />
      {!isSideBarHidden && needUserVerification && !userInfo?.isEmailVerified && <RootVerifyUserEmail />}
      {withHeader && (
        <Box displayPrint="none">
          <RootSideNavigation />
          {userInfo && (
            <RootMobileHeaderProvider>
              <RootHeader
                needUserVerification={needUserVerification}
                isFullScreen={isFullScreen}
                isMenuCollapsed={isMenuCollapsed}
                userInfo={userInfo}
              />
            </RootMobileHeaderProvider>
          )}
        </Box>
      )}
      <Box
        className={clsx(
          isMobileView ? localClasses.rootWrapperMobile : localClasses.rootWrapper,
          !isSideBarHidden && localClasses.rootWrapperDesktop,
          isSideBarHiddenOnMobile && localClasses.rootWrapperFull,
          !isSideBarHidden &&
            !isFullScreen &&
            (isMenuCollapsed ? localClasses.sidebarCollapsed : localClasses.sidebarExpanded),
          isFullScreen && localClasses.fullScreen,
          !isFullScreen && localClasses.sideBarTransition
        )}
      >
        <Suspense fallback={null}>{children}</Suspense>
      </Box>
    </>
  );
};
