import { PortalLogoType } from '@lib/features-bll';
import { PortalLogo } from '@lib/features-ui';
import { FlexBox, getDefaultCardBoxShadow, useBreakpoints, zIndexValues } from '@lib/react-components';
import { AppBar, Toolbar, useTheme } from '@mui/material';

import { unauthorizedUserRequestConfig } from '../config';
import { unAuthorizedUserRequestModel } from '../model';

import { GlobalSearchField } from 'features/GlobalSearchDetails';
import { usePortal } from 'features/Portal';
import { UnauthorizedUserRequest } from 'features/Requests/components';
import { SupportRequestUiCanWeHelp } from 'features/SupportRequest/ui';

const { UNAUTHORIZED_HEADER_HEIGHT } = unauthorizedUserRequestConfig;

export const UnauthorizedUserRequestHeader = (): JSX.Element => {
  const { palette } = useTheme();
  const { isMobileView } = useBreakpoints();
  const { portal } = usePortal();
  const { isGlobalSearchVisible } = unAuthorizedUserRequestModel.useUnauthorizedUserHeaderControls();

  return (
    <AppBar
      sx={{
        width: '100%',
        minHeight: `${UNAUTHORIZED_HEADER_HEIGHT}px`,
        backgroundColor: isMobileView ? 'white.main' : 'background.default',
        zIndex: zIndexValues.header,
        boxShadow: isMobileView ? getDefaultCardBoxShadow(palette.shadow.main) : 'none',
      }}
      position="fixed"
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '14px 20px',
          '&.MuiToolbar-root': {
            minHeight: 'unset',
          },
        }}
        disableGutters
      >
        <PortalLogo variant={PortalLogoType.NORMAL} logoUrl={portal?.logoUrl?.[PortalLogoType.NORMAL]} />
        {isGlobalSearchVisible && <GlobalSearchField sx={{ marginLeft: '16px', width: '100%' }} height={38} />}
        <FlexBox ml="42px">
          <SupportRequestUiCanWeHelp isStaticPosition />
          <UnauthorizedUserRequest.ActionAvatar />
        </FlexBox>
      </Toolbar>
    </AppBar>
  );
};
