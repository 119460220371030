import { useBreakpoints } from '@lib/react-components';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { usePortalSlug } from 'features/Portal';
import { useRootModelHiddenSidebarRoutes, useRootModelUiStore } from 'features/Root/model';
import { useUserModelStore } from 'features/Users/model';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';

interface IUseRootReturn {
  needUserVerification: boolean;
  isFullScreen: boolean;
  isSideBarHiddenOnMobile: boolean;
  isSideBarHidden: boolean;
  isMenuCollapsed: boolean;
}

export const useRootModelRoot = (): IUseRootReturn => {
  const portalSlug = usePortalSlug();
  const { pathname } = useLocation();
  const { isMobileView } = useBreakpoints();
  const isMobileAppWebView = useIsMobileAppWebView();
  const { userInfo, activeProfile } = useUserModelStore();
  const { isFullScreen, isMenuCollapsed } = useRootModelUiStore();
  const { mobileHiddenSidebarRoutes, hiddenSidebarRoutes } = useRootModelHiddenSidebarRoutes();

  const isSideBarHiddenOnMobile =
    mobileHiddenSidebarRoutes.some(url => !!matchPath(pathname, `/${portalSlug}${url}`)) || isMobileAppWebView;
  const isSideBarHidden =
    hiddenSidebarRoutes.some(url => !!matchPath(pathname, `/${portalSlug}${url}`)) || isMobileAppWebView;
  const needUserVerification =
    !isSideBarHiddenOnMobile && !activeProfile?.hasPostRegister && !!(userInfo && !userInfo.isEmailVerified);

  useEffect(() => {
    if (isMobileView && !isSideBarHidden) {
      document.body.style.overflowX = 'auto';
    }

    return () => {
      if (isMobileView && !isSideBarHidden) {
        document.body.style.removeProperty('overflowX');
      }
    };
  }, [isMobileView]);

  return {
    needUserVerification,
    isSideBarHiddenOnMobile,
    isSideBarHidden,
    isFullScreen,
    isMenuCollapsed,
  };
};
