import { ActionType } from 'typesafe-actions';

import * as actions from './actionCreators';

import { IInfoThread } from 'graphql/types/info';

export interface IState {
  infoThread: IInfoThread[] | null;
  requestLoading: boolean;
}

export enum ActionTypeList {
  SET_INFO_THREAD = '@@info/SET_INFO_THREAD',
  REMOVE_INFO_THREAD = '@@info/REMOVE_INFO_THREAD',

  ADD_MESSAGE_INFO_THREAD = '@@info/ADD_MESSAGE_INFO_THREAD',

  UPDATE_INFO_THREAD = '@@info/UPDATE_INFO_THREAD',

  UPDATE_INFO_THREAD_MESSAGE_READ_STATUS = '@@info/UPDATE_INFO_THREAD_MESSAGE_READ_STATUS',

  SET_INFO_THREAD_PENDING_STATUS = '@@info/SET_INFO_THREAD_PENDING_STATUS',

  SET_REQUEST_LOADING = '@@consultation/SET_REQUEST_LOADING',
}

export type Action = ActionType<typeof actions>;

export const initialState: IState = {
  infoThread: null,
  requestLoading: false,
};
