import { NotificationType, useNotifications, ROUTE_PATHS } from '@lib/core';
import { WebToNativeActions } from '@lib/features-bll';
import { openLinkInNewTab } from '@lib/react-components';
import { useHistory } from 'react-router-dom';

import { OneToOneChatVariables, useChatApiOneToOneChatLazyQuery } from '../api';
import { FormatRedirectPathPrams, UseChatModelChatRedirectReturn } from '../types';

import { useRootModelPath } from 'features/Root/model';
import { i18n } from 'i18n';
import { createNativeAction, usePlatformBasedAction } from 'lib/hooks/usePlatformBasedAction.hook';

export const useChatModelOneToOneChatRedirect = (): UseChatModelChatRedirectReturn => {
  const { getPath } = useRootModelPath();
  const history = useHistory();
  const platformBasedAction = usePlatformBasedAction();

  const { showNotification } = useNotifications();

  const [getOneToOneChatId] = useChatApiOneToOneChatLazyQuery({
    onError: () => {
      showNotification(i18n.t('general.notifications.requestError'), NotificationType.FAIL);
    },
  });

  const resolveChatId = async (params: OneToOneChatVariables): Promise<string | undefined> => {
    const { data } = await getOneToOneChatId({ variables: params });

    return data?.oneToOneChat.id;
  };

  const formatRedirectPath = ({ chatId, searchObj }: FormatRedirectPathPrams): string =>
    getPath({
      path: ROUTE_PATHS.chatOneToOne,
      pathParams: { chatId },
      searchObj,
      skipNulls: true,
    });

  const getRedirectHandlerById: UseChatModelChatRedirectReturn['getRedirectHandlerById'] = ({
    chatId,
    openNewTab,
    searchObj,
  }) => {
    const redirectPath = formatRedirectPath({ chatId, searchObj });

    return () => {
      platformBasedAction({
        webAction: () => (openNewTab ? openLinkInNewTab(redirectPath) : history.push(redirectPath)),
        nativeAction: createNativeAction(WebToNativeActions.openOneToOneChat, { id: chatId, searchObj }),
      });
    };
  };

  const getRedirectHandlerByParams: UseChatModelChatRedirectReturn['getRedirectHandlerByParams'] =
    ({ openNewTab, searchObj, ...params }) =>
    async () => {
      if ('portalUserId' in params) {
        const chatId = await resolveChatId(params);

        if (chatId) getRedirectHandlerById({ chatId, openNewTab, searchObj })();
      }
    };

  const getRedirectPathByParams: UseChatModelChatRedirectReturn['getRedirectPathByParams'] = async ({
    searchObj,
    ...params
  }) => {
    let chatId;

    if ('portalUserId' in params) {
      chatId = await resolveChatId(params);
    }

    return { redirectPath: chatId ? formatRedirectPath({ chatId, searchObj }) : null };
  };

  return {
    getRedirectHandlerById,
    getRedirectHandlerByParams,
    getRedirectPathByParams,
  };
};
