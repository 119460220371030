import { Reducer } from 'redux';

import { ActionTypeList as PatientActionTypeList } from '../patient/types';

import { ActionTypeList, initialState, IState } from './types';

import { StoreActions } from 'store';

export const infoReducer: Reducer<IState, StoreActions> = (state: IState = initialState, action) => {
  switch (action.type) {
    case ActionTypeList.SET_INFO_THREAD:
      return {
        ...state,
        infoThread: action.payload.infoThread,
      };

    case ActionTypeList.REMOVE_INFO_THREAD:
      return {
        ...state,
        infoThread: null,
      };

    case ActionTypeList.ADD_MESSAGE_INFO_THREAD:
      return {
        ...state,
        infoThread:
          state.infoThread &&
          state.infoThread.map((thread, index) =>
            index === (state.infoThread?.length || 0) - 1
              ? {
                  ...thread,
                  messages: thread.messages.concat(action.payload.infoThreadMessage),
                }
              : thread
          ),
      };

    case ActionTypeList.UPDATE_INFO_THREAD:
      return {
        ...state,
        infoThread:
          state.infoThread &&
          state.infoThread.map((thread, index) =>
            index === (state.infoThread?.length || 0) - 1
              ? {
                  ...thread,
                  ...action.payload.infoThread,
                }
              : thread
          ),
      };

    case ActionTypeList.UPDATE_INFO_THREAD_MESSAGE_READ_STATUS:
      return {
        ...state,
        infoThread:
          state.infoThread &&
          state.infoThread.map(thread => ({
            ...thread,
            messages: thread.messages.map(message =>
              message.id === action.payload.messageId
                ? {
                    ...message,
                    flags: [action.payload.flag],
                  }
                : message
            ),
          })),
      };

    case ActionTypeList.SET_INFO_THREAD_PENDING_STATUS:
      return {
        ...state,
        infoThread:
          state.infoThread &&
          state.infoThread.map(thread => ({
            ...thread,
            isPending: thread.id === action.payload.threadId && action.payload.isPending,
          })),
      };

    case PatientActionTypeList.EDIT_PATIENT_FILE_IN_LIST:
      return {
        ...state,
        infoThread:
          state.infoThread &&
          state.infoThread.map(thread => ({
            ...thread,
            messages: thread.messages.map(message => ({
              ...message,
              attachments: message.attachments.map(attachment =>
                attachment.file.id === action.payload.editedPatientFile.file.id
                  ? action.payload.editedPatientFile
                  : attachment
              ),
            })),
          })),
      };

    case PatientActionTypeList.CANCEL_PATIENT_FILE_IN_LIST:
      return {
        ...state,
        infoThread:
          state.infoThread &&
          state.infoThread.map(thread => ({
            ...thread,
            messages: thread.messages.map(message => ({
              ...message,
              attachments: message.attachments.map(attachment =>
                attachment.file.id === action.payload.canceledPatientFile.file.id
                  ? action.payload.canceledPatientFile
                  : attachment
              ),
            })),
          })),
      };

    case PatientActionTypeList.RESTORE_PATIENT_CANCELLED_FILE:
      return {
        ...state,
        infoThread:
          state.infoThread &&
          state.infoThread.map(thread => ({
            ...thread,
            messages: thread.messages.map(message => ({
              ...message,
              attachments: message.attachments.map(attachment =>
                attachment.file.id === action.payload.restoredFile.file.id ? action.payload.restoredFile : attachment
              ),
            })),
          })),
      };

    default:
      return state;
  }
};
