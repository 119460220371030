import { dateFormat } from '@lib/react-components';
import { getIsoTime, OPTIONS_DATE, OPTIONS_WITH_TIME, ParsableDate } from '@lib/utils';
import { format, setHours, setMinutes, setSeconds } from 'date-fns';

export const addZeroIfNeeded = (date: string | number): string =>
  typeof date === 'string' ? `${(date.length <= 1 && '0') || ''}${date}` : `${(date < 10 && '0') || ''}${date}`;

const getFullDate = (date: string): string =>
  date
    .split('/')
    .map(splittedDate => addZeroIfNeeded(splittedDate))
    .join('/');

export const getFormatDate = (date: string, withTime = false): string =>
  new Intl.DateTimeFormat('en-GB', withTime ? OPTIONS_WITH_TIME : OPTIONS_DATE).format(new Date(date));

export const replaceDayWithMonth = (date: string): string => {
  const splittedDate = date.split('/');

  return getFullDate(`${splittedDate[1]}/${splittedDate[0]}/${splittedDate[2]}`);
};

export const isFutureDate = (date: string): boolean =>
  new Date().getTime() < new Date(replaceDayWithMonth(date)).getTime();

export const isPreviousDate = (date: string): boolean =>
  new Date().getTime() > new Date(replaceDayWithMonth(date)).getTime();

export const getFormattedDateTime = (date: Date): string => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    timeZone: 'UTC',
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour12: false,
  };

  return new Intl.DateTimeFormat('en-GB', dateOptions).format(date);
};

const getHoursAndMinutes = (time: ParsableDate): { hours: number; minutes: number } => {
  const splitTime = (time || '').toString().split(':');

  return {
    hours: Number(splitTime[0]),
    minutes: Number(splitTime[1]),
  };
};

export const concatDates = (day: ParsableDate, time: ParsableDate): string => {
  const { hours, minutes } = getHoursAndMinutes(time);

  return setHours(setMinutes(setSeconds(new Date((day || '').toString()), 0), minutes), hours).toString();
};

export const getIsoFormatUTCDateTime = (day: ParsableDate, time: ParsableDate): string => {
  const date = new Date(day || '');

  const { hours, minutes } = getHoursAndMinutes(time);

  return getIsoTime(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0)) || '';
};

// TODO: NP-3692 Give helper a more generic name
export const formatAppointmentDate = (date: Date | string, created?: boolean, _24h?: boolean): string => {
  let dateFormatValue = dateFormat;
  if (_24h) dateFormatValue = `${dateFormat}, HH:mm`;
  if (!created) dateFormatValue = `${dateFormat}, hh:mm a`;

  return format(new Date(date), dateFormatValue);
};
