import { MaterialSymbolsIcon, SupportIcon } from '@lib/icons';
import { Box, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { useStyles } from './styles';

import { i18n } from 'i18n';

interface ISupportListItemProps {
  setSupportModalOpened: Dispatch<SetStateAction<boolean>>;
}

export const SupportListItem = ({ setSupportModalOpened }: ISupportListItemProps): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Box alignItems="center" className={classes.supportItem} onClick={(): void => setSupportModalOpened(true)}>
      <MaterialSymbolsIcon icon={<SupportIcon />} fontSize={26} mr="10px" />
      <Typography variant="body1">{i18n.t('components.mobileMenuModalList.profile.support')}</Typography>
    </Box>
  );
};
