import { GlobalSearch } from '@lib/features-ui';
import { FlexBox } from '@lib/react-components';
import { Button, SxProps, Theme } from '@mui/material';

import { globalSearchDetailsModel } from '../model';

import { i18n } from 'i18n';

interface IGlobalSearchFieldProps {
  isButtonVisible?: boolean;
  sx?: SxProps<Theme>;
  height?: number;
  onlySearchIconVisible?: boolean;
  isSearchingClinicAndDoctor?: boolean;
}

export const GlobalSearchField = ({
  isButtonVisible,
  onlySearchIconVisible,
  isSearchingClinicAndDoctor,
  height = 44,
  sx,
}: IGlobalSearchFieldProps): JSX.Element => {
  const { globalSearch, setGlobalSearch, onSearch, allowEmptySearch } = globalSearchDetailsModel.useGlobalSearch();

  return (
    <FlexBox sx={sx}>
      <GlobalSearch
        allowEmptySearch={allowEmptySearch}
        height={height}
        onSearch={onSearch}
        onInputChange={(search: string): void => setGlobalSearch({ search, source: 'web' })}
        inputValue={globalSearch.search}
        onlySearchIconVisible={onlySearchIconVisible}
        isSearchingClinicAndDoctor={isSearchingClinicAndDoctor}
      />
      {isButtonVisible && (
        <Button
          sx={{ marginLeft: '8px' }}
          disabled={!globalSearch.search.trim()}
          size="small"
          onClick={(): void => onSearch({ search: { value: globalSearch.search } })}
        >
          {i18n.t('components.dashboard.placeholder.searchHeader')}
        </Button>
      )}
    </FlexBox>
  );
};
