import { CheckCircleIcon, MaterialSymbolsIcon } from '@lib/icons';
import { CircularProgress, Modal, MODAL_CLASSES, useBreakpoints } from '@lib/react-components';
import { useTimer } from '@lib/utils';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';

import {
  useUnauthorizedUserChatMessageApiDraftDirectMessageConfirmed,
  useUnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail,
} from 'features/UnauthorizedUserChatMessage/api';
import { useUnauthorizedUserChatMessageModelLoginHandler } from 'features/UnauthorizedUserChatMessage/model';
import { i18n } from 'i18n';

interface IUnauthorizedUserChatMessageAuthorizeMessageSendingModalProps {
  onCancel: VoidFunction;
  draftDirectMessageId: string;
  isClinic?: boolean;
}

export const UnauthorizedUserChatMessageAuthorizeMessageSendingModal = ({
  onCancel,
  draftDirectMessageId,
  isClinic,
}: IUnauthorizedUserChatMessageAuthorizeMessageSendingModalProps): JSX.Element => {
  const { palette } = useTheme();
  const { isMobileView } = useBreakpoints();
  const { resetTimer, progressValue, isTimerCompleted } = useTimer();
  const [sendDraftDirectMessageConfirmationEmail, { data }] =
    useUnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail({
      variables: { draftDirectMessageId },
    });
  const { data: subscriptionData } = useUnauthorizedUserChatMessageApiDraftDirectMessageConfirmed({
    variables: { draftDirectMessageId },
  });
  const onClickHandler = useUnauthorizedUserChatMessageModelLoginHandler();

  useEffect(() => {
    sendDraftDirectMessageConfirmationEmail();
  }, []);

  const chatId = subscriptionData?.draftDirectMessageConfirmed.chatId;

  return (
    <Modal
      sx={{
        [`& .${MODAL_CLASSES.modalFooter}`]: {
          display: 'flex',
          justifyContent: 'center',
        },
      }}
      disableClickEvent
      bluredBackDrop
      fullscreenOnMobile={false}
      onCancel={onCancel}
      footer={
        chatId ? (
          <Button onClick={(): void => onClickHandler(chatId)} sx={{ minWidth: '183px' }}>
            {i18n.t('general.buttons.login')}
          </Button>
        ) : (
          <Typography textAlign="center">
            <Trans
              i18n={i18n}
              i18nKey="features.unauthorizedUserChatMessage.authorizeMessageSendingModal.pending.resend"
              components={{
                resendButton: (
                  <Typography
                    variant="bodyLink"
                    onClick={async (): Promise<void> => {
                      await sendDraftDirectMessageConfirmationEmail();

                      if (data?.sendDraftDirectMessageConfirmationEmail) resetTimer();
                    }}
                    sx={{
                      ...(!isTimerCompleted && {
                        color: palette.action.disabled,
                        pointerEvents: 'none',
                      }),
                    }}
                  />
                ),
              }}
            />
          </Typography>
        )
      }
    >
      <Stack gap={`${chatId ? 8 : 16}px`} alignItems="center" mt={isMobileView ? '24px' : undefined}>
        {chatId ? (
          <>
            <MaterialSymbolsIcon fill={palette.green.main} fontSize={96} icon={<CheckCircleIcon />} />
            <Typography textAlign="center" variant="h6">
              {i18n.t('features.unauthorizedUserChatMessage.authorizeMessageSendingModal.approved', {
                context: isClinic ? 'clinic' : '',
              })}
            </Typography>
          </>
        ) : (
          <>
            <Typography textAlign="center" variant="h6">
              {i18n.t('features.unauthorizedUserChatMessage.authorizeMessageSendingModal.pending.title')}
            </Typography>
            <Typography textAlign="center">
              {i18n.t('features.unauthorizedUserChatMessage.authorizeMessageSendingModal.pending.description')}
            </Typography>
            <CircularProgress value={progressValue} size={60} />
          </>
        )}
      </Stack>
    </Modal>
  );
};
