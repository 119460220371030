import { GlobalSearchDetailsTabType, useGlobalSearchDetailsTabsConfiguration } from 'features/GlobalSearchDetails';
import { i18n } from 'i18n';

export const useResultSearchDetailsTitle = (): string => {
  const { tabsCountsByItems, isAffiliatedDoctorTabEnabled, isClinicTabEnabled, isFreeClinicTabEnabled } =
    useGlobalSearchDetailsTabsConfiguration();

  const doctorsCount = tabsCountsByItems[GlobalSearchDetailsTabType.PRIVATE_DOCTORS] || 0;
  const affiliationsCount = tabsCountsByItems[GlobalSearchDetailsTabType.AFFILIATED_DOCTORS] || 0;
  const genericClinicsCount = tabsCountsByItems[GlobalSearchDetailsTabType.CLINICS] || 0;
  const freeClinicsCount = tabsCountsByItems[GlobalSearchDetailsTabType.FREE_CLINICS] || 0;

  return [
    `${doctorsCount} ${i18n.t('features.search.tabs.label.privateDoctor', { count: doctorsCount })}`,
    isAffiliatedDoctorTabEnabled &&
      `${affiliationsCount} ${i18n.t('features.search.tabs.label.affiliatedDoctor', { count: affiliationsCount })}`,
    isClinicTabEnabled &&
      `${genericClinicsCount} ${i18n.t('features.search.tabs.label.clinic', { count: genericClinicsCount })}`,
    isFreeClinicTabEnabled &&
      `${freeClinicsCount} ${i18n.t('features.search.tabs.label.freeClinic', { count: freeClinicsCount })}`,
  ]
    .filter(Boolean)
    .join('; ');
};
