import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type UnauthorizedUserChatMessageApiDraftDirectMessageConfirmedVariables = Types.Exact<{
  draftDirectMessageId: Types.Scalars['String']['input'];
}>;


export type UnauthorizedUserChatMessageApiDraftDirectMessageConfirmed = { __typename?: 'Subscription', draftDirectMessageConfirmed: { __typename?: 'DraftDirectMessageConfirmedModel', chatId: string } };


export const UnauthorizedUserChatMessageApiDraftDirectMessageConfirmedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UnauthorizedUserChatMessageApiDraftDirectMessageConfirmed"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"draftDirectMessageId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"draftDirectMessageConfirmed"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"draftDirectMessageId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"draftDirectMessageId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chatId"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useUnauthorizedUserChatMessageApiDraftDirectMessageConfirmed__
 *
 * To run a query within a React component, call `useUnauthorizedUserChatMessageApiDraftDirectMessageConfirmed` and pass it any options that fit your needs.
 * When your component renders, `useUnauthorizedUserChatMessageApiDraftDirectMessageConfirmed` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnauthorizedUserChatMessageApiDraftDirectMessageConfirmed({
 *   variables: {
 *      draftDirectMessageId: // value for 'draftDirectMessageId'
 *   },
 * });
 */
export function useUnauthorizedUserChatMessageApiDraftDirectMessageConfirmed(baseOptions: ApolloReactHooks.SubscriptionHookOptions<UnauthorizedUserChatMessageApiDraftDirectMessageConfirmed, UnauthorizedUserChatMessageApiDraftDirectMessageConfirmedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<UnauthorizedUserChatMessageApiDraftDirectMessageConfirmed, UnauthorizedUserChatMessageApiDraftDirectMessageConfirmedVariables>(UnauthorizedUserChatMessageApiDraftDirectMessageConfirmedDocument, options);
      }
export type UnauthorizedUserChatMessageApiDraftDirectMessageConfirmedHookResult = ReturnType<typeof useUnauthorizedUserChatMessageApiDraftDirectMessageConfirmed>;
export type UnauthorizedUserChatMessageApiDraftDirectMessageConfirmedSubscriptionResult = Apollo.SubscriptionResult<UnauthorizedUserChatMessageApiDraftDirectMessageConfirmed>;