import config from '../../../config.json';

/**
 * Feature flags are declared for the development process when the task is not yet ready
 *
 * @example
 * {
 *  AindoAiExtractionEnabled = 'AindoAiExtractionEnabled',
 * }
 */
export enum FeatureFlags {
  PushNotificationsFlowEnabled = 'PushNotificationsFlowEnabled',
}

type FeaturesConfig = Partial<Record<FeatureFlags, boolean>>;

interface IConfig {
  Features: Partial<Record<FeatureFlags, string>>;
}

// ts-unused-exports:disable-next-line
export const getFeatureFlags = (): FeaturesConfig => {
  // Temp solution based on the env variables
  const features = (config as unknown as IConfig).Features || {};

  return Object.entries(features).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value === 'true' }),
    {} as FeaturesConfig
  );
};
