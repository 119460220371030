import validate from 'validate.js';

type ValidationError = Record<string, string[] | undefined>;

export const validateEmail = (value: string): boolean => !validate.single(value, { email: true });

export const concatValidationErrors = <T extends ValidationError>(errors: T | undefined): T | undefined => {
  if (!errors) {
    return errors;
  }

  return Object.fromEntries(
    Object.entries(errors).map(([key, value]): [string, string[] | undefined] => [key, value && [value.join('; ')]])
  ) as T;
};

export const getValidationError = <T extends ValidationError>(errors: T | undefined): T | undefined => {
  if (!errors) {
    return errors;
  }

  return Object.fromEntries(
    Object.entries(errors).map(([key, value]): [string, string[] | undefined] => [key, value && [value[0]]])
  ) as T;
};
