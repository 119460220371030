import { useOnDeleteChip } from '@lib/react-components';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';

import { globalSearchDetailsModel } from '../model';

import { GlobalSearchDetails } from 'features/GlobalSearchDetails';

interface IGlobalSearchDetailsFilterFieldMobileProps {
  children: ReactNode;
  fieldName: string;
}

export const GlobalSearchDetailsFilterFieldMobile = ({
  children,
  fieldName,
}: IGlobalSearchDetailsFilterFieldMobileProps): JSX.Element => {
  const filterChipItems = globalSearchDetailsModel.useGlobalSearchDetailsFilterChipItems();
  const { filters, setFilter } = globalSearchDetailsModel.useGlobalSearchFilters();
  const onDeleteChip = useOnDeleteChip(filters, setFilter);

  return (
    <Stack gap="12px" sx={{ '& .MuiInputBase-root': { height: '54px' } }}>
      {children}
      <GlobalSearchDetails.FilterChips
        onDeleteChip={onDeleteChip}
        filterChipItems={filterChipItems.filter(({ filterName }) => filterName === fieldName)}
      />
    </Stack>
  );
};
