import { useInstitutionUserProfileImage } from '@lib/features-bll';

import { useUserModelStore } from '../model';

export const useActiveProfileAvatar = (isThumbnail = true): string | undefined => {
  const { activeProfile } = useUserModelStore();

  const { data } = useInstitutionUserProfileImage({
    variables: {
      institutionUserId: activeProfile?.id || '',
    },
    skip: !activeProfile?.id || activeProfile.hasPostRegister,
    fetchPolicy: 'cache-first',
  });

  const avatarData = data?.institutionUserProfileImage;

  return (isThumbnail ? avatarData?.thumbnailImageUrl : avatarData?.profileImageUrl) || undefined;
};
