import { gql } from '@apollo/client';

import {
  DOCTOR_FRAGMENT,
  NON_DOCTOR_FRAGMENT,
  PATIENT_FRAGMENT,
  USER_FRAGMENT,
  USER_MODEL_FRAGMENT,
} from 'graphql/fragments';
import {
  ALLERGIES_ANAPHYLACTIC_SHOCK_DATA_FRAGMENT,
  ALLERGIES_HYMENOPTERA_DATA_FRAGMENT,
  ALLERGIES_OTHER_DATA_FRAGMENT,
  ANAMNESIS_BREAST_FAMILY_HISTORY_HISTORY_FRAGMENT,
  ANAMNESIS_DONOR_HISTORY_FRAGMENT,
  ANAMNESIS_MELANOMA_FAMILY_HISTORY_HISTORY_FRAGMENT,
  ANAMNESIS_SURGERY_FOR_BREAST_CANCER_HISTORY_FRAGMENT,
  DIAGNOSES_GENERIC_NOTES_HISTORY_FRAGMENT,
  LAB_RESULT_PARAMETER_HISTORY_FRAGMENT,
  LAB_RESULTS_BCR_ABL1_MUTATION_HISTORY_FRAGMENT,
  LAB_RESULTS_BCR_ABL1_Q_PCR_HISTORY_FRAGMENT,
  LAB_RESULTS_BCR_ABL1_TRANSCRIPT_HISTORY_FRAGMENT,
  LAB_RESULTS_CALR_HISTORY_FRAGMENT,
  LAB_RESULTS_JAK2_EXON_HISTORY_FRAGMENT,
  LAB_RESULTS_JAK2_V617_HISTORY_FRAGMENT,
  LAB_RESULTS_MPL_HISTORY_FRAGMENT,
  LAB_RESULTS_NGS_HISTORY_FRAGMENT,
  LAB_RESULTS_SUBCLONAL_HISTORY_FRAGMENT,
  MEDICAL_DATA_FRAGMENT,
  PATHOLOGY_RESULTS_ACA_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BMA_DATA_FRAGMENT,
  PATHOLOGY_RESULTS_BMB_DATA_FRAGMENT,
  PATHOLOGY_RESULTS_BRAF_MUTATION_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BREAST_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BREAST_HISTOTYPE_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BREAST_METASTASIS_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BREAST_NODES_DISSECTION_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BREAST_RESECTION_MARGINS_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_BREAST_SENTINEL_NODE_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_C_KIT_MUTATION_DATA_FRAGMENT,
  PATHOLOGY_RESULTS_IMMUNOPHENOTYPING_DATA_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_HISTOTYPE_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_METASTASIS_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_NODES_DISSECTION_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_RESECTION_MARGINS_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_SENTINEL_NODE_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT,
  PATHOLOGY_RESULTS_NON_BRAF_MUTATION_HISTORY_FRAGMENT,
  PATIENT_CALCULATION_RESULT_RECORD_FRAGMENT,
  PHYSIOLOGICAL_HISTORY_GENERIC_NOTES_HISTORY_FRAGMENT,
  PHYSIOLOGICAL_HISTORY_MENOPAUSE_HISTORY_FRAGMENT,
  PHYSIOLOGICAL_HISTORY_PHOTOTYPE_HISTORY_FRAGMENT,
  PHYSIOLOGICAL_HISTORY_SMOKE_HISTORY_FRAGMENT,
  RADIOLOGY_ABDOMEN_ULTRASOUND_DATA_FRAGMENT,
  RADIOLOGY_BONE_DENSITOMETRY_DATA_FRAGMENT,
  RADIOLOGY_BONE_IMAGING_DATA_FRAGMENT,
  RISK_SCORE_GENERIC_NOTES_HISTORY_FRAGMENT,
  SIGNS_AND_SYMPTOMS_ECOG_HISTORY_FRAGMENT,
  SIGNS_AND_SYMPTOMS_GASTROINTESTINAL_DISORDERS_DATA_FRAGMENT,
  SIGNS_AND_SYMPTOMS_GENERIC_NOTES_HISTORY_FRAGMENT,
  SIGNS_AND_SYMPTOMS_SKIN_INVOLVEMENT_DATA_FRAGMENT,
  SIGNS_AND_SYMPTOMS_SPLENOMEGALY_PALPABLE_HISTORY_FRAGMENT,
  SIGNS_AND_SYMPTOMS_SYNCOPES_DATA_FRAGMENT,
  THERAPY_BREAST_ADJUVANT_THERAPY_HISTORY_FRAGMENT,
  THERAPY_BREAST_HORMONE_THERAPY_HISTORY_FRAGMENT,
  THERAPY_BREAST_NEOADJUVANT_THERAPY_HISTORY_FRAGMENT,
  THERAPY_BREAST_RADIOTHERAPY_HISTORY_FRAGMENT,
  THERAPY_HEMATOLOGICAL_HISTORY_FRAGMENT,
  TUMOURS_GENERIC_INFORMATION_DATA_FRAGMENT,
  VITALS_PARAMETERS_GENERIC_NOTES_HISTORY_FRAGMENT,
} from 'graphql/fragments/patient';

const SEARCH_PATIENT_BY_FULL_NAME = gql`
  query SearchPatientByFullName($fullName: String!, $institutionId: String) {
    searchUser(fullName: $fullName, institutionId: $institutionId, profileTypeList: [PATIENT]) {
      firstName
      lastName
      birthDate
      id
      patient {
        id
        email
        phoneNumber
      }
      profileList {
        id
        profileType
        doctor {
          ...DoctorFragment
        }
        nonDoctor {
          ...NonDoctorFragment
        }
      }
    }
  }
  ${DOCTOR_FRAGMENT}
  ${NON_DOCTOR_FRAGMENT}
`;

const GET_MEDICAL_DATA_BY_PATIENT = gql`
  query ($patientId: String!) {
    medicalData(patientId: $patientId) {
      ...MedicalDataFragment
    }
  }
  ${MEDICAL_DATA_FRAGMENT}
`;

const GET_MEDICAL_DATA_PATIENT_USER = gql`
  query ($patientId: String!) {
    medicalData(patientId: $patientId) {
      patient {
        id
        user {
          id
          firstName
          lastName
          phoneNumber
          email
          gender
          birthDate
          birthAddress {
            fullAddress
          }
          currentResidenceAddress {
            fullAddress
            city
            province
          }
        }
      }
    }
  }
`;

const GET_PATIENT_CARD_HEADER = gql`
  query GetPatientCardHeader($patient: String!) {
    patient(patient: $patient) {
      id
      email
      phoneNumber
      portalUser {
        id
      }
      privacyAcceptance {
        ehrConsensus
      }
      user {
        ...UserModelFragment
      }
    }
  }
  ${USER_MODEL_FRAGMENT}
`;

const GET_MASTOCYTOSIS_PATIENT_MEDICAL_DATA = gql`
  query GetMastocytosisPatientMedicalData($patient: String!) {
    getMastocytosisPatientMedicalData(patient: $patient) {
      tumours {
        genericInformation {
          ...TumoursGenericInformationDataFragment
        }
      }
      allergies {
        anaphylacticShock {
          ...AllergiesAnaphylacticShockDataFragment
        }
        hymenoptera {
          ...AllergiesHymenopteraDataFragment
        }
        other {
          ...AllergiesOtherDataFragment
        }
      }
      signsAndSymptoms {
        syncopes {
          ...SignsAndSymptomsSyncopesDataFragment
        }
        gastrointestinalDisorders {
          ...SignsAndSymptomsGastrointestinalDisordersDataFragment
        }
        skinInvolvement {
          ...SignsAndSymptomsSkinInvolvementDataFragment
        }
      }
      radiology {
        boneDensitometry {
          ...RadiologyBoneDensitometryDataFragment
        }
        boneImaging {
          ...RadiologyBoneImagingDataFragment
        }
        abdomenUltrasound {
          ...RadiologyAbdomenUltrasoundDataFragment
        }
      }
      pathologyResults {
        immunophenotyping {
          ...PathologyResultsImmunophenotypingDataFragment
        }
        cKitMutation {
          ...PathologyResultsCKitMutationDataFragment
        }
      }
    }
  }
  ${TUMOURS_GENERIC_INFORMATION_DATA_FRAGMENT}
  ${ALLERGIES_ANAPHYLACTIC_SHOCK_DATA_FRAGMENT}
  ${ALLERGIES_HYMENOPTERA_DATA_FRAGMENT}
  ${ALLERGIES_OTHER_DATA_FRAGMENT}
  ${SIGNS_AND_SYMPTOMS_SYNCOPES_DATA_FRAGMENT}
  ${SIGNS_AND_SYMPTOMS_GASTROINTESTINAL_DISORDERS_DATA_FRAGMENT}
  ${SIGNS_AND_SYMPTOMS_SKIN_INVOLVEMENT_DATA_FRAGMENT}
  ${RADIOLOGY_BONE_DENSITOMETRY_DATA_FRAGMENT}
  ${RADIOLOGY_BONE_IMAGING_DATA_FRAGMENT}
  ${RADIOLOGY_ABDOMEN_ULTRASOUND_DATA_FRAGMENT}
  ${PATHOLOGY_RESULTS_IMMUNOPHENOTYPING_DATA_FRAGMENT}
  ${PATHOLOGY_RESULTS_C_KIT_MUTATION_DATA_FRAGMENT}
`;

const GET_TUMOURS_GENERIC_INFORMATION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetTumoursGenericInformationPatientMedicalDataHistory($patient: String!) {
    getTumoursGenericInformationPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...TumoursGenericInformationDataFragment
      }
    }
  }
  ${TUMOURS_GENERIC_INFORMATION_DATA_FRAGMENT}
`;

const GET_ALLERGIES_ANAPHYLACTIC_SHOCK_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetAllergiesAnaphylacticShockPatientMedicalDataHistory($patient: String!) {
    getAllergiesAnaphylacticShockPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...AllergiesAnaphylacticShockDataFragment
      }
    }
  }
  ${ALLERGIES_ANAPHYLACTIC_SHOCK_DATA_FRAGMENT}
`;

const GET_ALLERGIES_HYMENOPTERA_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetAllergiesHymenopteraPatientMedicalDataHistory($patient: String!) {
    getAllergiesHymenopteraPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...AllergiesHymenopteraDataFragment
      }
    }
  }
  ${ALLERGIES_HYMENOPTERA_DATA_FRAGMENT}
`;

const GET_ALLERGIES_OTHER_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetAllergiesOtherPatientMedicalDataHistory($patient: String!) {
    getAllergiesOtherPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...AllergiesOtherDataFragment
      }
    }
  }
  ${ALLERGIES_OTHER_DATA_FRAGMENT}
`;

const GET_SIGNS_AND_SYMPTOMS_SYNCOPES_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetSignsAndSymptomsSyncopesPatientMedicalDataHistory($patient: String!) {
    getSignsAndSymptomsSyncopesPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...SignsAndSymptomsSyncopesDataFragment
      }
    }
  }
  ${SIGNS_AND_SYMPTOMS_SYNCOPES_DATA_FRAGMENT}
`;

const GET_SIGNS_AND_SYMPTOMS_GASTROINTESTINAL_DISORDERS_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetSignsAndSymptomsGastrointestinalDisordersPatientMedicalDataHistory($patient: String!) {
    getSignsAndSymptomsGastrointestinalDisordersPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...SignsAndSymptomsGastrointestinalDisordersDataFragment
      }
    }
  }
  ${SIGNS_AND_SYMPTOMS_GASTROINTESTINAL_DISORDERS_DATA_FRAGMENT}
`;

const GET_SIGNS_AND_SYMPTOMS_SKIN_INVOLVEMENT_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetSignsAndSymptomsSkinInvolvementPatientMedicalDataHistory($patient: String!) {
    getSignsAndSymptomsSkinInvolvementPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...SignsAndSymptomsSkinInvolvementDataFragment
      }
    }
  }
  ${SIGNS_AND_SYMPTOMS_SKIN_INVOLVEMENT_DATA_FRAGMENT}
`;

const GET_RADIOLOGY_BONE_DENSITOMETRY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetRadiologyBoneDensitometryPatientMedicalDataHistory($patient: String!) {
    getRadiologyBoneDensitometryPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...RadiologyBoneDensitometryDataFragment
      }
    }
  }
  ${RADIOLOGY_BONE_DENSITOMETRY_DATA_FRAGMENT}
`;

const GET_RADIOLOGY_BONE_IMAGING_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetRadiologyBoneImagingPatientMedicalDataHistory($patient: String!) {
    getRadiologyBoneImagingPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...RadiologyBoneImagingDataFragment
      }
    }
  }
  ${RADIOLOGY_BONE_IMAGING_DATA_FRAGMENT}
`;

const GET_RADIOLOGY_ABDOMEN_ULTRASOUND_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetRadiologyAbdomenUltrasoundPatientMedicalDataHistory($patient: String!) {
    getRadiologyAbdomenUltrasoundPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...RadiologyAbdomenUltrasoundDataFragment
      }
    }
  }
  ${RADIOLOGY_ABDOMEN_ULTRASOUND_DATA_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_BMB_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsBMBPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsBMBPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...PathologyResultsBMBDataFragment
      }
    }
  }
  ${PATHOLOGY_RESULTS_BMB_DATA_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_BMB_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsBMBPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsBMBPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...PathologyResultsBMBDataFragment
      }
    }
  }
  ${PATHOLOGY_RESULTS_BMB_DATA_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_BMA_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsBMAPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsBMAPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...PathologyResultsBMADataFragment
      }
    }
  }
  ${PATHOLOGY_RESULTS_BMA_DATA_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_BMA_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsBMAPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsBMAPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...PathologyResultsBMADataFragment
      }
    }
  }
  ${PATHOLOGY_RESULTS_BMA_DATA_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_IMMUNOPHENOTYPING_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsImmunophenotypingPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsImmunophenotypingPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...PathologyResultsImmunophenotypingDataFragment
      }
    }
  }
  ${PATHOLOGY_RESULTS_IMMUNOPHENOTYPING_DATA_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_C_KIT_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsCKitMutationPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsCKitMutationPatientMedicalDataHistory(patient: $patient) {
      id
      section
      subSection
      creator {
        user {
          firstName
          lastName
        }
        profileType
      }
      createdAt
      data {
        ...PathologyResultsCKitMutationDataFragment
      }
    }
  }
  ${PATHOLOGY_RESULTS_C_KIT_MUTATION_DATA_FRAGMENT}
`;

const GET_MASTOCYTOSIS_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY = gql`
  query GetMastocytosisPatientLabResultsParametersHistory($patient: String!) {
    getMastocytosisPatientLabResultsParametersHistory(patient: $patient) {
      ...LabResultParameterHistoryFragment
    }
  }
  ${LAB_RESULT_PARAMETER_HISTORY_FRAGMENT}
`;

const GET_CML_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY = gql`
  query GetCMLPatientLabResultsParametersHistory($patient: String!) {
    getCMLPatientLabResultsParametersHistory(patient: $patient) {
      ...LabResultParameterHistoryFragment
    }
  }
  ${LAB_RESULT_PARAMETER_HISTORY_FRAGMENT}
`;

const GET_PV_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY = gql`
  query GetPVPatientLabResultsParametersHistory($patient: String!) {
    getPVPatientLabResultsParametersHistory(patient: $patient) {
      ...LabResultParameterHistoryFragment
    }
  }
  ${LAB_RESULT_PARAMETER_HISTORY_FRAGMENT}
`;

const GET_MF_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY = gql`
  query GetMFPatientLabResultsParametersHistory($patient: String!) {
    getMFPatientLabResultsParametersHistory(patient: $patient) {
      ...LabResultParameterHistoryFragment
    }
  }
  ${LAB_RESULT_PARAMETER_HISTORY_FRAGMENT}
`;

const GET_MELANOMA_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY = gql`
  query GetMelanomaPatientLabResultsParametersHistory($patient: String!) {
    getMelanomaPatientLabResultsParametersHistory(patient: $patient) {
      ...LabResultParameterHistoryFragment
    }
  }
  ${LAB_RESULT_PARAMETER_HISTORY_FRAGMENT}
`;

const GET_CAR_T_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY = gql`
  query GetCarTPatientLabResultsParametersHistory($patient: String!) {
    getCarTPatientLabResultsParametersHistory(patient: $patient) {
      ...LabResultParameterHistoryFragment
    }
  }
  ${LAB_RESULT_PARAMETER_HISTORY_FRAGMENT}
`;

const GET_BREAST_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY = gql`
  query GetBreastPatientLabResultsParametersHistory($patient: String!) {
    getBreastPatientLabResultsParametersHistory(patient: $patient) {
      ...LabResultParameterHistoryFragment
    }
  }
  ${LAB_RESULT_PARAMETER_HISTORY_FRAGMENT}
`;

const GET_MEDICAL_DATA_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY = gql`
  query GetMedicalDataPatientLabResultsParametersHistory($patient: String!) {
    getMedicalDataPatientLabResultsParametersHistory(patient: $patient) {
      ...LabResultParameterHistoryFragment
    }
  }
  ${LAB_RESULT_PARAMETER_HISTORY_FRAGMENT}
`;

const GET_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY = gql`
  query GetPatientLabResultsParametersHistory($labResultParameters: [SubSectionLabParameter!]!, $patientId: String!) {
    getPatientLabResultsParametersHistory(labResultParameters: $labResultParameters, patientId: $patientId) {
      ...LabResultParameterHistoryFragment
    }
  }
  ${LAB_RESULT_PARAMETER_HISTORY_FRAGMENT}
`;

const CALCULATE_SOKAL_INDEX_SCORE = gql`
  query ($sokalMedicalIndexParametersModel: SokalMedicalIndexParametersModel!) {
    sokalMedicalIndexCalculation(sokalMedicalIndexParametersModel: $sokalMedicalIndexParametersModel) {
      name
      value
      dimension
    }
  }
`;

const CALCULATE_ELTS_INDEX_SCORE = gql`
  query ($eltsMedicalIndexParametersModel: EltsMedicalIndexParametersModel!) {
    eltsMedicalIndexCalculation(eltsMedicalIndexParametersModel: $eltsMedicalIndexParametersModel) {
      name
      value
      dimension
    }
  }
`;

const PATIENT_CALCULATIONS = gql`
  query PatientCalculations($patientId: String!) {
    patientCalculations(patientId: $patientId) {
      id
      medicalIndex
      dateOfExecution
      createdAt
      results {
        name
        value
        dimension
      }
      parameters {
        name
        value
      }
    }
  }
`;

const CALCULATE_CHARLSON_INDEX_SCORE = gql`
  query ($cciMedicalIndexParametersModel: CciMedicalIndexParametersModel!) {
    cciMedicalIndexCalculation(cciMedicalIndexParametersModel: $cciMedicalIndexParametersModel) {
      name
      value
      dimension
    }
  }
`;

const GET_LAST_RISK_SCORE_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastRiskScoreGenericNotesPatientMedicalDataHistory($patient: String!) {
    getLastRiskScoreGenericNotesPatientMedicalDataHistory(patient: $patient) {
      ...RiskScoreGenericNotesHistoryFragment
    }
  }
  ${RISK_SCORE_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const GET_RISK_SCORE_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetRiskScoreGenericNotesPatientMedicalDataHistory($patient: String!) {
    getRiskScoreGenericNotesPatientMedicalDataHistory(patient: $patient) {
      ...RiskScoreGenericNotesHistoryFragment
    }
  }
  ${RISK_SCORE_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const GET_LAST_SIGNS_AND_SYMPTOMS_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastSignsAndSymptomsGenericNotesPatientMedicalDataHistory($patient: String!) {
    getLastSignsAndSymptomsGenericNotesPatientMedicalDataHistory(patient: $patient) {
      ...SignsAndSymptomsGenericNotesHistoryFragment
    }
  }
  ${SIGNS_AND_SYMPTOMS_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const GET_SIGNS_AND_SYMPTOMS_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetSignsAndSymptomsGenericNotesPatientMedicalDataHistory($patient: String!) {
    getSignsAndSymptomsGenericNotesPatientMedicalDataHistory(patient: $patient) {
      ...SignsAndSymptomsGenericNotesHistoryFragment
    }
  }
  ${SIGNS_AND_SYMPTOMS_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const GET_PHYSIOLOGICAL_HISTORY_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPhysiologicalHistoryGenericNotesPatientMedicalDataHistory($patient: String!) {
    getPhysiologicalHistoryGenericNotesPatientMedicalDataHistory(patient: $patient) {
      ...PhysiologicalHistoryGenericNotesHistoryFragment
    }
  }
  ${PHYSIOLOGICAL_HISTORY_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const GET_LAST_PHYSIOLOGICAL_HISTORY_SMOKE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPhysiologicalHistorySmokePatientMedicalDataHistory($patient: String!) {
    getLastPhysiologicalHistorySmokePatientMedicalDataHistory(patient: $patient) {
      ...PhysiologicalHistorySmokeHistoryFragment
    }
  }
  ${PHYSIOLOGICAL_HISTORY_SMOKE_HISTORY_FRAGMENT}
`;

const GET_PHYSIOLOGICAL_HISTORY_SMOKE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPhysiologicalHistorySmokePatientMedicalDataHistory($patient: String!) {
    getPhysiologicalHistorySmokePatientMedicalDataHistory(patient: $patient) {
      ...PhysiologicalHistorySmokeHistoryFragment
    }
  }
  ${PHYSIOLOGICAL_HISTORY_SMOKE_HISTORY_FRAGMENT}
`;

const GET_LAST_PHYSIOLOGICAL_HISTORY_MENOPAUSE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPhysiologicalHistoryMenopausePatientMedicalDataHistory($patient: String!) {
    getLastPhysiologicalHistoryMenopausePatientMedicalDataHistory(patient: $patient) {
      ...PhysiologicalHistoryMenopauseHistoryFragment
    }
  }
  ${PHYSIOLOGICAL_HISTORY_MENOPAUSE_HISTORY_FRAGMENT}
`;

const GET_PHYSIOLOGICAL_HISTORY_MENOPAUSE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPhysiologicalHistoryMenopausePatientMedicalDataHistory($patient: String!) {
    getPhysiologicalHistoryMenopausePatientMedicalDataHistory(patient: $patient) {
      ...PhysiologicalHistoryMenopauseHistoryFragment
    }
  }
  ${PHYSIOLOGICAL_HISTORY_MENOPAUSE_HISTORY_FRAGMENT}
`;

const GET_LAST_VITALS_PARAMETERS_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastVitalsParametersGenericNotesPatientMedicalDataHistory($patient: String!) {
    getLastVitalsParametersGenericNotesPatientMedicalDataHistory(patient: $patient) {
      ...VitalsParametersGenericNotesHistoryFragment
    }
  }
  ${VITALS_PARAMETERS_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const GET_VITALS_PARAMETERS_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetVitalsParametersGenericNotesPatientMedicalDataHistory($patient: String!) {
    getVitalsParametersGenericNotesPatientMedicalDataHistory(patient: $patient) {
      ...VitalsParametersGenericNotesHistoryFragment
    }
  }
  ${VITALS_PARAMETERS_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const GET_DIAGNOSES_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetDiagnosesGenericNotesPatientMedicalDataHistory($patient: String!) {
    getDiagnosesGenericNotesPatientMedicalDataHistory(patient: $patient) {
      ...DiagnosesGenericNotesHistoryFragment
    }
  }
  ${DIAGNOSES_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const GET_LAST_DIAGNOSES_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastDiagnosesGenericNotesPatientMedicalDataHistory($patient: String!) {
    getLastDiagnosesGenericNotesPatientMedicalDataHistory(patient: $patient) {
      ...DiagnosesGenericNotesHistoryFragment
    }
  }
  ${DIAGNOSES_GENERIC_NOTES_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_ACA_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsACAPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsACAPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsACAHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_ACA_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_ACA_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsACAPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsACAPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsACAHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_ACA_HISTORY_FRAGMENT}
`;

const GET_LAST_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastLabResultsBcrAbl1TranscriptPatientMedicalDataHistory($patient: String!) {
    getLastLabResultsBcrAbl1TranscriptPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsBcrAbl1TranscriptHistoryFragment
    }
  }
  ${LAB_RESULTS_BCR_ABL1_TRANSCRIPT_HISTORY_FRAGMENT}
`;

const GET_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLabResultsBcrAbl1TranscriptPatientMedicalDataHistory($patient: String!) {
    getLabResultsBcrAbl1TranscriptPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsBcrAbl1TranscriptHistoryFragment
    }
  }
  ${LAB_RESULTS_BCR_ABL1_TRANSCRIPT_HISTORY_FRAGMENT}
`;

const GET_LAST_LAB_RESULTS_BCR_ABL1_Q_PCR_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastLabResultsBcrAbl1PcrQPatientMedicalDataHistory($patient: String!) {
    getLastLabResultsBcrAbl1PcrQPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsBcrAbl1PcrQHistoryFragment
    }
  }
  ${LAB_RESULTS_BCR_ABL1_Q_PCR_HISTORY_FRAGMENT}
`;

const GET_LAB_RESULTS_BCR_ABL1_Q_PCR_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLabResultsBcrAbl1PcrQPatientMedicalDataHistory($patient: String!) {
    getLabResultsBcrAbl1PcrQPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsBcrAbl1PcrQHistoryFragment
    }
  }
  ${LAB_RESULTS_BCR_ABL1_Q_PCR_HISTORY_FRAGMENT}
`;

const GET_LAST_LAB_RESULTS_BCR_ABL1_MUTATION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastLabResultsBcrAbl1MutationPatientMedicalDataHistory($patient: String!) {
    getLastLabResultsBcrAbl1MutationPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsBcrAbl1MutationHistoryFragment
    }
  }
  ${LAB_RESULTS_BCR_ABL1_MUTATION_HISTORY_FRAGMENT}
`;

const GET_LAB_RESULTS_BCR_ABL1_MUTATION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLabResultsBcrAbl1MutationPatientMedicalDataHistory($patient: String!) {
    getLabResultsBcrAbl1MutationPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsBcrAbl1MutationHistoryFragment
    }
  }
  ${LAB_RESULTS_BCR_ABL1_MUTATION_HISTORY_FRAGMENT}
`;

const GET_THERAPY_HEMATOLOGICAL_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetTherapyHematologyPatientMedicalDataHistory($patient: String!) {
    getTherapyHematologyPatientMedicalDataHistory(patient: $patient) {
      rows {
        objectId
        history {
          ...TherapyHematologyHistoryFragment
        }
      }
    }
  }
  ${THERAPY_HEMATOLOGICAL_HISTORY_FRAGMENT}
`;

const CHECK_PATIENT_EXISTENCE_STATUS = gql`
  query CheckPatientExistenceStatus(
    $firstName: String!
    $lastName: String!
    $gender: Gender!
    $birthDate: DateTime!
    $birthAddressExternalGooglePlaceId: String!
  ) {
    checkPatientExistenceStatus(
      firstName: $firstName
      lastName: $lastName
      gender: $gender
      birthDate: $birthDate
      birthAddressExternalGooglePlaceId: $birthAddressExternalGooglePlaceId
    ) {
      status
      user {
        ...UserFragment
        patient {
          ...PatientFragment
          privacyAcceptance {
            ehrConsensus
          }
        }
      }
    }
  }
  ${PATIENT_FRAGMENT}
  ${USER_FRAGMENT}
`;

const GET_LAST_LAB_RESULTS_JAK2_V617_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastLabResultsJak2V617FPatientMedicalDataHistory($patient: String!) {
    getLastLabResultsJak2V617FPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsJak2V617FHistoryFragment
    }
  }
  ${LAB_RESULTS_JAK2_V617_HISTORY_FRAGMENT}
`;

const GET_LAB_RESULTS_JAK2_V617_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLabResultsJak2V617FPatientMedicalDataHistory($patient: String!) {
    getLabResultsJak2V617FPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsJak2V617FHistoryFragment
    }
  }
  ${LAB_RESULTS_JAK2_V617_HISTORY_FRAGMENT}
`;

const GET_LAST_LAB_RESULTS_JAK2_EXON_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastLabResultsJak2Exon12PatientMedicalDataHistory($patient: String!) {
    getLastLabResultsJak2Exon12PatientMedicalDataHistory(patient: $patient) {
      ...LabResultsJak2Exon12HistoryFragment
    }
  }
  ${LAB_RESULTS_JAK2_EXON_HISTORY_FRAGMENT}
`;

const GET_LAB_RESULTS_JAK2_EXON_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLabResultsJak2Exon12PatientMedicalDataHistory($patient: String!) {
    getLabResultsJak2Exon12PatientMedicalDataHistory(patient: $patient) {
      ...LabResultsJak2Exon12HistoryFragment
    }
  }
  ${LAB_RESULTS_JAK2_EXON_HISTORY_FRAGMENT}
`;

const GET_LAST_LAB_RESULTS_CALR_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastLabResultsCALRPatientMedicalDataHistory($patient: String!) {
    getLastLabResultsCALRPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsCALRHistoryFragment
    }
  }
  ${LAB_RESULTS_CALR_HISTORY_FRAGMENT}
`;

const GET_LAB_RESULTS_CALR_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLabResultsCALRPatientMedicalDataHistory($patient: String!) {
    getLabResultsCALRPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsCALRHistoryFragment
    }
  }
  ${LAB_RESULTS_CALR_HISTORY_FRAGMENT}
`;

const GET_LAST_LAB_RESULTS_MPL_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastLabResultsMPLPatientMedicalDataHistory($patient: String!) {
    getLastLabResultsMPLPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsMPLHistoryFragment
    }
  }
  ${LAB_RESULTS_MPL_HISTORY_FRAGMENT}
`;

const GET_LAB_RESULTS_MPL_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLabResultsMPLPatientMedicalDataHistory($patient: String!) {
    getLabResultsMPLPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsMPLHistoryFragment
    }
  }
  ${LAB_RESULTS_MPL_HISTORY_FRAGMENT}
`;

const GET_LAST_LAB_RESULTS_SUBCLONAL_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastLabResultsSubclonalPatientMedicalDataHistory($patient: String!) {
    getLastLabResultsSubclonalPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsSubclonalHistoryFragment
    }
  }
  ${LAB_RESULTS_SUBCLONAL_HISTORY_FRAGMENT}
`;

const GET_LAB_RESULTS_SUBCLONAL_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLabResultsSubclonalPatientMedicalDataHistory($patient: String!) {
    getLabResultsSubclonalPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsSubclonalHistoryFragment
    }
  }
  ${LAB_RESULTS_SUBCLONAL_HISTORY_FRAGMENT}
`;

const GET_LAST_LAB_RESULTS_NGS_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastLabResultsNGSPatientMedicalDataHistory($patient: String!) {
    getLastLabResultsNGSPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsNGSHistoryFragment
    }
  }
  ${LAB_RESULTS_NGS_HISTORY_FRAGMENT}
`;

const GET_LAB_RESULTS_NGS_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLabResultsNGSPatientMedicalDataHistory($patient: String!) {
    getLabResultsNGSPatientMedicalDataHistory(patient: $patient) {
      ...LabResultsNGSHistoryFragment
    }
  }
  ${LAB_RESULTS_NGS_HISTORY_FRAGMENT}
`;

const GET_LAST_ANAMNESIS_DONOR_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastAnamnesisDonorPatientMedicalDataHistory($patient: String!) {
    getLastAnamnesisDonorPatientMedicalDataHistory(patient: $patient) {
      ...AnamnesisDonorHistoryFragment
    }
  }
  ${ANAMNESIS_DONOR_HISTORY_FRAGMENT}
`;

const GET_ANAMNESIS_DONOR_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetAnamnesisDonorPatientMedicalDataHistory($patient: String!) {
    getAnamnesisDonorPatientMedicalDataHistory(patient: $patient) {
      ...AnamnesisDonorHistoryFragment
    }
  }
  ${ANAMNESIS_DONOR_HISTORY_FRAGMENT}
`;

const GET_LAST_ANAMNESIS_SURGERY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastAnamnesisSurgeryForBreastCancerPatientMedicalDataHistory($patient: String!) {
    getLastAnamnesisSurgeryForBreastCancerPatientMedicalDataHistory(patient: $patient) {
      ...AnamnesisSurgeryForBreastCancerHistoryFragment
    }
  }
  ${ANAMNESIS_SURGERY_FOR_BREAST_CANCER_HISTORY_FRAGMENT}
`;

const GET_ANAMNESIS_SURGERY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetAnamnesisSurgeryForBreastCancerPatientMedicalDataHistory($patient: String!) {
    getAnamnesisSurgeryForBreastCancerPatientMedicalDataHistory(patient: $patient) {
      ...AnamnesisSurgeryForBreastCancerHistoryFragment
    }
  }
  ${ANAMNESIS_SURGERY_FOR_BREAST_CANCER_HISTORY_FRAGMENT}
`;

const GET_LAST_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPhysiologicalHistoryFitzpatrickScalePatientMedicalDataHistory($patient: String!) {
    getLastPhysiologicalHistoryFitzpatrickScalePatientMedicalDataHistory(patient: $patient) {
      ...PhysiologicalHistoryFitzpatrickScaleHistoryFragment
    }
  }
  ${PHYSIOLOGICAL_HISTORY_PHOTOTYPE_HISTORY_FRAGMENT}
`;

const GET_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPhysiologicalHistoryFitzpatrickScalePatientMedicalDataHistory($patient: String!) {
    getPhysiologicalHistoryFitzpatrickScalePatientMedicalDataHistory(patient: $patient) {
      ...PhysiologicalHistoryFitzpatrickScaleHistoryFragment
    }
  }
  ${PHYSIOLOGICAL_HISTORY_PHOTOTYPE_HISTORY_FRAGMENT}
`;

const GET_LAST_SIGNS_AND_SYMPTOMS_SPLENOMEGALY_PALPABLE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastSignsAndSymptomsSplenomegalyPalpablePatientMedicalDataHistory($patient: String!) {
    getLastSignsAndSymptomsSplenomegalyPalpablePatientMedicalDataHistory(patient: $patient) {
      ...SignsAndSymptomsSplenomegalyPalpableHistoryFragment
    }
  }
  ${SIGNS_AND_SYMPTOMS_SPLENOMEGALY_PALPABLE_HISTORY_FRAGMENT}
`;

const GET_SIGNS_AND_SYMPTOMS_SPLENOMEGALY_PALPABLE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetSignsAndSymptomsSplenomegalyPalpablePatientMedicalDataHistory($patient: String!) {
    getSignsAndSymptomsSplenomegalyPalpablePatientMedicalDataHistory(patient: $patient) {
      ...SignsAndSymptomsSplenomegalyPalpableHistoryFragment
    }
  }
  ${SIGNS_AND_SYMPTOMS_SPLENOMEGALY_PALPABLE_HISTORY_FRAGMENT}
`;

const GET_MPN10_MEDICAL_INDEX_CALCULATION = gql`
  query GetMpn10MedicalIndexCalculation($mpn10MedicalIndexParametersModel: Mpn10MedicalIndexParametersModel!) {
    mpn10MedicalIndexCalculation(mpn10MedicalIndexParametersModel: $mpn10MedicalIndexParametersModel) {
      ...PatientCalculationResultRecordFragment
    }
  }
  ${PATIENT_CALCULATION_RESULT_RECORD_FRAGMENT}
`;

const GET_DIPSS_PLUS_MEDICAL_INDEX_CALCULATION = gql`
  query GetDipssPlusMedicalIndexCalculation(
    $dipssPlusMedicalIndexParametersModel: DipssPlusMedicalIndexParametersModel!
  ) {
    dipssPlusMedicalIndexCalculation(dipssPlusMedicalIndexParametersModel: $dipssPlusMedicalIndexParametersModel) {
      ...PatientCalculationResultRecordFragment
    }
  }
  ${PATIENT_CALCULATION_RESULT_RECORD_FRAGMENT}
`;

const GET_IPSSR_MEDICAL_INDEX_CALCULATION = gql`
  query GetIpssrMedicalIndexCalculation($ipssrMedicalIndexParametersModel: IpssrMedicalIndexParametersModel!) {
    ipssrMedicalIndexCalculation(ipssrMedicalIndexParametersModel: $ipssrMedicalIndexParametersModel) {
      ...PatientCalculationResultRecordFragment
    }
  }
  ${PATIENT_CALCULATION_RESULT_RECORD_FRAGMENT}
`;

const GET_MIPSS_70_INDEX_CALCULATION = gql`
  query GetMipss70IndexCalculation($mipss70MedicalIndexParametersModel: Mipss70MedicalIndexParametersModel!) {
    mipss70MedicalIndexCalculation(mipss70MedicalIndexParametersModel: $mipss70MedicalIndexParametersModel) {
      ...PatientCalculationResultRecordFragment
    }
  }
  ${PATIENT_CALCULATION_RESULT_RECORD_FRAGMENT}
`;

const MYSEC_PM_MEDICAL_INDEX_CALCULATION = gql`
  query MysecPmMedicalIndexCalculation($mysecPmMedicalIndexParametersModel: MysecPmMedicalIndexParametersModel!) {
    mysecPmMedicalIndexCalculation(mysecPmMedicalIndexParametersModel: $mysecPmMedicalIndexParametersModel) {
      ...PatientCalculationResultRecordFragment
    }
  }
  ${PATIENT_CALCULATION_RESULT_RECORD_FRAGMENT}
`;

const GET_DIPSS_MEDICAL_INDEX_CALCULATION = gql`
  query GetDipssMedicalIndexCalculation($dipssMedicalIndexParametersModel: DipssMedicalIndexParametersModel!) {
    dipssMedicalIndexCalculation(dipssMedicalIndexParametersModel: $dipssMedicalIndexParametersModel) {
      ...PatientCalculationResultRecordFragment
    }
  }
  ${PATIENT_CALCULATION_RESULT_RECORD_FRAGMENT}
`;

const GET_CTNM_MEDICAL_INDEX_CALCULATION = gql`
  query GetCtnmMedicalIndexCalculation($ctnmMedicalIndexParametersModel: CtnmMedicalIndexParametersModel!) {
    ctnmMedicalIndexCalculation(ctnmMedicalIndexParametersModel: $ctnmMedicalIndexParametersModel) {
      ...PatientCalculationResultRecordFragment
    }
  }
  ${PATIENT_CALCULATION_RESULT_RECORD_FRAGMENT}
`;

const GET_PTNM_MEDICAL_INDEX_CALCULATION = gql`
  query GetPtnmMedicalIndexCalculation($ptnmMedicalIndexParametersModel: PtnmMedicalIndexParametersModel!) {
    ptnmMedicalIndexCalculation(ptnmMedicalIndexParametersModel: $ptnmMedicalIndexParametersModel) {
      ...PatientCalculationResultRecordFragment
    }
  }
  ${PATIENT_CALCULATION_RESULT_RECORD_FRAGMENT}
`;

const GET_TNM_MEDICAL_INDEX_CALCULATION = gql`
  query GetTnmMedicalIndexCalculation($tnmMedicalIndexParametersModel: TnmMedicalIndexParametersModel!) {
    tnmMedicalIndexCalculation(tnmMedicalIndexParametersModel: $tnmMedicalIndexParametersModel) {
      ...PatientCalculationResultRecordFragment
    }
  }
  ${PATIENT_CALCULATION_RESULT_RECORD_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_BRAF_MUTATION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsBrafMutationPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsBrafMutationPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBrafMutationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BRAF_MUTATION_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_BRAF_MUTATION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsBrafMutationPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsBrafMutationPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBrafMutationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BRAF_MUTATION_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_NON_BRAF_MUTATION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsNonBrafMutationPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsNonBrafMutationPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsNonBrafMutationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_NON_BRAF_MUTATION_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_NON_BRAF_MUTATION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsNonBrafMutationPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsNonBrafMutationPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsNonBrafMutationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_NON_BRAF_MUTATION_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_MELANOMA_SENTINEL_NODE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsMelanomaSentinelNodePatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsMelanomaSentinelNodePatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaSentinelNodeHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_SENTINEL_NODE_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_MELANOMA_SENTINEL_NODE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsMelanomaSentinelNodePatientMedicalDataHistory($patient: String!) {
    getPathologyResultsMelanomaSentinelNodePatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaSentinelNodeHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_SENTINEL_NODE_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_BREAST_SENTINEL_NODE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsBreastSentinelNodePatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsBreastSentinelNodePatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBreastSentinelNodeHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_SENTINEL_NODE_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_BREAST_SENTINEL_NODE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsBreastSentinelNodePatientMedicalDataHistory($patient: String!) {
    getPathologyResultsBreastSentinelNodePatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBreastSentinelNodeHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_SENTINEL_NODE_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_MELANOMA_NODES_DISSECTION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsMelanomaNodesDissectionPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsMelanomaNodesDissectionPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaNodesDissectionHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_NODES_DISSECTION_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_MELANOMA_NODES_DISSECTION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsMelanomaNodesDissectionPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsMelanomaNodesDissectionPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaNodesDissectionHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_NODES_DISSECTION_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_BREAST_NODES_DISSECTION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsBreastNodesDissectionPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsBreastNodesDissectionPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBreastNodesDissectionHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_NODES_DISSECTION_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_BREAST_NODES_DISSECTION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsBreastNodesDissectionPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsBreastNodesDissectionPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBreastNodesDissectionHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_NODES_DISSECTION_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_MELANOMA_METASTASIS_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsMelanomaMetastasisPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsMelanomaMetastasisPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaMetastasisHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_METASTASIS_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_MELANOMA_METASTASIS_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsMelanomaMetastasisPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsMelanomaMetastasisPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaMetastasisHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_METASTASIS_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_BREAST_RESULTS_METASTASIS_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsBreastMetastasisPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsBreastMetastasisPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBreastMetastasisHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_METASTASIS_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_BREAST_RESULTS_METASTASIS_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsBreastMetastasisPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsBreastMetastasisPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBreastMetastasisHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_METASTASIS_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_MELANOMA_RESECTION_MARGINS_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsMelanomaResectionMarginsPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsMelanomaResectionMarginsPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaResectionMarginsHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_RESECTION_MARGINS_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_MELANOMA_RESECTION_MARGINS_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsMelanomaResectionMarginsPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsMelanomaResectionMarginsPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaResectionMarginsHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_RESECTION_MARGINS_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_BREAST_RESECTION_MARGINS_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsBreastResectionMarginsPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsBreastResectionMarginsPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBreastResectionMarginsHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_RESECTION_MARGINS_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_BREAST_RESECTION_MARGINS_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsResectionMarginsPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsResectionMarginsPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBreastResectionMarginsHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_RESECTION_MARGINS_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_MELANOMA_HISTOTYPE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsMelanomaHistotypePatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsMelanomaHistotypePatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaHistotypeHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_HISTOTYPE_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_MELANOMA_HISTOTYPE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsMelanomaHistotypePatientMedicalDataHistory($patient: String!) {
    getPathologyResultsMelanomaHistotypePatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaHistotypeHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_HISTOTYPE_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_BREAST_HISTOTYPE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsBreastHistotypePatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsBreastHistotypePatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBreastHistotypeHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_HISTOTYPE_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_BREAST_HISTOTYPE_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsBreastHistotypePatientMedicalDataHistory($patient: String!) {
    getPathologyResultsBreastHistotypePatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBreastHistotypeHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_HISTOTYPE_HISTORY_FRAGMENT}
`;

const GET_LAST_ANAMNESIS_MELANOMA_FAMILY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastAnamnesisMelanomaFamilyHistoryPatientMedicalDataHistory($patient: String!) {
    getLastAnamnesisMelanomaFamilyHistoryPatientMedicalDataHistory(patient: $patient) {
      ...AnamnesisMelanomaFamilyHistoryHistoryFragment
    }
  }
  ${ANAMNESIS_MELANOMA_FAMILY_HISTORY_HISTORY_FRAGMENT}
`;

const GET_ANAMNESIS_MELANOMA_FAMILY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetAnamnesisMelanomaFamilyHistoryPatientMedicalDataHistory($patient: String!) {
    getAnamnesisMelanomaFamilyHistoryPatientMedicalDataHistory(patient: $patient) {
      ...AnamnesisMelanomaFamilyHistoryHistoryFragment
    }
  }
  ${ANAMNESIS_MELANOMA_FAMILY_HISTORY_HISTORY_FRAGMENT}
`;

const GET_LAST_ANAMNESIS_BREAST_FAMILY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastAnamnesisBreastFamilyHistoryPatientMedicalDataHistory($patient: String!) {
    getLastAnamnesisBreastFamilyHistoryPatientMedicalDataHistory(patient: $patient) {
      ...AnamnesisBreastFamilyHistoryHistoryFragment
    }
  }
  ${ANAMNESIS_BREAST_FAMILY_HISTORY_HISTORY_FRAGMENT}
`;

const GET_ANAMNESIS_BREAST_FAMILY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetAnamnesisBreastFamilyHistoryPatientMedicalDataHistory($patient: String!) {
    getAnamnesisBreastFamilyHistoryPatientMedicalDataHistory(patient: $patient) {
      ...AnamnesisBreastFamilyHistoryHistoryFragment
    }
  }
  ${ANAMNESIS_BREAST_FAMILY_HISTORY_HISTORY_FRAGMENT}
`;

const GET_LAST_SIGNS_AND_SYMPTOMS_ECOG_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastSignsAndSymptomsEcogPatientMedicalDataHistory($patient: String!) {
    getLastSignsAndSymptomsEcogPatientMedicalDataHistory(patient: $patient) {
      ...SignsAndSymptomsEcogHistoryFragment
    }
  }
  ${SIGNS_AND_SYMPTOMS_ECOG_HISTORY_FRAGMENT}
`;

const GET_SIGNS_AND_SYMPTOMS_ECOG_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetSignsAndSymptomsEcogPatientMedicalDataHistory($patient: String!) {
    getSignsAndSymptomsEcogPatientMedicalDataHistory(patient: $patient) {
      ...SignsAndSymptomsEcogHistoryFragment
    }
  }
  ${SIGNS_AND_SYMPTOMS_ECOG_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_BREAST_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsBreastHistologicalExaminationPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsBreastHistologicalExaminationPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBreastHistologicalExaminationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_BREAST_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsBreastHistologicalExaminationPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsBreastHistologicalExaminationPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsBreastHistologicalExaminationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_BREAST_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsMelanomaLymphNodesHistologicalExaminationPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsMelanomaLymphNodesHistologicalExaminationPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaLymphNodesHistologicalExaminationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsMelanomaLymphNodesHistologicalExaminationPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsMelanomaLymphNodesHistologicalExaminationPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaLymphNodesHistologicalExaminationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT}
`;

const GET_LAST_PATHOLOGY_RESULTS_MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastPathologyResultsMelanomaTumorHistologicalExaminationPatientMedicalDataHistory($patient: String!) {
    getLastPathologyResultsMelanomaTumorHistologicalExaminationPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaTumorHistologicalExaminationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT}
`;

const GET_PATHOLOGY_RESULTS_MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetPathologyResultsMelanomaTumorHistologicalExaminationPatientMedicalDataHistory($patient: String!) {
    getPathologyResultsMelanomaTumorHistologicalExaminationPatientMedicalDataHistory(patient: $patient) {
      ...PathologyResultsMelanomaTumorHistologicalExaminationHistoryFragment
    }
  }
  ${PATHOLOGY_RESULTS_MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION_HISTORY_FRAGMENT}
`;

const GET_THERAPY_BREAST_RADIOTHERAPY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetTherapyBreastRadiotherapyPatientMedicalDataHistory($patient: String!) {
    getTherapyBreastRadiotherapyPatientMedicalDataHistory(patient: $patient) {
      ...TherapyBreastRadiotherapyHistoryFragment
    }
  }
  ${THERAPY_BREAST_RADIOTHERAPY_HISTORY_FRAGMENT}
`;

const GET_LAST_THERAPY_BREAST_RADIOTHERAPY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastTherapyBreastRadiotherapyPatientMedicalDataHistory($patient: String!) {
    getLastTherapyBreastRadiotherapyPatientMedicalDataHistory(patient: $patient) {
      ...TherapyBreastRadiotherapyHistoryFragment
    }
  }
  ${THERAPY_BREAST_RADIOTHERAPY_HISTORY_FRAGMENT}
`;

const GET_THERAPY_BREAST_NEOADJUVANT_THERAPY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetTherapyBreastNeoadjuvantTherapyPatientMedicalDataHistory($patient: String!) {
    getTherapyBreastNeoadjuvantTherapyPatientMedicalDataHistory(patient: $patient) {
      ...TherapyBreastNeoadjuvantTherapyHistoryFragment
    }
  }
  ${THERAPY_BREAST_NEOADJUVANT_THERAPY_HISTORY_FRAGMENT}
`;

const GET_LAST_THERAPY_BREAST_NEOADJUVANT_THERAPY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastTherapyBreastNeoadjuvantTherapyPatientMedicalDataHistory($patient: String!) {
    getLastTherapyBreastNeoadjuvantTherapyPatientMedicalDataHistory(patient: $patient) {
      ...TherapyBreastNeoadjuvantTherapyHistoryFragment
    }
  }
  ${THERAPY_BREAST_NEOADJUVANT_THERAPY_HISTORY_FRAGMENT}
`;

const GET_THERAPY_BREAST_HORMONE_THERAPY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetTherapyBreastHormoneTherapyPatientMedicalDataHistory($patient: String!) {
    getTherapyBreastHormoneTherapyPatientMedicalDataHistory(patient: $patient) {
      ...TherapyBreastHormoneTherapyHistoryFragment
    }
  }
  ${THERAPY_BREAST_HORMONE_THERAPY_HISTORY_FRAGMENT}
`;

const GET_LAST_THERAPY_BREAST_HORMONE_THERAPY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastTherapyBreastHormoneTherapyPatientMedicalDataHistory($patient: String!) {
    getLastTherapyBreastHormoneTherapyPatientMedicalDataHistory(patient: $patient) {
      ...TherapyBreastHormoneTherapyHistoryFragment
    }
  }
  ${THERAPY_BREAST_HORMONE_THERAPY_HISTORY_FRAGMENT}
`;

const GET_THERAPY_BREAST_ADJUVANT_THERAPY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetTherapyBreastAdjuvantTherapyPatientMedicalDataHistory($patient: String!) {
    getTherapyBreastAdjuvantTherapyPatientMedicalDataHistory(patient: $patient) {
      ...TherapyBreastAdjuvantTherapyHistoryFragment
    }
  }
  ${THERAPY_BREAST_ADJUVANT_THERAPY_HISTORY_FRAGMENT}
`;

const GET_LAST_THERAPY_BREAST_ADJUVANT_THERAPY_PATIENT_MEDICAL_DATA_HISTORY = gql`
  query GetLastTherapyBreastAdjuvantTherapyPatientMedicalDataHistory($patient: String!) {
    getLastTherapyBreastAdjuvantTherapyPatientMedicalDataHistory(patient: $patient) {
      ...TherapyBreastAdjuvantTherapyHistoryFragment
    }
  }
  ${THERAPY_BREAST_ADJUVANT_THERAPY_HISTORY_FRAGMENT}
`;

export default {
  SEARCH_PATIENT_BY_FULL_NAME,
  GET_MEDICAL_DATA_BY_PATIENT,
  GET_MEDICAL_DATA_PATIENT_USER,
  GET_MASTOCYTOSIS_PATIENT_MEDICAL_DATA,
  GET_TUMOURS_GENERIC_INFORMATION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_ALLERGIES_ANAPHYLACTIC_SHOCK_PATIENT_MEDICAL_DATA_HISTORY,
  GET_ALLERGIES_HYMENOPTERA_PATIENT_MEDICAL_DATA_HISTORY,
  GET_ALLERGIES_OTHER_PATIENT_MEDICAL_DATA_HISTORY,
  GET_SIGNS_AND_SYMPTOMS_SYNCOPES_PATIENT_MEDICAL_DATA_HISTORY,
  GET_SIGNS_AND_SYMPTOMS_GASTROINTESTINAL_DISORDERS_PATIENT_MEDICAL_DATA_HISTORY,
  GET_SIGNS_AND_SYMPTOMS_SKIN_INVOLVEMENT_PATIENT_MEDICAL_DATA_HISTORY,
  GET_RADIOLOGY_BONE_DENSITOMETRY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_RADIOLOGY_BONE_IMAGING_PATIENT_MEDICAL_DATA_HISTORY,
  GET_RADIOLOGY_ABDOMEN_ULTRASOUND_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_BMB_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_BMA_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_IMMUNOPHENOTYPING_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_C_KIT_PATIENT_MEDICAL_DATA_HISTORY,
  GET_MEDICAL_DATA_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY,
  GET_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY,
  GET_MASTOCYTOSIS_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY,
  GET_CAR_T_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY,
  GET_CML_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY,
  GET_BREAST_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY,
  GET_PATIENT_CARD_HEADER,
  CALCULATE_SOKAL_INDEX_SCORE,
  CALCULATE_ELTS_INDEX_SCORE,
  CALCULATE_CHARLSON_INDEX_SCORE,
  GET_LAST_RISK_SCORE_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY,
  GET_RISK_SCORE_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_SIGNS_AND_SYMPTOMS_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY,
  GET_SIGNS_AND_SYMPTOMS_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PHYSIOLOGICAL_HISTORY_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PHYSIOLOGICAL_HISTORY_SMOKE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PHYSIOLOGICAL_HISTORY_SMOKE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_VITALS_PARAMETERS_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY,
  GET_VITALS_PARAMETERS_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY,
  GET_DIAGNOSES_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_DIAGNOSES_GENERIC_NOTES_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_ACA_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_ACA_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_LAB_RESULTS_BCR_ABL1_Q_PCR_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAB_RESULTS_BCR_ABL1_Q_PCR_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_LAB_RESULTS_BCR_ABL1_MUTATION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAB_RESULTS_BCR_ABL1_MUTATION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_THERAPY_HEMATOLOGICAL_PATIENT_MEDICAL_DATA_HISTORY,
  PATIENT_CALCULATIONS,
  GET_LAST_PATHOLOGY_RESULTS_BMA_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_BMB_PATIENT_MEDICAL_DATA_HISTORY,
  CHECK_PATIENT_EXISTENCE_STATUS,
  GET_LAST_LAB_RESULTS_JAK2_V617_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAB_RESULTS_JAK2_V617_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_LAB_RESULTS_JAK2_EXON_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAB_RESULTS_JAK2_EXON_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_LAB_RESULTS_CALR_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAB_RESULTS_CALR_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_LAB_RESULTS_MPL_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAB_RESULTS_MPL_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_LAB_RESULTS_SUBCLONAL_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAB_RESULTS_SUBCLONAL_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_LAB_RESULTS_NGS_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAB_RESULTS_NGS_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_ANAMNESIS_DONOR_PATIENT_MEDICAL_DATA_HISTORY,
  GET_ANAMNESIS_DONOR_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_SIGNS_AND_SYMPTOMS_SPLENOMEGALY_PALPABLE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_SIGNS_AND_SYMPTOMS_SPLENOMEGALY_PALPABLE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PV_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY,
  GET_MF_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY,
  GET_MELANOMA_PATIENT_LAB_RESULTS_PARAMETERS_HISTORY,
  GET_MPN10_MEDICAL_INDEX_CALCULATION,
  GET_DIPSS_PLUS_MEDICAL_INDEX_CALCULATION,
  MYSEC_PM_MEDICAL_INDEX_CALCULATION,
  GET_IPSSR_MEDICAL_INDEX_CALCULATION,
  GET_DIPSS_MEDICAL_INDEX_CALCULATION,
  GET_MIPSS_70_INDEX_CALCULATION,
  GET_LAST_PATHOLOGY_RESULTS_BRAF_MUTATION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_BRAF_MUTATION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_NON_BRAF_MUTATION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_NON_BRAF_MUTATION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_MELANOMA_SENTINEL_NODE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_MELANOMA_SENTINEL_NODE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_MELANOMA_NODES_DISSECTION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_MELANOMA_NODES_DISSECTION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_MELANOMA_METASTASIS_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_MELANOMA_METASTASIS_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_MELANOMA_RESECTION_MARGINS_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_MELANOMA_RESECTION_MARGINS_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_MELANOMA_HISTOTYPE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_MELANOMA_HISTOTYPE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_ANAMNESIS_MELANOMA_FAMILY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_ANAMNESIS_MELANOMA_FAMILY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_ANAMNESIS_BREAST_FAMILY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_ANAMNESIS_BREAST_FAMILY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_ANAMNESIS_SURGERY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_ANAMNESIS_SURGERY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_SIGNS_AND_SYMPTOMS_ECOG_PATIENT_MEDICAL_DATA_HISTORY,
  GET_SIGNS_AND_SYMPTOMS_ECOG_PATIENT_MEDICAL_DATA_HISTORY,
  GET_CTNM_MEDICAL_INDEX_CALCULATION,
  GET_PTNM_MEDICAL_INDEX_CALCULATION,
  GET_LAST_PATHOLOGY_BREAST_RESULTS_METASTASIS_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_BREAST_RESULTS_METASTASIS_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_BREAST_SENTINEL_NODE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_BREAST_SENTINEL_NODE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_BREAST_NODES_DISSECTION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_BREAST_NODES_DISSECTION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PHYSIOLOGICAL_HISTORY_MENOPAUSE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PHYSIOLOGICAL_HISTORY_MENOPAUSE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_BREAST_RESECTION_MARGINS_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_BREAST_RESECTION_MARGINS_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_BREAST_HISTOTYPE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_BREAST_HISTOTYPE_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_BREAST_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_BREAST_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_MELANOMA_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_PATHOLOGY_RESULTS_MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_PATHOLOGY_RESULTS_MELANOMA_TUMOR_HISTOLOGICAL_EXAMINATION_PATIENT_MEDICAL_DATA_HISTORY,
  GET_THERAPY_BREAST_RADIOTHERAPY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_THERAPY_BREAST_RADIOTHERAPY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_THERAPY_BREAST_NEOADJUVANT_THERAPY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_THERAPY_BREAST_NEOADJUVANT_THERAPY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_THERAPY_BREAST_HORMONE_THERAPY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_THERAPY_BREAST_HORMONE_THERAPY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_THERAPY_BREAST_ADJUVANT_THERAPY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_LAST_THERAPY_BREAST_ADJUVANT_THERAPY_PATIENT_MEDICAL_DATA_HISTORY,
  GET_TNM_MEDICAL_INDEX_CALCULATION,
};
