import { ShowNotification } from '@lib/core';
import { RouterProps } from 'react-router-dom';
import { combineReducers, Reducer } from 'redux';

import { attachmentsReducer } from './attachments/reducer';
import {
  Action as AttachmentsAction,
  initialState as attachmentsInitialState,
  IState as AttachmentsState,
} from './attachments/types';
import { infoReducer } from './info/reducer';
import { Action as InfoAction, initialState as infoInitialState, IState as InfoState } from './info/types';
import { intramedReducer } from './intramed/reducer';
import {
  Action as IntramedAction,
  initialState as intramedInitialState,
  IState as IntramedState,
} from './intramed/types';
import { medicalParametersReducer } from './medicalParameters/reducer';
import {
  Action as MedicalParametersAction,
  initialState as medicalParametersInitialState,
  IState as MedicalParametersState,
} from './medicalParameters/types';
import { patientReducer } from './patient/reducer';
import { Action as PatientAction, initialState as patientInitialState, IState as PatientState } from './patient/types';
import { userReducer } from './user/reducer';
import {
  Action as UserAction,
  ActionTypeList as UserActionTypeList,
  initialState as userInitialState,
  IState as UserState,
} from './user/types';

export type StoreActions =
  | UserAction
  | PatientAction
  | IntramedAction
  | AttachmentsAction
  | InfoAction
  | MedicalParametersAction;

export interface IStoreState {
  user: UserState;
  patient: PatientState;
  intramed: IntramedState;
  attachments: AttachmentsState;
  info: InfoState;
  medicalParameters: MedicalParametersState;
}

export interface IActionOptions {
  showNotification?: ShowNotification;
  onSuccess?: VoidFunction;
  onError?: VoidFunction;
  history?: RouterProps['history'];
}

const appReducer: Reducer<IStoreState> = combineReducers<IStoreState>({
  user: userReducer,
  patient: patientReducer,
  intramed: intramedReducer,
  attachments: attachmentsReducer,
  info: infoReducer,
  medicalParameters: medicalParametersReducer,
});

export const rootReducer: Reducer<IStoreState> = (state, action) => {
  if (action.type === UserActionTypeList.SIGN_OUT) {
    return appReducer(
      {
        user: userInitialState,
        patient: patientInitialState,
        intramed: intramedInitialState,
        attachments: attachmentsInitialState,
        info: infoInitialState,
        medicalParameters: medicalParametersInitialState,
      },
      action
    );
  }

  return appReducer(state, action);
};
