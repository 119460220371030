import { REGISTRATION_ROUTES_TYPES, ROUTE_PATHS } from '@lib/core';
import { Portals } from '@lib/features-bll';
import { generatePath, matchPath } from 'react-router-dom';

import { i18n } from 'i18n';
import { CreateUserClaimErrorCodes, RegistrationsSteps } from 'lib/constants/registration';

export const isDoctorRegistration = (portalSlug: Portals) =>
  !!matchPath(
    window.location.pathname,
    generatePath(`/${portalSlug}${ROUTE_PATHS.registrationByTypeAndPortal}`, {
      type: REGISTRATION_ROUTES_TYPES.DOCTOR,
    })
  );

export const isNonDoctorRegistration = (portalSlug: Portals) =>
  !!matchPath(
    window.location.pathname,
    generatePath(`/${portalSlug}${ROUTE_PATHS.registrationByTypeAndPortal}`, {
      type: REGISTRATION_ROUTES_TYPES.SECRETARY,
    })
  );

export const isPatientRegistration = (portalSlug: Portals) =>
  !!matchPath(
    window.location.pathname,
    generatePath(`/${portalSlug}${ROUTE_PATHS.registrationByTypeAndPortal}`, {
      type: REGISTRATION_ROUTES_TYPES.PATIENT,
    })
  );

export const getRegistrationInvitation = (portalSlug: Portals): string =>
  (isDoctorRegistration(portalSlug) && i18n.t('pages.register.title.welcomeDoctor')) ||
  (isNonDoctorRegistration(portalSlug) && i18n.t('pages.register.title.welcomeNonDoctor')) ||
  (isPatientRegistration(portalSlug) && i18n.t('pages.register.title.welcomePatient')) ||
  '';

export const getRegistrationStepTitle = (currentStep: RegistrationsSteps): string => {
  const registrationTitles: Partial<Record<RegistrationsSteps, string>> = {
    [RegistrationsSteps.EnterPassword]: i18n.t('components.forms.registrationForm.title.enterPassphrase'),
    [RegistrationsSteps.ProceedVerification]: i18n.t('components.forms.verificationForms.verifyAccount.title'),
    [RegistrationsSteps.LoginInformation]: i18n.t('components.forms.registrationForm.title.loginInformation'),
    [RegistrationsSteps.PersonalInformation]: i18n.t('components.forms.registrationForm.title.personalData'),
  };

  return registrationTitles[currentStep] || '';
};

export const getRegistrationCreateUserClaimError = (code: CreateUserClaimErrorCodes): string => {
  const registrationCreateUserClaimError: Record<CreateUserClaimErrorCodes, string> = {
    [CreateUserClaimErrorCodes.EmailNotUnique]: i18n.t('errors.user.notUnique'),
    [CreateUserClaimErrorCodes.MissedAttachments]: i18n.t(
      'components.forms.registrationForm.errors.createUserClaim.missedAttachments'
    ),
    [CreateUserClaimErrorCodes.IncorrectFlowUsage]: i18n.t(
      'components.forms.registrationForm.errors.createUserClaim.incorrectFlowUsage'
    ),
    [CreateUserClaimErrorCodes.PhoneNumberNotUnique]: i18n.t('errors.user.notUnique'),
  };

  return registrationCreateUserClaimError[code];
};
