import { useState, useEffect } from 'react';

import { AffiliationDoctorJoinStatus, AffiliationForDoctorModel } from '__generated__/types';

interface IUseAffiliationsAccordionReturn {
  expandedAccordionId: string | undefined;
  handleAccordionChange: (id: string) => (_: React.SyntheticEvent, isExpanded: boolean) => void;
  openFirstPendingAffiliation: () => void;
}

export const useAffiliationsAccordion = (
  affiliations: AffiliationForDoctorModel[] = []
): IUseAffiliationsAccordionReturn => {
  const [expandedAccordionId, setExpandedAccordionId] = useState<string | undefined>();

  const openFirstPendingAffiliation = (): void => {
    const firstPending = affiliations.find(
      affiliation => affiliation.joinStatus === AffiliationDoctorJoinStatus.PENDING
    )?.affiliationId;
    setExpandedAccordionId(firstPending);
  };

  useEffect(() => {
    openFirstPendingAffiliation();
  }, [affiliations]);

  const handleAccordionChange =
    (id: string): ((_: React.SyntheticEvent, isExpanded: boolean) => void) =>
    (_: React.SyntheticEvent, isExpanded: boolean): void => {
      setExpandedAccordionId(isExpanded ? id : undefined);
    };

  return {
    expandedAccordionId,
    handleAccordionChange,
    openFirstPendingAffiliation,
  };
};
