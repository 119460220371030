import { BREAK_POINTS } from '@lib/react-components';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  buttonContainer: {
    textAlign: 'center',
    marginTop: 50,
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      marginTop: 70,
    },
  },

  saveButton: {
    padding: '10px 30px',
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      // TODO: h unknown, maybe body1
      fontSize: '16px',
    },
  },

  select: {
    padding: '50px 0',
    maxWidth: 400,
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      maxWidth: 'initial',
    },
  },
}));
