import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmailVariables = Types.Exact<{
  draftDirectMessageId: Types.Scalars['String']['input'];
}>;


export type UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail = { __typename?: 'Mutation', sendDraftDirectMessageConfirmationEmail: boolean };


export const UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"draftDirectMessageId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendDraftDirectMessageConfirmationEmail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"draftDirectMessageId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"draftDirectMessageId"}}}]}]}}]} as unknown as DocumentNode;
export type UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmailMutationFn = Apollo.MutationFunction<UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail, UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmailVariables>;

/**
 * __useUnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail__
 *
 * To run a mutation, you first call `useUnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail, { data, loading, error }] = useUnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail({
 *   variables: {
 *      draftDirectMessageId: // value for 'draftDirectMessageId'
 *   },
 * });
 */
export function useUnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail(baseOptions?: ApolloReactHooks.MutationHookOptions<UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail, UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmailVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail, UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmailVariables>(UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmailDocument, options);
      }
export type UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmailHookResult = ReturnType<typeof useUnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail>;
export type UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmailMutationResult = Apollo.MutationResult<UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail>;
export type UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmailMutationOptions = Apollo.BaseMutationOptions<UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmail, UnauthorizedUserChatMessageApiSendDraftDirectMessageConfirmationEmailVariables>;