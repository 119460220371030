import { ChatIcon, MaterialSymbolsIcon } from '@lib/icons';
import { useBreakpoints } from '@lib/react-components';
import { Button, ButtonProps, SxProps, Theme, Typography, useTheme } from '@mui/material';

interface IChatUiWriteToButtonProps extends Pick<ButtonProps, 'onClick'> {
  label: string;
  sx?: SxProps<Theme>;
}

export const ChatUiWriteToButton = ({ label, onClick, sx }: IChatUiWriteToButtonProps): JSX.Element => {
  const { palette } = useTheme();
  const { isMobileView } = useBreakpoints();

  return (
    <Button sx={sx} fullWidth={isMobileView} onClick={onClick}>
      <MaterialSymbolsIcon icon={<ChatIcon fontWeight="300" />} fill={palette.white.main} mr="12px" />
      <Typography variant={isMobileView ? 'button2' : 'button1'}>{label}</Typography>
    </Button>
  );
};
