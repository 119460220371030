import { FlexBox, fontWeight, Loader } from '@lib/react-components';
import { Stack, Typography } from '@mui/material';

import {
  GlobalSearchDetails,
  globalSearchDetailsModel,
  GlobalSearchDetailsTabType,
  useGlobalSearchDetailsTabsConfiguration,
} from 'features/GlobalSearchDetails';
import { i18n } from 'i18n';

export const GlobalSearchDetailsAccordions = (): JSX.Element => {
  const {
    loading,
    isAllTabsEmpty,
    priorityTab,
    isActiveTabValid,
    isDoctorTabEnabled,
    isAffiliatedDoctorTabEnabled,
    isClinicTabEnabled,
    isFreeClinicTabEnabled,
  } = useGlobalSearchDetailsTabsConfiguration();
  const { accordionsWrapper } = globalSearchDetailsModel.useGlobalSearchDetailsSetAccordionsWrapperMinHeightEffect();

  const isAccordionTabsVisible = priorityTab || isActiveTabValid;

  return (
    <Loader loading={loading && !isAccordionTabsVisible} withShadow>
      <Stack
        ref={accordionsWrapper}
        sx={{
          height: '100%',
          backgroundColor: 'white.main',
        }}
      >
        {isAccordionTabsVisible ? (
          <>
            <Typography
              variant="h7"
              sx={{
                fontWeight: fontWeight.regular,
                flex: '0 1 auto',
                textAlign: 'center',
                margin: '23px 0',
              }}
            >
              {i18n.t('features.search.title')}
            </Typography>
            <Stack flex="1 1 auto" position="relative">
              {isDoctorTabEnabled && (
                <GlobalSearchDetails.Accordion
                  TabComponent={GlobalSearchDetails.DoctorTab}
                  tabType={GlobalSearchDetailsTabType.PRIVATE_DOCTORS}
                />
              )}
              {isAffiliatedDoctorTabEnabled && (
                <GlobalSearchDetails.Accordion
                  TabComponent={GlobalSearchDetails.AffiliatedDoctorTab}
                  tabType={GlobalSearchDetailsTabType.AFFILIATED_DOCTORS}
                />
              )}
              {isClinicTabEnabled && (
                <GlobalSearchDetails.Accordion
                  TabComponent={GlobalSearchDetails.ClinicsTab}
                  tabType={GlobalSearchDetailsTabType.CLINICS}
                />
              )}
              {isFreeClinicTabEnabled && (
                <GlobalSearchDetails.Accordion
                  TabComponent={GlobalSearchDetails.FreeClinicsTab}
                  tabType={GlobalSearchDetailsTabType.FREE_CLINICS}
                />
              )}
            </Stack>
          </>
        ) : (
          (isAllTabsEmpty && (
            <FlexBox flex="1 1 auto" margin="81px 36px">
              <GlobalSearchDetails.NoResult />
            </FlexBox>
          )) || <FlexBox flex="1 1 auto" />
        )}
        <GlobalSearchDetails.ContactsLabel
          sx={{
            flex: '0 1 auto',
            backgroundColor: 'surface.generic',
            textAlign: 'center',
            padding: '4px 36px',
            marginTop: '40px',
          }}
        />
      </Stack>
    </Loader>
  );
};
