import { MaterialSymbolsIcon } from '@lib/icons';
import { BubbleWithNumberIconWrapper } from '@lib/react-components';
import { ListItem, ListItemText } from '@mui/material';
import { matchPath, useLocation } from 'react-router-dom';

import { useStyles as useMenuListStyles } from '../../styles';

import { ConsultationMenuItem } from './ConsultationMenuItem';

import { usePortalSlug } from 'features/Portal';
import { useRootModelPath } from 'features/Root/model';
import { IPortalSidebarItem } from 'graphql/types/portal';
import { i18n } from 'i18n';
import { SidebarItems, SidebarItemState, getSidebarItemConfig } from 'lib/helpers/sidebarConfig';

export interface IMenuListItemProps extends IPortalSidebarItem {
  isExpanded: boolean;
  goToLink: (link: string) => VoidFunction;
  getCounterForSidebarItem: (sidebarItem: SidebarItems) => number | undefined;
}

export const MenuListItem = ({
  isExpanded,
  state,
  label,
  code,
  goToLink,
  getCounterForSidebarItem,
}: IMenuListItemProps): JSX.Element => {
  const { classes: menuListClasses } = useMenuListStyles();
  const { getPath } = useRootModelPath();
  const portalSlug = usePortalSlug();
  const location = useLocation();

  const { icon: Icon, link } = getSidebarItemConfig(code) ?? {};

  const getIsSelected = (codeToCheck: SidebarItems): boolean => {
    const codeLink = getSidebarItemConfig(codeToCheck)?.link;

    return !!(codeLink && matchPath(`/${portalSlug}${codeLink}`, location.pathname));
  };

  if (code === SidebarItems.Consultations) {
    return (
      <ConsultationMenuItem
        getIsSelected={getIsSelected}
        isExpanded={isExpanded}
        code={code}
        state={state}
        goToLink={goToLink}
        label={label}
        getCounterForSidebarItem={getCounterForSidebarItem}
      />
    );
  }

  return (
    <ListItem
      className={menuListClasses.listItem}
      button
      selected={getIsSelected(code)}
      disabled={state === SidebarItemState.DISABLED}
      onClick={goToLink(getPath({ path: link ?? '' }))}
      data-cy={`sidebarItem-${code}`}
    >
      {Icon && (
        <BubbleWithNumberIconWrapper numberValue={getCounterForSidebarItem(code)}>
          <MaterialSymbolsIcon icon={<Icon />} fontSize={26} mr="10px" />
        </BubbleWithNumberIconWrapper>
      )}
      {isExpanded && <ListItemText className={menuListClasses.listItemText} primary={i18n.t(label)} />}
    </ListItem>
  );
};
