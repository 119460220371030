export enum InputTypes {
  date = 'date',
  password = 'password',
  otp = 'otp',
  bp = 'bloodPressure',
  phoneNumber = 'phoneNumber',
  email = 'email',
  number = 'number',
}

export enum InputPlaceholders {
  date = 'dd/mm/yyyy',
  otp = '- - -  - - -',
  bp = '- - - / - - -',
  phoneNumber = '(+__) ___ ___ ____',
  email = 'example@mail.com',
}

export const inputNumberDecimalDefaultStep = '0.01';

export const inputNumberPositive = {
  min: '0',
  step: inputNumberDecimalDefaultStep,
};
