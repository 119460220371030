import { BREAK_POINTS } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  svgNavLogoIconTop: {
    margin: '23px auto 20px',
  },

  svgNavLogoIconBottom: {
    margin: '16px auto 0',
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      margin: '9px 10px 25px 20px',
    },
  },
}));
