import { AffiliationForDoctorModel } from '__generated__/types';
import { useDoctorAffiliationsToPrompt } from 'features/Affiliations/graphql/__generated__/DoctorAffiliationsToPrompt.query';

interface IUseSortedAffiliationsReturn {
  affiliations?: AffiliationForDoctorModel[];
  loading: boolean;
}

export const useSortedAffiliations = (): IUseSortedAffiliationsReturn => {
  const { data, loading } = useDoctorAffiliationsToPrompt({
    fetchPolicy: 'cache-first',
  });

  return {
    affiliations: data?.doctorAffiliationsToPrompt,
    loading,
  };
};
