import { MaterialSymbolsIcon, MicOffIcon } from '@lib/icons';
import { IconButton, useTheme } from '@mui/material';

import { VolumeLevel } from './VolumeLevel';

interface IAudioLevelIndicatorProps {
  isMuted?: boolean;
  handleMute?: (isMuted: boolean) => void;
  volume: number;
  size: number;
}

export const AudioLevelIndicator = ({
  isMuted = false,
  handleMute,
  volume,
  size,
}: IAudioLevelIndicatorProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <IconButton onClick={(): void => handleMute?.(!isMuted)}>
      {!isMuted ? (
        <VolumeLevel volume={volume} size={size} />
      ) : (
        <MaterialSymbolsIcon fontSize={size} icon={<MicOffIcon />} fill={palette.red.main} />
      )}
    </IconButton>
  );
};
