import { VideoVisitAvailability } from '__generated__/types';
import { i18n } from 'i18n';

export const GLOBAL_SEARCH_DETAILS_VIDEO_VISIT_AVAILABILITY_ITEMS = [
  {
    label: i18n.t('features.search.filterSection.availabilityItems.within7Days'),
    value: VideoVisitAvailability.AVAILABLE_WITHIN_7_DAYS,
  },
  {
    label: i18n.t('features.search.filterSection.availabilityItems.onDemand'),
    value: VideoVisitAvailability.ALLOWS_ON_DEMAND_SCHEDULE,
  },
];
