import { AvatarEntity } from '@lib/features-bll';
import { UserAvatarInfo } from '@lib/features-ui';
import { useBreakpoints } from '@lib/react-components';
import { getUserFullName, getUserInitials } from '@lib/utils';
import { Box, useTheme } from '@mui/material';

import { IGlobalSearchDetailsDoctorTabCard } from '../model';

import { AllowDenyPolicyType } from '__generated__/types';
import { ChatUiWriteToButton } from 'features/Chat/ui';
import { useDoctorModelWriteToDoctor, useDoctorModelWriteToDoctorButtonVisibility } from 'features/Doctor/model';
import { UnauthorizedUserChatMessageWriteToModalsSection } from 'features/UnauthorizedUserChatMessage/ui';
import { i18n } from 'i18n';

interface IGlobalSearchDetailsCardDoctorAvatarProps
  extends Pick<IGlobalSearchDetailsDoctorTabCard, 'user' | 'portalUserId'> {
  defaultDoctorPolicy?: AllowDenyPolicyType;
  patientWriteButtonVisibilityPolicy?: AllowDenyPolicyType;
}

export const GlobalSearchDetailsCardDoctorAvatar = ({
  user,
  portalUserId,
  defaultDoctorPolicy,
  patientWriteButtonVisibilityPolicy,
}: IGlobalSearchDetailsCardDoctorAvatarProps): JSX.Element => {
  const { typography, palette } = useTheme();
  const { isDesktopView } = useBreakpoints();
  const {
    isBlockingModalOpened,
    isWriteToDoctorModalOpened,
    openBlockingModal,
    closeBlockingModal,
    handleWriteToDoctorButtonClick,
    closeWriteToDoctorModal,
  } = useDoctorModelWriteToDoctor({
    doctorPortalUserId: portalUserId,
  });
  const { isWriteToDoctorButtonVisible } = useDoctorModelWriteToDoctorButtonVisibility({
    isListing: true,
    doctorPortalUserId: portalUserId,
    defaultDoctorPolicy,
    patientWriteButtonVisibilityPolicy,
  });

  const avatarSize = isDesktopView ? 64 : 82;

  return (
    <Box sx={{ ...(!isDesktopView && { display: 'flex', justifyContent: 'center' }) }}>
      <UserAvatarInfo
        id={portalUserId}
        initials={getUserInitials(user)}
        name={getUserFullName(user)}
        avatarEntity={AvatarEntity.PORTAL_USER_ID}
        avatarWidth={avatarSize}
        avatarHeight={avatarSize}
        additionalInfoContent={
          isWriteToDoctorButtonVisible ? (
            <ChatUiWriteToButton
              label={i18n.t('features.chat.writeToButton.doctor')}
              onClick={handleWriteToDoctorButtonClick}
              sx={{
                width: 'fit-content',
                minWidth: 'unset',
                minHeight: 'unset',
                padding: '8px 24px',
                marginTop: '8px',
                '& svg': {
                  fontSize: '20px',
                },
                '& .MuiTypography-root': {
                  ...typography.button2,
                },
              }}
            />
          ) : undefined
        }
        sx={{
          '& .MuiAvatar-root': {
            ...typography.h5,
            // TODO: h unknown, maybe h4
            ...(!isDesktopView && { fontSize: '24px', lineHeight: '32px' }),
            margin: isDesktopView ? 0 : '0 0 4px',
          },
          '& .avatar-name': {
            ...(!isDesktopView && { maxWidth: '200px', textAlign: 'left' }),
            ...typography.bodyLink,
            color: palette.black.main,
          },
        }}
        withAvatar
      />
      <UnauthorizedUserChatMessageWriteToModalsSection
        isWriteToModalOpened={isWriteToDoctorModalOpened}
        closeWriteToModal={closeWriteToDoctorModal}
        isBlockingModalOpened={isBlockingModalOpened}
        openBlockingModal={openBlockingModal}
        closeBlockingModal={closeBlockingModal}
        doctorPortalUserId={portalUserId}
      />
    </Box>
  );
};
