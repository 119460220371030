import { PersistedDataKeys } from '@lib/core';

import { persistedData } from 'lib/helpers/persistedData';

export const initPersistedData: VoidFunction = () => {
  window.initPersistedData = ({ accessToken, refreshToken, expirationTime, activeProfileId, platform }): void => {
    persistedData.setMultiple({
      ...(accessToken && { [PersistedDataKeys.AccessToken]: accessToken }),
      ...(expirationTime && { [PersistedDataKeys.ExpirationTime]: expirationTime }),
      ...(refreshToken && { [PersistedDataKeys.RefreshToken]: refreshToken }),
      ...(activeProfileId && { [PersistedDataKeys.ActiveProfileId]: activeProfileId }),
      ...(platform && { [PersistedDataKeys.Platform]: platform }),
    });

    window.location.reload();
  };
};
