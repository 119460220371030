import { PortalLogoType, Portals, PortalsSponsors } from '@lib/features-bll';
import { PortalLogo } from '@lib/features-ui';
import { useBreakpoints } from '@lib/react-components';

import { MenuList } from '../MenuList';

import { useStyles } from './styles';

import { usePortal } from 'features/Portal';
import { IPortalSidebarItem } from 'graphql/types/portal';
import { useCheckIfPortalAllowed } from 'lib/hooks/useCheckIfPortalAllowed.hook';

interface IDrawerContent {
  menuListItem: IPortalSidebarItem[][];
  expanded: boolean;
  handleDrawerToggle?: VoidFunction;
}

const exceptedPortalsLogo = [Portals.WELCOMEDICINE, Portals.POLIMEDICO, Portals.ASTICLINIC];

export const DrawerContent = ({ menuListItem, expanded, handleDrawerToggle }: IDrawerContent): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { portal } = usePortal();
  const { checkIfPortalAllowed } = useCheckIfPortalAllowed();
  const { isMobileView } = useBreakpoints();

  const isPortalLogoExcepted = checkIfPortalAllowed(exceptedPortalsLogo);

  const logoType = expanded
    ? (isPortalLogoExcepted && !isMobileView && PortalLogoType.SMALL) || PortalLogoType.NORMAL
    : PortalLogoType.SMALL;

  return (
    <>
      <PortalLogo
        variant={logoType}
        classes={{
          icon: localClasses.svgNavLogoIconTop,
        }}
        logoUrl={portal?.logoUrl?.[logoType]}
      />
      <MenuList
        menuListItem={menuListItem}
        isExpanded={expanded}
        handleDrawerToggle={handleDrawerToggle}
        portalLogo={
          isMobileView ? (
            <PortalLogo
              variant={logoType}
              classes={{
                icon: localClasses.svgNavLogoIconBottom,
              }}
              logoBottomName={portal?.sidebarBottomLogoName as PortalsSponsors}
            />
          ) : undefined
        }
      />
      {!isMobileView ? (
        <PortalLogo
          variant={logoType}
          classes={{
            icon: localClasses.svgNavLogoIconBottom,
          }}
          logoBottomName={portal?.sidebarBottomLogoName as PortalsSponsors}
        />
      ) : undefined}
    </>
  );
};
