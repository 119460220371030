import { useBreakpoints } from '@lib/react-components';
import { Typography } from '@mui/material';

import { IGlobalSearchDetailsCardDoctor } from '../model';

import { DoctorUiSphereOfInterestList } from 'features/Doctor/ui';
import { i18n } from 'i18n';

type GlobalSearchDetailsCardSphereOfInterestProps = Pick<IGlobalSearchDetailsCardDoctor, 'sphereOfInterest'>;

export const GlobalSearchDetailsCardSphereOfInterest = ({
  sphereOfInterest,
}: GlobalSearchDetailsCardSphereOfInterestProps): JSX.Element => {
  const { isDesktopView } = useBreakpoints();

  return (
    <>
      <Typography variant="h8" component={isDesktopView ? 'span' : 'p'} mb={isDesktopView ? undefined : '4px'}>
        {i18n.t('general.labels.sphereOfInterest')}
      </Typography>
      <DoctorUiSphereOfInterestList sphereOfInterest={sphereOfInterest} />
    </>
  );
};
