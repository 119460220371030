import { PersistedDataKeys } from '@lib/core';
import { useModalState } from '@lib/react-components';
import { useEffect } from 'react';

import { IdentityVerificationMethod } from '__generated__/types';
import { useUserModelStore } from 'features/Users/model';
import { FeatureFlags, getFeatureFlags } from 'lib/helpers/featureFlags';
import { persistedData } from 'lib/helpers/persistedData';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';

interface IUseNativeProAppModalStateReturn {
  isNativeProAppModalAllowed: boolean;
  isSetupNativeProAppModalOpened: boolean;
  openSetupNativeProAppModal: VoidFunction;
  closeSetupNativeProAppModal: VoidFunction;
}

export const useRootModelNativeProAppModalState = (): IUseNativeProAppModalStateReturn => {
  const { activeDoctor } = useUserModelStore();

  const isMobileAppWebView = useIsMobileAppWebView();
  const [isSetupNativeProAppModalOpened, openSetupNativeProAppModal, closeSetupNativeProAppModal] = useModalState();

  const doctorWithPushNotificationVerification =
    activeDoctor?.doctor.identityVerificationMethod === IdentityVerificationMethod.PUSH_NOTIFICATION;
  const flagPushNotifications = getFeatureFlags().PushNotificationsFlowEnabled;
  const runtimePushNotificationPatch = localStorage.getItem(FeatureFlags.PushNotificationsFlowEnabled) === 'true';

  const isPushNotificationFlowEnabledDoctor =
    doctorWithPushNotificationVerification && (flagPushNotifications || runtimePushNotificationPatch);

  const isNativeProAppModalAllowed =
    !isMobileAppWebView &&
    !!activeDoctor &&
    !activeDoctor.hasPostRegister &&
    !isPushNotificationFlowEnabledDoctor &&
    !persistedData.get(PersistedDataKeys.NativeAppBannerShown);

  const closeModal: VoidFunction = () => {
    persistedData.set(PersistedDataKeys.NativeAppBannerShown, true);
    closeSetupNativeProAppModal();
  };

  useEffect(() => {
    if (!activeDoctor) {
      closeSetupNativeProAppModal();
    }
  }, [activeDoctor]);

  return {
    isNativeProAppModalAllowed,
    isSetupNativeProAppModalOpened,
    openSetupNativeProAppModal,
    closeSetupNativeProAppModal: closeModal,
  };
};
