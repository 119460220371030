import { GlobalSearchDetailsTotalCountsType } from '../api';
import { GlobalSearchDetailsTabType } from '../config';

export interface IGetGlobalSearchDetailsTabsConfigurationReturn {
  isDoctorTabEnabled: boolean;
  isAffiliatedDoctorTabEnabled: boolean;
  isClinicTabEnabled: boolean;
  isFreeClinicTabEnabled: boolean;
  tabsCountsByItems: Partial<Record<GlobalSearchDetailsTabType, number | undefined>>;
  priorityTab?: GlobalSearchDetailsTabType;
}

export const getGlobalSearchDetailsTabsConfiguration = (
  globalSearchDetails?: GlobalSearchDetailsTotalCountsType['globalSearchDetails']
): IGetGlobalSearchDetailsTabsConfigurationReturn => {
  const isDoctorTabEnabled = !!globalSearchDetails?.doctors;
  const isAffiliatedDoctorTabEnabled = !!globalSearchDetails?.affiliations;
  const isClinicTabEnabled = !!globalSearchDetails?.genericClinics;
  const isFreeClinicTabEnabled = !!globalSearchDetails?.freeClinics;

  const tabsCountsByItems = {
    ...(isDoctorTabEnabled && {
      [GlobalSearchDetailsTabType.PRIVATE_DOCTORS]: globalSearchDetails?.doctors.totalCount,
    }),
    ...(isAffiliatedDoctorTabEnabled && {
      [GlobalSearchDetailsTabType.AFFILIATED_DOCTORS]: globalSearchDetails?.affiliations?.totalCount,
    }),
    ...(isClinicTabEnabled && {
      [GlobalSearchDetailsTabType.CLINICS]: globalSearchDetails?.genericClinics?.totalCount,
    }),
    ...(isFreeClinicTabEnabled && {
      [GlobalSearchDetailsTabType.FREE_CLINICS]: globalSearchDetails?.freeClinics?.totalCount,
    }),
  };

  // First tab with results according to priority order
  const priorityTab = Object.entries(tabsCountsByItems).find(
    ([_, totalCount]) => totalCount
  )?.[0] as GlobalSearchDetailsTabType;

  return {
    isDoctorTabEnabled,
    isAffiliatedDoctorTabEnabled,
    isClinicTabEnabled,
    isFreeClinicTabEnabled,
    priorityTab,
    tabsCountsByItems,
  };
};
