import { Call, ICallPatient, IVolumes } from '@lib/features-bll';
import { CallEndIcon, MaterialSymbolsIcon, SettingsIcon } from '@lib/icons';
import { Modal, ModalSize } from '@lib/react-components';
import { FullNameFormat, getUserFullName } from '@lib/utils';
import { Avatar, IconButton, Stack, Typography, useTheme } from '@mui/material';

import { AudioLevelIndicator } from './AudioLevelIndicator';
import { CallDuration } from './CallDuration';
import { useStyles } from './styles';

import { Maybe } from '__generated__/types';

interface ICallControlsProps {
  areOptionsEnabled: boolean;
  onSettingsClick: VoidFunction;
  call: Call;
  isMuted: boolean;
  setCall: (call?: Call) => void;
  volumes: IVolumes;
  startedAt?: Date;
  patient?: ICallPatient;
}

export const CallControls = ({
  areOptionsEnabled,
  onSettingsClick,
  call,
  isMuted,
  volumes,
  startedAt,
  patient,
}: ICallControlsProps): Maybe<JSX.Element> => {
  const { classes: localClasses } = useStyles();
  const { palette } = useTheme();

  return (
    <Modal
      size={ModalSize.XS}
      classes={{ content: localClasses.controlsWrapper, draggableBar: localClasses.draggableBar }}
    >
      <Stack direction="row" gap="8px" alignItems="flex-start">
        <Avatar src={patient?.avatarUrl} />
        <Stack direction="column" flexGrow={1} display="flex" textAlign="left">
          <Typography variant="body3Medium">{getUserFullName(patient, FullNameFormat.FIRST_NAME_LAST_NAME)}</Typography>
          <CallDuration startedAt={startedAt} />
        </Stack>

        <Stack direction="row" gap="8px" p="4px" bgcolor="surface.generic" borderRadius="8px">
          <AudioLevelIndicator
            isMuted={isMuted}
            handleMute={(muted): void => call.mute(muted)}
            volume={volumes.inputVolume}
            size={20}
          />
          <IconButton
            onClick={(): void => {
              call.disconnect();
            }}
          >
            <MaterialSymbolsIcon fill={palette.red.main} fontSize={24} icon={<CallEndIcon fontWeight="300" />} />
          </IconButton>
          {areOptionsEnabled && (
            <IconButton onClick={onSettingsClick}>
              <MaterialSymbolsIcon fontSize={24} icon={<SettingsIcon fontWeight="300" />} />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};
