import { MedicalRoleModel } from '__generated__/types';

/**
 * Sort medical roles from most to less valuable
 *
 * @param medicalRoles unsorted list of medical roles
 * @returns a sorted list of medical roles
 */
export const sortMedicalRoles = (medicalRoles: MedicalRoleModel[]): MedicalRoleModel[] =>
  [...medicalRoles].sort((a: MedicalRoleModel, b: MedicalRoleModel): number => Math.sign(a.sortOrder - b.sortOrder));
