import { MaterialSymbolsIcon, PersonIcon } from '@lib/icons';
import { Avatar, useTheme } from '@mui/material';

import { unauthorizedUserRequestConfig } from '../config';

const { UNAUTHORIZED_HEADER_ACTION_AVATAR_SIZE } = unauthorizedUserRequestConfig;

interface IUnauthorizedUserRequestHeaderAvatarProps {
  onClick?: VoidFunction;
}

export const UnauthorizedUserRequestHeaderAvatar = ({
  onClick,
}: IUnauthorizedUserRequestHeaderAvatarProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Avatar
      onClick={onClick}
      sx={{
        width: `${UNAUTHORIZED_HEADER_ACTION_AVATAR_SIZE}px`,
        height: `${UNAUTHORIZED_HEADER_ACTION_AVATAR_SIZE}px`,
        backgroundColor: palette.surface.default,
        marginLeft: '6px',
      }}
    >
      <MaterialSymbolsIcon icon={<PersonIcon />} fontSize={32} />
    </Avatar>
  );
};
