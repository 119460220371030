import { FilterChips, IFilterChipItem, OnDeleteChipHandler } from '@lib/react-components';
import { SxProps, Theme, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface IGlobalSearchDetailsFilterChipsProps {
  filterChipItems: IFilterChipItem[];
  clearFilters?: VoidFunction;
  onDeleteChip: OnDeleteChipHandler;
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

export const GlobalSearchDetailsFilterChips = ({
  filterChipItems,
  children,
  clearFilters,
  onDeleteChip,
  sx,
}: IGlobalSearchDetailsFilterChipsProps): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <FilterChips
      sx={{
        '&.MuiBox-root': {
          marginBottom: 0,
        },
        '& .MuiChip-root': {
          height: 'unset',
          backgroundColor: palette.background.default,
          padding: '4px 2px 4px 16px',
          borderRadius: '10px',
        },
        '& .MuiChip-label': {
          ...typography.body3Medium,
          color: palette.generic.dark,
        },
        ...sx,
      }}
      filterChipItems={filterChipItems}
      clearFilters={clearFilters}
      onDeleteChip={onDeleteChip}
    >
      {children}
    </FilterChips>
  );
};
