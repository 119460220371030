import { useTheme } from '@mui/material';

interface IUseGetSearchListTabIconFillReturn {
  getSearchListTabIconFill: (isActiveTab: boolean) => string;
}

export const useGetSearchListTabIconFill = (): IUseGetSearchListTabIconFillReturn => {
  const theme = useTheme();

  const getSearchListTabIconFill = (isActiveTab: boolean): string =>
    isActiveTab ? theme.palette.primary.main : theme.palette.aux.disabled;

  return { getSearchListTabIconFill };
};
