import { PortalContextModel } from '@lib/core';
import { Portals } from '@lib/features-bll';
import { RouteProps } from 'react-router-dom';

import { ProfileType } from '__generated__/types';

export enum RouteGuardReturnType {
  Allowed = 'Allowed',
  Denied = 'Denied',
}

export type PortalSettingsGuard = (portal?: PortalContextModel) => RouteGuardReturnType;

export interface IRoute extends RouteProps {
  authorizedRoles?: ProfileType[];
  forbiddenAction?: boolean;
  autoSignOut?: boolean;
  portalSettingsGuards?: PortalSettingsGuard[];
}

export interface IPortalUrlParams {
  portalSlug: Portals;
}

export interface IReminderUrlParams {
  reminderId: string;
}

export interface ISurveyFormPageUrlParams {
  surveyId: string;
}
