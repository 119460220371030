import { FlexBox, PopoverItems, useModalState, useBreakpoints, MobileMenuModal } from '@lib/react-components';
import { Typography } from '@mui/material';

import { unAuthorizedUserRequestModel } from '../model';

import { UnauthorizedUserRequest } from 'features/Requests/components';

export const UnauthorizedUserRequestHeaderActionAvatar = (): JSX.Element => {
  const { isMobileView } = useBreakpoints();
  const [isModalOpened, openModal, closeModal] = useModalState();
  const actionItems = unAuthorizedUserRequestModel.useUnauthorizedHeaderActions();

  return isMobileView ? (
    <>
      <UnauthorizedUserRequest.Avatar onClick={openModal} />
      {isModalOpened && (
        <MobileMenuModal onCancel={closeModal}>
          <FlexBox flexDirection="column" gap="16px" px="36px">
            {actionItems.map(({ title, onClickHandler }) => (
              <Typography key={title.toString()} onClick={onClickHandler}>
                {title}
              </Typography>
            ))}
          </FlexBox>
        </MobileMenuModal>
      )}
    </>
  ) : (
    <PopoverItems items={actionItems} disableRipple>
      <UnauthorizedUserRequest.Avatar />
    </PopoverItems>
  );
};
