import { ROUTE_PATHS } from '@lib/core';
import { Portals } from '@lib/features-bll';
import { useBreakpoints } from '@lib/react-components';
import { matchPath, useLocation } from 'react-router-dom';

import { globalSearchDetailsModel } from 'features/GlobalSearchDetails';
import { usePortalSlug } from 'features/Portal';
import { rootConfig } from 'features/Root/config';

interface IUseUnauthorizedUsertHeaderControlsReturn {
  isSsnClinicLinkVisibleOnPageMobile: boolean;
  isGlobalSearchVisible: boolean;
}

export const useUnauthorizedUserHeaderControls = (): IUseUnauthorizedUsertHeaderControlsReturn => {
  const { pathname } = useLocation();
  const portalSlug = usePortalSlug();
  const { isMobileView } = useBreakpoints();
  const isSsnClinicAvailable = globalSearchDetailsModel.useSsnClinicAvailability();

  const isSsnClinicLinkVisibleOnPageMobile =
    !!matchPath(pathname, `/${portalSlug}${ROUTE_PATHS.search}`) && isSsnClinicAvailable && isMobileView;

  const isGlobalSearchVisible =
    !isMobileView &&
    portalSlug !== Portals.BRAVOMED &&
    !rootConfig.PUBLIC_HIDDEN_GLOBAL_SEARCH_ROUTES.some(currentRoute =>
      matchPath(pathname, `/${portalSlug}${currentRoute}`)
    );

  return {
    isSsnClinicLinkVisibleOnPageMobile,
    isGlobalSearchVisible,
  };
};
