import { useEffect, useRef, useState } from 'react';

import { parseCallDuration } from 'features/Calls/helpers';

interface IUseCallDurationProps {
  startedAt?: Date;
}
interface IUseCallDurationReturn {
  duration: string;
}
type UseCallDuration = (props: IUseCallDurationProps) => IUseCallDurationReturn;

export const useCallDuration: UseCallDuration = ({ startedAt }) => {
  const [duration, setDuration] = useState<string>('');
  const timerRef = useRef<number>();

  useEffect((): VoidFunction => {
    if (startedAt && !timerRef.current) {
      timerRef.current = window.setInterval(() => {
        setDuration(parseCallDuration(startedAt));
      }, 1000);
    }

    if (!startedAt && timerRef.current) {
      clearInterval(timerRef.current);
      setDuration('');
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        setDuration('');
      }
    };
  }, [startedAt]);

  return { duration };
};
