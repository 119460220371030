import { isFutureDate, isPreviousDate } from '../date';

import { DATE_VALID_LENGTH_REG_EXP } from 'lib/constants';

export const isDateValidFormat = (date: string): boolean => !!date?.match(DATE_VALID_LENGTH_REG_EXP);

export const isEvaluationDateValid = (date: string): boolean => !isFutureDate(date) && isDateValidFormat(date);

export const isFutureDateValid = (date: string): boolean => isFutureDate(date) && isDateValidFormat(date);

export const futureDateValidationHandler = (date: string): boolean => {
  if (isPreviousDate(date)) {
    return false;
  }

  return isDateValidFormat(date);
};
