import { ServiceCardType } from '@lib/react-components';

import { IService } from '../model';

import { Maybe, ServiceType } from '__generated__/types';

interface IServiceForPatientFiltering {
  isDisabled?: boolean;
  baseServiceType: ServiceCardType;
  patientBookingRequiresRequestProposal: boolean;
  allowsOnDemandSchedule?: Maybe<boolean>;
  earliestAvailabilityDate?: Maybe<string>;
}

interface IServiceForDoctorFiltering {
  doctorCanCreateRequestProposal: boolean;
}

export const canPatientCreateRequestForService = ({
  patientBookingRequiresRequestProposal,
  isDisabled,
  baseServiceType,
  earliestAvailabilityDate,
  allowsOnDemandSchedule,
}: Readonly<IServiceForPatientFiltering>): Readonly<boolean> => {
  if (patientBookingRequiresRequestProposal) {
    return false;
  }

  if (isDisabled) {
    return false;
  }

  if (baseServiceType === ServiceType.VIDEO_VISIT) {
    if (!allowsOnDemandSchedule && !earliestAvailabilityDate) {
      return false;
    }
  }

  return true;
};

export const filterServicesForPatients = (services: readonly IService[]): readonly IService[] =>
  services.filter(service => canPatientCreateRequestForService(service));

export const canDoctorProposeService = (service: Readonly<IServiceForDoctorFiltering>): Readonly<boolean> =>
  service.doctorCanCreateRequestProposal;

export const filterProposableServicesForDoctors = (services: readonly IService[]): readonly IService[] =>
  services.filter(service => canDoctorProposeService(service));
