import { Loader, useBreakpoints } from '@lib/react-components';
import { Box } from '@mui/material';
import { useRef, useState } from 'react';
import { Virtuoso, VirtuosoHandle, VirtuosoProps } from 'react-virtuoso';

import { GlobalSearchDetails, globalSearchDetailsModel } from 'features/GlobalSearchDetails';

interface IGlobalSearchDetailsListWrapperProps<T, C> extends Pick<VirtuosoProps<T, C>, 'data' | 'itemContent'> {
  listLoading: boolean;
  fetchMore: VoidFunction;
}

const SCROLL_TO_TOP_PAGE_OFFSET = -1000;

export const GlobalSearchDetailsTabWrapper = <T, C>({
  data,
  itemContent,
  listLoading,
  fetchMore,
}: IGlobalSearchDetailsListWrapperProps<T, C>): JSX.Element => {
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const [isStartListPosition, setStartListPosition] = useState(true);
  const { isDesktopView, isMobileView } = useBreakpoints();
  const { isFetchMoreAvailable, hasNoListResults } = globalSearchDetailsModel.useGlobalSearchDetailsListConfiguration({
    itemsLength: data?.length,
    listLoading,
  });

  return hasNoListResults ? (
    <GlobalSearchDetails.NoResult />
  ) : (
    <Box minHeight="100px" p="0 24px" pb={isDesktopView ? '14px' : '8px'}>
      <Virtuoso
        ref={virtuosoRef}
        atTopStateChange={setStartListPosition}
        endReached={isFetchMoreAvailable ? fetchMore : undefined}
        useWindowScroll
        data={data}
        itemContent={itemContent}
        components={{
          Item: GlobalSearchDetails.ListItemWrapper,
          Footer: isFetchMoreAvailable ? (): JSX.Element => <Loader /> : undefined,
        }}
      />
      {isMobileView && !isStartListPosition && (
        <GlobalSearchDetails.ScrollTopBottomButton
          onClick={(): void =>
            virtuosoRef.current?.scrollToIndex({
              index: 0,
              align: 'start',
              behavior: 'smooth',
              offset: SCROLL_TO_TOP_PAGE_OFFSET,
            })
          }
        />
      )}
    </Box>
  );
};
