export const TOP_NAVIGATION_OFFSET = 30;

export const ABSOLUTE_SCROLLING_OFFSET = 95;

// TODO: change it to dynamic top margin
export const TOP_ROOT_WRAPPER_OFFSET = 10;

export const BOTTOM_MARGIN = 25;

export const TOP_MARGIN_MOBILE = 90;

export const SSN_LINK_HEIGHT = 32;

export const LEFT_NAVIGATION_MOBILE_WIDTH = 250;
