import { defaultBorderRadius, useBreakpoints } from '@lib/react-components';
import { Box, Typography, useTheme } from '@mui/material';
import { Trans } from 'react-i18next';

import { globalSearchDetailsConfig } from '../config';
import { useGlobalSearchDetailsTabsConfiguration } from '../model';

import { i18n } from 'i18n';

export const GlobalSearchDetailsTabDescription = (): JSX.Element => {
  const { palette } = useTheme();
  const { isMobileView, isDesktopView } = useBreakpoints();
  const { activeTab } = useGlobalSearchDetailsTabsConfiguration();

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: palette.surface.light,
        padding: isMobileView ? '16px' : '12px 24px',
        borderRadius: isMobileView ? 0 : `0 ${defaultBorderRadius}px 0 0`,
        ...(isMobileView && {
          textAlign: 'center',
        }),
      }}
    >
      <Typography variant={isMobileView ? 'body2' : 'body1'} sx={{ ...(isDesktopView && { maxWidth: '75%' }) }}>
        <Trans
          i18n={i18n}
          i18nKey={globalSearchDetailsConfig.GLOBAL_SEARCH_DETAILS_TAB_DESCRIPTION[activeTab]}
          components={{
            strong: <Typography variant={isMobileView ? 'h9' : 'h8'} />,
          }}
        />
      </Typography>
    </Box>
  );
};
