import { BREAK_POINTS } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

const mobileModalHeaderHeight = 40;

export const useStyles = makeStyles()(theme => ({
  content: {
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      height: '90vh',
    },
  },

  contentBox: {
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: '1 1 auto',
    },
  },

  title: {
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      textAlign: 'left',
    },
  },

  secondTitle: {
    '&.MuiTypography-root.MuiTypography-body1': {
      [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
        textAlign: 'left',
      },
    },
  },

  titleBox: {
    marginBottom: 15,
  },

  loader: {
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      height: `calc(100% - ${mobileModalHeaderHeight}px)`,
    },
  },
}));
