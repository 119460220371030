import { useBreakpoints } from '@lib/react-components';
import { Button, Stack, Typography } from '@mui/material';

import {
  globalSearchDetailsModel,
  useGlobalSearchDetailsTabChangeHandler,
  useGlobalSearchDetailsTabsConfiguration,
} from 'features/GlobalSearchDetails';
import { i18n } from 'i18n';

export const GlobalSearchDetailsNoResult = (): JSX.Element => {
  const { isMobileView } = useBreakpoints();
  const { activeTab } = useGlobalSearchDetailsTabsConfiguration();
  const handleSearchTabChange = useGlobalSearchDetailsTabChangeHandler();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        padding: isMobileView ? '48px 26px' : (activeTab && '38px 24px 56px') || '56px 24px 32px',
        textAlign: 'center',
      }}
    >
      <Typography variant="h3" mb="16px">
        {globalSearchDetailsModel.getSearchListNoResultsTitle(activeTab)}
      </Typography>
      <Typography mb="32px">{i18n.t('features.search.noResult.label')}</Typography>
      <Button
        onClick={(): void => handleSearchTabChange(isMobileView ? undefined : activeTab, '')}
        sx={{ padding: '12px 44px' }}
      >
        <Typography variant="button1">{i18n.t('features.search.noResult.button')}</Typography>
      </Button>
    </Stack>
  );
};
