import { Typography } from '@mui/material';
import clsx from 'clsx';
import { FormikHandlers } from 'formik';
import { ReactNode } from 'react';

import { useStyles as useCheckboxesAreaStyles } from './styles';

import Checkbox from 'components/Checkbox/Checkbox';
import { useStyles as commonStyles } from 'components/Forms/FormStyles';
import { RegistrationCheckboxesAreaValues } from 'features/Registration/types';

interface IRegistrationUiCheckboxItem {
  label: keyof RegistrationCheckboxesAreaValues;
  title?: string;
  agreementText: string | ReactNode;
  errorMessage?: string;
  values: RegistrationCheckboxesAreaValues;
  handleChange: FormikHandlers['handleChange'];
}

export const RegistrationUiCheckboxItem = ({
  label,
  title,
  agreementText,
  errorMessage,
  values,
  handleChange,
}: IRegistrationUiCheckboxItem): JSX.Element => {
  const { classes: checkboxesAreaClasses } = useCheckboxesAreaStyles();
  const { classes: commonFormClasses } = commonStyles();

  return (
    <>
      {title && <Typography className={checkboxesAreaClasses.title}>{title}</Typography>}
      <div className={clsx(commonFormClasses.formFieldCheckbox, checkboxesAreaClasses.checkboxWrapper)}>
        <Checkbox onChange={handleChange} name={label} id={label} value={!!values[label]} />
        <label className={clsx(commonFormClasses.formFieldLabel, checkboxesAreaClasses.checkboxLabel)} htmlFor={label}>
          {agreementText}
        </label>
      </div>
      {errorMessage && (
        <Typography className={commonFormClasses.backendError} variant="caption">
          {errorMessage}
        </Typography>
      )}
    </>
  );
};
