import { LoginOrRegisterBlockingModalAuthorizedUserWithPatientModal } from './LoginOrRegisterBlockingModalAuthorizedUserWithPatientModal';
import { LoginOrRegisterBlockingModalButtonPrimary } from './LoginOrRegisterBlockingModalButtonPrimary';
import { LoginOrRegisterBlockingModalButtonSecondary } from './LoginOrRegisterBlockingModalButtonSecondary';
import { LoginOrRegisterBlockingModalSwitchToPatientModal } from './LoginOrRegisterBlockingModalSwitchToPatientModal';
import { LoginOrRegisterBlockingModalTitle } from './LoginOrRegisterBlockingModalTitle';
import { LoginOrRegisterBlockingModalUnauthorizedModal } from './LoginOrRegisterBlockingModalUnauthorizedModal';
import { LoginOrRegisterBlockingModalUserWithoutPatientModal } from './LoginOrRegisterBlockingModalUserWithoutPatientModal';
import { LoginOrRegisterBlockingModalWarningIcon } from './LoginOrRegisterBlockingModalWarningIcon';
import { LoginOrRegisterBlockingModalWrapper } from './LoginOrRegisterBlockingModalWrapper';

export const LoginOrRegisterBlockingModalContent = {
  ModalWrapper: LoginOrRegisterBlockingModalWrapper,
  WarningIcon: LoginOrRegisterBlockingModalWarningIcon,
  Title: LoginOrRegisterBlockingModalTitle,
  ButtonPrimary: LoginOrRegisterBlockingModalButtonPrimary,
  ButtonSecondary: LoginOrRegisterBlockingModalButtonSecondary,
  UnauthorizedUserModal: LoginOrRegisterBlockingModalUnauthorizedModal,
  AuthorizedWithPatientModal: LoginOrRegisterBlockingModalAuthorizedUserWithPatientModal,
  UserWithoutPatientModal: LoginOrRegisterBlockingModalUserWithoutPatientModal,
  SwitchToPatientModal: LoginOrRegisterBlockingModalSwitchToPatientModal,
};
