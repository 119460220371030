import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type UnauthorizedUserChatMessageApiCreateDirectMessageFromDraftVariables = Types.Exact<{
  draftDirectMessageId: Types.Scalars['String']['input'];
}>;


export type UnauthorizedUserChatMessageApiCreateDirectMessageFromDraft = { __typename?: 'Mutation', createDirectMessageFromDraft: { __typename?: 'ClinicChatModel', id: string } | { __typename?: 'OneToOneChatModel', id: string } };


export const UnauthorizedUserChatMessageApiCreateDirectMessageFromDraftDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UnauthorizedUserChatMessageApiCreateDirectMessageFromDraft"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"draftDirectMessageId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createDirectMessageFromDraft"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"draftDirectMessageId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"draftDirectMessageId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AbstractChatModel"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UnauthorizedUserChatMessageApiCreateDirectMessageFromDraftMutationFn = Apollo.MutationFunction<UnauthorizedUserChatMessageApiCreateDirectMessageFromDraft, UnauthorizedUserChatMessageApiCreateDirectMessageFromDraftVariables>;

/**
 * __useUnauthorizedUserChatMessageApiCreateDirectMessageFromDraft__
 *
 * To run a mutation, you first call `useUnauthorizedUserChatMessageApiCreateDirectMessageFromDraft` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnauthorizedUserChatMessageApiCreateDirectMessageFromDraft` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unauthorizedUserChatMessageApiCreateDirectMessageFromDraft, { data, loading, error }] = useUnauthorizedUserChatMessageApiCreateDirectMessageFromDraft({
 *   variables: {
 *      draftDirectMessageId: // value for 'draftDirectMessageId'
 *   },
 * });
 */
export function useUnauthorizedUserChatMessageApiCreateDirectMessageFromDraft(baseOptions?: ApolloReactHooks.MutationHookOptions<UnauthorizedUserChatMessageApiCreateDirectMessageFromDraft, UnauthorizedUserChatMessageApiCreateDirectMessageFromDraftVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UnauthorizedUserChatMessageApiCreateDirectMessageFromDraft, UnauthorizedUserChatMessageApiCreateDirectMessageFromDraftVariables>(UnauthorizedUserChatMessageApiCreateDirectMessageFromDraftDocument, options);
      }
export type UnauthorizedUserChatMessageApiCreateDirectMessageFromDraftHookResult = ReturnType<typeof useUnauthorizedUserChatMessageApiCreateDirectMessageFromDraft>;
export type UnauthorizedUserChatMessageApiCreateDirectMessageFromDraftMutationResult = Apollo.MutationResult<UnauthorizedUserChatMessageApiCreateDirectMessageFromDraft>;
export type UnauthorizedUserChatMessageApiCreateDirectMessageFromDraftMutationOptions = Apollo.BaseMutationOptions<UnauthorizedUserChatMessageApiCreateDirectMessageFromDraft, UnauthorizedUserChatMessageApiCreateDirectMessageFromDraftVariables>;