import { useBreakpoints } from '@lib/react-components';
import { SxProps, Theme, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

import { i18n } from 'i18n';

interface IGlobalSearchDetailsContactsLabelProps {
  sx?: SxProps<Theme>;
}

export const GlobalSearchDetailsContactsLabel = ({ sx }: IGlobalSearchDetailsContactsLabelProps): JSX.Element => {
  const { isMobileView } = useBreakpoints();

  return (
    <Typography variant={isMobileView ? 'body3' : 'body2'} sx={sx}>
      <Trans
        i18n={i18n}
        i18nKey="features.search.filterSection.helpToFind"
        components={{
          emailLink: (
            <Typography
              variant={isMobileView ? 'bodyLink3' : 'bodyLink2'}
              component="a"
              href="mailto:support@welmed.it"
              target="_blank"
              rel="noreferrer"
              color="black.main"
            />
          ),
          phoneLink: (
            <Typography
              color="black.main"
              variant={isMobileView ? 'bodyLink3' : 'bodyLink2'}
              component="a"
              href="tel:0282960009"
            />
          ),
        }}
      />
    </Typography>
  );
};
