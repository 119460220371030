import { getTimeDetails } from '@lib/features-bll';
import { IServiceAddonSectionProps, IServiceCardProps } from '@lib/features-ui';

import { ServiceAddonModel, ServiceModel } from '__generated__/types';

const mapAffiliationServiceAddonToProps = ({
  price,
  providedWithinDays,
  type,
}: ServiceAddonModel): IServiceAddonSectionProps => ({
  price,
  providedWithinDays: providedWithinDays || undefined,
  type,
});

export const mapAffiliationServiceToAffiliationServiceCardProps = (
  affiliationService: ServiceModel
): IServiceCardProps => ({
  serviceType: affiliationService.baseServiceType,
  title: affiliationService.title,
  description: affiliationService.description,
  price: affiliationService.price,
  timeDetails: getTimeDetails(
    affiliationService.baseServiceType,
    affiliationService.durationMinutes,
    affiliationService.providedWithinDays
  ),
  addons: affiliationService.addons.map(addon => mapAffiliationServiceAddonToProps(addon)),
  showIconAndDescription: true,
});
