import { Modal, ModalSize, StatusCard } from '@lib/react-components';
import { Button, Stack, Typography } from '@mui/material';

import { useStyles } from './styles';

import { i18n } from 'i18n';

interface IInfoModalProps {
  onCancel?: VoidFunction;
  onClick?: VoidFunction;
}

export const InfoModal = ({ onCancel, onClick }: IInfoModalProps): JSX.Element => {
  const { classes: localClasses } = useStyles();

  return (
    <Modal
      classes={{ contentBox: localClasses.contentBox }}
      size={ModalSize.SM}
      onCancel={onCancel}
      footer={
        <Stack flexDirection="row" gap="12px">
          <Button variant="contained" onClick={onClick}>
            {i18n.t('general.buttons.goToSettings')}
          </Button>
          <Button variant="secondary" onClick={onCancel}>
            {i18n.t('general.buttons.close')}
          </Button>
        </Stack>
      }
    >
      <Typography p="20px 0 16px" variant="h3">
        {i18n.t('features.patient.infoModal.title')}
      </Typography>
      <Typography pb="24px" variant="body1">
        {i18n.t('features.patient.infoModal.description')}
      </Typography>
      <StatusCard text={i18n.t('features.patient.infoModal.info')} />
    </Modal>
  );
};
