import { makeVar, useReactiveVar } from '@apollo/client';

export interface IDocument {
  base64String: string;
  text?: string;
  mimeType: string;
  fileName: string;
}

interface IDocumentStore {
  uploadedDocuments: IDocument[];
}

interface IDocumentStoreActions {
  setUploadedDocuments: (params: IDocumentStore['uploadedDocuments']) => void;
}

export type UseDocumentsStore = () => IDocumentStore & IDocumentStoreActions;

const uploadDocumentsVar = makeVar<IDocumentStore['uploadedDocuments']>([]);

export const setUploadedDocuments: IDocumentStoreActions['setUploadedDocuments'] = uploadDocumentsVar;

export const useDocumentsStore: UseDocumentsStore = () => {
  const uploadedDocuments = useReactiveVar(uploadDocumentsVar);

  return { uploadedDocuments, setUploadedDocuments };
};
