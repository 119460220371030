import { useEffect, useRef } from 'react';
import { v4 } from 'uuid';

const VIEWBOX_SIZE = 24;

const VOLUME_MAX_LEVEL = 21;

interface IVolumeLevelProps {
  volume: number;
  size?: number;
}

export const VolumeLevel = ({ volume, size = VIEWBOX_SIZE }: IVolumeLevelProps): JSX.Element => {
  const SVGRectRef = useRef<SVGRectElement>(null);
  const clipPathId = useRef<string>(`audio-level-clip-${v4()}`);

  useEffect(() => {
    const SVGClipElement = SVGRectRef.current;

    if (SVGClipElement) {
      SVGClipElement.setAttribute('y', String(VOLUME_MAX_LEVEL - volume * VOLUME_MAX_LEVEL));
    }

    return () => {
      if (SVGClipElement) {
        SVGClipElement.setAttribute('y', `${VOLUME_MAX_LEVEL}`);
      }
    };
  }, [volume]);

  return (
    <svg
      focusable="false"
      viewBox={`0 0 ${VIEWBOX_SIZE} ${VIEWBOX_SIZE}`}
      aria-hidden="true"
      height={`${size}px`}
      width={`${size}px`}
    >
      <defs>
        <clipPath id={clipPathId.current}>
          <rect ref={SVGRectRef} x="0" y={`${VOLUME_MAX_LEVEL}`} width={`${VIEWBOX_SIZE}`} height={`${VIEWBOX_SIZE}`} />
        </clipPath>
      </defs>
      <path
        fill="rgba(255, 255, 255, 0.1)"
        d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"
      />
      <path
        fill="#0c0"
        clipPath={`url(#${clipPathId.current})`}
        d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"
      />
    </svg>
  );
};
