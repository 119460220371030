import { CheckboxCheckedIcon, CheckboxUncheckedIcon, MaterialSymbolsIcon } from '@lib/icons';
import { Checkbox, FormHelperText, useTheme } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

import { useStyles } from './CheckboxStyles';

const ICON_FONT_SIZE = 28;

interface ICheckboxClasses {
  label?: string;
  checkbox?: string;
}

interface ICheckboxProps {
  value: boolean;
  name?: string;
  id?: string;
  disabled?: boolean;
  labelText?: string | ReactNode;
  errorText?: string | null;
  checkedIcon?: ReactNode;
  iconFontSize?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  classes?: ICheckboxClasses;
}

const CheckboxComponent = ({
  value,
  onChange,
  onBlur,
  id,
  checkedIcon,
  iconFontSize = ICON_FONT_SIZE,
  name,
  disabled,
  labelText,
  errorText,
  classes,
}: ICheckboxProps): JSX.Element => {
  const { palette } = useTheme();
  const { classes: localClasses } = useStyles();

  const uncheckedFill = errorText ? palette.error.main : palette.lines.main;

  return (
    <>
      <Checkbox
        className={clsx(localClasses.checkbox, classes?.checkbox)}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
        id={id}
        checkedIcon={
          checkedIcon || (
            <MaterialSymbolsIcon
              icon={<CheckboxCheckedIcon />}
              fill={disabled ? palette.background.default : palette.primary.main}
              fontSize={iconFontSize}
            />
          )
        }
        icon={
          <MaterialSymbolsIcon
            icon={<CheckboxUncheckedIcon />}
            fill={disabled ? palette.background.default : uncheckedFill}
            fontSize={iconFontSize}
          />
        }
        color="secondary"
        inputProps={{
          'aria-label': 'secondary checkbox',
        }}
        disableTouchRipple
      />
      {labelText && (
        <label htmlFor={id} className={classes && classes.label}>
          {labelText}
          {errorText && <FormHelperText className={localClasses.errorText}>{errorText}</FormHelperText>}
        </label>
      )}
    </>
  );
};

export default CheckboxComponent;
