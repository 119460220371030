import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type UpdateDoctorSphereOfInterestVariables = Types.Exact<{
  sphereOfInterest: Array<Types.Scalars['String']['input']>;
}>;


export type UpdateDoctorSphereOfInterest = { __typename?: 'Mutation', updateDoctorSphereOfInterest: { __typename?: 'DoctorModel', sphereOfInterest: Array<string> } };


export const UpdateDoctorSphereOfInterestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateDoctorSphereOfInterest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sphereOfInterest"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateDoctorSphereOfInterest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sphereOfInterest"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sphereOfInterest"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sphereOfInterest"}}]}}]}}]} as unknown as DocumentNode;
export type UpdateDoctorSphereOfInterestMutationFn = Apollo.MutationFunction<UpdateDoctorSphereOfInterest, UpdateDoctorSphereOfInterestVariables>;

/**
 * __useUpdateDoctorSphereOfInterest__
 *
 * To run a mutation, you first call `useUpdateDoctorSphereOfInterest` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoctorSphereOfInterest` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoctorSphereOfInterest, { data, loading, error }] = useUpdateDoctorSphereOfInterest({
 *   variables: {
 *      sphereOfInterest: // value for 'sphereOfInterest'
 *   },
 * });
 */
export function useUpdateDoctorSphereOfInterest(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDoctorSphereOfInterest, UpdateDoctorSphereOfInterestVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateDoctorSphereOfInterest, UpdateDoctorSphereOfInterestVariables>(UpdateDoctorSphereOfInterestDocument, options);
      }
export type UpdateDoctorSphereOfInterestHookResult = ReturnType<typeof useUpdateDoctorSphereOfInterest>;
export type UpdateDoctorSphereOfInterestMutationResult = Apollo.MutationResult<UpdateDoctorSphereOfInterest>;
export type UpdateDoctorSphereOfInterestMutationOptions = Apollo.BaseMutationOptions<UpdateDoctorSphereOfInterest, UpdateDoctorSphereOfInterestVariables>;