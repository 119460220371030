import { WebToNativeActions } from '@lib/features-bll';

export interface INativeAction<T = Record<string, unknown>> {
  action: WebToNativeActions;
  parameters: T;
}

interface IPlatformBasedActionParameters {
  webAction: VoidFunction;
  nativeAction: INativeAction;
}

export enum PlatformBasedActionReturnType {
  WEB_ACTION = 'web_action',
  NATIVE_ACTION = 'native_action',
}

export type PlatformBasedAction = (_: IPlatformBasedActionParameters) => PlatformBasedActionReturnType;
