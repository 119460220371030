import { ApolloQueryResult } from '@apollo/client';
import { DEFAULT_ROWS_PER_PAGE_VALUE } from '@lib/react-components';
import { useMemo } from 'react';

import { GlobalSearchHitType, Maybe, PaginationModel, QueryglobalSearchDetailsArgs } from '__generated__/types';
import { globalSearchDetailsModel } from 'features/GlobalSearchDetails';

interface IUseGlobalSearchDetailsQueryConfigurationReturn<T> {
  globalSearchVariables: QueryglobalSearchDetailsArgs;
  fetchMoreWithPagination: (
    fetchMoreCallback: (newPagination: PaginationModel) => Promise<ApolloQueryResult<T>>,
    itemsLength?: number
  ) => Promise<void>;
}

export const useGlobalSearchDetailsQueryConfiguration = <T>(): IUseGlobalSearchDetailsQueryConfigurationReturn<T> => {
  const { filters, search: globalSearch, hitType } = globalSearchDetailsModel.useGlobalSearchFilters();
  const { order } = globalSearchDetailsModel.useGlobalSearchDetailsOrderFilter();
  const globalSearchVariables = useMemo(
    () => ({
      filters: {
        globalSearch,
        hitType: hitType as Maybe<GlobalSearchHitType | undefined>,
        specificSearch: filters.specificSearch,
        serviceTypes: filters.serviceTypes,
        specializations: filters.specializations,
        videoVisitAvailability: filters.videoVisitAvailability,
        affiliationIds: filters.affiliationIds,
        patientCanWriteOneToOneChatMessage: filters.patientCanWriteOneToOneChatMessage,
      },
      pagination: {
        page: 1,
        numberOfRecords: DEFAULT_ROWS_PER_PAGE_VALUE,
      },
      order,
    }),
    [filters, order, globalSearch, hitType]
  );

  const fetchMoreWithPagination: IUseGlobalSearchDetailsQueryConfigurationReturn<T>['fetchMoreWithPagination'] = async (
    fetchMoreCallback,
    itemsLength = 0
  ) => {
    const currentPage = Math.ceil(itemsLength / DEFAULT_ROWS_PER_PAGE_VALUE);
    await fetchMoreCallback({
      page: currentPage + 1,
      numberOfRecords: DEFAULT_ROWS_PER_PAGE_VALUE,
    });
  };

  return {
    fetchMoreWithPagination,
    globalSearchVariables,
  };
};
