import { makeVar, useReactiveVar } from '@apollo/client';
import {
  RegistrationApiConfirmPasswordViaRegistrationVariables,
  RegistrationApiRegisteringUser,
  i18n,
  useRegistrationApiConfirmPassword,
  useRegistrationApiConfirmPasswordViaRegistration,
} from '@lib/features-bll';

import { CodeDeliveryDetailsModel, Maybe, UserRegistrationStatusCheckDecision } from '__generated__/types';
import { updatePersistedAuthorizationData } from 'authorization/helper';
import { isError } from 'authorization/types';
import { Hint, RegisterUserPersonalInformationField, RegistrationErrors } from 'features/Registration/types';
import { useUserModelStore } from 'features/Users/model';
import { errorCreator } from 'lib/helpers';

type RegistrationModelStore = {
  isRegistering: boolean;
  registrationHint: Maybe<Hint>;
  registrationErrors: RegistrationErrors;
  registrationStatus: Maybe<UserRegistrationStatusCheckDecision>;
  hiddenUsername: Maybe<string>;
  hiddenPasswordAttributes: Maybe<CodeDeliveryDetailsModel>;
  registerUserFields: Maybe<RegistrationApiRegisteringUser>;
  registerUserPersonalInformationsFields: Maybe<RegisterUserPersonalInformationField>;
};

type ConfirmPasswordParams = {
  variables: RegistrationApiConfirmPasswordViaRegistrationVariables;
  onSuccess?: VoidFunction;
  registerStatus: UserRegistrationStatusCheckDecision;
  callVerifyEmailHandler: () => Promise<void>;
};
type RegistrationModelActions = {
  setIsRegistering: (registrationHint: RegistrationModelStore['isRegistering']) => void;
  setRegistrationHint: (registrationHint: RegistrationModelStore['registrationHint']) => void;
  setRegistrationErrors: (registrationErrors: RegistrationModelStore['registrationErrors']) => void;
  resetRegistrationErrors: VoidFunction;
  resetEmailErrors: VoidFunction;
  resetPhoneErrors: VoidFunction;
  confirmPassword: (params: ConfirmPasswordParams) => Promise<void>;
  setRegistrationStatus: (registrationStatus: RegistrationModelStore['registrationStatus']) => void;
  setHiddenUsername: (username: RegistrationModelStore['hiddenUsername']) => void;
  setHiddenPasswordAttributes: (username: RegistrationModelStore['hiddenPasswordAttributes']) => void;
  setRegisterUserFields: (registerUserFields: RegistrationModelStore['registerUserFields']) => void;
  setRegisterUserPersonalInformationsFields: (
    registerUserPersonalInformationsFields: RegistrationModelStore['registerUserPersonalInformationsFields']
  ) => void;
};

type UseRegistrationModelStore = RegistrationModelStore & RegistrationModelActions;

const isRegisteringVar = makeVar<RegistrationModelStore['isRegistering']>(false);
const registrationHintVar = makeVar<RegistrationModelStore['registrationHint']>(null);
const registrationStatusVar = makeVar<RegistrationModelStore['registrationStatus']>(null);

const initialErrors = { notUniqueEmail: false, notUniquePhone: false, notValidPhone: false };
const registrationErrorsVar = makeVar<RegistrationModelStore['registrationErrors']>(initialErrors);

const hiddenUsernameVar = makeVar<RegistrationModelStore['hiddenUsername']>(null);
const hiddenPasswordAttributesVar = makeVar<RegistrationModelStore['hiddenPasswordAttributes']>(null);
const registerUserFieldsVar = makeVar<RegistrationModelStore['registerUserFields']>(null);
const registerUserPersonalInformationsFieldsVar =
  makeVar<RegistrationModelStore['registerUserPersonalInformationsFields']>(null);

const setIsRegistering: RegistrationModelActions['setIsRegistering'] = isRegisteringVar;
const setRegistrationHint: RegistrationModelActions['setRegistrationHint'] = registrationHintVar;
const setRegistrationErrors: RegistrationModelActions['setRegistrationErrors'] = registrationErrorsVar;
const setRegistrationStatus: RegistrationModelActions['setRegistrationStatus'] = registrationStatusVar;
const setHiddenUsername: RegistrationModelActions['setHiddenUsername'] = hiddenUsernameVar;
const setHiddenPasswordAttributes: RegistrationModelActions['setHiddenPasswordAttributes'] =
  hiddenPasswordAttributesVar;
const setRegisterUserFields: RegistrationModelActions['setRegisterUserFields'] = registerUserFieldsVar;
const setRegisterUserPersonalInformationsFields: RegistrationModelActions['setRegisterUserPersonalInformationsFields'] =
  registerUserPersonalInformationsFieldsVar;

export const useRegistrationModelStore = (): UseRegistrationModelStore => {
  const { setUserError } = useUserModelStore();
  const isRegistering = useReactiveVar(isRegisteringVar);
  const registrationHint = useReactiveVar(registrationHintVar);
  const registrationErrors = useReactiveVar(registrationErrorsVar);
  const registrationStatus = useReactiveVar(registrationStatusVar);

  const hiddenUsername = useReactiveVar(hiddenUsernameVar);
  const hiddenPasswordAttributes = useReactiveVar(hiddenPasswordAttributesVar);
  const registerUserFields = useReactiveVar(registerUserFieldsVar);
  const registerUserPersonalInformationsFields = useReactiveVar(registerUserPersonalInformationsFieldsVar);

  const resetRegistrationErrors: RegistrationModelActions['resetRegistrationErrors'] = () =>
    setRegistrationErrors(initialErrors);

  const resetEmailErrors: RegistrationModelActions['resetEmailErrors'] = () => {
    setRegistrationErrors({ ...registrationErrors, notUniqueEmail: false });
  };

  const resetPhoneErrors: RegistrationModelActions['resetPhoneErrors'] = () => {
    setRegistrationErrors({ ...registrationErrors, notUniquePhone: false, notValidPhone: false });
  };

  const [confirmPasswordMutation] = useRegistrationApiConfirmPassword();
  const [confirmPasswordViaRegistrationMutation] = useRegistrationApiConfirmPasswordViaRegistration();

  const confirmPassword: RegistrationModelActions['confirmPassword'] = async ({
    variables,
    registerStatus,
    onSuccess,
    callVerifyEmailHandler,
  }) => {
    setIsRegistering(true);

    try {
      try {
        if (
          registerStatus === UserRegistrationStatusCheckDecision.PROCEED_TO_PASSWORD_CONFIRMATION_AND_EMAIL_VERIFICATION
        ) {
          const { data } = await confirmPasswordMutation({ variables });

          if (!data) {
            setIsRegistering(false);

            setUserError(
              errorCreator({
                message: i18n.t('components.forms.forgotPasswordForm.validationError.invalidPassword'),
              })
            );

            return;
          }

          updatePersistedAuthorizationData(data.confirmPassword);
        } else {
          const { data } = await confirmPasswordViaRegistrationMutation({ variables });

          if (data?.confirmPasswordViaRegistration)
            updatePersistedAuthorizationData(data?.confirmPasswordViaRegistration);
        }
      } catch (error) {
        setUserError(errorCreator({ message: i18n.t('errors.user.signingIn') }));
        setIsRegistering(false);
      }

      try {
        await callVerifyEmailHandler();
      } catch {
        setUserError(errorCreator({ message: i18n.t('errors.user.emailVerification') }));
        setIsRegistering(false);

        return;
      }

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (isError(error)) {
        setUserError(error);
      }

      setIsRegistering(false);
    }

    setIsRegistering(false);
  };

  return {
    isRegistering,
    setIsRegistering,
    registrationHint,
    setRegistrationHint,
    registrationStatus,
    setRegistrationStatus,
    registrationErrors,
    setRegistrationErrors,
    resetRegistrationErrors,
    resetEmailErrors,
    resetPhoneErrors,
    confirmPassword,
    hiddenUsername,
    setHiddenUsername,
    hiddenPasswordAttributes,
    setHiddenPasswordAttributes,
    registerUserFields,
    setRegisterUserFields,
    registerUserPersonalInformationsFields,
    setRegisterUserPersonalInformationsFields,
  };
};
