import { DeepPartial } from '@lib/utils';

import { usePortal } from './usePortal';

import { PortalConfigurationModel } from '__generated__/types';

export const usePortalConfiguration = (): DeepPartial<PortalConfigurationModel> | undefined => {
  const { portal } = usePortal();

  return portal?.configuration;
};
