import { ActiveProfileContext } from '@lib/core';
import { useContext, useEffect } from 'react';

import { Maybe } from '__generated__/types';
import { useUserModelStore } from 'features/Users/model';

/**
 * This component is used like an adapter between redux store and core Active Profile context
 * We should move from action creators from redux and use core contexts directly
 * Then this Adapter can be removed
 */
export const ActiveProfileProviderAdapter = (): Maybe<JSX.Element> => {
  const { activeProfile } = useUserModelStore();
  const { setActiveProfile } = useContext(ActiveProfileContext);

  useEffect(() => {
    setActiveProfile(activeProfile as Parameters<typeof setActiveProfile>[0]);
  }, [activeProfile]);

  return null;
};
