import { ROUTE_PATHS } from '@lib/core';
import { WebToNativeActions } from '@lib/features-bll';

import { SearchParams, useRootModelPath } from 'features/Root/model';
import { platformBasedAction, createNativeAction } from 'lib/helpers/platform';

type UseLoginModelLoginLinksReturnParams = SearchParams & {
  redirectForApp?: string;
  path?: string;
  pathParams?: Record<string, string>;
};
type UseLoginModelLoginLinksReturn = (params: UseLoginModelLoginLinksReturnParams) => VoidFunction;
type UseLoginModelLoginLinks = () => UseLoginModelLoginLinksReturn;

export const useLoginModelLoginLinks: UseLoginModelLoginLinks = () => {
  const { makeGoToPath, getPath } = useRootModelPath();

  return ({ redirectForApp, path = ROUTE_PATHS.loginByPortal, ...otherParams }) =>
    () =>
      platformBasedAction({
        webAction: makeGoToPath({
          path,
          ...otherParams,
        }),
        nativeAction: createNativeAction(WebToNativeActions.login, {
          redirect: redirectForApp ? getPath({ path: redirectForApp, ...otherParams }) : undefined,
        }),
      });
};
