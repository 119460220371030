import { NotificationsContext } from '@lib/core';
import { Notification } from '@lib/react-components';
import { ReactNode } from 'react';

export const NotificationsConsumer = (): JSX.Element => (
  <NotificationsContext.Consumer>
    {({ notifications, notificationsOffset, closeNotification }): ReactNode =>
      notifications.map(({ id, message, type, ref }, index) => (
        <Notification
          id={id}
          key={id}
          ref={ref}
          label={message}
          type={type}
          offset={notificationsOffset[index]}
          onClose={closeNotification}
        />
      ))
    }
  </NotificationsContext.Consumer>
);
