import { Reducer } from 'redux';

import { Action, ActionTypeList, initialState, IState } from './types';

export const attachmentsReducer: Reducer<IState, Action> = (state: IState = initialState, action) => {
  switch (action.type) {
    case ActionTypeList.SET_ATTACHMENTS_TO_UPLOAD:
      return {
        ...state,
        attachmentsToUpload: action.payload.attachmentsToUpload,
      };

    case ActionTypeList.REMOVE_ATTACHMENTS_TO_UPLOAD:
      return {
        ...state,
        attachmentsToUpload: null,
      };

    case ActionTypeList.ADD_ATTACHMENT_TO_LIST:
      return {
        ...state,
        attachmentsToUpload: [...(state.attachmentsToUpload || []), action.payload.attachmentToUpload],
      };

    case ActionTypeList.ADD_ATTACHMENTS_TO_LIST:
      return {
        ...state,
        attachmentsToUpload: [...(state.attachmentsToUpload || []), ...action.payload.attachmentsToUpload],
      };

    case ActionTypeList.REMOVE_ATTACHMENT_FROM_LIST:
      return {
        ...state,
        attachmentsToUpload:
          state.attachmentsToUpload && state.attachmentsToUpload.filter((_, index) => action.payload.index !== index),
      };

    case ActionTypeList.UPDATE_ATTACHMENT_IN_LIST:
      return {
        ...state,
        attachmentsToUpload:
          state.attachmentsToUpload &&
          state.attachmentsToUpload.map((attachment, index) =>
            index === action.payload.attachmentToUpdateIndex
              ? { ...attachment, ...action.payload.newParameters }
              : attachment
          ),
      };

    case ActionTypeList.UPDATE_ATTACHMENT_BY_FILE_ID:
      return {
        ...state,
        attachmentsToUpload:
          state.attachmentsToUpload &&
          state.attachmentsToUpload.map(attachment =>
            attachment.fileId === action.payload.fileId
              ? { ...attachment, ...action.payload.newParameters }
              : attachment
          ),
      };

    default:
      return state;
  }
};
