import { ROUTE_PATHS } from '@lib/core';
import qs from 'qs';

import { IDoctorClinicsQueryFilters } from 'features/Clinics/components/DoctorClinicsList/DoctorClinicsFilters/types';

const reservationQueryFilters: IDoctorClinicsQueryFilters = {
  specializations: null,
  types: null,
  showBookableForPatient: 'true',
  showAllClinics: null,
  withHelpTitle: 'true',
};

export const RESERVATION_QUERY_PARAMS = qs.stringify(reservationQueryFilters, {
  addQueryPrefix: true,
  skipNulls: true,
});

export const PUBLIC_HIDDEN_GLOBAL_SEARCH_ROUTES = [ROUTE_PATHS.clinic, ROUTE_PATHS.freeClinic, ROUTE_PATHS.doctor];

export const HIDDEN_GLOBAL_SEARCH_ROUTES = [
  ROUTE_PATHS.dashboard,
  ROUTE_PATHS.chatCreation,
  ROUTE_PATHS.chatOneToOne,
  ROUTE_PATHS.chatClinic,
  ROUTE_PATHS.affiliatedDoctor,
  ...PUBLIC_HIDDEN_GLOBAL_SEARCH_ROUTES,
];
