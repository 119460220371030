const SPECIFIC_DAY_OF_MONTH = '3';
const dateRegExps = [
  [/[0-3]/, /\d/, '/', /[0-1]/, /[0-2]/, '/', /\d/, /\d/, /\d/, /\d/],
  [/[0-3]/, /\d/, '/', /[0-1]/, /[13456789]/, '/', /\d/, /\d/, /\d/, /\d/],
  [/[0-3]/, /[0-1]/, '/', /[0-1]/, /[013456789]/, '/', /\d/, /\d/, /\d/, /\d/],
  [/[0-3]/, /\d/, '/', /[0-1]/, /[013456789]/, '/', /\d/, /\d/, /\d/, /\d/],
  [/[0-3]/, /\d/, '/', /[1]/, /[02]/, '/', /\d/, /\d/, /\d/, /\d/],
  [/[0-3]/, /\d/, '/', /[0]/, /[13578]/, '/', /\d/, /\d/, /\d/, /\d/],
  [/[0-3]/, /\d/, '/', /[0-1]/, /[1-9]/, '/', /\d/, /\d/, /\d/, /\d/],
  [/[0-3]/, /\d/, '/', /[0-1]/, /[1-9]/, '/', /\d/, /\d/, /\d/, /\//],
];

const bpRegExps = [
  [/\d/, /\d/, '/', /\d/, /\d/],
  [/\d/, /\d/, '/', /\d/, /\d/, /\d/],
  [/\d/, /\d/, /\d/, '/', /\d/, /\d/],
  [/\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/],
];

const isYearLeap = (leap: string) => {
  const integerYear = Number.parseInt(leap, 10);

  return integerYear ? integerYear % 400 === 0 || (integerYear % 100 !== 0 && integerYear % 4 === 0) : true;
};

const monthCheck = (month: string, day: string, year: string) => {
  if (month && month.length && month[0] === '1') {
    if (day[1] === '1' && day[0] === '3') {
      return dateRegExps[4];
    }

    return dateRegExps[0];
  }

  if (month && month.length && month[0] === '0') {
    if (day <= '29') {
      if (day === '29' && month[1] === '2') {
        if (!isYearLeap(year)) {
          return dateRegExps[7];
        }
      }

      return dateRegExps[6];
    }

    if (day[1] === '1') {
      return dateRegExps[5];
    }

    return dateRegExps[1];
  }

  return undefined;
};

export const dateMask = (input: string) => {
  const replacedInput = input.replace(/[/_]/g, '');

  const parsedInput = replacedInput.match(/(\d{1,2})(\d{1,2})?(\d{4})?/);

  if (parsedInput) {
    const day = parsedInput[1];
    const month = parsedInput[2];
    const year = parsedInput[3];
    const checkedMonth = monthCheck(month, day, year);

    if (day && day.length && day[0] === SPECIFIC_DAY_OF_MONTH) {
      if (checkedMonth) {
        return checkedMonth;
      }

      return dateRegExps[2];
    }

    if (checkedMonth) {
      return checkedMonth;
    }
  }

  return dateRegExps[3];
};

export const bpMask = (input: string) => {
  const insertedValues = input.replace(/[_]/g, '').split('/');
  const MIN_BP_VALUE = 35;
  const twoNumberSecondValue = insertedValues.length > 1 && insertedValues[1].length < 3;

  if (+insertedValues[0] > MIN_BP_VALUE && insertedValues[0].length < 3) {
    return twoNumberSecondValue ? bpRegExps[0] : bpRegExps[1];
  }

  return twoNumberSecondValue ? bpRegExps[2] : bpRegExps[3];
};

export const otpMask = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
