import { getClinicTabData } from './getClinicTabData';
import { getDoctorTabData } from './getDoctorTabData';
import { getEarliestAvailabilityDate } from './getEarliestAvailabilityDate';
import { getSearchListNoResultsTitle } from './getSearchListNoResultsTitle';
import { useGetSearchListTabIconFill } from './useGetSearchListTabIconFill';
import { useGlobalSearch } from './useGlobalSearch';
import { useGlobalSearchDetailsAccordionScrollEffect } from './useGlobalSearchDetailsAccordionScrollEffect';
import { useGlobalSearchDetailsAffiliationIdsChipItems } from './useGlobalSearchDetailsAffiliationIdsChipItems';
import { useGlobalSearchDetailsAffiliationIdsFilterData } from './useGlobalSearchDetailsAffiliationIdsFilterData';
import { useGlobalSearchDetailsAffiliations } from './useGlobalSearchDetailsAffiliations';
import { useGlobalSearchDetailsDoctors } from './useGlobalSearchDetailsDoctors';
import { useGlobalSearchDetailsDoctorWriteMeFilterAvailability } from './useGlobalSearchDetailsDoctorWriteMeFilterAvailability';
import { useGlobalSearchDetailsFilterChipItems } from './useGlobalSearchDetailsFilterChipItems';
import { useGlobalSearchDetailsFreeClinics } from './useGlobalSearchDetailsFreeClinics';
import { useGlobalSearchDetailsGenericClinics } from './useGlobalSearchDetailsGenericClinics';
import { useGlobalSearchDetailsListConfiguration } from './useGlobalSearchDetailsListConfiguration';
import { useGlobalSearchDetailsListLoadings } from './useGlobalSearchDetailsListLoadings';
import { useGlobalSearchDetailsOrderFilter } from './useGlobalSearchDetailsOrderFilter';
import { useGlobalSearchDetailsQueryConfiguration } from './useGlobalSearchDetailsQueryConfiguration';
import { useGlobalSearchDetailsSetAccordionsWrapperMinHeightEffect } from './useGlobalSearchDetailsSetAccordionsWrapperMinHeightEffect';
import { useGlobalSearchFilters } from './useGlobalSearchFilters';
import { useHasAvailableSsnClinic } from './useHasAvailableSsnClinic';
import { useResultSearchDetailsTitle } from './useResultSearchDetailsTitle';
import { useSsnClinicAvailability } from './useSsnClinicAvailability';

export { useGlobalSearchDetailsTotalCounts } from './useGlobalSearchDetailsTotalCounts';

export { useGlobalSearchDetailsTabsConfiguration } from './useGlobalSearchDetailsTabsConfiguration';

export { getGlobalSearchDetailsTabsConfiguration } from './getGlobalSearchDetailsTabsConfiguration';

export { useGlobalSearchDetailsTabRedirectsEffect } from './useGlobalSearchDetailsTabRedirectsEffect';

export { useGlobalSearchDetailsTabChangeHandler } from './useGlobalSearchDetailsTabChangeHandler';

export type {
  IGlobalSearchDetailsCardDoctor,
  IGlobalSearchDetailsCardService,
  IGlobalSearchDetailsDoctorTabCard,
  IGlobalSearchDetailsClinicTabCard,
  GlobalSearchDetailsClinicItems,
  IUseGlobalSearchDetailsGenericReturn,
} from './types';

export const globalSearchDetailsModel = {
  getDoctorTabData,
  getClinicTabData,
  getEarliestAvailabilityDate,
  getSearchListNoResultsTitle,
  useGlobalSearch,
  useGlobalSearchDetailsListConfiguration,
  useGetSearchListTabIconFill,
  useGlobalSearchFilters,
  useResultSearchDetailsTitle,
  useGlobalSearchDetailsFilterChipItems,
  useSsnClinicAvailability,
  useGlobalSearchDetailsSetAccordionsWrapperMinHeightEffect,
  useGlobalSearchDetailsAccordionScrollEffect,
  useGlobalSearchDetailsOrderFilter,
  useGlobalSearchDetailsAffiliationIdsFilterData,
  useGlobalSearchDetailsAffiliationIdsChipItems,
  useGlobalSearchDetailsDoctorWriteMeFilterAvailability,
  useGlobalSearchDetailsQueryConfiguration,
  useGlobalSearchDetailsDoctors,
  useGlobalSearchDetailsAffiliations,
  useGlobalSearchDetailsFreeClinics,
  useGlobalSearchDetailsGenericClinics,
  useGlobalSearchDetailsListLoadings,
  useHasAvailableSsnClinic,
};

export { useGlobalSearchModelStore } from './useGlobalSearchModelStore';
