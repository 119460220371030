import { Bubble, FlexBox, useBreakpoints } from '@lib/react-components';
import { IdStrings } from '@lib/utils';
import { Box, Stack, Typography } from '@mui/material';
import { isMobile, isTablet } from 'react-device-detect';
import { Trans } from 'react-i18next';

import { useStyles } from './styles';

import { Maybe, ProfileType } from '__generated__/types';
import AppStore from 'assets/images/AppStore.png';
import GooglePlay from 'assets/images/GooglePlay.png';
import { useUserModelNativeAppConfiguration } from 'features/Users/model';
import { i18n } from 'i18n';

export interface ISetupNativeAppSectionProps {
  // Remove when legacy OTP flow will be eliminated
  isUsingLegacyOtp?: boolean;
  isShownOnLogin?: boolean;
  hideSplash?: boolean;
  profileType: ProfileType;
}

export const SetupNativeAppSection = ({
  isUsingLegacyOtp,
  isShownOnLogin,
  hideSplash,
  profileType,
}: ISetupNativeAppSectionProps): Maybe<JSX.Element> => {
  const { classes: localClasses } = useStyles();
  const { isDesktopView, isMobileView } = useBreakpoints();

  const configuration = useUserModelNativeAppConfiguration({ isUsingLegacyOtp, isShownOnLogin, profileType });

  if (!configuration) {
    return null;
  }

  const onStoreIconClick: (urlType: 'appStoreUrl' | 'googlePlayUrl') => VoidFunction = urlType => () => {
    const url = configuration[urlType];

    if (url) {
      window.location.href = url;
    }
  };

  const { title, sectionLabel, downloadLabel, img, qrCode } = configuration;

  return (
    <>
      {(isUsingLegacyOtp || isShownOnLogin) && (
        <Bubble label={i18n.t('general.labels.novelty')} color="tealishBlue" classes={{ root: localClasses.bubble }} />
      )}
      <Stack>
        <Stack
          alignItems="center"
          justifyContent={isMobileView ? 'flex-start' : 'center'}
          textAlign={isMobileView ? 'left' : 'center'}
        >
          <Typography
            width={1}
            variant="h6"
            marginBottom={isDesktopView ? 1.5 : 1}
            marginTop={!isUsingLegacyOtp && isMobileView ? 0.5 : undefined}
          >
            <Trans i18n={i18n} i18nKey={title} />
          </Typography>
          <Typography variant="info" marginBottom={isDesktopView ? 1.5 : (isMobileView && 4.5) || 1}>
            {sectionLabel}
          </Typography>
        </Stack>
        <FlexBox
          alignItems="center"
          justifyContent={isMobileView ? 'space-around' : 'center'}
          flexDirection={isMobileView ? 'column-reverse' : 'row'}
          gap={isMobileView ? 7.75 : (isDesktopView && 2) || 4.75}
          marginBottom={isMobileView ? undefined : 6}
        >
          {!hideSplash && (
            <Box
              sx={{
                objectFit: 'contain',
                width: isMobileView ? '343px' : '247px',
                height: isMobileView ? '335px' : '241px',
              }}
              component="img"
              src={img}
            />
          )}
          <FlexBox
            width={1}
            alignItems="center"
            justifyContent="center"
            flexDirection={isMobileView ? 'row' : 'column'}
          >
            {isMobile || isTablet ? (
              <FlexBox flexDirection={isMobileView ? 'row' : 'column'} gap={isMobile ? 1.75 : 2.5}>
                <Box
                  id={`${
                    profileType === ProfileType.DOCTOR
                      ? IdStrings.doctorIosStoreButton
                      : IdStrings.patientIosStoreButton
                  }`}
                  sx={{
                    objectFit: 'contain',
                    width: '132px',
                    height: '44px',
                  }}
                  component="img"
                  onClick={onStoreIconClick('appStoreUrl')}
                  src={AppStore}
                />
                <Box
                  id={`${
                    profileType === ProfileType.DOCTOR
                      ? IdStrings.doctorAandroidStoreButton
                      : IdStrings.patientAandroidStoreButton
                  }`}
                  sx={{
                    objectFit: 'contain',
                    width: '149px',
                    height: '44px',
                  }}
                  component="img"
                  onClick={onStoreIconClick('googlePlayUrl')}
                  src={GooglePlay}
                />
              </FlexBox>
            ) : (
              <>
                <Typography variant="info">{downloadLabel}</Typography>
                <Box
                  sx={{
                    objectFit: 'contain',
                    width: '130px',
                    height: '130px',
                  }}
                  component="img"
                  src={qrCode}
                />
              </>
            )}
          </FlexBox>
        </FlexBox>
      </Stack>
    </>
  );
};
