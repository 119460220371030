import { Loader, Select, StatusCard, useBreakpoints } from '@lib/react-components';
import { IdStrings } from '@lib/utils';
import { Box, Button, FormControl, InputLabel, Typography } from '@mui/material';

import { useRoleStepControls } from './hooks';
import { useStyles } from './styles';

import { useRoleOptions } from 'features/Doctor/components/ProfessionalDoctorData/components/MedicalRole/SelectRoleModal/hooks/useRoleOptions.hook';
import { i18n } from 'i18n';

interface IRoleStepProps {
  goToNextStep: () => void;
}

export const RoleStep = ({ goToNextStep }: IRoleStepProps): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { availableRoles, loading, selectedRole, onRoleChange, saveAndContinue } = useRoleStepControls(goToNextStep);
  const roleOptions = useRoleOptions();
  const { isMobileView } = useBreakpoints();

  if (!availableRoles || loading) {
    return <Loader />;
  }

  const roleLabel = i18n.t('features.affiliations.role');

  return (
    <Box sx={{ padding: { xs: '0 24px', mobile: 'initial' } }}>
      <Typography>{i18n.t('features.affiliations.activationStepper.roleStep.chooseYourRole')}</Typography>
      <Box className={localClasses.select}>
        <FormControl fullWidth>
          <InputLabel id={IdStrings.roleLabel}>{roleLabel}</InputLabel>
          <Select
            labelId={IdStrings.roleLabel}
            variant="standard"
            onChange={onRoleChange}
            label={roleLabel}
            value={selectedRole?.id ?? ''}
            items={roleOptions}
          />
        </FormControl>
      </Box>
      <StatusCard text={i18n.t('features.affiliations.activationStepper.roleStep.infoBox')} />
      <Box className={localClasses.buttonContainer}>
        <Button fullWidth={isMobileView} className={localClasses.saveButton} onClick={saveAndContinue}>
          {i18n.t('features.affiliations.activationStepper.roleStep.saveAndContinue')}
        </Button>
      </Box>
    </Box>
  );
};
