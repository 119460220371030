import { BroadcastChannel } from 'broadcast-channel';

export enum MessageCodes {
  LOG_OUT = 'LOG_OUT',
}

export interface IBroadcastMessage {
  code: MessageCodes;
}

const CHANNEL_NAME = 'welmed';

export const broadcastChannel = new BroadcastChannel<IBroadcastMessage>(CHANNEL_NAME);
