import { PersistedDataKeys } from '@lib/core';
import { useModalState } from '@lib/react-components';
import { useEffect } from 'react';

import { useRootModelGetInstalledApp } from './useRootModelGetInstalledApp';

import { ProfileType } from '__generated__/types';
import { usePortalSlug } from 'features/Portal';
import { APP_PATIENT_URL } from 'features/Users/constants';
import { useUserModelStore } from 'features/Users/model';
import { persistedData } from 'lib/helpers/persistedData';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';

interface IUseNativePatientAppModalStateReturn {
  isNativePatientAppModalAllowed: boolean;
  isSetupNativePatientAppModalOpened: boolean;
  openSetupNativePatientAppModal: VoidFunction;
  closeSetupNativePatientAppModal: VoidFunction;
}

export const useRootModelNativePatientAppModalState = (): IUseNativePatientAppModalStateReturn => {
  const portalSlug = usePortalSlug();
  const { activePatient } = useUserModelStore();

  const isMobileAppWebView = useIsMobileAppWebView();
  const [isSetupNativePatientAppModalOpened, openSetupNativePatientAppModal, closeSetupNativePatientAppModal] =
    useModalState();

  const mobileAppConfig = useRootModelGetInstalledApp(ProfileType.PATIENT);

  const isNativePatientAppModalAllowed =
    !isMobileAppWebView &&
    !mobileAppConfig &&
    !!activePatient &&
    !!APP_PATIENT_URL[portalSlug] &&
    !persistedData.get(PersistedDataKeys.NativeAppBannerShownPatient);

  const closeModal: VoidFunction = () => {
    persistedData.set(PersistedDataKeys.NativeAppBannerShownPatient, true);
    closeSetupNativePatientAppModal();
  };

  useEffect(() => {
    if (!activePatient) {
      closeSetupNativePatientAppModal();
    }
  }, [activePatient]);

  return {
    isNativePatientAppModalAllowed,
    isSetupNativePatientAppModalOpened,
    openSetupNativePatientAppModal,
    closeSetupNativePatientAppModal: closeModal,
  };
};
