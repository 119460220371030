import { ROUTE_PATHS } from '@lib/core';
import {
  ClinicsIcon,
  EquipeIcon,
  IntramedIcon,
  InvitesIcon,
  PatientIcon,
  ReminderIcon,
  RequestsIcon,
  SurveysIcon,
} from '@lib/icons';

import { isSideBarItemAvailableInPortal } from '../../../helpers';

import { IStaffMenuItem, UseStaffMenuItems } from './types';

import { ProfileType } from '__generated__/types';
import { usePortalConfiguration, usePortalSlug } from 'features/Portal';
import { rootConfig } from 'features/Root/config';
import { useRootModelPath } from 'features/Root/model';
import { usePortalSidebarItems } from 'features/Root/ui';
import { useUserModelStore } from 'features/Users/model';
import { i18n } from 'i18n';
import { NEW_ENTITY_PARAM } from 'routes/constants';

export const useStaffMenuItems: UseStaffMenuItems = () => {
  const configuration = usePortalConfiguration();
  const { getPath } = useRootModelPath();
  const portalSlug = usePortalSlug();
  const { activeProfile } = useUserModelStore();
  const portalSidebarItems = usePortalSidebarItems();

  const links: IStaffMenuItem[] = [
    {
      title: i18n.t('components.mobileMenuModalList.staff.patient'),
      linkPath: ROUTE_PATHS.patientsCreate,
      icon: PatientIcon,
    },
    ...(configuration?.isPatientSelfRegistrationEnabled
      ? [
          {
            title: i18n.t('general.chat'),
            linkPath: ROUTE_PATHS.chatCreation,
            blockedRoles: [ProfileType.NON_DOCTOR],
            // TODO: Replace with chat icon after develop merge
            icon: RequestsIcon,
          },
        ]
      : []),
    ...(isSideBarItemAvailableInPortal(portalSidebarItems, 'clinics')
      ? [
          {
            title: i18n.t('components.mobileMenuModalList.staff.reservation'),
            linkPath: `${ROUTE_PATHS.clinics}${rootConfig.RESERVATION_QUERY_PARAMS}`,
            icon: ClinicsIcon,
          },
        ]
      : []),
    ...(isSideBarItemAvailableInPortal(portalSidebarItems, 'intramed')
      ? [
          {
            title: i18n.t('components.mobileMenuModalList.staff.intramed'),
            linkPath: ROUTE_PATHS.intramedCreate,
            icon: IntramedIcon,
          },
        ]
      : []),
    ...(isSideBarItemAvailableInPortal(portalSidebarItems, 'equipes')
      ? [
          {
            title: i18n.t('components.mobileMenuModalList.staff.equipe'),
            linkPath: ROUTE_PATHS.equipesCreate,
            icon: EquipeIcon,
          },
        ]
      : []),
    ...(isSideBarItemAvailableInPortal(portalSidebarItems, 'reminders')
      ? [
          {
            title: i18n.t('components.mobileMenuModalList.staff.reminder'),
            linkPath: `${ROUTE_PATHS.reminders}/${NEW_ENTITY_PARAM}`,
            icon: ReminderIcon,
          },
        ]
      : []),
    ...(isSideBarItemAvailableInPortal(portalSidebarItems, 'invites')
      ? [
          {
            title: i18n.t('components.mobileMenuModalList.staff.invite'),
            linkPath: ROUTE_PATHS.inviteCreation,
            icon: InvitesIcon,
          },
        ]
      : []),
    ...(configuration?.hasAvailableSurveys
      ? [
          {
            title: i18n.t('components.mobileMenuModalList.staff.survey'),
            linkPath: ROUTE_PATHS.surveyIssueCreation,
            icon: SurveysIcon,
          },
        ]
      : []),
  ];

  const filteredLinks = links.filter(({ blockedPortals, blockedRoles }: IStaffMenuItem): boolean => {
    const blockedByPortal = blockedPortals ? !blockedPortals.includes(portalSlug) : true;
    const blockedByRole = blockedRoles
      ? !blockedRoles.some(blockedRole => blockedRole === activeProfile?.profileType)
      : true;

    return blockedByRole && blockedByPortal;
  });

  return filteredLinks.map(({ linkPath, title, icon }) => ({
    title,
    icon,
    linkPath: getPath({ path: linkPath }),
  }));
};
