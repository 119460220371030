import { ActionType } from 'typesafe-actions';

import * as actions from './actionCreators';

import {
  IAdditionalCytogeneticAlterationsHistoryValues,
  IAnamnesisSurgeryForBreastHistoryValues,
  IBmaPatientMedicalData,
  IBmbPatientMedicalData,
  IBrafMutationHistoryValues,
  IDonorsHistoryValues,
  IFamilyBreastHistoryValues,
  IFamilyMelanomaHistoryValues,
  IFusionTranscriptsHistoryValues,
  IHematologicalTherapyRow,
  IHistotypeHistoryValues,
  ILabResultsPatientMedicalData,
  ILymphDissectionHistoryValues,
  IMenopauseHistoryValues,
  IMetastasisHistoryValues,
  IMplHistoryValues,
  IMutationalExaminationHistoryValues,
  IMutationCalrHistoryValues,
  IMutationJak2Exon12HistoryValues,
  IMutationJak2V617FHistoryValues,
  IMutationSubclonalHistoryValues,
  INgsHistoryValues,
  INonBrafMutationHistoryValues,
  INotesHistoryValues,
  IOncologicalTherapyTreatmentCourseHistory,
  IPathologyResultsBreastHistologicalExaminationHistory,
  IPathologyResultsMelanomaLymphNodesHistologicalExaminationHistory,
  IPathologyResultsMelanomaTumorHistologicalExaminationHistory,
  IPhototypeHistoryValues,
  IQuantitativePcrHistoryValues,
  IResectionMarginsHistoryValues,
  ISentinelLymphHistoryValues,
  ISmokeHistoryValues,
} from 'graphql/types/patient';

export interface IState {
  melanomaFamilyLastValue: IFamilyMelanomaHistoryValues | null;
  melanomaFamilyHistoryValues: IFamilyMelanomaHistoryValues[] | null;
  breastFamilyLastValue: IFamilyBreastHistoryValues | null;
  breastFamilyHistoryValues: IFamilyBreastHistoryValues[] | null;
  resectionMarginsLastValue: IResectionMarginsHistoryValues | null;
  resectionMarginsHistoryValues: IResectionMarginsHistoryValues[] | null;
  histotypeLastValue: IHistotypeHistoryValues | null;
  histotypeHistoryValues: IHistotypeHistoryValues[] | null;
  brafMutationLastValue: IBrafMutationHistoryValues | null;
  brafMutationHistoryValues: IBrafMutationHistoryValues[] | null;
  nonBrafMutationLastValue: INonBrafMutationHistoryValues | null;
  nonBrafMutationHistoryValues: INonBrafMutationHistoryValues[] | null;
  sentinelLymphLastValue: ISentinelLymphHistoryValues | null;
  sentinelLymphHistoryValues: ISentinelLymphHistoryValues[] | null;
  lymphDissectionLastValue: ILymphDissectionHistoryValues | null;
  lymphDissectionHistoryValues: ILymphDissectionHistoryValues[] | null;
  metastasisLastValue: IMetastasisHistoryValues | null;
  metastasisHistoryValues: IMetastasisHistoryValues[] | null;
  labResults: ILabResultsPatientMedicalData[] | null;
  anamnesisDonorsLastValue: IDonorsHistoryValues | null;
  anamnesisDonorsHistoryValues: IDonorsHistoryValues[] | null;
  anamnesisSurgeryLastValue: IAnamnesisSurgeryForBreastHistoryValues | null;
  anamnesisSurgeryHistoryValues: IAnamnesisSurgeryForBreastHistoryValues[] | null;
  riskScoreNotesLastValue: INotesHistoryValues | null;
  riskScoreNotesHistoryValues: INotesHistoryValues[] | null;
  vitalsParametersNotesLastValue: INotesHistoryValues | null;
  vitalsParametersNotesHistoryValues: INotesHistoryValues[] | null;
  signsAndSymptomsNotesLastValue: INotesHistoryValues | null;
  signsAndSymptomsNotesHistoryValues: INotesHistoryValues[] | null;
  physiologicalHistoryNotesLastValue: INotesHistoryValues | null;
  physiologicalHistoryNotesHistoryValues: INotesHistoryValues[] | null;
  pathologyResultsBmaLastValue: IBmaPatientMedicalData | null;
  pathologyResultsBmaHistoryValues: IBmaPatientMedicalData[] | null;
  pathologyResultsBmbLastValue: IBmbPatientMedicalData | null;
  pathologyResultsBmbHistoryValues: IBmbPatientMedicalData[] | null;
  pathologyResultsAcaLastValue: IAdditionalCytogeneticAlterationsHistoryValues | null;
  pathologyResultsAcaHistoryValues: IAdditionalCytogeneticAlterationsHistoryValues[] | null;
  pathologyResultsHistologicalExaminationLastValue: IPathologyResultsBreastHistologicalExaminationHistory | null;
  pathologyResultsHistologicalExaminationHistoryValues: IPathologyResultsBreastHistologicalExaminationHistory[] | null;
  pathologyResultsPrimaryTumorHistologicalExaminationLastValue: IPathologyResultsMelanomaTumorHistologicalExaminationHistory | null;
  pathologyResultsPrimaryTumorHistologicalExaminationHistoryValues:
    | IPathologyResultsMelanomaTumorHistologicalExaminationHistory[]
    | null;
  pathologyResultsLymphNodesHistologicalExaminationLastValue: IPathologyResultsMelanomaLymphNodesHistologicalExaminationHistory | null;
  pathologyResultsLymphNodesHistologicalExaminationHistoryValues:
    | IPathologyResultsMelanomaLymphNodesHistologicalExaminationHistory[]
    | null;
  labResultsBcrAbl1TranscriptLastValue: IFusionTranscriptsHistoryValues | null;
  labResultsBcrAbl1TranscriptHistoryValues: IFusionTranscriptsHistoryValues[] | null;
  labResultsBcrAbl1PcrQLastValue: IQuantitativePcrHistoryValues | null;
  labResultsBcrAbl1PcrQHistoryValues: IQuantitativePcrHistoryValues[] | null;
  labResultsBcrAbl1MutationLastValue: IMutationalExaminationHistoryValues | null;
  labResultsBcrAbl1MutationHistoryValues: IMutationalExaminationHistoryValues[] | null;
  labResultsJak2V617FLastValue: IMutationJak2V617FHistoryValues | null;
  labResultsJak2V617FHistoryValues: IMutationJak2V617FHistoryValues[] | null;
  labResultsJak2Exon12LastValue: IMutationJak2Exon12HistoryValues | null;
  labResultsJak2Exon12HistoryValues: IMutationJak2Exon12HistoryValues[] | null;
  labResultsCalrLastValue: IMutationCalrHistoryValues | null;
  labResultsCalrHistoryValues: IMutationCalrHistoryValues[] | null;
  labResultsMplLastValue: IMplHistoryValues | null;
  labResultsMplHistoryValues: IMplHistoryValues[] | null;
  labResultsSubclonalLastValue: IMutationSubclonalHistoryValues | null;
  labResultsSubclonalHistoryValues: IMutationSubclonalHistoryValues[] | null;
  labResultsNgsLastValue: INgsHistoryValues | null;
  labResultsNgsHistoryValues: INgsHistoryValues[] | null;
  hematologicalTherapyRows: IHematologicalTherapyRow[] | null;
  physiologicalHistorySmokeLastValue: ISmokeHistoryValues | null;
  physiologicalHistorySmokeHistoryValues: ISmokeHistoryValues[] | null;
  physiologicalHistoryMenopauseLastValue: IMenopauseHistoryValues | null;
  physiologicalHistoryMenopauseHistoryValues: IMenopauseHistoryValues[] | null;
  physiologicalHistoryPhototypeLastValue: IPhototypeHistoryValues | null;
  physiologicalHistoryPhototypeHistoryValues: IPhototypeHistoryValues[] | null;
  therapyRadiotherapyLastValue: IOncologicalTherapyTreatmentCourseHistory | null;
  therapyRadiotherapyHistoryValues: IOncologicalTherapyTreatmentCourseHistory[] | null;
  therapyNeoadjuvantLastValue: IOncologicalTherapyTreatmentCourseHistory | null;
  therapyNeoadjuvantHistoryValues: IOncologicalTherapyTreatmentCourseHistory[] | null;
  therapyHormoneLastValue: IOncologicalTherapyTreatmentCourseHistory | null;
  therapyHormoneHistoryValues: IOncologicalTherapyTreatmentCourseHistory[] | null;
  therapyAdjuvantLastValue: IOncologicalTherapyTreatmentCourseHistory | null;
  therapyAdjuvantHistoryValues: IOncologicalTherapyTreatmentCourseHistory[] | null;
}

export enum ActionTypeList {
  SET_LAB_RESULTS_DATA = '@@medicalParameters/SET_LAB_RESULTS_DATA',
  ADD_LAB_RESULTS_DATA = '@@medicalParameters/ADD_LAB_RESULTS_DATA',
  REMOVE_LAB_RESULTS_DATA = '@@medicalParameters/REMOVE_LAB_RESULTS_DATA',

  SET_ANAMNESIS_DONORS_LAST_VALUE = '@@medicalParameters/SET_ANAMNESIS_DONORS_LAST_VALUE',
  REMOVE_ANAMNESIS_DONORS_LAST_VALUE = '@@medicalParameters/REMOVE_ANAMNESIS_DONORS_LAST_VALUE',

  SET_ANAMNESIS_DONORS_HISTORY_VALUES = '@@medicalParameters/SET_ANAMNESIS_DONORS_HISTORY_VALUES',
  REMOVE_ANAMNESIS_DONORS_HISTORY_VALUES = '@@medicalParameters/REMOVE_ANAMNESIS_DONORS_HISTORY_VALUES',

  SET_ANAMNESIS_SURGERY_LAST_VALUE = '@@medicalParameters/SET_ANAMNESIS_SURGERY_LAST_VALUE',
  REMOVE_ANAMNESIS_SURGERY_LAST_VALUE = '@@medicalParameters/REMOVE_ANAMNESIS_SURGERY_LAST_VALUE',

  SET_ANAMNESIS_SURGERY_HISTORY_VALUES = '@@medicalParameters/SET_ANAMNESIS_SURGERY_HISTORY_VALUES',
  REMOVE_ANAMNESIS_SURGERY_HISTORY_VALUES = '@@medicalParameters/REMOVE_ANAMNESIS_SURGERY_HISTORY_VALUES',

  SET_RISK_SCORE_NOTES_LAST_VALUE = '@@medicalParameters/SET_RISK_SCORE_NOTES_LAST_VALUE',
  REMOVE_RISK_SCORE_NOTES_LAST_VALUE = '@@medicalParameters/REMOVE_RISK_SCORE_NOTES_LAST_VALUE',

  SET_RISK_SCORE_NOTES_HISTORY_VALUES = '@@medicalParameters/SET_RISK_SCORE_NOTES_HISTORY_VALUES',
  REMOVE_RISK_SCORE_NOTES_HISTORY_VALUES = '@@medicalParameters/REMOVE_RISK_SCORE_NOTES_HISTORY_VALUES',

  SET_VITALS_PARAMETERS_NOTES_LAST_VALUE = '@@medicalParameters/SET_VITALS_PARAMETERS_NOTES_LAST_VALUE',
  REMOVE_VITALS_PARAMETERS_NOTES_LAST_VALUE = '@@medicalParameters/REMOVE_VITALS_PARAMETERS_NOTES_LAST_VALUE',

  SET_VITALS_PARAMETERS_NOTES_HISTORY_VALUES = '@@medicalParameters/SET_VITALS_PARAMETERS_NOTES_HISTORY_VALUES',
  REMOVE_VITALS_PARAMETERS_NOTES_HISTORY_VALUES = '@@medicalParameters/REMOVE_VITALS_PARAMETERS_NOTES_HISTORY_VALUES',

  SET_SIGNS_AND_SYMPTOMS_NOTES_LAST_VALUE = '@@medicalParameters/SET_SIGNS_AND_SYMPTOMS_NOTES_LAST_VALUE',
  REMOVE_SIGNS_AND_SYMPTOMS_NOTES_LAST_VALUE = '@@medicalParameters/REMOVE_SIGNS_AND_SYMPTOMS_NOTES_LAST_VALUE',

  SET_SIGNS_AND_SYMPTOMS_NOTES_HISTORY_VALUES = '@@medicalParameters/SET_SIGNS_AND_SYMPTOMS_NOTES_HISTORY_VALUES',
  REMOVE_SIGNS_AND_SYMPTOMS_NOTES_HISTORY_VALUES = '@@medicalParameters/REMOVE_SIGNS_AND_SYMPTOMS_NOTES_HISTORY_VALUES',

  SET_PHYSIOLOGICAL_HISTORY_NOTES_LAST_VALUE = '@@/medicalParameters/SET_PHYSIOLOGICAL_HISTORY_NOTES_LAST_VALUE',
  REMOVE_PHYSIOLOGICAL_HISTORY_NOTES_LAST_VALUE = '@@/medicalParameters/REMOVE_PHYSIOLOGICAL_HISTORY_NOTES_LAST_VALUE',

  SET_PHYSIOLOGICAL_HISTORY_NOTES_HISTORY_VALUES = '@@/medicalParameters/SET_PHYSIOLOGICAL_HISTORY_NOTES_HISTORY_VALUES',
  REMOVE_PHYSIOLOGICAL_HISTORY_NOTES_HISTORY_VALUES = '@@/medicalParameters/REMOVE_PHYSIOLOGICAL_HISTORY_NOTES_HISTORY_VALUES',

  SET_PHYSIOLOGICAL_HISTORY_SMOKE_LAST_VALUE = '@@medicalParameter/SET_PHYSIOLOGICAL_HISTORY_SMOKE_LAST_VALUE',
  REMOVE_PHYSIOLOGICAL_HISTORY_SMOKE_LAST_VALUE = '@@medicalParameter/REMOVE_PHYSIOLOGICAL_HISTORY_SMOKE_LAST_VALUE',

  SET_PHYSIOLOGICAL_HISTORY_SMOKE_HISTORY_VALUES = '@@medicalParameter/SET_PHYSIOLOGICAL_HISTORY_SMOKE_HISTORY_VALUES',
  REMOVE_PHYSIOLOGICAL_HISTORY_SMOKE_HISTORY_VALUES = '@@medicalParameter/REMOVE_PHYSIOLOGICAL_HISTORY_SMOKE_HISTORY_VALUES',

  SET_PHYSIOLOGICAL_HISTORY_MENOPAUSE_LAST_VALUE = '@@medicalParameter/SET_PHYSIOLOGICAL_HISTORY_MENOPAUSE_LAST_VALUE',
  REMOVE_PHYSIOLOGICAL_HISTORY_MENOPAUSE_LAST_VALUE = '@@medicalParameter/REMOVE_PHYSIOLOGICAL_HISTORY_MENOPAUSE_LAST_VALUE',

  SET_PHYSIOLOGICAL_HISTORY_MENOPAUSE_HISTORY_VALUES = '@@medicalParameter/SET_PHYSIOLOGICAL_HISTORY_MENOPAUSE_HISTORY_VALUES',
  REMOVE_PHYSIOLOGICAL_HISTORY_MENOPAUSE_HISTORY_VALUES = '@@medicalParameter/REMOVE_PHYSIOLOGICAL_HISTORY_MENOPAUSE_HISTORY_VALUES',

  SET_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_LAST_VALUE = '@@medicalParameter/SET_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_LAST_VALUE',
  REMOVE_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_LAST_VALUE = '@@medicalParameter/REMOVE_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_LAST_VALUE',

  SET_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_HISTORY_VALUES = '@@medicalParameter/SET_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_HISTORY_VALUES',
  REMOVE_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_HISTORY_VALUES = '@@medicalParameter/REMOVE_PHYSIOLOGICAL_HISTORY_PHOTOTYPE_HISTORY_VALUES',

  SET_DIAGNOSES_NOTES_LAST_VALUE = '@@medicalParameters/SET_DIAGNOSES_NOTES_LAST_VALUE',
  REMOVE_DIAGNOSES_NOTES_LAST_VALUE = '@@medicalParameters/REMOVE_DIAGNOSES_NOTES_LAST_VALUE',

  SET_DIAGNOSES_NOTES_HISTORY_VALUES = '@@medicalParameters/SET_DIAGNOSES_NOTES_HISTORY_VALUES',
  REMOVE_DIAGNOSES_NOTES_HISTORY_VALUES = '@@medicalParameters/REMOVE_DIAGNOSES_NOTES_HISTORY_VALUES',

  SET_PATHOLOGY_RESULTS_BMA_LAST_VALUE = '@@medicalParameters/SET_PATHOLOGY_RESULTS_BMA_LAST_VALUE',
  REMOVE_PATHOLOGY_RESULTS_BMA_LAST_VALUE = '@@medicalParameters/REMOVE_PATHOLOGY_RESULTS_BMA_LAST_VALUE',

  SET_PATHOLOGY_RESULTS_BMA_HISTORY_VALUES = '@@medicalParameters/SET_PATHOLOGY_RESULTS_BMA_HISTORY_VALUES',
  REMOVE_PATHOLOGY_RESULTS_BMA_HISTORY_VALUES = '@@medicalParameters/REMOVE_PATHOLOGY_RESULTS_BMA_HISTORY_VALUES',

  SET_PATHOLOGY_RESULTS_BMB_LAST_VALUE = '@@medicalParameters/SET_PATHOLOGY_RESULTS_BMB_LAST_VALUE',
  REMOVE_PATHOLOGY_RESULTS_BMB_LAST_VALUE = '@@medicalParameters/REMOVE_PATHOLOGY_RESULTS_BMB_LAST_VALUE',

  SET_PATHOLOGY_RESULTS_BMB_HISTORY_VALUES = '@@medicalParameters/SET_PATHOLOGY_RESULTS_BMB_HISTORY_VALUES',
  REMOVE_PATHOLOGY_RESULTS_BMB_HISTORY_VALUES = '@@medicalParameters/REMOVE_PATHOLOGY_RESULTS_BMB_HISTORY_VALUES',

  SET_PATHOLOGY_RESULTS_ACA_LAST_VALUE = '@@medicalParameter/SET_PATHOLOGY_RESULTS_ACA_LAST_VALUE',
  REMOVE_PATHOLOGY_RESULTS_ACA_LAST_VALUE = '@@medicalParameter/REMOVE_PATHOLOGY_RESULTS_ACA_LAST_VALUE',

  SET_PATHOLOGY_RESULTS_ACA_HISTORY_VALUES = '@@medicalParameter/SET_PATHOLOGY_RESULTS_ACA_HISTORY_VALUES',
  REMOVE_PATHOLOGY_RESULTS_ACA_HISTORY_VALUES = '@@medicalParameter/REMOVE_PATHOLOGY_RESULTS_ACA_HISTORY_VALUES',

  SET_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_LAST_VALUE = '@@medicalParameters/SET_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_LAST_VALUE',
  REMOVE_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_LAST_VALUE = '@@medicalParameters/REMOVE_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_LAST_VALUE',

  SET_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_HISTORY_VALUES = '@@medicalParameters/SET_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_HISTORY_VALUES',
  REMOVE_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_HISTORY_VALUES = '@@medicalParameters/REMOVE_LAB_RESULTS_BCR_ABL1_TRANSCRIPT_HISTORY_VALUES',

  SET_LAB_RESULTS_BCR_ABL1_Q_PCR_LAST_VALUE = '@@medicalParameters/SET_LAB_RESULTS_BCR_ABL1_Q_PCR_LAST_VALUE',
  REMOVE_LAB_RESULTS_BCR_ABL1_Q_PCR_LAST_VALUE = '@@medicalParameters/REMOVE_LAB_RESULTS_BCR_ABL1_Q_PCR_LAST_VALUE',

  SET_LAB_RESULTS_BCR_ABL1_Q_PCR_HISTORY_VALUES = '@@medicalParameters/SET_LAB_RESULTS_BCR_ABL1_Q_PCR_HISTORY_VALUES',
  REMOVE_LAB_RESULTS_BCR_ABL1_Q_PCR_HISTORY_VALUES = '@@medicalParameters/REMOVE_LAB_RESULTS_BCR_ABL1_Q_PCR_HISTORY_VALUES',

  SET_LAB_RESULTS_BCR_ABL1_MUTATION_LAST_VALUE = '@@medicalParameters/SET_LAB_RESULTS_BCR_ABL1_MUTATION_LAST_VALUE',
  REMOVE_LAB_RESULTS_BCR_ABL1_MUTATION_LAST_VALUE = '@@medicalParameters/REMOVE_LAB_RESULTS_BCR_ABL1_MUTATION_LAST_VALUE',

  SET_LAB_RESULTS_BCR_ABL1_MUTATION_HISTORY_VALUES = '@@medicalParameters/SET_LAB_RESULTS_BCR_ABL1_MUTATION_HISTORY_VALUES',
  REMOVE_LAB_RESULTS_BCR_ABL1_MUTATION_HISTORY_VALUES = '@@medicalParameters/REMOVE_LAB_RESULTS_BCR_ABL1_MUTATION_HISTORY_VALUES',

  SET_LAB_RESULTS_JAK2_V617F_LAST_VALUE = '@@medicalParameters/SET_LAB_RESULTS_JAK2_V617F_LAST_VALUE',
  REMOVE_LAB_RESULTS_JAK2_V617F_LAST_VALUE = '@@medicalParameters/REMOVE_LAB_RESULTS_JAK2_V617F_LAST_VALUE',

  SET_LAB_RESULTS_JAK2_V617F_HISTORY_VALUES = '@@medicalParameters/SET_LAB_RESULTS_JAK2_V617F_HISTORY_VALUES',
  REMOVE_LAB_RESULTS_JAK2_V617F_HISTORY_VALUES = '@@medicalParameters/REMOVE_LAB_RESULTS_JAK2_V617F_HISTORY_VALUES',

  SET_LAB_RESULTS_JAK2_EXON12_LAST_VALUE = '@@medicalParameters/SET_LAB_RESULTS_JAK2_EXON12_LAST_VALUE',
  REMOVE_LAB_RESULTS_JAK2_EXON12_LAST_VALUE = '@@medicalParameters/REMOVE_LAB_RESULTS_JAK2_EXON12_LAST_VALUE',

  SET_LAB_RESULTS_JAK2_EXON12_HISTORY_VALUES = '@@medicalParameters/SET_LAB_RESULTS_JAK2_EXON12_HISTORY_VALUES',
  REMOVE_LAB_RESULTS_JAK2_EXON12_HISTORY_VALUES = '@@medicalParameters/REMOVE_LAB_RESULTS_JAK2_EXON12_HISTORY_VALUES',

  SET_LAB_RESULTS_CALR_LAST_VALUE = '@@medicalParameters/SET_LAB_RESULTS_CALR_LAST_VALUE',
  REMOVE_LAB_RESULTS_CALR_LAST_VALUE = '@@medicalParameters/REMOVE_LAB_RESULTS_CALR_LAST_VALUE',

  SET_LAB_RESULTS_CALR_HISTORY_VALUES = '@@medicalParameters/SET_LAB_RESULTS_CALR_HISTORY_VALUES',
  REMOVE_LAB_RESULTS_CALR_HISTORY_VALUES = '@@medicalParameters/REMOVE_LAB_RESULTS_CALR_HISTORY_VALUES',

  SET_LAB_RESULTS_MPL_LAST_VALUE = '@@medicalParameters/SET_LAB_RESULTS_MPL_LAST_VALUE',
  REMOVE_LAB_RESULTS_MPL_LAST_VALUE = '@@medicalParameters/REMOVE_LAB_RESULTS_MPL_LAST_VALUE',

  SET_LAB_RESULTS_MPL_HISTORY_VALUES = '@@medicalParameters/SET_LAB_RESULTS_MPL_HISTORY_VALUES',
  REMOVE_LAB_RESULTS_MPL_HISTORY_VALUES = '@@medicalParameters/REMOVE_LAB_RESULTS_MPL_HISTORY_VALUES',

  SET_LAB_RESULTS_SUBCLONAL_LAST_VALUE = '@@medicalParameters/SET_LAB_RESULTS_SUBCLONAL_LAST_VALUE',
  REMOVE_LAB_RESULTS_SUBCLONAL_LAST_VALUE = '@@medicalParameters/REMOVE_LAB_RESULTS_SUBCLONAL_LAST_VALUE',

  SET_LAB_RESULTS_SUBCLONAL_HISTORY_VALUES = '@@medicalParameters/SET_LAB_RESULTS_SUBCLONAL_HISTORY_VALUES',
  REMOVE_LAB_RESULTS_SUBCLONAL_HISTORY_VALUES = '@@medicalParameters/REMOVE_LAB_RESULTS_SUBCLONAL_HISTORY_VALUES',

  SET_LAB_RESULTS_NGS_LAST_VALUE = '@@medicalParameters/SET_LAB_RESULTS_NGS_LAST_VALUE',
  REMOVE_LAB_RESULTS_NGS_LAST_VALUE = '@@medicalParameters/REMOVE_LAB_RESULTS_NGS_LAST_VALUE',

  SET_LAB_RESULTS_NGS_HISTORY_VALUES = '@@medicalParameters/SET_LAB_RESULTS_NGS_HISTORY_VALUES',
  REMOVE_LAB_RESULTS_NGS_HISTORY_VALUES = '@@medicalParameters/REMOVE_LAB_RESULTS_NGS_HISTORY_VALUES',

  SET_HEMATOLOGICAL_THERAPY_ROWS = '@@medicalParameters/SET_HEMATOLOGICAL_THERAPY_ROWS',
  REMOVE_HEMATOLOGICAL_THERAPY_ROWS = '@@medicalParameters/REMOVE_HEMATOLOGICAL_THERAPY_ROWS',
  ADD_HEMATOLOGICAL_THERAPY_ROW = '@@medicalParameters/ADD_HEMATOLOGICAL_THERAPY_ROW',
  UPDATE_HEMATOLOGICAL_THERAPY_ROW = '@@medicalParameters/EDIT_HEMATOLOGICAL_THERAPY_ROW',

  SET_BRAF_MUTATION_LAST_VALUE = '@@medicalParameters/SET_BRAF_MUTATION_LAST_VALUE',
  REMOVE_BRAF_MUTATION_LAST_VALUE = '@@medicalParameters/REMOVE_BRAF_MUTATION_LAST_VALUE',

  SET_BRAF_MUTATION_HISTORY_VALUES = '@@medicalParameters/SET_BRAF_MUTATION_HISTORY_VALUES',
  REMOVE_BRAF_MUTATION_HISTORY_VALUES = '@@medicalParameters/REMOVE_BRAF_MUTATION_HISTORY_VALUES',

  SET_NON_BRAF_MUTATION_LAST_VALUE = '@@medicalParameters/SET_NON_BRAF_MUTATION_LAST_VALUE',
  REMOVE_NON_BRAF_MUTATION_LAST_VALUE = '@@medicalParameters/REMOVE_NON_BRAF_MUTATION_LAST_VALUE',

  SET_NON_BRAF_MUTATION_HISTORY_VALUES = '@@medicalParameters/SET_NON_BRAF_MUTATION_HISTORY_VALUES',
  REMOVE_NON_BRAF_MUTATION_HISTORY_VALUES = '@@medicalParameters/REMOVE_NON_BRAF_MUTATION_HISTORY_VALUES',

  SET_SENTINEL_LYMPH_LAST_VALUE = '@@medicalParameters/SET_SENTINEL_LYMPH_LAST_VALUE',
  REMOVE_SENTINEL_LYMPH_LAST_VALUE = '@@medicalParameters/REMOVE_SENTINEL_LYMPH_LAST_VALUE',

  SET_SENTINEL_LYMPH_HISTORY_VALUES = '@@medicalParameters/SET_SENTINEL_LYMPH_HISTORY_VALUES',
  REMOVE_SENTINEL_LYMPH_HISTORY_VALUES = '@@medicalParameters/REMOVE_SENTINEL_LYMPH_HISTORY_VALUES',

  SET_LYMPH_DISSECTION_LAST_VALUE = '@@medicalParameters/SET_LYMPH_DISSECTION_LAST_VALUE',
  REMOVE_LYMPH_DISSECTION_LAST_VALUE = '@@medicalParameters/REMOVE_LYMPH_DISSECTION_LAST_VALUE',

  SET_LYMPH_DISSECTION_HISTORY_VALUES = '@@medicalParameters/SET_LYMPH_DISSECTION_HISTORY_VALUES',
  REMOVE_LYMPH_DISSECTION_HISTORY_VALUES = '@@medicalParameters/REMOVE_LYMPH_DISSECTION_HISTORY_VALUES',

  SET_METASTASIS_LAST_VALUE = '@@medicalParameters/SET_METASTASIS_LAST_VALUE',
  REMOVE_METASTASIS_LAST_VALUE = '@@medicalParameters/REMOVE_METASTASIS_LAST_VALUE',

  SET_METASTASIS_HISTORY_VALUES = '@@medicalParameters/SET_METASTASIS_HISTORY_VALUES',
  REMOVE_METASTASIS_HISTORY_VALUES = '@@medicalParameters/REMOVE_METASTASIS_HISTORY_VALUES',

  SET_RESECTION_MARGINS_LAST_VALUE = '@@medicalParameters/SET_RESECTION_MARGINS_LAST_VALUE',
  REMOVE_RESECTION_MARGINS_LAST_VALUE = '@@medicalParameters/REMOVE_RESECTION_MARGINS_LAST_VALUE',

  SET_RESECTION_MARGINS_HISTORY_VALUES = '@@medicalParameters/SET_RESECTION_MARGINS_HISTORY_VALUES',
  REMOVE_RESECTION_MARGINS_HISTORY_VALUES = '@@medicalParameters/REMOVE_RESECTION_MARGINS_HISTORY_VALUES',

  SET_HISTOTYPE_LAST_VALUE = '@@medicalParameters/SET_HISTOTYPE_LAST_VALUE',
  REMOVE_HISTOTYPE_LAST_VALUE = '@@medicalParameters/REMOVE_HISTOTYPE_LAST_VALUE',

  SET_HISTOTYPE_HISTORY_VALUES = '@@medicalParameters/SET_HISTOTYPE_HISTORY_VALUES',
  REMOVE_HISTOTYPE_HISTORY_VALUES = '@@medicalParameters/REMOVE_HISTOTYPE_HISTORY_VALUES',

  SET_MELANOMA_FAMILY_LAST_VALUE = '@@medicalParameters/SET_MELANOMA_FAMILY_LAST_VALUE',
  REMOVE_MELANOMA_FAMILY_LAST_VALUE = '@@medicalParameters/REMOVE_MELANOMA_FAMILY_LAST_VALUE',

  SET_MELANOMA_FAMILY_HISTORY_VALUES = '@@medicalParameters/SET_MELANOMA_FAMILY_HISTORY_VALUES',
  REMOVE_MELANOMA_FAMILY_HISTORY_VALUES = '@@medicalParameters/REMOVE_MELANOMA_FAMILY_HISTORY_VALUES',

  SET_BREAST_FAMILY_LAST_VALUE = '@@medicalParameters/SET_BREAST_FAMILY_LAST_VALUE',
  REMOVE_BREAST_FAMILY_LAST_VALUE = '@@medicalParameters/REMOVE_BREAST_FAMILY_LAST_VALUE',

  SET_BREAST_FAMILY_HISTORY_VALUES = '@@medicalParameters/SET_BREAST_FAMILY_HISTORY_VALUES',
  REMOVE_BREAST_FAMILY_HISTORY_VALUES = '@@medicalParameters/REMOVE_BREAST_FAMILY_HISTORY_VALUES',

  SET_PATHOLOGY_RESULTS_HISTOLOGICAL_EXAMINATION_LAST_VALUE = '@@medicalParameters/SET_PATHOLOGY_RESULTS_HISTOLOGICAL_EXAMINATION_LAST_VALUE',
  REMOVE_PATHOLOGY_RESULTS_HISTOLOGICAL_EXAMINATION_LAST_VALUE = '@@medicalParameters/REMOVE_PATHOLOGY_RESULTS_HISTOLOGICAL_EXAMINATION_LAST_VALUE',

  SET_PATHOLOGY_RESULTS_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES = '@@medicalParameters/SET_PATHOLOGY_RESULTS_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES',
  REMOVE_PATHOLOGY_RESULTS_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES = '@@medicalParameters/REMOVE_PATHOLOGY_RESULTS_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES',

  SET_PATHOLOGY_RESULTS_PRIMARY_TUMOR_HISTOLOGICAL_EXAMINATION_LAST_VALUE = '@@medicalParameters/SET_PATHOLOGY_RESULTS_PRIMARY_TUMOR_HISTOLOGICAL_EXAMINATION_LAST_VALUE',
  REMOVE_PATHOLOGY_RESULTS_PRIMARY_TUMOR_HISTOLOGICAL_EXAMINATION_LAST_VALUE = '@@medicalParameters/REMOVE_PATHOLOGY_RESULTS_PRIMARY_TUMOR_HISTOLOGICAL_EXAMINATION_LAST_VALUE',

  SET_PATHOLOGY_RESULTS_PRIMARY_TUMOR_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES = '@@medicalParameters/SET_PATHOLOGY_RESULTS_PRIMARY_TUMOR_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES',
  REMOVE_PATHOLOGY_RESULTS_PRIMARY_TUMOR_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES = '@@medicalParameters/REMOVE_PATHOLOGY_RESULTS_PRIMARY_TUMOR_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES',

  SET_PATHOLOGY_RESULTS_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_LAST_VALUE = '@@medicalParameters/SET_PATHOLOGY_RESULTS_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_LAST_VALUE',
  REMOVE_PATHOLOGY_RESULTS_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_LAST_VALUE = '@@medicalParameters/REMOVE_PATHOLOGY_RESULTS_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_LAST_VALUE',

  SET_PATHOLOGY_RESULTS_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES = '@@medicalParameters/SET_PATHOLOGY_RESULTS_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES',
  REMOVE_PATHOLOGY_RESULTS_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES = '@@medicalParameters/REMOVE_PATHOLOGY_RESULTS_LYMPH_NODES_HISTOLOGICAL_EXAMINATION_HISTORY_VALUES',

  SET_THERAPY_RADIOTHERAPY_LAST_VALUE = '@@medicalParameters/SET_THERAPY_RADIOTHERAPY_LAST_VALUE',
  REMOVE_THERAPY_RADIOTHERAPY_LAST_VALUE = '@@medicalParameters/REMOVE_THERAPY_RADIOTHERAPY_LAST_VALUE',

  SET_THERAPY_RADIOTHERAPY_HISTORY_VALUES = '@@medicalParameters/SET_THERAPY_RADIOTHERAPY_HISTORY_VALUES',
  REMOVE_THERAPY_RADIOTHERAPY_HISTORY_VALUES = '@@medicalParameters/REMOVE_THERAPY_RADIOTHERAPY_HISTORY_VALUES',

  SET_THERAPY_NEOADJUVANT_LAST_VALUE = '@@medicalParameters/SET_THERAPY_NEOADJUVANT_LAST_VALUE',
  REMOVE_THERAPY_NEOADJUVANT_LAST_VALUE = '@@medicalParameters/REMOVE_THERAPY_NEOADJUVANT_LAST_VALUE',

  SET_THERAPY_NEOADJUVANT_HISTORY_VALUES = '@@medicalParameters/SET_THERAPY_NEOADJUVANT_HISTORY_VALUES',
  REMOVE_THERAPY_NEOADJUVANT_HISTORY_VALUES = '@@medicalParameters/REMOVE_THERAPY_NEOADJUVANT_HISTORY_VALUES',

  SET_THERAPY_HORMONE_LAST_VALUE = '@@medicalParameters/SET_THERAPY_HORMONE_LAST_VALUE',
  REMOVE_THERAPY_HORMONE_LAST_VALUE = '@@medicalParameters/REMOVE_THERAPY_HORMONE_LAST_VALUE',

  SET_THERAPY_HORMONE_HISTORY_VALUES = '@@medicalParameters/SET_THERAPY_HORMONE_HISTORY_VALUES',
  REMOVE_THERAPY_HORMONE_HISTORY_VALUES = '@@medicalParameters/REMOVE_THERAPY_HORMONE_HISTORY_VALUES',

  SET_THERAPY_ADJUVANT_LAST_VALUE = '@@medicalParameters/SET_THERAPY_ADJUVANT_LAST_VALUE',
  REMOVE_THERAPY_ADJUVANT_LAST_VALUE = '@@medicalParameters/REMOVE_THERAPY_ADJUVANT_LAST_VALUE',

  SET_THERAPY_ADJUVANT_HISTORY_VALUES = '@@medicalParameters/SET_THERAPY_ADJUVANT_HISTORY_VALUES',
  REMOVE_THERAPY_ADJUVANT_HISTORY_VALUES = '@@medicalParameters/REMOVE_THERAPY_ADJUVANT_HISTORY_VALUES',
}

export type Action = ActionType<typeof actions>;

export const initialState: IState = {
  melanomaFamilyLastValue: null,
  melanomaFamilyHistoryValues: null,
  breastFamilyLastValue: null,
  breastFamilyHistoryValues: null,
  resectionMarginsLastValue: null,
  resectionMarginsHistoryValues: null,
  histotypeLastValue: null,
  histotypeHistoryValues: null,
  brafMutationLastValue: null,
  brafMutationHistoryValues: null,
  sentinelLymphLastValue: null,
  sentinelLymphHistoryValues: null,
  nonBrafMutationLastValue: null,
  nonBrafMutationHistoryValues: null,
  lymphDissectionLastValue: null,
  lymphDissectionHistoryValues: null,
  metastasisLastValue: null,
  metastasisHistoryValues: null,
  labResults: null,
  anamnesisDonorsLastValue: null,
  anamnesisDonorsHistoryValues: null,
  anamnesisSurgeryLastValue: null,
  anamnesisSurgeryHistoryValues: null,
  riskScoreNotesLastValue: null,
  riskScoreNotesHistoryValues: null,
  vitalsParametersNotesLastValue: null,
  vitalsParametersNotesHistoryValues: null,
  signsAndSymptomsNotesLastValue: null,
  signsAndSymptomsNotesHistoryValues: null,
  physiologicalHistoryNotesLastValue: null,
  physiologicalHistoryNotesHistoryValues: null,
  pathologyResultsBmaLastValue: null,
  pathologyResultsBmaHistoryValues: null,
  pathologyResultsBmbLastValue: null,
  pathologyResultsBmbHistoryValues: null,
  pathologyResultsAcaLastValue: null,
  pathologyResultsAcaHistoryValues: null,
  pathologyResultsHistologicalExaminationLastValue: null,
  pathologyResultsHistologicalExaminationHistoryValues: null,
  pathologyResultsPrimaryTumorHistologicalExaminationLastValue: null,
  pathologyResultsPrimaryTumorHistologicalExaminationHistoryValues: null,
  pathologyResultsLymphNodesHistologicalExaminationLastValue: null,
  pathologyResultsLymphNodesHistologicalExaminationHistoryValues: null,
  labResultsBcrAbl1TranscriptLastValue: null,
  labResultsBcrAbl1TranscriptHistoryValues: null,
  labResultsBcrAbl1PcrQLastValue: null,
  labResultsBcrAbl1PcrQHistoryValues: null,
  labResultsBcrAbl1MutationLastValue: null,
  labResultsBcrAbl1MutationHistoryValues: null,
  labResultsJak2V617FLastValue: null,
  labResultsJak2V617FHistoryValues: null,
  labResultsJak2Exon12LastValue: null,
  labResultsJak2Exon12HistoryValues: null,
  labResultsCalrLastValue: null,
  labResultsCalrHistoryValues: null,
  labResultsMplLastValue: null,
  labResultsMplHistoryValues: null,
  labResultsSubclonalLastValue: null,
  labResultsSubclonalHistoryValues: null,
  labResultsNgsLastValue: null,
  labResultsNgsHistoryValues: null,
  hematologicalTherapyRows: null,
  physiologicalHistoryMenopauseLastValue: null,
  physiologicalHistoryMenopauseHistoryValues: null,
  physiologicalHistorySmokeLastValue: null,
  physiologicalHistorySmokeHistoryValues: null,
  physiologicalHistoryPhototypeLastValue: null,
  physiologicalHistoryPhototypeHistoryValues: null,
  therapyRadiotherapyLastValue: null,
  therapyRadiotherapyHistoryValues: null,
  therapyNeoadjuvantLastValue: null,
  therapyNeoadjuvantHistoryValues: null,
  therapyHormoneLastValue: null,
  therapyHormoneHistoryValues: null,
  therapyAdjuvantLastValue: null,
  therapyAdjuvantHistoryValues: null,
};
