import { PersistedDataKeys, useGetDefaultHeaders } from '@lib/core';
import { Redirect, useLocation } from 'react-router-dom';

import { useRootModelPath } from 'features/Root/model';
import { persistedData } from 'lib/helpers/persistedData';
import { useIsMobileAppWebView } from 'lib/hooks/useIsMobileAppWebView';
import { DebugPaths } from 'pages/WrongPath/DebugPaths';
import { DEFAULT_AUTHORIZED_ROUTE, DEFAULT_UNAUTHORIZED_ROUTE } from 'routes/constants';

export const RootEmptyPortalRouteRedirect = (): JSX.Element => {
  const { getPath } = useRootModelPath();
  const headers = useGetDefaultHeaders();
  const isMobileAppWebView = useIsMobileAppWebView();
  const { search } = useLocation();
  const accessToken = persistedData.get<string>(PersistedDataKeys.AccessToken);

  const getRoutePath = (): string => {
    if (isMobileAppWebView) {
      console.error(`Redirecting to Dashboard because of missing path in url. Headers: ${JSON.stringify(headers)}`);

      return DebugPaths.noPath;
    }

    return accessToken ? DEFAULT_AUTHORIZED_ROUTE : DEFAULT_UNAUTHORIZED_ROUTE;
  };

  return <Redirect to={getPath({ path: getRoutePath(), search })} />;
};
