import { BREAK_POINTS, defaultBorderRadius, getDefaultBoxShadow } from '@lib/react-components';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  greyCard: {
    margin: '12px 0px 24px',
    boxShadow: getDefaultBoxShadow(theme.palette.shadow.main),
    borderRadius: defaultBorderRadius,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      margin: '24px 0 0',
      boxShadow: 'unset',
      borderRadius: 'unset',
      backgroundColor: theme.palette.white.main,
    },
    '& .MuiAccordionSummary-root': {
      [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 'unset',
        padding: '20px 24px',
        borderTop: `1px solid ${theme.palette.lines.main}`,
      },
    },
    '& :last-child': {
      '& .MuiAccordionSummary-root': {
        borderBottomLeftRadius: defaultBorderRadius,
        borderBottomRightRadius: defaultBorderRadius,
        [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
          borderRadius: 'unset',
        },
      },
    },
  },

  accordion: {
    backgroundColor: theme.palette.background.default,
    '&.MuiAccordion-root.Mui-expanded': {
      marginTop: 1,
      [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
        backgroundColor: theme.palette.white.main,
      },
    },
  },
}));
