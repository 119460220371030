import { GlobalSearchDetailsTabType } from '../config';

import { i18n } from 'i18n';

export const getSearchListNoResultsTitle = (tabType?: GlobalSearchDetailsTabType): string => {
  switch (tabType) {
    case GlobalSearchDetailsTabType.PRIVATE_DOCTORS:
      return i18n.t('features.search.noResult.title.privateDoctor');
    case GlobalSearchDetailsTabType.AFFILIATED_DOCTORS:
      return i18n.t('features.search.noResult.title.affiliatedDoctor');
    case GlobalSearchDetailsTabType.CLINICS:
      return i18n.t('features.search.noResult.title.clinic');
    case GlobalSearchDetailsTabType.FREE_CLINICS:
      return i18n.t('features.search.noResult.title.freeClinic');

    default:
      return i18n.t('features.search.noResult.title.any');
  }
};
