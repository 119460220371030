import { useModalState } from '@lib/react-components';

import { loginOrRegisterBlockingModalModel } from './model';
import { LoginOrRegisterBlockingModalContent } from './ui';

interface ILoginOrRegisterBlockingModalsControlProps {
  isForbiddenAction?: boolean;
  doctorPortalUserId?: string;
  handleCloseModalClick: VoidFunction;
  isUnauthorizedUserWithoutPatientProfile?: boolean;
  handleUnathorizedUserProceedToPatientRegisterClick?: VoidFunction;
}

export const LoginOrRegisterBlockingModalsControl = ({
  isForbiddenAction,
  doctorPortalUserId,
  handleCloseModalClick,
  isUnauthorizedUserWithoutPatientProfile,
  handleUnathorizedUserProceedToPatientRegisterClick,
}: ILoginOrRegisterBlockingModalsControlProps): JSX.Element => {
  const [isSwitchToPatientProfile, openSwitchToPatientProfile] = useModalState();
  const { isUnauthorizedUser, isUserWithoutPatientProfile, isAuthorizedUserWithPatientProfile } =
    loginOrRegisterBlockingModalModel.useLoginOrRegisterBlockingModalActiveProfile(
      !!isUnauthorizedUserWithoutPatientProfile
    );
  const { handleHistoryGoBackClick, handleProceedToPatientRegisterClick } =
    loginOrRegisterBlockingModalModel.useLoginOrRegisterBlockingModalControls();

  const handleDeclineModalActionClick: VoidFunction = isForbiddenAction
    ? handleHistoryGoBackClick
    : handleCloseModalClick;

  return (
    <>
      {isUnauthorizedUser && (
        <LoginOrRegisterBlockingModalContent.UnauthorizedUserModal
          handleProceedToPatientRegisterClick={handleProceedToPatientRegisterClick}
          onCancel={handleDeclineModalActionClick}
        />
      )}
      {isSwitchToPatientProfile ? (
        <LoginOrRegisterBlockingModalContent.SwitchToPatientModal
          handleModalClose={handleCloseModalClick}
          isForbiddenAction={isForbiddenAction}
          doctorPortalUserId={doctorPortalUserId}
        />
      ) : (
        <>
          {isAuthorizedUserWithPatientProfile && (
            <LoginOrRegisterBlockingModalContent.AuthorizedWithPatientModal
              handleOpenSwitchProfile={openSwitchToPatientProfile}
              onCancel={handleDeclineModalActionClick}
            />
          )}
          {isUserWithoutPatientProfile && (
            <LoginOrRegisterBlockingModalContent.UserWithoutPatientModal
              handleOpenSwitchProfile={openSwitchToPatientProfile}
              handleRegisterPatientClick={handleUnathorizedUserProceedToPatientRegisterClick}
              onCancel={handleDeclineModalActionClick}
            />
          )}
        </>
      )}
    </>
  );
};
