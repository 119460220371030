import { ProfileType } from '../__generated__/types';

export interface ISignInCredentials {
  username: string;
  password: string;
}

export interface IError {
  code: string;
  message: string;
  name: string;
}

export const isError = (error: IError | unknown): error is IError =>
  !!(error as IError).message || !!(error as IError).code || !!(error as IError).name;

export interface INewUser {
  email: string;
  phoneNumber: string;
  profileType: ProfileType;
}
