import { useUpdateNoticeApiMarkAsRead } from '../api';

interface IUseUpdateNoticeModelMarkAsReadReturn {
  markNoticeAsRead: ReturnType<typeof useUpdateNoticeApiMarkAsRead>[0];
}

type UseUpdateNoticeModelMarkAsRead = () => IUseUpdateNoticeModelMarkAsReadReturn;

export const useUpdateNoticeModelMarkAsRead: UseUpdateNoticeModelMarkAsRead = () => {
  const [markNoticeAsRead] = useUpdateNoticeApiMarkAsRead();

  return { markNoticeAsRead };
};
