import { isPast } from 'date-fns';
import isEqual from 'date-fns/isEqual';
import validate from 'validate.js';

import { concatDates, isFutureDate, isPreviousDate } from '../date';

import { isDateValidFormat } from './dateValidation';

import { ServiceAddonType } from '__generated__/types';
import { TimeErrorType } from 'features/Service/config';
import { IVideoVisitFormOnDemandValues } from 'features/Service/model/types';
import { IServiceAddon } from 'graphql/types/services';
import { i18n } from 'i18n';

export interface IBooleanValidationOptions {
  equality: boolean;
  message: string;
}

type ValidationOptions = { message: string };

interface IUsernameValidationOptions {
  email: ValidationOptions;
  phone: ValidationOptions;
}

export interface IDateValidationOptions {
  equality: boolean;
  disablePast: boolean;
}

const PHONE_PATTERN = /^\+\d{5,15}$/;
const BP_PATTERN = /^\d{1,3}\/\d{1,3}$/;

const setPhoneValidation = (): void => {
  validate.validators.phone = (value: string, { message }: ValidationOptions): string | undefined =>
    validate.single(value, {
      format: {
        pattern: PHONE_PATTERN,
        message: message ? `^${message}` : `^${i18n.t('components.forms.validationErrors.phoneNumber')}`,
      },
    });
};

const setBloopPressureValidation: VoidFunction = () => {
  validate.validators.bp = (value: string): string | undefined => {
    if (
      validate.single(value, {
        format: {
          pattern: BP_PATTERN,
        },
      })
    ) {
      return i18n.t('general.validation.bp.wrongFormat');
    }

    const slittedBloodPressure = value.split('/');

    if (+slittedBloodPressure[0] < +slittedBloodPressure[1]) {
      return i18n.t('general.validation.bp.minMaxError');
    }

    return undefined;
  };
};

const setUsernameValidation = (): void => {
  validate.validators.username = (
    value: string,
    usernameValidationOptions: IUsernameValidationOptions
  ): string | undefined => {
    if (value.charAt(0) === '+' || !Number.isNaN(+value)) {
      return validate.single(value, {
        phone: {
          message: usernameValidationOptions.phone.message
            ? `^${usernameValidationOptions.phone.message}`
            : `^${i18n.t('components.forms.validationErrors.phoneNumber')}`,
        },
      });
    }

    return validate.single(value, {
      email: {
        message: usernameValidationOptions.email.message
          ? `^${usernameValidationOptions.email.message}`
          : `^${i18n.t('components.forms.validationErrors.email')}`,
      },
    });
  };
};

const setBooleanValidation: VoidFunction = () => {
  validate.validators.boolean = (
    value: boolean,
    { equality, message }: IBooleanValidationOptions
  ): string | undefined => (value === equality ? undefined : message);
};

const setDateValidation: VoidFunction = () => {
  validate.validators.date = (value: string, attributes: IDateValidationOptions): string => {
    if (!isDateValidFormat(value)) {
      return i18n.t('general.validation.date.wrongFormat');
    }

    if (attributes.disablePast) {
      return isPreviousDate(value) ? i18n.t('general.validation.date.expired') : '';
    }

    return isFutureDate(value) ? i18n.t('general.validation.date.future') : '';
  };
};

const setServiceAddonsValidation: VoidFunction = () => {
  validate.validators.addons = (value: IServiceAddon[]): string | undefined => {
    const isValid = value.reduce<boolean>((acc, { type, price, providedWithinDays, isEnabled }) => {
      if (!acc) {
        return acc;
      }

      if (!isEnabled) {
        return true;
      }

      if (type === ServiceAddonType.PRIORITY) {
        return (!!price || price === 0) && !!providedWithinDays;
      }

      return !!price || price === 0;
    }, true);

    return isValid ? undefined : 'Not valid';
  };
};

const setEventBookingRequestValidation: VoidFunction = () => {
  validate.validators.eventBooking = (
    _: string,
    __: Record<string, unknown>,
    ___: string,
    all: IVideoVisitFormOnDemandValues
  ): TimeErrorType.different | undefined => {
    const firstDate = concatDates(all.firstPreferenceDay, all.firstPreferenceTime);
    const secondDate = concatDates(all.secondPreferenceDay, all.secondPreferenceTime);

    return !isEqual(new Date(firstDate), new Date(secondDate)) ? undefined : TimeErrorType.different;
  };
};

const setIsPastValidation: VoidFunction = () => {
  validate.validators.isPast = (
    value: string,
    _: Record<string, unknown>,
    key: string,
    all: IVideoVisitFormOnDemandValues
  ): TimeErrorType.notPast | undefined => {
    const dayKey = key.replace('Time', 'Day') as keyof typeof all;
    const day = all[dayKey];

    if (isPast(new Date(concatDates(day, value)))) {
      return TimeErrorType.notPast;
    }

    return undefined;
  };
};

export const init = (): void => {
  setPhoneValidation();
  setBloopPressureValidation();
  setUsernameValidation();
  setBooleanValidation();
  setDateValidation();
  setServiceAddonsValidation();
  setEventBookingRequestValidation();
  setIsPastValidation();
};
