import { SelectWithLabel } from '@lib/react-components';
import { Typography, Box, SelectChangeEvent } from '@mui/material';
import { isNil } from 'lodash-es';

import { Maybe } from '__generated__/types';
import { AudioLevelIndicator } from 'features/Calls/components/CallControls/AudioLevelIndicator';

interface IDevice {
  deviceId: string;
  label: string;
}
interface IAudioDeviceListProps {
  name: string;
  label: string;
  defaultLabel: string;
  devices: IDevice[];
  selectedDevice: Maybe<IDevice>;
  onChange: (event: SelectChangeEvent) => void;
  volume?: number;
  disabled?: boolean;
}

export const AudioDeviceList = ({
  name,
  label: listLabel,
  defaultLabel,
  devices,
  selectedDevice,
  onChange,
  volume,
  disabled,
}: IAudioDeviceListProps): JSX.Element => (
  <Box>
    {devices.length > 1 ? (
      <Box display="flex" alignItems="flex-end">
        <SelectWithLabel
          name={name}
          value={selectedDevice?.deviceId || ''}
          items={devices.map(({ deviceId: value, label }) => ({
            label,
            value,
          }))}
          variant="standard"
          onChange={onChange}
          label={listLabel}
          disabled={disabled}
        />
        {!isNil(volume) && <AudioLevelIndicator size={30} volume={volume} />}
      </Box>
    ) : (
      <>
        <Typography variant="h6">{listLabel}</Typography>
        <Typography>{selectedDevice?.label || defaultLabel}</Typography>
      </>
    )}
  </Box>
);
