import { ModalSize } from '@lib/react-components';
import { iif } from '@lib/utils';
import { Stack } from '@mui/material';
import { initial } from 'lodash-es';
import { useState, ChangeEventHandler, KeyboardEventHandler } from 'react';
import { Trans } from 'react-i18next';

import { SphereOfInterestStatusCard } from './components';

import { FieldsOfInterestTextArea } from 'components/FieldsOfInterestTextArea';
import { SettingsModalWrapper } from 'components/SettingsModalWrapper';
import { useUpdateDoctorSphereOfInterest } from 'features/Doctor/graphql/__generated__/UpdateDoctorSphereOfInterest.mutation';
import { useUserModelStore } from 'features/Users/model';
import { i18n } from 'i18n';

interface IUpdateSphereOfInterestModalProps {
  closeModal: VoidFunction;
  sphereOfInterest: string[];
}

export const UpdateSphereOfInterestModal = ({
  closeModal,
  sphereOfInterest,
}: IUpdateSphereOfInterestModalProps): JSX.Element => {
  const { updateDoctorRole } = useUserModelStore();
  const [inputValue, setInputValue] = useState<string>('');
  const [saveDoctorSphereOfInterest] = useUpdateDoctorSphereOfInterest();
  const [sphereOfInterestValue, setSphereOfInterestValue] = useState<string[]>(sphereOfInterest);

  const handleSave = (): void => {
    saveDoctorSphereOfInterest({
      variables: {
        sphereOfInterest:
          inputValue !== ''
            ? [...sphereOfInterestValue, ...iif(!sphereOfInterestValue.includes(inputValue), inputValue)]
            : sphereOfInterestValue,
      },
      onCompleted: data => {
        closeModal();
        updateDoctorRole({ sphereOfInterest: data.updateDoctorSphereOfInterest.sphereOfInterest });
      },
    });
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = event =>
    setInputValue(() => (event.target.value.trim() ? event.target.value : ''));

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === 'Enter' && inputValue) {
      const value = event.currentTarget.value.trim();

      if (!sphereOfInterestValue.includes(value)) {
        setSphereOfInterestValue([...sphereOfInterestValue, value]);
      }

      setInputValue('');
    }

    if (event.key === 'Backspace' && !inputValue) {
      setSphereOfInterestValue(initial(sphereOfInterestValue));
    }
  };

  const removeSphereOfInterestValueHandler = (fieldIndex: number): void => {
    setSphereOfInterestValue(oldValues => oldValues.filter((_, index) => index !== fieldIndex));
  };

  return (
    <SettingsModalWrapper
      size={ModalSize.MD}
      title={i18n.t('pages.profileSettings.fields.fieldOfInterest.modalTitle')}
      secondTitle={<Trans i18n={i18n} i18nKey="pages.profileSettings.fields.fieldOfInterestInfo" />}
      onSave={handleSave}
      onCancel={closeModal}
    >
      <Stack gap={2}>
        <SphereOfInterestStatusCard />
        <FieldsOfInterestTextArea
          values={sphereOfInterestValue}
          inputValue={inputValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onClick={removeSphereOfInterestValueHandler}
        />
      </Stack>
    </SettingsModalWrapper>
  );
};
