import { IGlobalSearchDetailsClinicTabCard } from '../model';

import { GlobalSearchDetails, GlobalSearchDetailsCardClinicAvatar } from 'features/GlobalSearchDetails';

interface IGlobalSearchDetailsCardClinicProps extends IGlobalSearchDetailsClinicTabCard {
  onClinicCardClickHandler: (id: string) => void;
}

export const GlobalSearchDetailsCardClinic = ({
  id,
  name,
  members,
  services,
  specializations,
  allowsOnDemandSchedule,
  earliestAvailabilityDate,
  defaultPatientWritePolicy,
  patientWriteButtonVisibilityPolicy,
  onClinicCardClickHandler,
}: IGlobalSearchDetailsCardClinicProps): JSX.Element => (
  <GlobalSearchDetails.CardWrapper
    objectContent={
      <GlobalSearchDetails.CardObject
        specializations={specializations}
        allowsOnDemandSchedule={allowsOnDemandSchedule}
        earliestAvailabilityDate={earliestAvailabilityDate}
      >
        <GlobalSearchDetailsCardClinicAvatar
          name={name}
          members={members}
          id={id}
          defaultPatientWritePolicy={defaultPatientWritePolicy}
          patientWriteButtonVisibilityPolicy={patientWriteButtonVisibilityPolicy}
        />
      </GlobalSearchDetails.CardObject>
    }
    servicesContent={
      services.length > 0
        ? services.map(props => <GlobalSearchDetails.CardServiceChip key={props.id} {...props} />)
        : undefined
    }
    onCardClickHandler={(): void => onClinicCardClickHandler(id)}
  />
);
