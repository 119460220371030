import { Reducer } from 'redux';

import { ActionTypeList, initialState, IState } from './types';

import { StoreActions } from 'store';

export const intramedReducer: Reducer<IState, StoreActions> = (state: IState = initialState, action) => {
  switch (action.type) {
    case ActionTypeList.ADD_INTRAMED_FILE:
      return {
        ...state,
        intramedFilesList: [...(state.intramedFilesList || []), action.payload.file],
      };

    case ActionTypeList.ADD_INTRAMED_FILES:
      return {
        ...state,
        intramedFilesList: [...(state.intramedFilesList || []), ...action.payload.fileList],
      };

    case ActionTypeList.REMOVE_INTRAMED_FILES:
      return {
        ...state,
        intramedFilesList: null,
      };

    case ActionTypeList.REMOVE_INTRAMED_FILE:
      return {
        ...state,
        intramedFilesList:
          state.intramedFilesList && state.intramedFilesList.filter(({ file: { id } }) => id !== action.payload.id),
      };

    case ActionTypeList.SET_ATTACHMENTS_LOADING:
      return {
        ...state,
        attachmentsLoading: action.payload.attachmentsLoading,
      };

    default:
      return state;
  }
};
