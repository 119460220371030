import { Button } from '@mui/material';
import { MouseEventHandler } from 'react';

interface ILoginOrRegisterBlockingModalButtonSecondaryProps {
  label: string;
  onClick: VoidFunction;
  disabled?: boolean;
}

export const LoginOrRegisterBlockingModalButtonSecondary = ({
  label,
  onClick,
  disabled,
}: ILoginOrRegisterBlockingModalButtonSecondaryProps): JSX.Element => {
  const onClickHandler: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();

    onClick();
  };

  return (
    <Button size="medium" variant="outlined" onClick={onClickHandler} disabled={disabled}>
      {label}
    </Button>
  );
};
