import { PortalFeatures, useCheckPortalFeature } from '@lib/features-bll';

import { Maybe, ProfileType, RequestStatus } from '__generated__/types';
import { helperThreadMessageTypeUtil } from 'features/Thread/helpers';
import { ThreadMessageUnion } from 'features/Thread/types';

interface IUseChatAssistantModelAbilitationParams {
  requestStatus?: Maybe<RequestStatus>;
  activeProfileType: Maybe<ProfileType>;
  lastMessage?: Maybe<ThreadMessageUnion>;
}

interface IUseChatAssistantModelAbilitationReturn {
  isChatAssistantSupported: boolean;
  isChatAssistantEnabledForPatient: boolean;
  isChatAssistantEnabledForDoctor: boolean;
}

type UseChatAssistantModelAbilitation = (
  params: IUseChatAssistantModelAbilitationParams
) => IUseChatAssistantModelAbilitationReturn;

export const useChatAssistantModelAbilitation: UseChatAssistantModelAbilitation = ({
  requestStatus,
  activeProfileType,
  lastMessage,
}) => {
  const { getIsFeatureEnabledForPortal } = useCheckPortalFeature();
  const isChatAssistantSupported = getIsFeatureEnabledForPortal(PortalFeatures.NewChatExperience);

  const hasActiveRequest = requestStatus === RequestStatus.ACTIVE;

  const isChatAssistantEnabledForPatient =
    isChatAssistantSupported && activeProfileType === ProfileType.PATIENT && !hasActiveRequest;

  const isChatAssistantEnabledForDoctor =
    isChatAssistantSupported &&
    activeProfileType === ProfileType.DOCTOR &&
    !hasActiveRequest &&
    !!lastMessage &&
    (helperThreadMessageTypeUtil.isMessage(lastMessage) ||
      helperThreadMessageTypeUtil.isAssistedSearch(lastMessage) ||
      helperThreadMessageTypeUtil.isMailMessage(lastMessage)) &&
    !!lastMessage.metadata?.patientOriginatorClaim;

  return { isChatAssistantSupported, isChatAssistantEnabledForPatient, isChatAssistantEnabledForDoctor };
};
