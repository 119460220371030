import { PersistedDataKeys } from '@lib/core';

import { IAuthResult } from 'graphql/mutations/authorization/types';
import { persistedData } from 'lib/helpers/persistedData';

export const clearPersistedData = (): void => {
  persistedData.clear();
};

export const updatePersistedAuthorizationData = (data: IAuthResult): void => {
  const { accessToken, expiresIn: expirationTime, refreshToken } = data;

  persistedData.setMultiple({
    [PersistedDataKeys.AccessToken]: accessToken,
    [PersistedDataKeys.ExpirationTime]: expirationTime,
    [PersistedDataKeys.RefreshToken]: refreshToken,
  });
};

export const prepareEmailOrPhoneNumber = (emailOrPhoneNumber: string): string =>
  emailOrPhoneNumber.trim().toLowerCase();
