import { Portals } from '@lib/features-bll';
import { useParams } from 'react-router-dom';

import { IPortalUrlParams } from 'routes/types';

export const usePortalSlug = (): Portals => {
  const { portalSlug } = useParams<IPortalUrlParams>();

  return portalSlug;
};
