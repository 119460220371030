import { useGlobalSearchDetailsTabsConfiguration } from 'features/GlobalSearchDetails';

interface IUseGlobalSearchDetailsDoctorWriteMeFilterAvailabilityReturn {
  writeMeFilterAvailability: boolean;
}

export const useGlobalSearchDetailsDoctorWriteMeFilterAvailability =
  (): IUseGlobalSearchDetailsDoctorWriteMeFilterAvailabilityReturn => {
    const { isPrivateDoctorActiveTab } = useGlobalSearchDetailsTabsConfiguration();

    return {
      writeMeFilterAvailability: isPrivateDoctorActiveTab,
    };
  };
