import { useBreakpoints } from '@lib/react-components';
import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface IGlobalSearchDetailsCardDetailsWrapperProps {
  children: ReactNode;
}

export const GlobalSearchDetailsCardDetailsWrapper = ({
  children,
}: IGlobalSearchDetailsCardDetailsWrapperProps): JSX.Element => {
  const { isDesktopView } = useBreakpoints();

  return (
    <Box
      sx={{
        width: '100%',
        ...(isDesktopView && {
          display: 'grid',
          gap: '32px 40px',
          gridTemplateColumns: 'repeat(auto-fit, minmax(30%, 210px) minmax(calc(70% - 250px), calc(100% - 250px)))',
        }),
      }}
    >
      {children}
    </Box>
  );
};
