import { useState, useEffect } from 'react';

import { AffiliationsActivationSteps } from '../enums';

import { useUserModelStore } from 'features/Users/model';

interface IUseActiveStepReturn {
  activeStep: AffiliationsActivationSteps;
  setActiveStep: React.Dispatch<React.SetStateAction<AffiliationsActivationSteps>>;
  startFromStepTwo: boolean;
}

export const useActiveStep = (): IUseActiveStepReturn => {
  const [activeStep, setActiveStep] = useState<AffiliationsActivationSteps>(AffiliationsActivationSteps.Role);

  const { activeDoctor } = useUserModelStore();
  const medicalRole = activeDoctor?.doctor.medicalRole;

  useEffect(() => {
    if (medicalRole) {
      setActiveStep(AffiliationsActivationSteps.Affiliations);
    }
  }, []);

  return {
    startFromStepTwo: !!medicalRole,
    activeStep,
    setActiveStep,
  };
};
