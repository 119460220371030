import { PortalLogoType, useDoctorPortalUserToDoCounters, usePatientToDoCounters } from '@lib/features-bll';
import { PortalLogo } from '@lib/features-ui';
import { MaterialSymbolsIcon, MenuIcon } from '@lib/icons';
import {
  FlexBox,
  getDefaultCardBoxShadow,
  MobileMenuModal,
  Popover,
  RedBadgeIconWrapper,
  TOP_MARGIN,
  useBreakpoints,
} from '@lib/react-components';
import { getUserInitials } from '@lib/utils';
import { AppBar, Avatar, Box, IconButton, Toolbar, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { useContext } from 'react';

import { MobileMenuList } from './components';
import { useHeaderControls } from './hooks';
import { MobileHeaderContext } from './providers';
import { useStyles } from './styles';
import { IHeaderProps } from './types';

import { useStyles as useContentWrapperStyles } from 'components/PageWrapper/styles';
import { AnnouncementIcon } from 'features/Announcements';
import { useGetNumberOfUnreadAnnouncements } from 'features/Announcements/graphql/__generated__/GetNumberOfUnreadAnnouncements.query';
import { GlobalSearchDetails, GlobalSearchField } from 'features/GlobalSearchDetails';
import { usePortal } from 'features/Portal';
import { SupportRequestUiAddSupportRequestModal, SupportRequestUiCanWeHelp } from 'features/SupportRequest/ui';
import { useUserModelStore } from 'features/Users/model';
import { HEADER_ID } from 'lib/constants';
import { useGetVerifyUserNotificationHeight } from 'lib/hooks/useGetVerifyUserNotificationHeight';

export const RootHeader = ({
  needUserVerification,
  isFullScreen,
  isMenuCollapsed,
  userInfo,
}: IHeaderProps): JSX.Element => {
  const { palette } = useTheme();
  const { isMobileView } = useBreakpoints();
  const verifyUserNotificationHeight = useGetVerifyUserNotificationHeight();
  const { classes: localClasses } = useStyles();
  const { classes: contentWrapperClasses } = useContentWrapperStyles();
  const { isPatient, activeProfile } = useUserModelStore();
  const { portal } = usePortal();
  const { data: announcementsData } = useGetNumberOfUnreadAnnouncements({
    fetchPolicy: 'cache-first',
    skip: activeProfile?.hasPostRegister || !isMobileView,
  });
  const patientToDoCounters = usePatientToDoCounters({ skip: !isPatient });
  const doctorToDoCounters = useDoctorPortalUserToDoCounters({ skip: isPatient });
  const { isMobileMenuModalOpened, showSupportModal, toggleProfileModalHandler, toggleSupportModalHandler, onCancel } =
    useContext(MobileHeaderContext);
  const {
    isGlobalSearchVisibleOnPage,
    isSsnClinicLinkVisibleOnPageDesktop,
    isSsnClinicLinkVisibleOnPageMobile,
    handleDrawerToggle,
    thumbnailImageFilePath,
    userProfileListContent,
    profileSwitcherRoleItems,
  } = useHeaderControls();

  return (
    <AppBar
      position="fixed"
      sx={{
        color: palette.black.main,
        transition: 'margin-top 0.2s',
        ...(isMobileView
          ? {
              top: needUserVerification ? verifyUserNotificationHeight : 0,
              backgroundColor: palette.surface.default,
              boxShadow: getDefaultCardBoxShadow(palette.shadow.main),
            }
          : {
              top: !isFullScreen && needUserVerification ? verifyUserNotificationHeight : 0,
              backgroundColor: palette.background.default,
              boxShadow: 'none',
            }),
        ...(isFullScreen && { marginTop: `-${TOP_MARGIN}px` }),
      }}
      className={clsx(
        !isFullScreen && (isMenuCollapsed ? localClasses.sidebarCollapsed : localClasses.sidebarExpanded)
      )}
    >
      <Toolbar
        id={HEADER_ID}
        sx={{
          flexGrow: 1,
          transition: 'margin 0.2s',
          ...(isMobileView && {
            margin: `${TOP_MARGIN}px 0 15px`,
          }),
          '&.MuiToolbar-root': {
            padding: isMobileView ? '0 8px' : 0,
          },
        }}
        className={clsx(
          !isFullScreen &&
            (isMenuCollapsed ? contentWrapperClasses.sidebarCollapsed : contentWrapperClasses.sidebarExpanded),
          localClasses.toolBar
        )}
      >
        <Box
          sx={{
            ...(isMobileView
              ? {
                  display: 'flex',
                  alignItems: 'center',
                }
              : {
                  width: 'fit-content',
                  flex: '1 1 auto',
                  marginRight: '40px',
                }),
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={localClasses.menuButton}
            size="large"
          >
            <RedBadgeIconWrapper
              size={12}
              right={-12}
              visible={
                (patientToDoCounters && patientToDoCounters.totalCount > 0) ||
                (doctorToDoCounters && doctorToDoCounters.totalCount > 0)
              }
            >
              <MaterialSymbolsIcon icon={<MenuIcon fontWeight="300" />} fontSize={40} />
            </RedBadgeIconWrapper>
          </IconButton>
          {isGlobalSearchVisibleOnPage && (
            <GlobalSearchField
              onlySearchIconVisible={isMobileView}
              sx={isMobileView ? undefined : { width: '100%', minWidth: '136px' }}
              height={38}
            />
          )}
        </Box>
        {isSsnClinicLinkVisibleOnPageDesktop && (
          <GlobalSearchDetails.SsnClinicLink sx={{ width: '100%', maxWidth: '224px' }} />
        )}
        {isMobileView ? (
          <PortalLogo
            variant={PortalLogoType.SMALL}
            logoUrl={portal?.logoUrl?.[PortalLogoType.SMALL]}
            classes={{ icon: localClasses.portalIcon }}
          />
        ) : (
          <FlexBox>
            <SupportRequestUiCanWeHelp profileId={activeProfile?.id} />
            {!activeProfile?.hasPostRegister && <AnnouncementIcon />}
          </FlexBox>
        )}
        {isMobileView ? (
          <RedBadgeIconWrapper
            top={-2}
            right={2}
            size={12}
            visible={!!announcementsData?.getNumberOfUnreadAnnouncements}
          >
            <Avatar
              sx={{
                height: 56,
                width: 56,
                backgroundColor: palette?.white.main,
              }}
              alt="user-avatar"
              src={thumbnailImageFilePath}
              onClick={toggleProfileModalHandler}
            >
              {getUserInitials(userInfo)}
            </Avatar>
          </RedBadgeIconWrapper>
        ) : (
          <Popover
            listItem={userProfileListContent}
            classes={{
              root: localClasses.menuRoot,
              menuItem: localClasses.menuItem,
              menuItemLink: clsx(localClasses.toggleUserMenuItemLink, 'rightMenuLink'),
              toggleButton: clsx(localClasses.toggleButton, localClasses.toggleUserMenuButton),
              divider: localClasses.divider,
            }}
            placement="bottom-end"
          >
            <Avatar alt="user-avatar" src={thumbnailImageFilePath}>
              <Typography variant="h9">{getUserInitials(userInfo)}</Typography>
            </Avatar>
          </Popover>
        )}
      </Toolbar>
      {isMobileMenuModalOpened && (
        <MobileMenuModal onCancel={onCancel}>
          <MobileMenuList profileSwitcherRoleItems={profileSwitcherRoleItems} />
        </MobileMenuModal>
      )}
      {showSupportModal && (
        <SupportRequestUiAddSupportRequestModal profileId={activeProfile?.id} onClose={toggleSupportModalHandler} />
      )}
      {isSsnClinicLinkVisibleOnPageMobile && (
        <GlobalSearchDetails.SsnClinicLink
          sx={{ width: '100%', textAlign: 'center', padding: '4px', backgroundColor: palette.white.main }}
        />
      )}
    </AppBar>
  );
};
