import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type RootApiCallEmailVerificationFlowVariables = Types.Exact<{ [key: string]: never; }>;


export type RootApiCallEmailVerificationFlow = { __typename?: 'Mutation', callEmailVerificationFlow: { __typename?: 'CodeDeliveryDetailsModel', attributeName: Types.CognitoVerifyAttribute, deliveryMedium: string, destination: string } };


export const RootApiCallEmailVerificationFlowDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RootApiCallEmailVerificationFlow"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"callEmailVerificationFlow"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"attributeName"}},{"kind":"Field","name":{"kind":"Name","value":"deliveryMedium"}},{"kind":"Field","name":{"kind":"Name","value":"destination"}}]}}]}}]} as unknown as DocumentNode;
export type RootApiCallEmailVerificationFlowMutationFn = Apollo.MutationFunction<RootApiCallEmailVerificationFlow, RootApiCallEmailVerificationFlowVariables>;

/**
 * __useRootApiCallEmailVerificationFlow__
 *
 * To run a mutation, you first call `useRootApiCallEmailVerificationFlow` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRootApiCallEmailVerificationFlow` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rootApiCallEmailVerificationFlow, { data, loading, error }] = useRootApiCallEmailVerificationFlow({
 *   variables: {
 *   },
 * });
 */
export function useRootApiCallEmailVerificationFlow(baseOptions?: ApolloReactHooks.MutationHookOptions<RootApiCallEmailVerificationFlow, RootApiCallEmailVerificationFlowVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RootApiCallEmailVerificationFlow, RootApiCallEmailVerificationFlowVariables>(RootApiCallEmailVerificationFlowDocument, options);
      }
export type RootApiCallEmailVerificationFlowHookResult = ReturnType<typeof useRootApiCallEmailVerificationFlow>;
export type RootApiCallEmailVerificationFlowMutationResult = Apollo.MutationResult<RootApiCallEmailVerificationFlow>;
export type RootApiCallEmailVerificationFlowMutationOptions = Apollo.BaseMutationOptions<RootApiCallEmailVerificationFlow, RootApiCallEmailVerificationFlowVariables>;