import { ROUTE_PATHS } from '@lib/core';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { GlobalSearchDetails, globalSearchDetailsModel } from 'features/GlobalSearchDetails';
import { useRootModelPath } from 'features/Root/model';

export const GlobalSearchDetailsAffiliatedDoctorTab = (): JSX.Element => {
  const { goToPath } = useRootModelPath();
  const { globalSearchDetails, loading, fetchMore } = globalSearchDetailsModel.useGlobalSearchDetailsAffiliations();
  const affiliations = globalSearchDetailsModel.getDoctorTabData(globalSearchDetails?.affiliations?.items);
  const { search } = useLocation();

  const onAffiliatedDoctorCardClickHandler = (doctorSlug: string): void =>
    goToPath({ path: ROUTE_PATHS.affiliatedDoctor, pathParams: { doctorSlug }, search });

  return (
    <GlobalSearchDetails.TabWrapper
      listLoading={loading}
      fetchMore={fetchMore}
      data={affiliations}
      itemContent={(_, affiliation): ReactNode => (
        <GlobalSearchDetails.CardDoctor
          {...affiliation}
          key={affiliation.portalUserId}
          onDoctorCardClickHandler={onAffiliatedDoctorCardClickHandler}
        />
      )}
    />
  );
};
