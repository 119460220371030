import {
  GlobalSearchDetailsAffiliations,
  useGlobalSearchDetailsAffiliations,
} from './graphql/__generated__/GlobalSearchDetailsAffiliations.query';
import {
  GlobalSearchDetailsDoctors,
  useGlobalSearchDetailsDoctors,
} from './graphql/__generated__/GlobalSearchDetailsDoctors.query';
import {
  GlobalSearchDetailsFreeClinics,
  useGlobalSearchDetailsFreeClinics,
} from './graphql/__generated__/GlobalSearchDetailsFreeClinics.query';
import {
  GlobalSearchDetailsGenericClinics,
  useGlobalSearchDetailsGenericClinics,
} from './graphql/__generated__/GlobalSearchDetailsGenericClinics.query';
import {
  GlobalSearchDetailsTotalCounts,
  useGlobalSearchDetailsTotalCounts,
} from './graphql/__generated__/GlobalSearchDetailsTotalCounts.query';

export const searchApi = {
  useGlobalSearchDetailsDoctors,
  useGlobalSearchDetailsAffiliations,
  useGlobalSearchDetailsGenericClinics,
  useGlobalSearchDetailsFreeClinics,
  useGlobalSearchDetailsTotalCounts,
};

export type {
  GlobalSearchDetailsDoctors as GlobalSearchDetailsDoctorsType,
  GlobalSearchDetailsAffiliations as GlobalSearchDetailsAffiliationsType,
  GlobalSearchDetailsFreeClinics as GlobalSearchDetailsFreeClinicsType,
  GlobalSearchDetailsGenericClinics as GlobalSearchDetailsGenericClinicsType,
  GlobalSearchDetailsTotalCounts as GlobalSearchDetailsTotalCountsType,
};
