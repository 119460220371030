import { PrivaciesApiPrivacy, PrivaciesType } from '@lib/features-bll';
import validate from 'validate.js';

import { REQUIRED_FIELD_ERROR_MESSAGE } from './constants';

import { concatValidationErrors } from '.';

import { PrivacyType } from '__generated__/types';

export type UpdatePrivaciesError = Record<keyof PrivaciesType, string[] | undefined>;

type UpdatePrivaciesValidationType = Record<PrivacyType, { boolean: { equality: boolean; message: string } }>;

export const validateUpdatePrivacies = (
  values: PrivaciesType,
  privaciesData: PrivaciesApiPrivacy[] | undefined
): UpdatePrivaciesError | undefined => {
  if (!privaciesData) {
    return undefined;
  }

  const privaciesValidationSchema = privaciesData.reduce((acc, curr) => {
    if (!curr.isMandatory) {
      return acc;
    }

    return {
      ...acc,
      [curr.type]: {
        boolean: { equality: true, message: REQUIRED_FIELD_ERROR_MESSAGE },
      },
    };
  }, {} as UpdatePrivaciesValidationType);

  return concatValidationErrors<UpdatePrivaciesError>(validate(values, privaciesValidationSchema));
};
