import { WebToNativeActions } from '@lib/features-bll';

import { isPlatformNative } from './platform';
import { INativeAction, PlatformBasedAction, PlatformBasedActionReturnType } from './types';

const sendActionToNative = (nativeActionPayload: INativeAction): void =>
  window.ReactNativeWebView?.postMessage(JSON.stringify(nativeActionPayload));

export const createNativeAction = <T>(
  action: WebToNativeActions,
  parameters?: INativeAction<T>['parameters']
): INativeAction => ({
  action,
  parameters: parameters ?? {},
});

export const platformBasedAction: PlatformBasedAction = ({ webAction, nativeAction }) => {
  if (isPlatformNative()) {
    sendActionToNative(nativeAction);

    return PlatformBasedActionReturnType.NATIVE_ACTION;
  }

  webAction();

  return PlatformBasedActionReturnType.WEB_ACTION;
};
