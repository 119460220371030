import { useBreakpoints } from '@lib/react-components';
import { ReactNode, useState } from 'react';

import { MobileHeaderContext } from './MobileHeaderContext';

interface IMobileHeaderProviderProps {
  children: ReactNode;
}

export const RootMobileHeaderProvider = ({ children }: IMobileHeaderProviderProps): JSX.Element => {
  const { isMobileView } = useBreakpoints();
  const [isStaffModalOpened, setStaffModalOpened] = useState<boolean>(false);
  const [isProfileModalOpened, setProfileModalOpened] = useState<boolean>(false);
  const [isSupportModalOpened, setSupportModalOpened] = useState<boolean>(false);

  const onCancel = (): void => {
    setStaffModalOpened(false);
    setProfileModalOpened(false);
  };

  const showSupportModal = isMobileView && isSupportModalOpened;

  const toggleStaffModalHandler = (): void => setStaffModalOpened(prev => !prev);

  const toggleProfileModalHandler = (): void => setProfileModalOpened(prev => !prev);

  const toggleSupportModalHandler = (): void => setSupportModalOpened(prev => !prev);

  const isMobileMenuModalOpened = isStaffModalOpened || isProfileModalOpened;

  return (
    <MobileHeaderContext.Provider
      value={{
        isStaffModalOpened,
        isProfileModalOpened,
        showSupportModal,
        isMobileMenuModalOpened,
        toggleStaffModalHandler,
        toggleProfileModalHandler,
        toggleSupportModalHandler,
        onCancel,
      }}
    >
      {children}
    </MobileHeaderContext.Provider>
  );
};
