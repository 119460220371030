import { useBreakpoints } from '@lib/react-components';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';

import { PageWrapper } from 'components/PageWrapper';

interface IGlobalSearchDetailsPageWrapperProps {
  children: ReactNode;
}

export const GlobalSearchDetailsPageWrapper = ({ children }: IGlobalSearchDetailsPageWrapperProps): JSX.Element => {
  const { isMobileView } = useBreakpoints();

  return (
    <PageWrapper>
      <Stack gap={isMobileView ? undefined : '16px'}>{children}</Stack>
    </PageWrapper>
  );
};
