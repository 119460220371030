import { getCustomPrivacyConfigByPortal, PrivacyUrl } from '@lib/features-bll';
import { Trans } from 'react-i18next';

import { RegistrationUiCheckboxItem } from './RegistrationUiCheckboxItem';
import { useStyles as useCheckboxesAreaStyles } from './styles';

import { PrivacyType } from '__generated__/types';
import { usePortalSlug } from 'features/Portal';
import { RegistrationCheckboxesAreaFormikProps } from 'features/Registration/types';
import { PrivacyPolicyCheckbox } from 'features/Users/components';
import { i18n } from 'i18n';
import { getFormErrors } from 'lib/hooks/formErrorsHook';

interface IRegistrationUiCheckboxesAreaDoctorItems {
  termsOfUse: PrivacyUrl;
  privacyPolicy: PrivacyUrl;
  newsletterConsensus: PrivacyUrl;
  codeOfMedicalEthics: PrivacyUrl;
  doctorPatientContract: PrivacyUrl;
}

type RegistrationUiCheckboxesAreaDoctorItemsProps = IRegistrationUiCheckboxesAreaDoctorItems &
  RegistrationCheckboxesAreaFormikProps;

export const RegistrationUiCheckboxesAreaDoctorItems = ({
  values,
  errors,
  touched,
  termsOfUse,
  handleChange,
  privacyPolicy,
  newsletterConsensus,
  codeOfMedicalEthics,
  doctorPatientContract,
}: RegistrationUiCheckboxesAreaDoctorItemsProps): JSX.Element => {
  const portalSlug = usePortalSlug();
  const privacyConfig = getCustomPrivacyConfigByPortal(portalSlug);

  const { classes: checkboxesAreaClasses } = useCheckboxesAreaStyles();

  const getError = getFormErrors(errors, touched);

  return (
    <>
      {termsOfUse.enabled && (
        <RegistrationUiCheckboxItem
          label={PrivacyType.TERMS_OF_USE}
          title={i18n.t('components.forms.registrationForm.privacy.rulesOfUse')}
          agreementText={
            <>
              <Trans
                i18n={i18n}
                i18nKey="components.forms.registrationForm.privacy.rulesOfUseAgreeDoctor"
                context={privacyConfig}
              >
                I declare that I have read and accept the
                <a
                  className={checkboxesAreaClasses.documentLink}
                  href={termsOfUse.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of use
                </a>
              </Trans>
              *
            </>
          }
          values={values}
          handleChange={handleChange}
          errorMessage={
            getError(PrivacyType.TERMS_OF_USE) &&
            i18n.t('components.forms.registrationForm.validationAdvice.rulesOfUse')
          }
        />
      )}
      {privacyPolicy.enabled && (
        <PrivacyPolicyCheckbox
          link={privacyPolicy.link}
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
        />
      )}
      {newsletterConsensus.enabled && (
        <RegistrationUiCheckboxItem
          label={PrivacyType.NEWSLETTER_CONSENSUS}
          title={i18n.t('components.forms.registrationForm.privacy.newsletter')}
          agreementText={i18n.t('components.forms.registrationForm.privacy.newsletterAgree')}
          values={values}
          handleChange={handleChange}
        />
      )}
      {doctorPatientContract.enabled && (
        <RegistrationUiCheckboxItem
          label={PrivacyType.DOCTOR_PATIENT_CONTRACT}
          title={i18n.t('components.forms.registrationForm.privacy.contacts')}
          agreementText={
            <>
              <Trans i18n={i18n} i18nKey="components.forms.registrationForm.privacy.contactsAgreeDoctor">
                I declare that I have read the medical
                <a
                  className={checkboxesAreaClasses.documentLink}
                  href={doctorPatientContract.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Doctor/Patient contract
                </a>
                and accept the conditions reported
              </Trans>
              *
            </>
          }
          values={values}
          handleChange={handleChange}
          errorMessage={
            getError(PrivacyType.DOCTOR_PATIENT_CONTRACT) &&
            i18n.t('components.forms.registrationForm.validationAdvice.contacts')
          }
        />
      )}
      {codeOfMedicalEthics.enabled && (
        <RegistrationUiCheckboxItem
          label={PrivacyType.CODE_OF_MEDICAL_ETHICS}
          title={i18n.t('components.forms.registrationForm.privacy.medicalCodeEthics')}
          agreementText={
            <>
              <Trans i18n={i18n} i18nKey="components.forms.registrationForm.privacy.medicalCodeEthicsAgree">
                I declare that I have read the
                <a
                  className={checkboxesAreaClasses.documentLink}
                  href={codeOfMedicalEthics.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Medical Code of Conduct
                </a>
                and I commit myself to respecting its rules
              </Trans>
              *
            </>
          }
          values={values}
          handleChange={handleChange}
          errorMessage={
            getError(PrivacyType.CODE_OF_MEDICAL_ETHICS) &&
            i18n.t('components.forms.registrationForm.validationAdvice.medicalCodeEthics')
          }
        />
      )}
    </>
  );
};
