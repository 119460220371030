import { BREAK_POINTS, fontWeight } from '@lib/react-components';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  modalSubmit: {
    display: 'flex',
    width: 250,
    margin: '0 auto',
  },

  inputWrapper: {
    [theme.breakpoints.up(BREAK_POINTS.MOBILE)]: {
      paddingRight: 10,
    },
  },

  inputFieldWrapper: {
    [theme.breakpoints.down(BREAK_POINTS.MOBILE)]: {
      maxWidth: 'unset',
    },
    maxWidth: 250,
    marginBottom: 36,
    '& .MuiFormHelperText-root.Mui-error': {
      whiteSpace: 'break-spaces',
    },
  },

  infoBoxWrapper: {
    marginTop: 32,
  },

  phoneLink: {
    color: theme.palette.primary.main,
    fontWeight: fontWeight.medium,
    textDecoration: 'none',
    '&:hover, &:visited, &:focus, &:active': {
      textDecoration: 'none',
    },
  },
}));
