export enum GlobalSearchDetailsTabType {
  PRIVATE_DOCTORS = 'doctors',
  AFFILIATED_DOCTORS = 'affiliated-doctors',
  CLINICS = 'clinics',
  FREE_CLINICS = 'free-clinics',
}

export interface IGlobalSearchDetailsUrlParams {
  activeTab: GlobalSearchDetailsTabType;
}

export enum GlobalSearchDetailsFilterFields {
  specificSearch = 'specificSearch',
  serviceTypes = 'serviceTypes',
  specializations = 'specializations',
  videoVisitAvailability = 'videoVisitAvailability',
  affiliationIds = 'affiliationIds',
  patientCanWriteOneToOneChatMessage = 'patientCanWriteOneToOneChatMessage',
}

export enum GlobalSearchDetailsTabFilterFields {
  order = 'order',
}
