import { FlexBox, CircularProgress, MODAL_CLASSES } from '@lib/react-components';
import { useTimer } from '@lib/utils';

import { LoginOrRegisterBlockingModalContent, loginOrRegisterBlockingModalModel } from 'features/Users/components';

interface ILoginOrRegisterModalSwitchToPatientModalProps {
  handleModalClose: VoidFunction;
  isForbiddenAction?: boolean;
  doctorPortalUserId?: string;
}

export const LoginOrRegisterBlockingModalSwitchToPatientModal = ({
  handleModalClose,
  isForbiddenAction,
  doctorPortalUserId,
}: ILoginOrRegisterModalSwitchToPatientModalProps): JSX.Element => {
  const { progressValue, isTimerCompleted } = useTimer(3000);
  loginOrRegisterBlockingModalModel.useLoginOrRegisterBlockingModalSwitchToPatientProfileEffect({
    handleModalClose,
    isForbiddenAction,
    doctorPortalUserId,
    isSwitchProfileTimerCompleted: isTimerCompleted,
  });

  return (
    <LoginOrRegisterBlockingModalContent.ModalWrapper
      hideModalHeader
      sx={{
        [`& .${MODAL_CLASSES.modalContent}`]: {
          padding: '48px',
        },
      }}
    >
      <LoginOrRegisterBlockingModalContent.Title translationPath="features.users.loginOrRegisterBlockingModal.title.switchToPatientProfile" />
      <FlexBox width={1} justifyContent="center" marginTop="30px">
        <CircularProgress value={progressValue} size={60} />
      </FlexBox>
    </LoginOrRegisterBlockingModalContent.ModalWrapper>
  );
};
