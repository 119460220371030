import { OverflownText, useBreakpoints } from '@lib/react-components';
import { Button, Chip, Stack, SxProps, Theme, Typography } from '@mui/material';
import { isNil } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

import { Maybe } from '__generated__/types';
import { i18n } from 'i18n';

interface IDoctorUiSphereOfInterestListProps {
  sphereOfInterest: string[];
  showMoreLinesThreshold?: number;
  sx?: SxProps<Theme>;
}

export const DoctorUiSphereOfInterestList = ({
  sphereOfInterest,
  showMoreLinesThreshold = 0,
  sx,
}: IDoctorUiSphereOfInterestListProps): JSX.Element => {
  const { isMobileView } = useBreakpoints();
  const [isShowingMore, setIsShowingMore] = useState<Maybe<boolean>>(null);

  const chipsContainerRef = useRef<HTMLDivElement>(null);

  const CHIP_GAP = 1;

  useEffect(() => {
    if (chipsContainerRef.current && showMoreLinesThreshold) {
      const containerBoundingRect = chipsContainerRef.current.getBoundingClientRect();
      const itemBoundingRect = chipsContainerRef.current.children[0].getBoundingClientRect();
      const linesThresholdHeight =
        itemBoundingRect.height * showMoreLinesThreshold + (showMoreLinesThreshold - 1) * 8 * CHIP_GAP;

      if (containerBoundingRect.height > linesThresholdHeight) {
        setIsShowingMore(false);
      }
    }
  }, [showMoreLinesThreshold, chipsContainerRef.current]);

  return (
    <>
      <Stack
        ref={chipsContainerRef}
        direction="row"
        flexWrap="wrap"
        gap={CHIP_GAP}
        minWidth={0}
        width={1}
        sx={sx}
        overflow="hidden"
        height={isShowingMore || isNil(isShowingMore) ? 'auto' : `${showMoreLinesThreshold}px`}
      >
        {sphereOfInterest.map((interest: string, index: number) => (
          <Chip
            key={`${interest}-${index}`}
            sx={{
              backgroundColor: 'background.default',
              margin: 0,
              height: 'unset',
            }}
            label={
              <OverflownText lines={isMobileView ? 2 : 1} variant="info">
                {interest}
              </OverflownText>
            }
          />
        ))}
      </Stack>
      {!isNil(isShowingMore) && (
        <Stack direction="row">
          <Button
            size="small"
            variant="link"
            onClick={(): void => {
              setIsShowingMore(oldValue => !oldValue);
            }}
            sx={{ padding: 0, minWidth: 'auto', minHeight: 'auto' }}
          >
            <Typography variant="bodyLink3">
              {i18n.t(isShowingMore ? 'general.buttons.showLess' : 'general.buttons.showAll')}
            </Typography>
          </Button>
        </Stack>
      )}
    </>
  );
};
