import { Call as OriginalCall, Device as OriginalDevice } from '@twilio/voice-sdk';

interface getTwilioSdkReturn {
  Device: typeof OriginalDevice;
  Call: typeof OriginalCall;
}

export const getTwilioSdk = async (): Promise<getTwilioSdkReturn> => {
  // @ts-expect-error workaround https://github.com/twilio/twilio-voice.js/issues/55
  await import('@twilio/voice-sdk/dist/twilio.min.js');
  // @ts-expect-error workaround https://github.com/twilio/twilio-voice.js/issues/55
  const Device = window.Twilio.Device as typeof OriginalDevice;
  // @ts-expect-error workaround https://github.com/twilio/twilio-voice.js/issues/55
  const Call = window.Twilio.Call as typeof OriginalCall;

  return { Device, Call };
};
