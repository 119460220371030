import { PersistedDataKeys } from '@lib/core';

import { ProfileListItem, useUserModelStore } from './useUserModelStore';

import { UserTitle, ProfileType, Maybe, AllowDenyPolicyType } from '__generated__/types';
import { useUserApiUserRolesLazyQuery } from 'features/Users/api';
import { persistedData } from 'lib/helpers/persistedData';

interface IProfileItem {
  profileType: ProfileType;
  isBlocked?: boolean;
}

const isProfileValid = (profile: IProfileItem): boolean => !profile.isBlocked;

const rateProfile = ({ profileType }: IProfileItem): number => {
  if (profileType === ProfileType.DOCTOR) return 30;
  if (profileType === ProfileType.NON_DOCTOR) return 20;
  if (profileType === ProfileType.PATIENT) return 10;

  return 0;
};

const sortProfiles = (profileA: IProfileItem, profileB: IProfileItem): number =>
  rateProfile(profileA) - rateProfile(profileB);

export const useUserModelActiveProfile = (): (() => Promise<{
  profileType?: Maybe<ProfileType>;
  isBlocked?: Maybe<boolean>;
}>) => {
  const { setProfileList } = useUserModelStore();
  const [getUserRoles] = useUserApiUserRolesLazyQuery();

  return async () => {
    const { data: { userRoles } = {} } = await getUserRoles();

    const doctorsIntention =
      userRoles?.registrationIntentions?.reduce<ProfileListItem[]>((acc, intention) => {
        if (intention?.profileType === ProfileType.DOCTOR) {
          acc.push({
            id: intention.id,
            profileType: intention.profileType,
            portal: { id: '', logoUrl: { big: '', small: '', normal: '' }, name: '', slug: '' },
            portalUser: { id: '' },
            institution: intention.institution,
            user: { ...intention.user, createdAt: intention.createdAt, updatedAt: intention.createdAt },
            doctor: {
              id: '',
              specializations: [],
              defaultPatientWritePolicy: AllowDenyPolicyType.DENY,
              patientWriteButtonVisibilityPolicy: AllowDenyPolicyType.DENY,
              titleCode: UserTitle.NO_VALUE,
              slug: '',
              isInfoManagementHasBeenDelegatedToSecretaries: false,
              detailsUrls: { internal: '' },
            },
            nonDoctor: null,
            patient: null,
            isBlocked: false,
            hasPostRegister: true,
          });
        }

        return acc;
      }, []) || [];

    const userProfileList = userRoles?.profiles.filter(isProfileValid).sort(sortProfiles).reverse() ?? [];
    const profileListWithIntentions = [...doctorsIntention, ...userProfileList];

    if (profileListWithIntentions.length > 0) {
      setProfileList(profileListWithIntentions);
    }

    const activeProfileId = persistedData.get<string>(PersistedDataKeys.ActiveProfileId);
    const priorProfileType = persistedData.get<string>(PersistedDataKeys.PriorProfileType);
    const hasValidPriorProfileType =
      !!priorProfileType && profileListWithIntentions.some(({ profileType }) => profileType === priorProfileType);

    if (!hasValidPriorProfileType) persistedData.remove(PersistedDataKeys.PriorProfileType);

    const activeProfile = profileListWithIntentions.find(profile => {
      if (activeProfileId) {
        const isLocalStorageActiveProfile = profile.id === activeProfileId;

        return hasValidPriorProfileType
          ? isLocalStorageActiveProfile && profile.profileType === priorProfileType
          : isLocalStorageActiveProfile;
      }

      return hasValidPriorProfileType ? profile.profileType === priorProfileType : true;
    });

    return activeProfile
      ? { profileType: activeProfile.profileType, isBlocked: activeProfile.isBlocked }
      : { profileType: null, isBlocked: null };
  };
};
