import { Modal, useBreakpoints } from '@lib/react-components';
import { Button, Stack } from '@mui/material';

import { useStyles } from './styles';
import { ISettingsModalWrapperProps } from './types';

import { i18n } from 'i18n';

export const SettingsModalWrapper = ({
  title,
  secondTitle,
  saveButtonTitle,
  isDraggable,
  isCancelButtonHidden,
  onCancel,
  size,
  onSave,
  isSaveButtonDisabled,
  children,
}: ISettingsModalWrapperProps): JSX.Element => {
  const { classes: localClasses } = useStyles();
  const { isMobileView } = useBreakpoints();

  return (
    <Modal
      title={title}
      secondTitle={secondTitle}
      onCancel={onCancel}
      size={size}
      isDraggable={isDraggable}
      classes={{
        content: localClasses.content,
        contentBox: localClasses.contentBox,
        title: localClasses.title,
        secondTitle: localClasses.secondTitle,
        titleBox: localClasses.titleBox,
        loader: localClasses.loader,
      }}
      footer={
        <Stack direction={isMobileView ? 'column' : 'row'} gap={2}>
          <Button disabled={isSaveButtonDisabled} onClick={(): void => onSave()}>
            {saveButtonTitle || i18n.t('general.buttons.confirm')}
          </Button>
          {!isCancelButtonHidden && (
            <Button variant="secondary" onClick={onCancel}>
              {i18n.t('general.buttons.cancel')}
            </Button>
          )}
        </Stack>
      }
    >
      {children && children}
    </Modal>
  );
};
