import { ActionType } from '../../const';

import { IActionVariables, IUseAffiliationsActionsReturn, IUseAffiliationsActionsProps } from './types';

import { DoctorAffiliationsToPromptDocument } from 'features/Affiliations/graphql/__generated__/DoctorAffiliationsToPrompt.query';
import { useDoctorJoinAffiliation } from 'features/Affiliations/graphql/__generated__/DoctorJoinAffiliation.mutation';
import { useDoctorRejectAffiliation } from 'features/Affiliations/graphql/__generated__/DoctorRejectAffiliation.mutation';

export const useAffiliationsActionHandler = ({
  onActionSuccess,
}: IUseAffiliationsActionsProps): IUseAffiliationsActionsReturn => {
  const [joinAffiliation] = useDoctorJoinAffiliation({ onCompleted: onActionSuccess });
  const [rejectAffiliation] = useDoctorRejectAffiliation({ onCompleted: onActionSuccess });

  const onActionButtonClick = async (affiliationId: string, actionType: ActionType): Promise<void> => {
    const variables: IActionVariables = { affiliationId };
    const refetchQueries = [DoctorAffiliationsToPromptDocument];

    if (actionType === ActionType.Accept) {
      await joinAffiliation({ variables, refetchQueries });
    } else {
      await rejectAffiliation({ variables, refetchQueries });
    }
  };

  return { onActionButtonClick };
};
