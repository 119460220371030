import { ProfileListInstitutionUser } from '@lib/features-bll';

import { Maybe, ProfileType } from '__generated__/types';
import { useUserModelStore } from 'features/Users/model';

interface IUseLoginOrRegisterBlockingModalPatientProfileReturn {
  patientProfile?: Maybe<ProfileListInstitutionUser>;
}

export const useLoginOrRegisterBlockingModalPatientProfile =
  (): IUseLoginOrRegisterBlockingModalPatientProfileReturn => {
    const { profileList } = useUserModelStore();

    const patientProfile = profileList.find(({ profileType }) => profileType === ProfileType.PATIENT);

    return { patientProfile };
  };
