import { PasswordIconButton, PasswordMeterBar } from '@lib/react-components';
import { DASH_EMPTY_VALUE } from '@lib/utils';
import { Box, FormHelperText, TextField, TextFieldProps } from '@mui/material';
import clsx from 'clsx';
import { ChangeEvent, ClipboardEvent, memo, useCallback, useState } from 'react';

import { useStyles as localStyles } from './InputStyles';
import { IInputProps } from './types';

import { InputPlaceholders, InputTypes } from 'lib/constants/input';
import { bpMask, dateMask, otpMask } from 'lib/helpers/masks';

/**
 * @deprecated(reason: "Use TextField from @mui/material")
 */
const InputComponent = ({
  errorText,
  helperText,
  label,
  name,
  onBlur,
  onFocus,
  onChange,
  type,
  value,
  defaultValue,
  disabled,
  className,
  placeholder,
  classes,
  autoFocus,
  multiline,
  maxRows,
  guide,
  disableAutocomplete,
  disablePastedBufferValue,
  inputNumberProps,
  'data-cy': dataCy,
  withoutPlaceholder,
  InputProps,
  onKeyDown,
  focused,
  passwordScore,
}: IInputProps): JSX.Element => {
  const [isPasswordHidden, setPasswordHidden] = useState(true);
  const togglePasswordVisibility = (): void => setPasswordHidden(state => !state);

  const isError = errorText ? !!errorText.length : false;
  const isDate = type === InputTypes.date;
  const isPassword = type === InputTypes.password;
  const isOtp = type === InputTypes.otp;
  const isPhoneNumber = type === InputTypes.phoneNumber;
  const isEmail = type === InputTypes.email;
  const isBp = name === InputTypes.bp;
  const isNumber = type === InputTypes.number;

  const { classes: localClasses } = localStyles();

  const BaseInputProps = {
    classes,
    autoComplete: disableAutocomplete ? 'off' : 'on',
    'data-cy': dataCy,
    ...InputProps,
  };

  const MaskedInputProps = {
    inputProps: {
      mask: (isDate && dateMask) || (isOtp && otpMask) || (isBp && bpMask) || undefined,
      guide,
    },
    ...BaseInputProps,
  };

  const PasswordInputProps = {
    endAdornment: (
      <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        {passwordScore !== undefined && <PasswordMeterBar score={passwordScore} />}
        <PasswordIconButton
          isPasswordHidden={isPasswordHidden}
          togglePasswordVisibilityHandler={togglePasswordVisibility}
        />
      </Box>
    ),
    ...BaseInputProps,
  };

  const numberInputOnChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { value: targetValue } = event.target;

    if (onChange && (!targetValue || targetValue.match(/^([+-]?\d+\.?\d*)$/) || targetValue === DASH_EMPTY_VALUE)) {
      onChange(event);
    }
  }, []);

  const numberInputProps: TextFieldProps['InputProps'] = {
    inputProps: {
      min: inputNumberProps?.min,
      max: inputNumberProps?.max,
      step: inputNumberProps?.step,
    },
    ...BaseInputProps,
  };

  const disableCutCopyPastHandler = (event: ClipboardEvent<HTMLInputElement>): void => {
    if (disablePastedBufferValue) {
      event.preventDefault();
    }
  };

  if (!label && disabled) {
    return (
      <TextField
        type="text"
        label={label}
        value={value}
        name={name}
        disabled={disabled}
        variant="filled"
        className={clsx(className, isError && localClasses.validationFailed)}
        InputProps={BaseInputProps}
        autoFocus={autoFocus}
        focused={focused}
      />
    );
  }

  if (isPassword) {
    return (
      <>
        <TextField
          label={label}
          helperText={errorText}
          FormHelperTextProps={{ error: isError }}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          name={name}
          type={isPasswordHidden ? 'password' : 'text'}
          disabled={disabled}
          variant="standard"
          className={clsx(className, isError && localClasses.validationFailed)}
          placeholder={placeholder}
          InputProps={PasswordInputProps}
          autoFocus={autoFocus}
          focused={focused}
          onPaste={disableCutCopyPastHandler}
        />
        {helperText && <FormHelperText className={clsx(className)}>{helperText}</FormHelperText>}
      </>
    );
  }

  if (isDate || isOtp || isPhoneNumber || isBp) {
    return (
      <>
        <TextField
          label={label}
          helperText={errorText}
          FormHelperTextProps={{ error: isError }}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          name={name}
          type="text"
          disabled={disabled}
          variant="standard"
          className={clsx(className, isError && localClasses.validationFailed, isDate && 'input-date')}
          placeholder={
            (isDate && InputPlaceholders.date) ||
            (isOtp && InputPlaceholders.otp) ||
            (isEmail && InputPlaceholders.email) ||
            (!withoutPlaceholder && isPhoneNumber && InputPlaceholders.phoneNumber) ||
            (isBp && InputPlaceholders.bp) ||
            placeholder
          }
          InputProps={MaskedInputProps}
          autoFocus={autoFocus}
          focused={focused}
          onPaste={disableCutCopyPastHandler}
        />
        {helperText && <FormHelperText className={clsx(className)}>{helperText}</FormHelperText>}
      </>
    );
  }

  if (isNumber) {
    return (
      <>
        <TextField
          label={label}
          helperText={errorText}
          FormHelperTextProps={{ error: isError }}
          value={value}
          defaultValue={defaultValue}
          onChange={numberInputOnChange}
          onBlur={onBlur}
          onFocus={onFocus}
          name={name}
          type="text"
          disabled={disabled}
          variant="standard"
          className={clsx(className, localClasses.numberInput, isError && localClasses.validationFailed)}
          InputProps={numberInputProps}
          autoFocus={autoFocus}
          focused={focused}
          onPaste={disableCutCopyPastHandler}
        />
        {helperText && <FormHelperText className={clsx(className)}>{helperText}</FormHelperText>}
      </>
    );
  }

  if (disabled) {
    return (
      <TextField
        type="text"
        label={label}
        value={value}
        name={name}
        disabled={disabled}
        defaultValue={defaultValue}
        variant="filled"
        className={clsx(className, isError && localClasses.validationFailed)}
        InputProps={BaseInputProps}
        autoFocus={autoFocus}
        focused={focused}
      />
    );
  }

  return (
    <>
      <TextField
        label={label}
        helperText={errorText}
        FormHelperTextProps={{ error: isError }}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        type="text"
        disabled={disabled}
        variant="standard"
        className={clsx(className, isError && localClasses.validationFailed)}
        placeholder={
          placeholder ||
          (isEmail && InputPlaceholders.email) ||
          (!withoutPlaceholder && isPhoneNumber && InputPlaceholders.phoneNumber) ||
          undefined
        }
        InputProps={BaseInputProps}
        autoFocus={autoFocus}
        focused={focused}
        multiline={multiline}
        maxRows={maxRows}
        onPaste={disableCutCopyPastHandler}
        onKeyDown={onKeyDown}
      />
      {helperText && <FormHelperText className={clsx(className)}>{helperText}</FormHelperText>}
    </>
  );
};

export default memo(InputComponent);
