import { useUpdateNoticeUnreadList } from '../api';
import { UpdateNotice } from '../types';

import { Maybe } from '__generated__/types';
import { useUserModelStore } from 'features/Users/model';

interface IUseUpdateNoticeModelUnreadListReturn {
  unreadNoticeToShow: Maybe<UpdateNotice>;
  unreadNoticesList: UpdateNotice[];
  loading: boolean;
}

type UseUpdateNoticeModelUnreadList = () => IUseUpdateNoticeModelUnreadListReturn;

export const useUpdateNoticeModelUnreadList: UseUpdateNoticeModelUnreadList = () => {
  const { activeProfile } = useUserModelStore();
  const { data, loading } = useUpdateNoticeUnreadList({ skip: activeProfile?.hasPostRegister });

  return {
    unreadNoticeToShow: data?.unreadUpdateNoticeList?.[0] ?? null,
    unreadNoticesList: data?.unreadUpdateNoticeList ?? [],
    loading,
  };
};
