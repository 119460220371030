export enum ThreadMessageType {
  LOG = 'LOG',
  MESSAGE = 'MESSAGE',
  MAIL = 'MAIL',
  REMINDER_CREATION = 'REMINDER_CREATION',
  REQUEST_PROPOSAL = 'REQUEST_PROPOSAL',
  REQUEST = 'REQUEST',
  VOIP_CALL = 'VOIP_CALL',
  ASSISTED_DOCTOR_SEARCH_FORM = 'ASSISTED_DOCTOR_SEARCH_FORM',
}
