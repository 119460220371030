import { SERVICE_TYPE_LABELS, SPECIALIZATIONS_TRANSLATIONS } from '@lib/features-bll';
import { convertOptionsToObject, createFilterChipItemsFromFilters, IFilterChipItem } from '@lib/react-components';
import { useMemo } from 'react';

import { GlobalSearchDetailsFilterFields, globalSearchDetailsConfig } from '../config';

import { globalSearchDetailsModel } from 'features/GlobalSearchDetails/model';

export const useGlobalSearchDetailsFilterChipItems = (): IFilterChipItem[] => {
  const {
    filters: { serviceTypes, specializations, specificSearch, videoVisitAvailability },
  } = globalSearchDetailsModel.useGlobalSearchFilters();

  return useMemo(
    () =>
      createFilterChipItemsFromFilters(
        {
          [GlobalSearchDetailsFilterFields.serviceTypes]: serviceTypes,
          [GlobalSearchDetailsFilterFields.specializations]: specializations,
          [GlobalSearchDetailsFilterFields.specificSearch]: specificSearch,
          [GlobalSearchDetailsFilterFields.videoVisitAvailability]: videoVisitAvailability,
        },
        {
          ...convertOptionsToObject(SPECIALIZATIONS_TRANSLATIONS),
          ...SERVICE_TYPE_LABELS,
          ...convertOptionsToObject(globalSearchDetailsConfig.GLOBAL_SEARCH_DETAILS_VIDEO_VISIT_AVAILABILITY_ITEMS),
        }
      ),
    [serviceTypes, specializations, specificSearch, videoVisitAvailability]
  );
};
