import { useValidateCurrentResidenceAddress, ValidateCurrentResidenceAddressDocument } from '@lib/features-bll';
import { useModalState } from '@lib/react-components';

import { useUserModelStore } from 'features/Users/model';
import { client } from 'graphql/apollo';

interface IUseResidenceAddressModalStateReturn {
  isUpdateResidenceAddressModalAllowed: boolean;
  isResidenceAddressLoading: boolean;
  isUpdateResidenceAddressModalOpened: boolean;
  openUpdateResidenceAddressModal: VoidFunction;
  closeUpdateResidenceAddressModal: VoidFunction;
}

export const useRootModelResidenceAddressModalState = (): IUseResidenceAddressModalStateReturn => {
  const { activeProfile } = useUserModelStore();

  const [isUpdateResidenceAddressModalOpened, openUpdateResidenceAddressModal, closeUpdateResidenceAddressModal] =
    useModalState();
  const { data: validateResidenceAddressData, loading } = useValidateCurrentResidenceAddress({
    fetchPolicy: 'cache-first',
    skip: activeProfile?.hasPostRegister,
  });
  const isUpdateResidenceAddressModalAllowed =
    (validateResidenceAddressData && !validateResidenceAddressData?.validateCurrentResidenceAddress) ||
    !validateResidenceAddressData;

  const closeModal = async (): Promise<void> => {
    await client.refetchQueries({
      include: [ValidateCurrentResidenceAddressDocument],
    });
    closeUpdateResidenceAddressModal();
  };

  return {
    isUpdateResidenceAddressModalAllowed,
    isUpdateResidenceAddressModalOpened,
    isResidenceAddressLoading: loading,
    openUpdateResidenceAddressModal,
    closeUpdateResidenceAddressModal: closeModal,
  };
};
