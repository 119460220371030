import { gql } from '@apollo/client';

import { INFO_THREAD_MESSAGE_FRAGMENT, INFO_THREAD_WITH_MESSAGES_FRAGMENT } from 'graphql/fragments/info';

const ADD_INFO_THREAD_MESSAGE = gql`
  mutation ($infoThread: String, $text: String, $attachments: [String!]) {
    addInfoThreadMessage(infoThread: $infoThread, text: $text, attachments: $attachments) {
      ...InfoThreadMessageFragment
    }
  }
  ${INFO_THREAD_MESSAGE_FRAGMENT}
`;

const UPDATE_INFO_THREAD_STATUS = gql`
  mutation ($infoThread: String!, $status: InfoThreadStatus!) {
    updateInfoThreadStatus(infoThread: $infoThread, status: $status) {
      ...InfoThreadWithMessagesFragment
    }
  }
  ${INFO_THREAD_WITH_MESSAGES_FRAGMENT}
`;

const UPDATE_INFO_MESSAGE_READ_STATUS = gql`
  mutation ($status: Boolean, $infoMessageId: String!) {
    updateInfoMessageReadStatus(status: $status, infoMessageId: $infoMessageId) {
      id
      isRead
    }
  }
`;

export default {
  ADD_INFO_THREAD_MESSAGE,
  UPDATE_INFO_THREAD_STATUS,
  UPDATE_INFO_MESSAGE_READ_STATUS,
};
