// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).

type EnsureMediaPermissions = () => Promise<void>;

export const ensureMediaPermissions: EnsureMediaPermissions = async () => {
  const enumeratedDevices = await navigator.mediaDevices.enumerateDevices();
  const shouldAskForMediaPermissions = await enumeratedDevices.every(device => !(device.deviceId && device.label));

  if (shouldAskForMediaPermissions) {
    const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaStream.getTracks().forEach(({ stop }) => stop());
  }
};
