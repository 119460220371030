import { FiltersIcon, MaterialSymbolsIcon } from '@lib/icons';
import {
  defaultBorderRadius,
  FlexBox,
  getDefaultCardBoxShadow,
  useModalState,
  zIndexValues,
} from '@lib/react-components';
import { Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { globalSearchDetailsModel } from '../model';

import { GlobalSearchDetails } from 'features/GlobalSearchDetails';
import { i18n } from 'i18n';

interface IGlobalSearchDetailsFiltersSectionMobileProps {
  children: ReactNode;
}

export const GlobalSearchDetailsFiltersSectionMobile = ({
  children,
}: IGlobalSearchDetailsFiltersSectionMobileProps): JSX.Element => {
  const { palette } = useTheme();
  const [isFiltersModalOpened, openFiltersModal, closeFiltersModal] = useModalState();
  const filterChipItems = globalSearchDetailsModel.useGlobalSearchDetailsFilterChipItems();

  return (
    <>
      <Stack
        sx={{
          gap: '12px',
          padding: '16px 20px',
          backgroundColor: palette.surface.generic,
          boxShadow: getDefaultCardBoxShadow(palette.shadow.main),
          zIndex: zIndexValues.defaultValue,
        }}
      >
        {children}
        <FlexBox justifyContent="space-between" alignItems="center">
          <Typography variant="body3Medium">{i18n.t('features.search.filterSection.list')}</Typography>
          <FlexBox
            gap="2px"
            alignItems="center"
            bgcolor={palette.primary.main}
            borderRadius={`${defaultBorderRadius}px`}
            p="7px"
          >
            <MaterialSymbolsIcon
              onClick={openFiltersModal}
              icon={<FiltersIcon fontWeight="300" fill={palette.white.main} />}
            />
            {!!filterChipItems.length && (
              <Typography variant="body3Medium" color={palette.white.main}>
                {filterChipItems.length}
              </Typography>
            )}
          </FlexBox>
        </FlexBox>
      </Stack>
      {isFiltersModalOpened && <GlobalSearchDetails.FiltersModal closeModal={closeFiltersModal} />}
    </>
  );
};
