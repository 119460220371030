import { StatusCard } from '@lib/react-components';

import { LoginOrRegisterBlockingModalContent } from 'features/Users/components';
import { i18n } from 'i18n';

interface ILoginOrRegisterBlockingModalAuthorizedUserWithPatientModalProps {
  handleOpenSwitchProfile: VoidFunction;
  onCancel: VoidFunction;
}

export const LoginOrRegisterBlockingModalAuthorizedUserWithPatientModal = ({
  handleOpenSwitchProfile,
  onCancel,
}: ILoginOrRegisterBlockingModalAuthorizedUserWithPatientModalProps): JSX.Element => (
  <LoginOrRegisterBlockingModalContent.ModalWrapper
    onCancel={onCancel}
    footer={
      <>
        <LoginOrRegisterBlockingModalContent.ButtonPrimary
          onClick={handleOpenSwitchProfile}
          label={i18n.t('features.users.loginOrRegisterBlockingModal.button.loginAsPatient')}
        />
        <LoginOrRegisterBlockingModalContent.ButtonSecondary
          onClick={onCancel}
          label={i18n.t('general.buttons.cancel')}
        />
      </>
    }
  >
    <LoginOrRegisterBlockingModalContent.WarningIcon />
    <LoginOrRegisterBlockingModalContent.Title translationPath="features.users.loginOrRegisterBlockingModal.title.onlyForPatients" />
    <StatusCard
      sx={{ textAlign: 'start', marginTop: '20px' }}
      text={i18n.t('features.users.loginOrRegisterBlockingModal.infoText.looseCurrentSessionData')}
    />
  </LoginOrRegisterBlockingModalContent.ModalWrapper>
);
