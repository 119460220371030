import { SelectItem } from '@lib/react-components';
import { useMemo } from 'react';

import { usePatientJoinedAffiliations } from 'features/Affiliations/graphql/__generated__/PatientJoinedAffiliations.query';
import { useGlobalSearchDetailsTabsConfiguration } from 'features/GlobalSearchDetails';

interface IUseGlobalSearchDetailsAffiliationIdsFilterDataReturn {
  affiliationIdsFilterItems: SelectItem<string>[];
  withAffiliationIdsFilter: boolean;
}

export const useGlobalSearchDetailsAffiliationIdsFilterData =
  (): IUseGlobalSearchDetailsAffiliationIdsFilterDataReturn => {
    const { isAffiliatedDoctorActiveTab } = useGlobalSearchDetailsTabsConfiguration();
    const { data: patientAffiliationsData } = usePatientJoinedAffiliations({
      fetchPolicy: 'cache-first',
      skip: !isAffiliatedDoctorActiveTab,
    });
    const affiliationIdsFilterItems = useMemo(
      () =>
        (patientAffiliationsData?.patientJoinedAffiliations.items || []).map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      [patientAffiliationsData?.patientJoinedAffiliations.items]
    );

    return {
      affiliationIdsFilterItems,
      withAffiliationIdsFilter: !!affiliationIdsFilterItems?.length && isAffiliatedDoctorActiveTab,
    };
  };
