import * as Types from '__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@lib/core';
const defaultOptions = {} as const;
export type UpdateNoticeApiMarkAsReadVariables = Types.Exact<{
  updateNoticeId: Types.Scalars['String']['input'];
}>;


export type UpdateNoticeApiMarkAsRead = { __typename?: 'Mutation', markUpdateNoticeAsRead: boolean };


export const UpdateNoticeApiMarkAsReadDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateNoticeApiMarkAsRead"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"updateNoticeId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"markUpdateNoticeAsRead"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"updateNoticeId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"updateNoticeId"}}}]}]}}]} as unknown as DocumentNode;
export type UpdateNoticeApiMarkAsReadMutationFn = Apollo.MutationFunction<UpdateNoticeApiMarkAsRead, UpdateNoticeApiMarkAsReadVariables>;

/**
 * __useUpdateNoticeApiMarkAsRead__
 *
 * To run a mutation, you first call `useUpdateNoticeApiMarkAsRead` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoticeApiMarkAsRead` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoticeApiMarkAsRead, { data, loading, error }] = useUpdateNoticeApiMarkAsRead({
 *   variables: {
 *      updateNoticeId: // value for 'updateNoticeId'
 *   },
 * });
 */
export function useUpdateNoticeApiMarkAsRead(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNoticeApiMarkAsRead, UpdateNoticeApiMarkAsReadVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateNoticeApiMarkAsRead, UpdateNoticeApiMarkAsReadVariables>(UpdateNoticeApiMarkAsReadDocument, options);
      }
export type UpdateNoticeApiMarkAsReadHookResult = ReturnType<typeof useUpdateNoticeApiMarkAsRead>;
export type UpdateNoticeApiMarkAsReadMutationResult = Apollo.MutationResult<UpdateNoticeApiMarkAsRead>;
export type UpdateNoticeApiMarkAsReadMutationOptions = Apollo.BaseMutationOptions<UpdateNoticeApiMarkAsRead, UpdateNoticeApiMarkAsReadVariables>;