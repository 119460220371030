import { Select, useBreakpoints } from '@lib/react-components';
import { useTheme } from '@mui/material';

import { GlobalSearchDetailsFilterFields } from '../config';
import { globalSearchDetailsModel } from '../model';

import { GlobalSearchDetails } from 'features/GlobalSearchDetails/ui';
import { i18n } from 'i18n';

export const GlobalSearchDetailsTabAffiliationFilter = (): JSX.Element => {
  const { isDesktopView } = useBreakpoints();
  const { typography } = useTheme();
  const { filters, setFilter } = globalSearchDetailsModel.useGlobalSearchFilters();
  const { affiliationIdsFilterItems } = globalSearchDetailsModel.useGlobalSearchDetailsAffiliationIdsFilterData();

  return (
    <GlobalSearchDetails.TabRefineSearchWrapper>
      <Select
        sx={{
          '&.MuiInputBase-root': { height: isDesktopView ? '38px' : '54px', width: isDesktopView ? '190px' : '100%' },
          '&.MuiInputBase-root .MuiTypography-root': { ...(!isDesktopView && typography.h8) },
        }}
        name={GlobalSearchDetailsFilterFields.affiliationIds}
        value={filters.affiliationIds || ''}
        placeholder={i18n.t('general.affiliation')}
        items={affiliationIdsFilterItems}
        onChange={({ target: { name, value } }): void => setFilter(name, value)}
        hideValue
        displayEmpty
        multiple
      />
    </GlobalSearchDetails.TabRefineSearchWrapper>
  );
};
