import { PersistedDataKeys } from '@lib/core';
import { usePortalSettings } from '@lib/features-bll';
import { Loader } from '@lib/react-components';
import { ReactNode } from 'react';

import { usePortal, usePortalSlug } from '../hooks';

import { persistedData } from 'lib/helpers/persistedData';

interface IPortalConfigProviderProps {
  children: ReactNode;
}

export const PortalConfigProvider = ({ children }: IPortalConfigProviderProps): JSX.Element => {
  const { portal, setPortal } = usePortal();
  const portalSlug = usePortalSlug();

  usePortalSettings({
    variables: {
      slug: portalSlug,
    },
    skip: !portalSlug,
    onCompleted: ({ portalSettings }) => {
      setPortal(portalSettings);
      persistedData.set(PersistedDataKeys.PortalId, portalSettings.id);
    },
    fetchPolicy: 'cache-first',
  });

  return (
    <Loader loading={!portal} fullScreen>
      {children}
    </Loader>
  );
};
