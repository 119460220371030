import { ActionType } from 'typesafe-actions';

import * as actions from './actionCreators';

import { Maybe } from '__generated__/types';
import { IError } from 'authorization/types';
import { IIntramedFile } from 'graphql/types/intramed';

export interface IState {
  intramedFilesList: Maybe<IIntramedFile[]>;
  attachmentsLoading: boolean;
  error: Maybe<IError>;
  requestLoading: boolean;
}

export enum ActionTypeList {
  SET_ATTACHMENTS_LOADING = '@@intramed/SET_ATTACHMENTS_LOADING',

  SET_REQUEST_LOADING = '@@intramed/SET_REQUEST_LOADING',

  ADD_INTRAMED_FILE = '@@intramed/ADD_INTRAMED_FILE',
  ADD_INTRAMED_FILES = '@@intramed/ADD_INTRAMED_FILES',
  REMOVE_INTRAMED_FILES = '@@intramed/REMOVE_INTRAMED_FILES',
  REMOVE_INTRAMED_FILE = '@@intramed/REMOVE_INTRAMED_FILE',
}

export type Action = ActionType<typeof actions>;

export const initialState: IState = {
  intramedFilesList: null,
  attachmentsLoading: false,
  error: null,
  requestLoading: false,
};
