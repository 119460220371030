import { Portals } from '@lib/features-bll';

import { usePortalSlug } from 'features/Portal';

interface IUseCheckIfPortalAllowed {
  checkIfPortalAllowed: (allowedPortals: Portals[]) => boolean;
}

export const useCheckIfPortalAllowed = (): IUseCheckIfPortalAllowed => {
  const portalSlug = usePortalSlug();

  const checkIfPortalAllowed = (allowedPortals: Portals[]): boolean => allowedPortals.includes(portalSlug);

  return { checkIfPortalAllowed };
};
