import { Modal, useBreakpoints } from '@lib/react-components';

import { Maybe, ProfileType } from '__generated__/types';
import { SetupNativeAppSection } from 'features/Users/components';

interface IPatientAppInitializationModalProps {
  onCancel: VoidFunction;
  onIdentityVerificationInitialized?: VoidFunction;
  isShownOnLogin?: boolean;
  loading?: boolean;
}

export const PatientAppInitializationModal = ({
  onCancel,
  isShownOnLogin,
}: IPatientAppInitializationModalProps): Maybe<JSX.Element> => {
  const { isMobileView } = useBreakpoints();

  return (
    <Modal onCancel={onCancel} isDraggable fullscreenOnMobile={false}>
      <SetupNativeAppSection
        isShownOnLogin={isShownOnLogin}
        hideSplash={isMobileView}
        profileType={ProfileType.PATIENT}
      />
    </Modal>
  );
};
