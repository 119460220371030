import { PersistedDataKeys } from '@lib/core';

import { ProfileType } from '__generated__/types';
import { persistedData } from 'lib/helpers/persistedData';

type UseUserModelSetPriorProfileType = () => (profileType: ProfileType) => void;

export const useUserModelSetPriorProfileType: UseUserModelSetPriorProfileType = () => {
  const priorProfileType = persistedData.get<string>(PersistedDataKeys.PriorProfileType);

  return profileType => {
    if (priorProfileType !== profileType) persistedData.set(PersistedDataKeys.PriorProfileType, profileType);
  };
};
