import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

import { IUseVerifyUserModal, IVerifyUserModal } from './types';

import { getFormErrors } from 'lib/hooks/formErrorsHook';
import { IVerificationCodeValues, useVerificationCodeValidation } from 'lib/hooks/useVerificationCodeValidation.hook';

export const useVerifyUserModal = ({
  setModalOpened,
  callVerificationHandler,
  submitVerificationHandler,
}: Pick<
  IVerifyUserModal,
  'setModalOpened' | 'callVerificationHandler' | 'submitVerificationHandler'
>): IUseVerifyUserModal => {
  const [isCodeSent, setCodeSent] = useState<boolean>(false);
  const { verificationCodeValidationSchema } = useVerificationCodeValidation();

  const { handleBlur, handleChange, values, errors, touched, handleSubmit } = useFormik<IVerificationCodeValues>({
    initialValues: {
      verificationCode: '',
    },

    validationSchema: verificationCodeValidationSchema,

    onSubmit: ({ verificationCode }) => {
      submitVerificationHandler(verificationCode, (): void => setModalOpened(false));
    },
  });

  const getError = getFormErrors(errors, touched);

  useEffect(() => {
    callVerificationHandler();
  }, []);

  const resendCode = (): void => {
    callVerificationHandler();

    setCodeSent(true);
  };

  return {
    values,
    getError,
    isCodeSent,
    resendCode,
    handleBlur,
    handleChange,
    handleSubmit,
  };
};
